import { httpClient } from 'td-api'
import Vue from 'vue'
import App from './App.vue'

import '@tada/colors/dist/index.css'
import './styles/_colors.scss'
import './styles/_variables.scss'
import './styles/_theme-helpers.scss'
import './styles/default.scss'
import './styles/_quasar-override.scss'
import 'td-ui/dist/style.css'

import './quasar'
import router from '@/router'
import store, { uiSettingsStore, teamsStore } from '@/store'
import i18n from '@/i18n'
import FaviconManager from '@/favicon'
import attachDirectives from '@/directives'
import { initialHook } from '@/setupHelpers'
import { setup as metrikSetup } from '@/utils/metrik'
import { init as initRecaptcha } from './recaptcha'

const init = async (): Promise<void> => {
  httpClient.configure({
    withCredentials: true,
    headers: {
      lang: uiSettingsStore.getters.language || i18n.locale || 'ru',
    },
  }, error => {
    if (error.response?.status === 402) {
      router.push({
        name: 'Blocked',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
        },
      })
    }
  })

  metrikSetup()
  FaviconManager.init()
  await initialHook()
  initRecaptcha()
  attachDirectives()

  new Vue({
    router,
    store,
    render: h => h(App),
    i18n,
  }).$mount('#main')
}

init()
