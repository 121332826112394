import Actions from '@/store/modules/chatImport/actions'
import Getters from '@/store/modules/chatImport/getters'
import Mutations from '@/store/modules/chatImport/mutations'
import State from '@/store/modules/chatImport/state'
import { Module } from 'vuex-smart-module'

const chatImportModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default chatImportModule
