import * as Sentry from '@sentry/browser'

/**
 * Sentry which send info about trying to use tfa with wrong status
 * For example - change password while TFA is disabled
 * @param {boolean?} disabled default true
 * @returns {boolean} false
 */
export const SentryWrongStatusTFA = (disabled = true) => {
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Error)
    Sentry.captureException(
      new Error(
        'Trying to use TFA while it is ' + disabled ? 'disabled' : 'enabled',
      ),
    )
  })
  return false
}

/**
 * Send sentry about wrong backend response for result.details.enabled field
 * For example - change password but backend says that enabled === false
 * @returns {boolean} false
 */
export const SentryBackendWrongEnabledTFA = () => {
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Error)
    Sentry.captureException(
      new Error('Wrong backend enabled status received with TFA request'),
    )
  })
  return false
}
