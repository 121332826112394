import Actions from '@/store/modules/publicGroups/actions'
import Getters from '@/store/modules/publicGroups/getters'
import Mutations from '@/store/modules/publicGroups/mutations'
import State from '@/store/modules/publicGroups/state'
import { Module } from 'vuex-smart-module'

const publicGroupsModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default publicGroupsModule
