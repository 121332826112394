import Actions from '@/store/modules/teams/actions'
import Getters from '@/store/modules/teams/getters'
import Mutations from '@/store/modules/teams/mutations'
import State from '@/store/modules/teams/state'
import { Module } from 'vuex-smart-module'

const teamsModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default teamsModule
