import { Actions } from 'vuex-smart-module'
import { Tag } from '@tada-team/tdproto-ts'
import * as Sentry from '@sentry/browser'
import api from '@/api/v3'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'

class ModuleActions extends Actions<
  State,
  Getters,
  Mutations,
  ModuleActions
> {
  async getTags (): Promise<Tag[]> {
    let tags: Tag[] = []
    try {
      tags = await api.tags.get()
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('tags', 'get')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to get tags.')
      })
      return Promise.reject(e)
    }
    this.mutations.SETUP_TAGS(tags)
    return tags
  }
}

export default ModuleActions
