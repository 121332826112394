import store, {
  contactsStore,
  teamsStore,
  loginStore,
  uiSettingsStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import { defaultLogger } from './loggers'

/**
 * @file src/themes.ts
 * !TODO move all theme changes to from .class-theme to document.body.style.setProperty() api
 */

const placeholdersPath = '/assets/i/placeholders'

export type ThemeName =
  | 'classic'
  | 'sea'
  | 'honey'
  | 'spring'
  | 'server-theme'
  | 'ny'

interface Theme {
  name: ThemeName;
  title: string;
}

const themes: Theme[] = [
  { name: 'classic', title: 'Classic' },
  { name: 'sea', title: 'Sea' },
  { name: 'honey', title: 'Honey' },
  { name: 'spring', title: 'Spring' },
  { name: 'server-theme', title: 'Preset' },
  { name: 'ny', title: 'New Year' },
]

const allowedThemes: Theme[] = [
  { name: 'classic', title: 'Classic' },
  { name: 'sea', title: 'Sea' },
  { name: 'honey', title: 'Honey' },
  { name: 'spring', title: 'Spring' },
  { name: 'server-theme', title: 'Preset' },
  // { name: 'ny', title: 'New Year' }
]

const defaultTheme: Theme = allowedThemes[0]

/**
 * List of themes that UI may display to user.
 * Hide 'server-theme' as it is forced upon user by default if present on team.
 */
const displayedThemes = allowedThemes.filter(t => {
  return t.name !== 'server-theme'
})

const getCurrentThemeName = (): ThemeName | undefined => {
  const themeOnBody = themes.find(t => {
    return document.body.classList.contains(`o-${t.name}`)
  })
  return themeOnBody?.name
}

const setTheme = (name: ThemeName) => {
  defaultLogger.info('Changing theme to', name)

  // check for selected theme to actually be allowed here
  // also works as an automatic reset of NY theme when it is deactivated
  const themesToTry: (ThemeName | '')[] = [name, uiSettingsStore.getters.theme, uiSettingsStore.getters.previousTheme]
  let switchTo: ThemeName | '' | undefined = themesToTry.find(t => {
    return allowedThemes.some(at => at.name === t)
  })

  if (!switchTo) {
    defaultLogger.error(
      'Theme', name, 'is not allowed here!',
      'Neither are current and previous themes',
    )
    switchTo = defaultTheme.name
  }

  const currentThemeName = getCurrentThemeName()
  defaultLogger.debug('Current theme on document is', currentThemeName)

  // check if theme classes need to be changed
  if (currentThemeName !== switchTo) {
    // remove current theme
    currentThemeName && document.body.classList.remove(`o-${currentThemeName}`)
    // add new theme
    document.body.classList.add(`o-${switchTo}`)
  }

  // this updates favicons
  // ? again, is this still used?
  // and saves new theme in ui-settings
  store.dispatch(actionTypes.THEME_CHANGED)
  uiSettingsStore.actions.themeChanged({ to: switchTo })
  contactsStore.actions.themeChanged()
  teamsStore.actions.themeChanged()
}

/**
 * Get from FEATURES new custom colors for new branding
 * expecially for login/onboarding/2fa/calls
 * @todo remove typecasting for features by upgrading of tdproto-ts package
 */
const setupFeaturesTheme = async (): Promise<void> => {
  const hasCustomTheme = window.FEATURES.custom_theme

  if (!hasCustomTheme) return

  const theme: Record<'web_base', Record<string, string>> = await loginStore.actions.getServerTheme()

  setServerTheme({
    '--q-color-positive': theme.web_base.brand, // primary
    '--main-accent': theme.web_base.brand, // primary
    '--td-blue-3': theme.web_base.brand, // primary

    '--td-gray-light': theme.web_base.brand_light, // light primary (input bg)
    '--td-blue-4': theme.web_base.brand_dark, // dark primary (loading primary btn)

    '--td-red-3': theme.web_base.error, // error
    '--td-red-4': theme.web_base.error_light, // error bg

    '--td-gray-25': theme.web_base.back_light, // secondary
  })
}

const setServerTheme = (theme: Record<string, string>) => {
  const styleEl = document.createElement('style')
  styleEl.type = 'text/css'
  document.getElementsByTagName('head')[0].appendChild(styleEl)
  const styleSheet = styleEl.sheet
  if (!styleSheet) return

  const themeName: ThemeName = 'server-theme'
  const ruleDeclaration = parseServerTheme(theme)

  styleSheet.insertRule(
    '.o-' + themeName + '{' + ruleDeclaration + '}',
    styleSheet.cssRules.length,
  )
  setTheme(themeName)
}

const parseServerTheme = (theme: Record<string, string>): Partial<CSSStyleDeclaration> => {
  return Object.entries(theme)
    .map(([param, value]) => param + ':' + value + ';')
    .join('')
}

const getDefaultAvatar = (
  instance: string,
  theme?: ThemeName,
): string => {
  // Пока хардкод, надо привести все к 1 формату
  // const ext = theme === 'gray' ? 'svg' : 'png'
  const ext = 'svg'

  if (!instance) return `${placeholdersPath}/photo.svg`

  const themeIndependendInstances: string[] = [
    'contact',
    'group',
    'private-group',
  ]

  if (themeIndependendInstances.includes(instance)) {
    return `${placeholdersPath}/${instance}.${ext}`
  }

  const appliedTheme = theme ?? getCurrentThemeName()
  return `${placeholdersPath}/${appliedTheme}-${instance}.${ext}`
}

const needToSetAvatar = (icon: string | undefined): boolean => {
  return icon?.indexOf(placeholdersPath) === 0
}

// window.addEventListener('keyup', (e) => {
//   if (e.key === 'x') setTheme('classic')
//   if (e.key === 'z') setTheme('ny')
// })

export default {
  displayedThemes,
  defaultTheme,
  getCurrentThemeName,
  getDefaultAvatar,
  needToSetAvatar,
  setServerTheme,
  setTheme,
  setupFeaturesTheme,
}
