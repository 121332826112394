import api from '@/api/v3'
import { loginLogger } from '@/loggers'
import store, {
  groupsStore,
  tasksStore,
  dataImportsStore,
  contactsStore,
  sectionsStore,
  activeCallStore,
  uiStore,
  uiSettingsStore,
  chatImportStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import Getters from '@/store/modules/teams/getters'
import Mutations from '@/store/modules/teams/mutations'
import State from '@/store/modules/teams/state'
import ThemeManager from '@/themes'
import { sentryUpdateDefaultScope } from '@/utils'
import Socket from '@/ws'
import * as Sentry from '@sentry/browser'
import {
  ChatType,
  Contact,
  Team,
  TeamCounter,
  TeamJSON,
} from '@tada-team/tdproto-ts'
import { Actions as BaseActions } from 'vuex-smart-module'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  /**
   * Team change handler with subsequent update of the entire store
   * @param payload
   */
  changeTeam (payload: { teamId: string, chatId: string }): void {
    const { teamId, chatId } = payload

    // cleanup
    uiSettingsStore.actions.saveSettings({ data: {}, instant: true })
    activeCallStore.actions.endCall()
    store.commit('SOCKET_READY_STATE', false)
    uiStore.mutations.setMiddleColumnInstance({ instance: 'chat', payload: null })

    // reset everything
    store.dispatch(actionTypes.RESET_STORE)
    uiStore.actions.resetRightBarTasksSortFilters()
    uiStore.actions.toggleLeftColumn(false)
    dataImportsStore.actions.resetStore()
    sectionsStore.actions.resetStore()
    contactsStore.actions.resetStore()
    tasksStore.mutations.reset()
    groupsStore.actions.resetStore()
    chatImportStore.actions.resetStore()

    store.commit('CHATS_CURRENT_CHAT', { chatId })

    // setup new and change me
    this.mutations.setCurrentTeamId(teamId)
    sentryUpdateDefaultScope(teamId)

    // handle right bar instance
    const rightBarInstance = uiStore.getters.currentRightBarInstance

    // hide right bar instance - bodge for single-group teams
    if (rightBarInstance === 'tasks' && this.getters.isSingleGroupTeam()) {
      const rightBarPayload = { instance: rightBarInstance, payload: null, hide: true }
      uiStore.actions.switchRightBarInstance(rightBarPayload)
    }

    // start again
    Socket.close('changeTeam')
  }

  /**
   * Changes to the left menu collapse settings for a profile
   * @param payload
   */
  setRosterListType (payload: { chatType: ChatType, isShort: boolean }): void {
    const { chatType, isShort } = payload
    this.mutations.changeMeShortView(payload)

    if (chatType === 'meeting') throw new Error('ChatType error')
    const key = {
      direct: 'contact_short_view',
      group: 'group_short_view',
      task: 'task_short_view',
      meeting: 'meeting_short_view',
      thread: 'thread_short_view',
    }[chatType]

    const jid = store.getters.getUserId
    const params = { [key]: isShort }

    contactsStore.actions.editContact({ jid, params })
  }

  /**
   * Create new team and add to teams store
   * @param name
   * @param isSingleGroup
   * @param avatar
   */
  async createTeam ({ name, isSingleGroup, avatar }: { name: string, isSingleGroup?: boolean, avatar?: Blob | null }): Promise<Team> {
    let team: Team | undefined
    let teamJson: TeamJSON | null = null
    const data = {
      name,
      single_group: isSingleGroup,
    }

    try {
      team = await api.teams.create(data)
      teamJson = team.toJSON()
      if (avatar) {
        // do not provide error if avatar error, cause it's simple error
        try {
          teamJson.icons = await api.teams.setIcon(team.uid, avatar)
        } catch (e) {
          loginLogger.error('Avatar for new team not set')
        }
        window.goal('onboardingAction', {
          onboarding: 'Team: Установлен аватар',
        })
      }
      const newTeam = Team.fromJSON(teamJson)
      this.mutations.addTeam(newTeam)
      return newTeam
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'create new team')
        scope.setContext('params', data)
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
  }

  /**
   * Edit team
   * @param payload - UID of team, new name and optional avatar in Blob format
   * @returns updated team
   */
  async editTeam (payload: { uid: string; name: string; avatar?: Blob | null }): Promise<Team> {
    let team: Team | null = null
    let teamJson: TeamJSON | null = null
    team = await api.teams.edit(payload.uid, { name: payload.name })
    teamJson = team.toJSON()

    if (payload.avatar) {
      // do not provide error if avatar error, cause it's simple error
      try {
        teamJson.icons = await api.teams.setIcon(payload.uid, payload.avatar)
      } catch (e) {
        loginLogger.error('Avatar for edit`s team not set')
      }
    }
    this.mutations.updateTeam(Team.fromJSON(teamJson))
    return Team.fromJSON(teamJson)
  }

  /**
   * Update team data
   * @param team
   */
  updateTeam (team: Team): void {
    const teamExists = !!this.state.data[team.uid]
    teamExists
      ? this.mutations.updateTeam(team)
      : this.mutations.addTeam(team)
  }

  /**
   * Team initialization
   * @param teamId
   */
  initTeam (teamId: string): void {
    const team = this.state.data[teamId]
    if (!team) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('initTeam', 'invalid team id passed ')
        scope.setContext('teamId', { teamId })
      })
      return
    }
    store.dispatch(actionTypes.SETUP_PROFILE, { profile: team.me })
    this.mutations.setCurrentTeamId(teamId)
  }

  /**
   * Delete team by team id
   * @param teamId
   */
  deleteTeam (teamId: string): void {
    const instance = uiStore.getters.currentRightBarInstance

    if (instance === 'team-profile' && uiStore.getters.currentRightBarPayload === teamId) {
      uiStore.actions.switchRightBarInstance()
    }

    this.mutations.deleteTeam(teamId)
  }

  /**
   * Setting team data
   * @param teams
   */
  setupTeams (teams: Record<string, Team>): void {
    this.mutations.setupTeams(teams)
  }

  /**
   * Item updates with unread
   * @param teamCounter
   */
  socketOnTeamsUnreads (teamCounter: TeamCounter[]): void {
    if (!teamCounter) return

    teamCounter.forEach(counter => {
      const { uid, unread } = counter
      if (!uid || !unread) return
      this.mutations.updateTeamUnread({ teamId: uid, unread })
    })
  }

  /**
   * Changing the theme for the team
   */
  themeChanged (): void {
    const icon = ThemeManager.getDefaultAvatar('team')
    this.getters.teams.forEach(team => {
      const { uid } = team
      const currentIcon = this.getters.teamIconURL(uid) ?? ''
      const update = ThemeManager.needToSetAvatar(currentIcon)
      update && this.mutations.setTeamIcon({ teamId: uid, icon })
    })
  }

  /**
   * Updating your profile data in a team
   * @param contact
   */
  updateContactInCurrentTeam (contact: Contact): void {
    this.mutations.updateContactInTeam(contact)
  }

  /**
   * Handler for joining a team by invite
   * @param token
   */
  async joinTeamByInvite (token: string): Promise<string> {
    let team: Team
    try {
      team = await api.invitations.join(token)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'join team by invite')
        scope.setContext('token', { token })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    this.mutations.addTeam(team)
    return team.uid
  }
}
