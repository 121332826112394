import { Actions as BaseActions } from 'vuex-smart-module'
import Getters from '@/store/modules/root/getters'
import Mutations from '@/store/modules/root/mutations'
import State from '@/store/modules/root/state'
import { subscribe, unsubscribe } from '@/notifications'
import { isNotificationsAllowed } from '@/notifications/Utils'
import { debounce } from 'quasar'

export default class Actions extends BaseActions <State, Getters, Mutations, Actions> {
  onChangeNotificationsAllowed = debounce(async (value: boolean) => {
    try {
      (value) ? await subscribe(true) : await unsubscribe()
    } finally {
      await this.actions.fetchNotificationsAllowed()
    }
  }, 500)

  $init (): void {
    this.actions.fetchNotificationsAllowed()
  }

  setIsAppReady (value: boolean): void {
    this.mutations.setIsAppReady(value)
  }

  async fetchNotificationsAllowed (): Promise<void> {
    const value = isNotificationsAllowed()
    this.mutations.setIsNotificationsAllowed(value)
  }

  async setNotificationsAllowed (value: boolean): Promise<void> {
    this.mutations.setIsNotificationsAllowed(value)
    await this.onChangeNotificationsAllowed(value)
  }
}
