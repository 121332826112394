import type { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import store, { loginStore, teamsStore, rootStore, uiStore, calendarStore } from '@/store'
import { loginLogger } from '@/loggers'
import { toggleBadge as toggleCaptchaBadge } from '@/recaptcha'
import * as actionTypes from '@/store/actionTypes'
import i18n from '@/i18n'

export const beforeEachGuard: NavigationGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
): Promise<void> => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const { isLoggedIn } = loginStore.state

  loginLogger.log(
    'Try to move root where auth and isLoggedIn',
    requiresAuth,
    isLoggedIn,
  )

  calendarStore.actions.setIsCalendarWasOpenBefore(from.name === 'Calendar')

  // TODO: Move all processing tab title here from tabAppearance.js
  updateSiteTitle(to)

  // if there is no team, then there is no point in downloading something
  if (to.params.teamId && !teamsStore.state.data[to.params.teamId]) {
    rootStore.actions.setIsAppReady(true)
  }

  if (to.name === 'Login' && !isLoggedIn) {
    toggleCaptchaBadge(true)
    rootStore.actions.setIsAppReady(true)
    return next()
  }

  if (to.name === 'Onboarding') {
    rootStore.actions.setIsAppReady(true)
  }

  await prepareStore(to)

  /**
   * Cases are intentionally kept simple (yet repetitive). KISS.
   */
  if (to.name !== 'Login') toggleCaptchaBadge(false)
  if (to.name === 'Login' && isLoggedIn) {
    return next({ name: 'Main' })
  }
  if (requiresAuth && !isLoggedIn) {
    return next({ name: 'Login' })
  }

  next()
  // todo set page title dynamically by getting it from store
  // todo store action-controller for title
  // document.title = store.getters.title
}

/**
 * Prepares shop depending on route
 * @param to
 */
async function prepareStore (to: Route): Promise<void> {
  // if the team has not yet been initialized, initialize
  if (!teamsStore.state.currentTeamId && to.params.teamId) {
    const jid = to.params.jid
    await teamsStore.actions.initTeam(to.params.teamId)
    // if we try to open a chat and it is not loaded yet, then we load it
    if (jid && !store.getters.chat(jid)) {
      await store.dispatch(actionTypes.LOAD_CHAT, jid)
    }
  }
  const isExternalTeam = teamsStore.state.currentTeamId !== to.params.teamId

  // open chat
  if (to.name === 'Chat') {
    if (isExternalTeam) {
      teamsStore.actions.changeTeam({
        chatId: to.params.jid,
        teamId: to.params.teamId,
      })
    } else {
      store.dispatch(actionTypes.OPEN_CHAT, {
        chatId: to.params.jid,
        messageId: to.query.message,
      })
      uiStore.actions.openChat()
    }
  } else if (to.name === 'Dashboard') {
    // switch to task-desk
    isExternalTeam &&
    teamsStore.actions.changeTeam({
      teamId: to.params.teamId,
      chatId: '',
    })
    uiStore.actions.toggleTaskDesk({ active: true })
  } else if (to.name === 'Team') {
    if (isExternalTeam) {
      uiStore.actions.toggleTaskDesk({ active: false })
      teamsStore.actions.changeTeam({
        teamId: to.params.teamId,
        chatId: '',
      })
      return
    }
  }

  if (to.name === 'Main' || to.name === 'Team') {
    uiStore.actions.resetMiddleColumnInstance()
    store.dispatch(actionTypes.RESET_CHAT)
  }
}

/**
 * The function updates the title on the tab
 * @param to
 */
function updateSiteTitle (to: Route) {
  if (to.name === 'Calendar') {
    document.title = window.isElectron
      ? `${window.FEATURES.app_title} – ${i18n.t('calendar.documentTitle').toString()}`
      : i18n.t('calendar.documentTitle').toString()
  }
}
