import api from '@/api/v3'
import DataGenerator from '@/api/v3/DataGenerator'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import {
  defaultLogger,
  networkLogger,
  replyLogger,
  supportTasksLogger,
} from '@/loggers'
import {
  contactsStore,
  groupsStore,
  publicGroupsStore,
  tasksStore,
  uiStore,
  uiSettingsStore,
  meetingsStore,
  draftsStore,
  threadsStore,
  chatsBarStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import Messages from '@/store/messages'
import * as mutationTypes from '@/store/modules/chat/mutationTypes'
import { mapFunctionForTypes } from '@/store/utils'
import { getChatType } from '@/utils'
import isSupportTask from '@/utils/IsSupportTask'
import { isEscape } from '@/utils/KeyUtils'
import * as Sentry from '@sentry/browser'

const escapeKeyListener = e => {
  // const key = e.keyCode || e.which
  // if (key !== 27) return
  if (!isEscape(e)) return

  defaultLogger.info('ESC key pressed, TOGGLE_MESSAGE_ACTIVE', { messageId: null })
  ChatEventBus.$emit(Events.TOGGLE_MESSAGE_ACTIVE, { messageId: null })
}

export default {
  [actionTypes.SETUP_CHATS] ({ dispatch, getters }, { chats }) {
    const newChatsIds = Object.keys(chats)
    newChatsIds.forEach(chatId => {
      const oldChatGentime = getters.chat(chatId) && getters.chat(chatId).gentime
      const newChat = chats[chatId]

      dispatch(actionTypes.UPDATE_CHAT, chats[chatId])

      if (oldChatGentime < newChat.gentime) {
        defaultLogger.warn('SETUP CHATS gentime mismatch', chatId, oldChatGentime, newChat.gentime, getters.currentChat)
        Messages.clearChatStore(chatId)

        if (getters.currentChat === chatId) {
          defaultLogger.warn('REINIT')
          ChatEventBus.$emit(Events.REINIT_CHAT)
        }
      }
    })
  },
  /**
   * Loads single chat by jid.
   * Saves corresponding chat and optionally task/ group locally.
   * Reports to Sentry on API error.
   * @param chatId chat jid to load
   * @return Obj{ chat, task | null, group | null } or null on error
   */
  async [actionTypes.LOAD_CHAT] ({ dispatch }, chatId) {
    let result = null
    try {
      result = await api.chats.get(chatId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTag('chats', 'load')
        scope.setContext('chat id', chatId)
        Sentry.captureException(e)
      })
    }
    if (!result) return null

    const { chat, task, group, thread } = result
    if (!chat) return null

    dispatch(actionTypes.UPDATE_CHAT, chat)
    task && tasksStore.mutations.addTask({ jid: task.jid, task })
    group && groupsStore.actions.update(group)
    thread && threadsStore.actions.update(thread)

    return { chat, task, group, thread }
  },
  [actionTypes.RESET_CHAT] ({ commit }) {
    commit(mutationTypes.CHATS_CURRENT_CHAT, { chatId: null })
  },
  async [actionTypes.OPEN_CHAT] ({ commit, dispatch, getters }, { chatId, messageId, isReopen = false }) {
    const { lastChat, lastOpenChat, chatsIds, getUserId, currentChat, previousOpenChat } = getters
    if (chatId === getUserId) return

    chatId = chatId || lastChat || previousOpenChat ||
      contactsStore.getters.defaultContact || lastOpenChat || chatsIds[0]
    if (!chatId) return

    if (!messageId && getters.isChatOpen(chatId) && !isReopen) return

    Messages.abortFetching()
    dispatch(actionTypes.SET_CURRENT_ACTIVE_MESSAGE_DATA, null)

    let chatToOpen = getters.chat(chatId)
    const chatType = chatToOpen?.chatType || getChatType(chatId)
    if (!chatToOpen && (chatType === 'meeting' || chatType === 'thread')) {
      await dispatch(actionTypes.LOAD_CHAT, chatId)
      chatToOpen = getters.chat(chatId)
    }

    const instance = chatType === 'task' ? 'full-task' : chatType === 'meeting' ? 'Meeting' : 'chat'
    if (chatType === 'meeting') {
      meetingsStore.actions.setSelectedMeetingUUID(chatId)
      uiStore.actions.switchRightBarInstance({
        instance: 'meetings',
      })
    } else {
      await meetingsStore.actions.setSelectedMeetingUUID(null)
    }
    uiStore.actions.switchMiddleColumnInstance({ instance })

    const isSupport = isSupportTask(chatId)

    if (!chatToOpen) {
      const entity = getters.entity(chatId)
      const createChat = (chatType === 'direct' && entity) || chatType === 'task' || chatType === 'meeting'
      if (createChat) {
        dispatch(actionTypes.UPDATE_CHAT, DataGenerator.generateChatFor({ entity }))
      } else if (chatType === 'group') {
        commit(mutationTypes.CHATS_CURRENT_CHAT, { chatId: null })
        await publicGroupsStore.actions.sneakpeekGroup(chatId)
        return
      } else if (chatType === 'thread') {
        commit(mutationTypes.CHATS_CURRENT_CHAT, { chatId: null })
        await threadsStore.actions.loadThread(chatId)
        return
      }
    } else {
      isSupport && supportTasksLogger.info('[OPEN_CHAT] > existing', chatToOpen)
      if (chatType === 'direct' && chatToOpen.hidden) {
        dispatch(actionTypes.TOGGLE_CHAT_EXCLUDED_STATE, { chatId, exclude: false })
      }
    }

    isSupport && supportTasksLogger.info('[OPEN_CHAT] > setup messages (start)')
    Messages.setupIfNeededFor(chatToOpen)
    isSupport && supportTasksLogger.info('[OPEN_CHAT] > setup messages (finish)')

    messageId && commit(mutationTypes.CHAT_SET_STARTING_MESSAGE_ID, { chatId, messageId })

    defaultLogger.info(`Open ${chatType} chat: ${chatId.slice(0, 5)}...`)

    chatType !== 'task' && chatType !== 'meeting' && commit(mutationTypes.CHATS_SET_LAST_CHAT, { chatId, previousOpenChat: currentChat })
    commit(mutationTypes.CHATS_CURRENT_CHAT, { chatId })
  },
  /**
   * Set error type to state and push url to history
   * @param {string} chatId JID of a chat
   * @param {string} type 403 or 404
   */
  [actionTypes.OPEN_CHAT_WITH_ERROR] ({ commit, dispatch }, { chatId, type }) {
    commit(mutationTypes.CHAT_SET_ERROR_TYPE, type)
    dispatch(actionTypes.PUSH_STATE, { chatId })
    uiStore.actions.switchRightBarInstance()
  },
  [actionTypes.CHAT_OPENED] ({ getters, dispatch, commit }, { chatId }) {
    isSupportTask(chatId) && supportTasksLogger.info('[CHAT_OPENED]', chatId)
    commit(mutationTypes.CHAT_SET_ERROR_TYPE, null)
    dispatch(actionTypes.PUSH_STATE, { chatId })

    if (getters.chatIsGroup(chatId)) {
      const instance = uiStore.getters.currentRightBarInstance
      if (instance === 'group-profile') {
        uiStore.actions.switchRightBarInstance({
          instance,
          payload: chatId,
        })
      }
    }
  },
  [actionTypes.PUSH_STATE] (_, { chatId }) {
    uiSettingsStore.actions.saveSettings({
      data: { lastChat: chatId },
    })
  },
  [actionTypes.SET_CURRENT_ACTIVE_MESSAGE_DATA] ({ commit }, data) {
    // TODO: uncomment this line when replyLogger is removed
    // defaultLogger.info('SET_CURRENT_ACTIVE_MESSAGE_DATA', data)
    replyLogger.info('SET_CURRENT_ACTIVE_MESSAGE_DATA:', data)
    commit(mutationTypes.CHAT_SET_CURRENT_ACTIVE_MESSAGE, data)

    if (data) {
      window.addEventListener('keydown', escapeKeyListener)
      return
    }
    window.removeEventListener('keydown', escapeKeyListener)
  },
  [actionTypes.UPDATE_CHAT] ({ state, dispatch, commit }, updatedChat) {
    if (!updatedChat) return
    const { chatId, chatType } = updatedChat

    const container = state[`${chatType}Chats`]
    if (!container) {
      defaultLogger.error(`[defaultLogger]: There is no container for "${chatType}" chat type`, updatedChat)
      return
    }

    const currentChat = container[chatId]
    if (!currentChat) {
      commit(mutationTypes.CHAT_UPDATE_CHAT, { chatType, chatId, updatedChat })
      return
    }

    const { at, sharp, botcommands } = currentChat
    const chat = Object.assign({ at, sharp, botcommands }, updatedChat)

    const { lastMessage: currentLastMessage } = currentChat
    const currentLastMessageId = currentLastMessage && currentLastMessage.messageId

    const { lastMessage: newLastMessage } = chat
    const newLastMessageId = newLastMessage && newLastMessage.messageId

    const { lastReadMessageId: currentLastReadMessageId } = currentChat
    const { lastReadMessageId: newLastReadMessageId } = chat
    if (currentLastReadMessageId !== newLastReadMessageId) {
      dispatch(actionTypes.SOCKET_SEND_LAST_READ_MESSAGE, {
        chatId, messageId: currentLastReadMessageId,
      })
    }

    const isLastMessagesDifferent = currentLastMessageId !== newLastMessageId
    isLastMessagesDifferent && (chat.lastMessage = currentLastMessage)

    commit(mutationTypes.CHAT_UPDATE_CHAT, { chatType, chatId, updatedChat: chat })

    isLastMessagesDifferent && dispatch(actionTypes.CHANGE_CHAT_LAST_MESSAGE, { chatId, lastMessage: newLastMessage })
  },
  [actionTypes.UPDATE_CONTACT] ({ rootGetters }, { contact }) {
    const { jid, displayName } = contact
    if (rootGetters.isChatOpen(jid) && rootGetters.chatName(jid) !== displayName) {
      const teamId = rootGetters.currentTeamId
      window.history.replaceState(null, contact.displayName, `/${teamId}/chats/${jid}`)
    }
  },
  [actionTypes.DELETE_CHAT] ({ dispatch, commit, rootGetters }, { chatId, type }) {
    if (!chatId) return

    type = type || rootGetters.chatType(chatId)
    commit(mutationTypes.CHATS_REMOVE_CHAT, { chatId, type })

    Messages.deleteOf(chatId)

    rootGetters.isChatOpen(chatId) && dispatch(actionTypes.OPEN_CHAT, { chatId: null })

    try {
      draftsStore.actions.onChatDeleted(chatId)
    } catch (e) {
      defaultLogger.warn('[DELETE_CHAT]', e)
    }

    type === 'group' && groupsStore.actions.remove(chatId)
  },
  async [actionTypes.LOAD_CHAT_COMMANDS] ({ commit }, { chatId, type = 'commands' }) {
    try {
      const commands = await api.chatlinks[type](chatId)
      commit(mutationTypes.CHATS_SETUP_CHAT_BOTCOMMANDS, { chatId, commands, type })
      return commands
    } catch (e) {
      networkLogger.warn('[actionTypes.LOAD_CHAT_COMMANDS]', e)
    }
    return []
  },
  [actionTypes.SOCKET_ON_OTHER_MESSAGE_COMPOSING] ({ commit, rootGetters }, { chatId, composing, actorId, audio }) {
    const { getUserId } = rootGetters
    contactsStore.actions.loadContact(actorId)
    commit(mutationTypes.CHAT_OTHER_MESSAGE_COMPOSING, {
      chatId: chatId === getUserId ? actorId : chatId,
      composing,
      actorId,
      audio,
    })
  },
  [actionTypes.SOCKET_SEND_MESSAGE_COMPOSING] ({ commit }, { chatId, composing }) {
    commit(mutationTypes.CHAT_MESSAGE_COMPOSING, { chatId, composing })
  },
  [actionTypes.CHANGE_CHAT_LAST_MESSAGE] ({ commit, getters }, { chatId, lastMessage }) {
    if (!lastMessage) return // TODO: add Sentry

    const currentChat = getters.chat(chatId)
    if (!currentChat) return // TODO: add Sentry

    commit(mutationTypes.CHAT_SET_LAST_MESSAGE, { chatId, lastMessage })
  },
  /**
   * Оперируем счетчиками непрочитанных
   */
  ...mapFunctionForTypes([
    actionTypes.SOCKET_ON_LAST_READ_MESSAGE,
    actionTypes.SOCKET_SEND_LAST_READ_MESSAGE,
  ], function ({ commit, getters }, { chatId, messageId }) {
    if (!messageId) {
      // messageId === null когда мы хотим прочитать все сообщения с клиента и источник - экшн SOCKET_SEND_LAST_READ_MESSAGE
      // В таком случае опускаем счетчик непрочитанных у чата до нуля
      commit(mutationTypes.CHAT_SET_NUM_UNREAD, { chatId, numUnread: 0 })

      const lastMessage = getters.chatLastMessage(chatId)
      lastMessage && (messageId = lastMessage.messageId)
    }

    ChatEventBus.$emit(Events.CHAT_WAS_READED_TO, { chatId, messageId })
    commit(mutationTypes.CHAT_SET_LAST_READ_MESSAGE_ID, { chatId, messageId })
  }),
  [actionTypes.SOCKET_ON_CHAT_COUNTERS] (
    { commit, getters, dispatch },
    { chatId, numUnread, numUnreadNotices, lastActivity },
  ) {
    const chat = getters.chat(chatId)
    // Если нам написал кто-то из контактов, а чата с ним еще нет, то создаем его.
    // Однако, лучше бы сервер в таком случае отправлял `chat.updated`
    if (!chat) {
      const contact = contactsStore.getters.contact(chatId)
      contact && dispatch(actionTypes.UPDATE_CHAT, DataGenerator.generateChatFor({ entity: contact, numUnread }))
      return
    } else {
      const { chatType, hidden } = chat
      if (hidden && chatType === 'direct') {
        dispatch(actionTypes.TOGGLE_CHAT_EXCLUDED_STATE, { chatId, exclude: false })
      }
    }

    commit(mutationTypes.CHAT_SET_NUM_UNREAD, { chatId, numUnread, numUnreadNotices })
    commit(mutationTypes.CHAT_SET_LAST_ACTIVITY, { chatId, lastActivity })

    // if (chatType === 'task') {
    if (chatId.startsWith('t-')) {
      const task = tasksStore.state.data[chatId]
      if (task) {
        task.numUnread = numUnread
        task.numUnreadNotices = numUnreadNotices
      }
    }
  },
  async [actionTypes.TOGGLE_CHAT_EXCLUDED_STATE] ({ commit }, { chatId, exclude = false }) {
    commit(mutationTypes.CHATS_TOGGLE_CHAT_EXCLUDED_STATE, { chatId, exclude })

    if (exclude) {
      // try-catch is a bodgy-bodge for deleting chat
      // that does not yet exist on server
      // server will return 404, which is in fact fine
      try {
        await api.roster.hide(chatId)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  },
  async [actionTypes.TOGGLE_CHAT_PIN_STATE] ({ commit, getters }, { chatId, pinned = false }) {
    let pinnedSortOrdering = null

    if (pinned) {
      const { directChats, groupChats, taskChats } = getters
      const allChats = { ...directChats, ...groupChats, ...taskChats }

      const pinnedOrders = Object.values(allChats)
        .filter(chat => chat.pinned)
        .map(chat => chat.pinnedSortOrdering)

      pinnedSortOrdering = Math.min(...pinnedOrders) - 1
    }

    commit(mutationTypes.CHATS_TOGGLE_CHAT_PIN_STATE, { chatId, pinned })

    await api.roster.pin(chatId, pinned, pinnedSortOrdering)
  },
  /**
   * Marks given message in a given chat important.
   * Reports to Sentry on failure.
   * @param params.chatId JID of a chat.
   * @param params.messageId JID of a message to mark.
   * @param params.important Mark on unmark.
   * @returns Parsed message object or a rejected promise with Error obj.
   */
  async [actionTypes.CHAT_MARK_MESSAGE_IMPORTANT] (_ctx, params) {
    const { chatId, messageId, important } = params
    try {
      return await api.chats.markMessageImportant(
        chatId,
        messageId,
        important,
      )
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('messages', 'important')
        scope.setContext('chat id', chatId)
        scope.setContext('message id', messageId)
        Sentry.captureException('Failed to mark message important.')
      })
      return Promise.reject(e)
    }
  },
}
