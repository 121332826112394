import ThemeManager from '@/themes'
import type {
  UISettingData,
  UISettings,
  UISettingsGlobal,
} from './types'

export default class State {
  language: null | string = null

  uiSettingData: UISettingData = {}

  defaultTeamSettings: UISettings = {
    sortingChatsType: {
      group: 0,
      direct: 0,
    },
    theme: ThemeManager.defaultTheme.name,
    previousTheme: ThemeManager.defaultTheme.name,
    triedNYThemes: [],
    taskDesk: {
      tabFilterKey: 'section',
      tabSortingKey: 'activity',
      groupsOrder: {
        section: [],
        status: [],
        assignee: [],
        owner: [],
        tag: [],
        importance: [],
      },
      reordableNotificationShown: false,
    },
    taskDraft: null,
    lastOpenTasksTab: null,
    experimentalTasksTabsDesign: false,
    desktopAutodownloadFiles: false,
  }

  data: UISettings = {
    debugLevel: 5,
    sortingChatsType: {
      group: 0,
      direct: 0,
    },
    theme: '',
    previousTheme: '',
    triedNYThemes: [],
    taskDesk: {
      tabFilterKey: 'section',
      tabSortingKey: 'activity',
      groupsOrder: { // sortBy: values
        section: [], // sections jids
        status: [], // statuses uids
        assignee: [], // assignees jids
        owner: [], // owners jids
        tag: [], // tags names
        importance: [], // importance options
      },
      reordableNotificationShown: false,
    },
    taskDraft: null,
    lastOpenTasksTab: null,
    experimentalTasksTabsDesign: false,
    desktopAutodownloadFiles: false,
    lastChat: null,
  }

  globalSettings: UISettingsGlobal = {
    useTeamAvatarForFavicon: false,
  }

  theme = ''
}
