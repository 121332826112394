import i18n from '@/i18n'
import store, { contactsStore } from '@/store'
import { formatHHMMSS, getChatType } from '@/utils'
import { CallOnliner, ChatType, IconData, JID } from '@tada-team/tdproto-ts'
import { Getters } from 'vuex-smart-module'
import { ActiveCall, CallDisplayTypeCode, CallEntity } from './models'
import State from './state'

class ModuleGetters extends Getters<State> {
  get uiDisplayTypeCode (): CallDisplayTypeCode {
    switch (this.state.uiDisplayType) {
      case 'card':
        return 'SM'
      case 'bar':
        return 'MD'
      case 'fullscreen':
        return 'LG'
      default:
        throw new Error(`Bad uiDisplayType: ${this.state.uiDisplayType}`)
    }
  }

  get activeCallChatType (): ChatType | undefined {
    const call = this.state.activeCall
    if (!call) return undefined
    return getChatType(call.jid)
  }

  get isActiveCallDirect (): boolean {
    return this.getters.activeCallChatType === 'direct'
  }

  get activeCallFormattedLength (): string {
    const c = this.getters.activeCall
    return formatHHMMSS(c.length)
  }

  get membersLimit (): number {
    return window.FEATURES.max_participants_per_call ?? 0
  }

  get addingMembersEnabled (): boolean {
    return (
      !!window.FEATURES.max_participants_per_call &&
      !this.getters.isActiveCallDirect
    )
  }

  get canAddMembersToActiveCall (): boolean {
    const call = this.state.activeCall
    if (
      !window.FEATURES.max_participants_per_call ||
      this.getters.activeCallChatType === 'direct' ||
      !call?.start
    ) return false

    const max = this.getters.membersLimit
    const current = call.connectedMembers.length
    const slotsAvailable = max - current
    return slotsAvailable > 0
  }

  get connectedEntities (): CallEntity[] {
    const call = this.getters.activeCall
    const { connectedMembers } = call
    const result = connectedMembers.map(m => this.getters.createCallEntity(m))

    const userId = store.getters.getUserId

    if (connectedMembers.every(e => e.jid !== userId)) {
      const entityJid = userId
      const s = this.getters.createCallEntity(entityJid)
      result.push(s)
    }

    return result
  }

  localizedLifecycleState (c: ActiveCall): string {
    const s = c.lifecycleState

    let l
    if (s === 'CONNECTED') l = i18n.t('calls.callDetails.info')
    else if (s === 'RECONNECTING') l = i18n.t('calls.callDetails.reconnecting')
    else if (s === 'ENDED') l = i18n.t('calls.callDetails.callEnded')
    else l = i18n.t('calls.callDetails.calling')

    return l.toString()
  }

  /**
   * @param v CallOnliner obj or entity jid as string
   */
  createCallEntity (v: CallOnliner | string): CallEntity {
    const c = this.getters.activeCall

    if (v instanceof CallOnliner) {
      return new CallEntity(
        v.displayName,
        contactsStore.getters.contact(v.jid)?.icons ?? IconData.fromJSON({
          lg: { height: 0, width: 0, url: v.icon },
          sm: { height: 0, width: 0, url: v.icon },
          stub: v.icon,
        }),
        v.muted,
        v.enabledVideo ?? false,
        v.jid,
        v.role,
      )
    }

    const entity = contactsStore.getters.contact(v)

    if (!entity) {
      throw new Error(
      `Unable to find local entity for CallEntity.
JID: ${v}.
Found entity: ${entity}`,
      )
    }

    const userId = store.getters.getUserId

    return new CallEntity(
      entity.displayName,
      entity.icons,
      v === userId ? c.isAudioMuted : false,
      v === userId ? !c.isVideoMuted : false,
      v,
      entity.role,
    )
  }

  get hasUserMediaPreferenceStatus (): boolean {
    return this.getters.activeCall.userMediaPreference !== 'unknown'
  }

  get activeCall (): ActiveCall {
    const c = this.state.activeCall
    if (!c) throw new Error('Tried getting non-existent active call')

    return c
  }

  isActiveMember (p: JID): boolean {
    return !!this.state.activeCall?.connectedMembers.some(m => m.jid === p)
  }

  /**
   * Gets presenter entity for active call.
   * Prefers user-pinned member over server-side presenter.
   * Member has to be one of connectedMembers array.
   * @returns CallEntity if found, or null otherwise
   * @throws if there is no current active call
   */
  get activePresenter (): CallEntity | null {
    const c = this.getters.activeCall

    const { pinnedByUser, fromServer } = c.presenter
    const ce = this.getters.connectedEntities

    const pinned = ce.find(e => e.jid === pinnedByUser)
    if (pinned) return pinned

    return ce.find(e => e.jid === fromServer) ?? null
  }
}

export default ModuleGetters
