import State from '@/store/modules/sections/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import { RecordSections } from '@/store/modules/sections/types'
import { ChatType, Section } from '@tada-team/tdproto-ts'
import Vue from 'vue'

export default class Mutations extends BaseMutations<State> {
  /**
   * Set sections for chat type
   * @param payload
   */
  setSections (payload: { sections: RecordSections, chatType?: ChatType }): void {
    const { chatType = 'direct', sections } = payload
    if (chatType === 'meeting') throw new Error('ChatType error')
    this.state[chatType] = sections
  }

  /**
   * Update data in section
   * @param payload
   */
  update (payload: { section: Section, chatType?: ChatType }): void {
    const { chatType = 'direct', section } = payload

    if (chatType === 'meeting') throw new Error('ChatType error')
    this.state[chatType][section.uid] = section
  }

  /**
   * Delete sections by uid and chat type
   * @param payload
   */
  delete (payload: { uid: string, chatType?: ChatType }): void {
    const { chatType = 'direct', uid } = payload
    if (chatType === 'direct') {
      Vue.delete(this.state[chatType], uid)
    } else {
      if (chatType === 'meeting') throw new Error('ChatType error')
      this.state[chatType][uid] && (this.state[chatType][uid].isArchive = true)
    }
  }
}
