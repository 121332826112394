import api from '@/api/v3'
import { ChatEventBus, Events as ChatEvents } from '@/components/Chat/ChatEventBus'
import store, { uiStore } from '@/store'
import * as actionTypes from '@/store/actionTypes'
import State from '@/store/modules/groups/state'
import Getters from '@/store/modules/groups/getters'
import Mutations from '@/store/modules/groups/mutations'
import * as Sentry from '@sentry/browser'
import {
  Chat,
  JID,
  GroupStatus,
  GroupMembership,
  TeamStatus,
  ChatType,
} from '@tada-team/tdproto-ts'
import { Actions as BaseActions } from 'vuex-smart-module'
import { GroupPayload } from './types'

export default class Actions extends BaseActions<
  State,
  Getters,
  Mutations,
  Actions
> {
  resetStore (): void {
    Object.assign(this.state, {})
  }

  /**
   * Gets a single group from server, saves it locally.
   * @param {string} jid Group jid to load.
   * @returns parsed loaded task or rejected promise.
   */
  async loadGroup (jid: JID): Promise<Chat> { // LOAD_GROUP
    let group
    try {
      group = await api.groups.get(jid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('groups', 'get')
        scope.setContext('chat id', { jid })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    this.mutations.update(group)
    return group
  }

  async loadMembers (groupId: JID): Promise<void> {
    if (this.getters.members(groupId).length > 0) return
    const members = await api.groups.getMembers(groupId)

    this.mutations.setMembers({ groupId, members })
  }

  async loadMember ({ groupId, memberId }: { groupId: JID, memberId: JID}) {
    const member = await api.groups.getMember(groupId, memberId)

    this.mutations.setMember({ groupId, member })

    return member
  }

  // TODO: remove any
  async create ({ payload }: { payload: GroupPayload }): Promise<Chat> {
    let group
    try {
      group = await api.groups.create(payload)
    } catch (e) {
      console.log(e)
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'create group')
        scope.setContext('params', { payload })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    this.actions.update(group)
    return group
  }

  update (group: Chat): void {
    this.mutations.update(group)
  }

  async delete (groupId: JID): Promise<void> {
    try {
      await api.groups.delete(groupId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'delete group')
        scope.setContext('params', { groupId })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    store.dispatch(actionTypes.DELETE_CHAT, { chatId: groupId, type: 'group' })
  }

  setup (groups: Record<JID, Chat>): void {
    this.mutations.setData(groups)
  }

  async changeMemberStatus ({ groupId, memberId, status }: {
    groupId: JID,
    memberId: JID,
    status: TeamStatus,
  }): Promise<void> {
    const member = await api.groups.setMemberStatus(groupId, memberId, status)
    this.actions.setMember({ groupId, member })
  }

  setMember ({ groupId, member }: {
    groupId: JID,
    member: GroupMembership,
  }): void {
    this.actions.loadMembers(groupId)
    this.mutations.setMember({ groupId, member })
  }

  /**
   * Makes request to delete member from group and remove it from store if success
   * @param {string} groupId group jid
   * @param {string} memberId member jid
   */
  async deleteMember ({ groupId, memberId }: { groupId: JID, memberId: JID }): Promise<void> {
    try {
      await api.groups.deleteMember(groupId, memberId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'delete member')
        scope.setContext('params', { groupId, memberId })
        Sentry.captureException(e)
      })
      return
    }
    this.mutations.deleteMember({ groupId, memberId })
  }

  // was DELETE_CHAT, removing group from store
  remove ({ groupId }: { groupId: JID, type: ChatType }): void {
    const instance = store.getters.currentRightBarInstance
    if (instance === 'group-profile' && store.getters.currentRightBarPayload === groupId) {
      uiStore.actions.switchRightBarInstance()
    }
    this.mutations.delete(groupId)
  }

  async pinMessage ({ groupId, messageId, unpin = false }: { groupId: JID, messageId: JID, unpin: boolean}): Promise<void> {
    // if group already had a pinned message
    const previouslyPinned = this.state.data[groupId]?.pinnedMessage

    const payload = { pinned_message: unpin ? null : messageId }
    await this.actions.edit({ groupId, payload })

    previouslyPinned && ChatEventBus.$emit(
      ChatEvents.MESSAGE_PINNED,
      { chatId: groupId, messageId: previouslyPinned.messageId },
    )
    ChatEventBus.$emit(
      ChatEvents.MESSAGE_PINNED,
      { chatId: groupId, messageId },
    )
  }

  // ToDo: remove any
  async edit ({ groupId, payload }: { groupId: JID, payload: any }): Promise<Chat> {
    const updatedGroup = await api.groups.edit(groupId, payload)
    this.actions.update(updatedGroup)
    return updatedGroup
  }

  async addMember ({ groupId, memberId, status }: {
    groupId: JID,
    memberId: JID,
    status?: GroupStatus
  }): Promise<GroupMembership> {
    try {
      const member = await api.groups.addMember(groupId, memberId, status)
      this.actions.setMember({ groupId, member: member })
      return member
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'add member')
        scope.setContext('params', { groupId, memberId, status })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
  }

  async setIcon ({ groupId, icon }: { groupId: JID, icon: Blob }): Promise<void> {
    try {
      await api.groups.setIcon(groupId, icon)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'set group icon')
        scope.setContext('params', { groupId, icon })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
  }

  async deleteIcon ({ groupId }: { groupId: JID}): Promise<void> {
    try {
      await api.groups.deleteIcon(groupId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'delete group icon')
        scope.setContext('params', { groupId })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
  }
}
