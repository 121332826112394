import Vue from 'vue'

import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import {
  Quasar,
  QTooltip,
  QBtn,
  QToggle,
  QInput,
  QDate,
  Notify,
  Dialog,
  AppFullscreen,
  Cookies,
  QMenu,
  QList,
  QItem,
  QItemSection,
} from 'quasar'

Vue.use(Quasar, {
  config: {
    screen: {
      bodyClasses: true, // <<< add this
    },
  },
  /**
   * Specifying components that should be available in functional templates.
   */
  components: {
    QTooltip,
    QBtn,
    QToggle,
    QDate,
    QInput,
    QMenu,
    QList,
    QItem,
    QItemSection,
  },
  directives: {},
  plugins: {
    Notify,
    Dialog,
    Cookies,
    AppFullscreen,
  },
  iconSet: iconSet,
})
