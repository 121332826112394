import State from '@/store/modules/reminds/state'
import { RemindId, RemindsMapByChat } from '@/store/modules/reminds/types'
import { compareDates } from '@/utils'
import { Remind } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'

export default class Getters extends BaseGetters<State> {
  get remindsByChat (): RemindsMapByChat {
    const map: RemindsMapByChat = {}

    this.state.list
      .sort((a, b) => compareDates(a.fireAt, b.fireAt))
      .forEach(item => map[item.chat]?.push(item) ?? (map[item.chat] = [item]))

    return map
  }

  remind (id: RemindId): Remind | undefined {
    return this.state.list.find(item => item.uid === id)
  }
}
