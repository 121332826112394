import State from '@/store/modules/publicGroups/state'
import { getIconUrl } from '@/utils'
import { Chat, JID } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'

export default class Getters extends BaseGetters<State> {
  exists (id: JID): boolean {
    return this.state.list.some(item => item.jid === id)
  }

  item (id: JID): Chat | undefined {
    return this.state.list.find(item => item.jid === id)
  }

  wasJoined (id: JID): boolean {
    return this.state.wasJoined.includes(id)
  }

  icon (id: JID, large?: boolean): string | undefined {
    const icons = this.getters.item(id)?.icons

    if (icons) return getIconUrl(icons, large)
  }
}
