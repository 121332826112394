import Getters from '@/store/modules/calendar/getters'
import Mutations from '@/store/modules/calendar/mutations'
import State from '@/store/modules/calendar/state'
import { Actions as BaseActions } from 'vuex-smart-module'
import { ViewMode } from '@/store/modules/calendar/types'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  changeViewMode (mode: ViewMode) {
    this.mutations.changeViewMode(mode)
  }

  setIsCalendarWasOpenBefore (val: boolean) {
    this.mutations.setIsCalendarWasOpenBefore(val)
  }
}
