import Actions from '@/store/modules/billing/actions'
import Mutations from '@/store/modules/billing/mutations'
import State from '@/store/modules/billing/state'
import Getters from '@/store/modules/billing/getters'
import { Module } from 'vuex-smart-module'

const billingModule = new Module({
  state: State,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
})

export default billingModule
