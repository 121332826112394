import { MutationTree } from 'vuex'
import { State, InfoTFA } from './types'
import * as mutationTypes from './mutationTypes'
import Vue from 'vue'

const mutations: MutationTree<State> = {
  [mutationTypes.PROFILE_ADD_DATA] (state, { profile }) {
    state.data = profile
  },
  [mutationTypes.PROFILE_SET_PROFILE_ICON] (state, icon) {
    state.data && Vue.set(state.data, 'icon', icon)
  },
  [mutationTypes.TFA_SET_STATUS] (
    state,
    payload: InfoTFA,
  ) {
    if (state.data) {
      Vue.set(state.data, 'auth2faEnabled', payload.enabled)
      Vue.set(state.data, 'auth2faStatus', payload.recoveryStatus)
    }
    // state.twoFactorAuth = payload.enabled
    // state.recoveryStatus = payload.recoveryStatus
  },
}

export default mutations
