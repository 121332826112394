const CALL_SOUNDS = {
  END: '/assets/end_call.mp3',
  OUTGOING: '/assets/outgoing_call_sound.mp3',
  INCOMING: '/assets/htc_ringtone.mp3',
} as const

let audio: HTMLAudioElement | null = null

const create = (
  track: keyof typeof CALL_SOUNDS,
  currentTime: number,
  loop: boolean,
): HTMLAudioElement => {
  const el = new Audio(CALL_SOUNDS[track])
  el.currentTime = currentTime
  el.loop = loop
  audio = el
  return audio
}

const stopAndClear = () => {
  if (!audio) return
  audio.pause()
  audio = null
}

/**
 * Try to play a given call sound.
 * @param track Call-related track to play.
 * @param currentTime Native currentTime param.
 * @param loop Native Audio loop param.
 * @returns Native Audio.play() Promise.
 */
export const play = (p: {
  track: keyof typeof CALL_SOUNDS,
  currentTime?: number,
  loop?: boolean
}): Promise<void> => {
  const currentTime = p.currentTime ?? 0
  const loop = p.loop ?? false

  stopAndClear()
  return create(p.track, currentTime, loop).play()
}

/**
 * Stops a playing audio track.
 * @param track Track to try to stop. If not provided will stop any track that is currently playing.
 */
export const stop = (track?: keyof typeof CALL_SOUNDS): void => {
  if (!audio) return
  if (!track) {
    stopAndClear()
    return
  }

  const currentSrc = audio.src
  const currentTrack = Object.keys(CALL_SOUNDS).find(k => {
    const key = k as keyof typeof CALL_SOUNDS
    return currentSrc.includes(CALL_SOUNDS[key])
  })
  track === currentTrack && stopAndClear()
}

export default {
  play,
  stop,
}
