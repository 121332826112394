import { Getters as BaseGetters } from 'vuex-smart-module'
import State from './state'
import { ActionType } from '@tada-team/tdproto-ts'

export default class Getters extends BaseGetters<State> {
  get unpackingProgress () {
    const unpackingAction = this.getters.getProcessingActionByType('archive_unpacking')
    if (!unpackingAction) return 0
    return unpackingAction.num
  }

  get generateProgress () {
    const unpackingAction = this.getters.getProcessingActionByType('generate_chat')
    if (!unpackingAction) return 0
    return unpackingAction.num
  }

  get step () {
    const status = this.state.parserState
    if (status === 'need_mapping') return 3
    if (status === 'generating') return 3
    if (status === 'not_found') return 1
    return 2
  }

  getProcessingActionByType (t: ActionType) {
    return Object.values(this.state.processingActions)
      .find(a => a.actionType === t)
  }
}
