import { MappedUser, ServerProcessingParams, ParseState } from '@tada-team/tdproto-ts'

export default class State {
  isChatImportDialogOpen = false

  loadingProgress = 0
  isLoadDone = false
  isLoading = false
  hasLoadError = false

  fileName = ''
  fileSize = 0
  loadingSize = 0
  chatName = ''

  mappedUsers: MappedUser[] = []
  mappedUsersLoading = false
  isMatching = false

  processingActions: Record<string, ServerProcessingParams> = {}
  parserState: ParseState | null = null

  abortController = new AbortController()
}
