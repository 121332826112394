import { Integration, Integrations } from '@tada-team/tdproto-ts'
import { Mutations } from 'vuex-smart-module'
import State from './state'

/**
 * why deep cloning (fromJSON(toJSON)) everywhere below?
 * because the object on the entry here may be passed elsewhere
 * by reference and accidently mutated
 * (and we should avoid mutating the state directly)
 */

class ModuleMutations extends Mutations<State> {
  setup (integrations: Integrations) {
    // TODO: clone in action. keep mutation simple
    const cloned = Integrations.fromJSON(integrations.toJSON())
    this.state.data = cloned.integrations
    this.state.kinds = cloned.kinds
  }

  add (integration: Integration) {
    this.state.data.push(Integration.fromJSON(integration.toJSON()))
  }

  edit (integration: Integration) {
    const index = this.state.data.findIndex(i => i.uid === integration.uid)
    if (index === -1) return
    this.state.data.splice(index, 1, Integration.fromJSON(integration.toJSON()))
  }

  remove (uid: string) {
    const index = this.state.data.findIndex(i => i.uid === uid)
    if (index === -1) return
    this.state.data.splice(index, 1)
  }
}

export default ModuleMutations
