export const generateDeviceId = (len = 16): string => {
  const sequence = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let result = ''
  for (let i = 0; i < len; i++) {
    result += sequence.charAt(Math.floor(Math.random() * sequence.length))
  }

  return result
}

export const isPushSupported = () => {
  return ('safari' in window)
    ? ('pushNotification' in (window as any).safari && (window as any).FEATURES.safari_push_id)
    : ('Notification' in window)
}

export const allowNotifications = (permission: string | boolean) => {
  const value: boolean = typeof permission === 'string' ? (permission === 'granted') : permission
  localStorage.setItem('allowed_notifications', '' + value)
}

export const isNotificationsAllowed = (): boolean => {
  const value = localStorage.getItem('allowed_notifications')
  if (!value) return false

  return value.toLowerCase() === 'true'
}
