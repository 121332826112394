import { Chat } from '@tada-team/tdproto-ts'
import Vue from 'vue'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import State from './state'
import { ChatId, MessageId, SortType, Tab } from './types'

export default class Mutations extends BaseMutations<State> {
  setIsLoaded (value: boolean) {
    this.state.isLoaded = value
  }

  setIsLoading (value: boolean) {
    this.state.isLoading = value
  }

  setSortType (value: SortType) {
    this.state.sortType = value
  }

  setTab (tab: Tab) {
    this.state.tab = tab
  }

  setSearch (value: string) {
    this.state.search = value
  }

  clearSearch () {
    this.state.search = ''
  }

  setRosterIsReady (value: boolean) {
    this.state.rosterIsReady = value
  }

  setLastMessagesReceived (
    data: Array<{
      chatId: ChatId,
      messageId: MessageId,
      received: boolean,
    }>,
  ) {
    for (const { chatId, messageId, received } of data) {
      const chat = this.state.chats.find(item => item.jid === chatId)
      if (!chat || chat.lastMessage?.messageId !== messageId) continue
      Vue.set(chat.lastMessage, 'received', received)
    }
  }

  addOrUpdateChats (chats: Chat[]) {
    const { chats: list } = this.state

    for (const chat of chats) {
      const index = list.findIndex(item => item.jid === chat.jid)
      index === -1 ? list.push(chat) : list.splice(index, 1, chat)
    }
  }

  removeChats (ids: ChatId[]) {
    const { chats: list } = this.state

    for (const id of ids) {
      const index = list.findIndex(item => item.jid === id)
      if (index > -1) list.splice(index, 1)
    }
  }

  updateDrafts (drafts: Array<{ id: string, context: string, revision: number }>) {
    for (const draft of drafts) {
      const chat = this.state.chats.find(chat => chat.jid === draft.id)
      if (!chat) continue
      Vue.set(chat, 'draft', draft.context)
      Vue.set(chat, 'draftRevision', draft.revision)
    }
  }

  reset () {
    this.state.chats = []
    this.state.isLoaded = false
    this.state.isLoading = false
    this.state.sortType = 'activity'
    this.state.tab = 'all'
  }
}
