import firebase from 'firebase/app'
import 'firebase/messaging'

import api from '@/api/v3'
import { defaultLogger } from '@/loggers'
import BaseNotification, { Type, Permission, SubscribeParameters } from '@/notifications/BaseNotification'
import { allowNotifications, isNotificationsAllowed } from '@/notifications/Utils'
import { uid } from 'quasar'
import { teamsStore } from '@/store'
import router from '@/router'

export default class Firebase extends BaseNotification {
  private messaging: firebase.messaging.Messaging | null = null

  init () {
    const {
      firebase_api_key: apiKey,
      firebase_auth_domain: authDomain,
      firebase_database_url: databaseURL,
      firebase_project_id: projectId,
      firebase_storage_bucket: storageBucket,
      firebase_sender_id: messagingSenderId,
      firebase_app_id: appId,
    } = window.FEATURES

    const options = {
      apiKey,
      authDomain,
      databaseURL,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
    }

    try {
      firebase.initializeApp(options)
    } catch (e) {
      defaultLogger.warn('Firebase error: ', e)
    }

    this.messaging = firebase.messaging()
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        const chatId = event.data.chatId
        const teamId = event.data.teamId
        // if the chat ID came, then you need to update the dialog
        if (chatId) {
          if (teamsStore.state.currentTeamId === teamId) {
            router.push({
              name: 'Chat',
              params: {
                teamId: teamsStore.getters.currentTeam.uid,
                jid: chatId,
              },
            })
          } else if (teamId) {
            teamsStore.actions.changeTeam({ teamId, chatId })
          }
        } else {
          defaultLogger.warn('[Firebase.message] There is no chat id', event)
        }
      })
    }
    this.messaging.onMessage((payload: any) => {
      defaultLogger.debug('[Firebase.init.onMessage] Message received.', payload)

      const data = payload.notification || payload.data
      if (!data) {
        defaultLogger.warn('[Firebase.init.onMessage] There is no notification data.', payload)
        return
      }

      const notification = new Notification(data.title, data)
      notification.onerror = error => { defaultLogger.warn('[Firebase.init.onMessage] Notification error:', error) }
    })

    this.getPermission() === Permission.GRANTED &&
    isNotificationsAllowed() &&
    this.subscribe({ callOnGranted: null })
  }

  async subscribe ({ callOnGranted, force }: SubscribeParameters) {
    defaultLogger.debug('Subscribing to notifications...')
    if (!this.messaging) {
      defaultLogger.warn('[Firebase.subscribe] Firebase messaging object is null')
      return
    }

    try {
      await Notification.requestPermission()

      callOnGranted && callOnGranted.forEach(fn => fn())

      try {
        const currentToken = await this.messaging.getToken()
        defaultLogger.debug('Got current token:', currentToken)

        if (!currentToken) {
          defaultLogger.warn('[Firebase.subscribe] Не удалось получить токен.')
          this.storeToken(null)
          return
        }

        await this.editDevice({ token: currentToken, force })
      } catch (err) {
        defaultLogger.warn('[Firebase.subscribe] При получении токена произошла ошибка.', err)
        this.storeToken(null)
      }
    } catch (err) {
      defaultLogger.warn('[Firebase.subscribe] Не удалось получить разрешение на показ уведомлений.', err)
    }
  }

  async editDevice ({ token, force }: { token: string; force?: boolean }) {
    defaultLogger.debug('Editing device', token, force)
    if (!this.isTokenCorrect(token) || force) {
      defaultLogger.debug('[Firebase.editDevice] Отправка токена на сервер...', force)

      if (!isNotificationsAllowed() && force === false) {
        defaultLogger.debug('[Firebase.editDevice] Уведомления выключены')
        return
      }

      const device = {
        active: true,
        allowed_notifications: true,
        name: window.navigator.userAgent,
        notification_token: token,
        type: 'web',
        data_pushes: true,
      }

      let deviceId = localStorage.getItem('device_id')
      try {
        if (deviceId) {
          await api.devices.edit(deviceId, device)
          force && allowNotifications(true)
        } else {
          deviceId = uid()
          defaultLogger.debug('Generating new device ID', deviceId)

          Object.assign(device, { device_id: deviceId })
          const r = await api.devices.create(device)

          localStorage.setItem('device_id', r.deviceId)
          allowNotifications(true)
        }
      } catch (error) {
        defaultLogger.warn('[Firebase.editDevice]', error)
      }
      this.storeToken(token)
    } else {
      defaultLogger.debug('[Firebase.editDevice] Токен уже отправлен на сервер.')
    }
  }

  isTokenCorrect (token: string) {
    return localStorage.getItem('sentFirebaseMessagingToken') === token
  }

  storeToken (token: string | null) {
    if (!token) {
      localStorage.removeItem('sentFirebaseMessagingToken')
      return
    }
    localStorage.setItem('sentFirebaseMessagingToken', token)
  }

  async unsubscribe () {
    if (!isNotificationsAllowed()) {
      defaultLogger.debug('[Firebase.unsubscribe] Уведомления уже выключены')
      return
    }

    const deviceId = localStorage.getItem('device_id')
    if (!deviceId) {
      defaultLogger.debug('[Firebase.unsubscribe] Уведомлений и не было (localStorage[\'device_id\'] is null)')
      return
    }

    const token = localStorage.getItem('sentFirebaseMessagingToken')
    if (!token) {
      defaultLogger.debug('[Firebase.unsubscribe] Уведомлений и не было (localStorage[\'sentFirebaseMessagingToken\'] is null)')
      return
    }

    await api.devices.delete(deviceId)
    allowNotifications(false)
    localStorage.removeItem('device_id')
  }

  getPermission () {
    return (window as any).Notification.permission
  }

  getType () {
    return Type.FIREBASE
  }
}
