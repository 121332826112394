import { webRTCLogger } from '@/loggers'
import { JSEP } from '@tada-team/tdproto-ts'
import { WebRTCBase, WebRTCBaseConstructorParams } from './WebRTCBase'

type WebRTCVideoSenderConstructorParams = WebRTCBaseConstructorParams & {
  // stream: MediaStream;
  muted?: boolean
}

type Transceivers = {
  audio: RTCRtpTransceiver;
  video: RTCRtpTransceiver;
}

export class WebRTCVideoSender extends WebRTCBase {
  // private localStream: MediaStream | null = null
  // private localAudio: MediaStreamTrack | null = null
  // private localVideo: MediaStreamTrack | null = null
  private readonly transceivers: Transceivers

  constructor (p: WebRTCVideoSenderConstructorParams) {
    super(p)
    // this.localStream = p.stream

    this.addOwnListeners()
    // this.addTracksFromLocalStream()
    this.transceivers = this.createTransceivers()
    this.updateOffer(p.muted)
  }

  public readonly onRemoteSDP = async (jsep: JSEP): Promise<void> => {
    webRTCLogger.log('Handling remote SDP of type:', jsep.type)
    webRTCLogger.debug('JSEP:', jsep)

    const { type, sdp } = jsep

    if (type !== 'answer') {
      webRTCLogger.error('Remote JSEP of invalid type:', type)
      return this.tryToReconnect()
    }

    await this.peerConnection.setRemoteDescription({ type, sdp })
    webRTCLogger.debug('Remote SDP set.')
  }

  public readonly removeVideoTrack = async (): Promise<void> => {
    await this.transceivers.video.sender.replaceTrack(null)
    // this.localVideo = null
  }

  public readonly useTracksFromStream = async (s: MediaStream): Promise<void> => {
    webRTCLogger.debug('Adding tracks from stream:', s)
    const a = s.getAudioTracks()
    const v = s.getVideoTracks()

    if (a.length > 0) {
      webRTCLogger.debug('Adding audio track...')
      if (a.length > 1) {
        webRTCLogger.warn('More than one track of the same type on stream', a)
      }
      await this.transceivers.audio.sender.replaceTrack(a[0])
      webRTCLogger.debug('Audio track added')
    } else {
      webRTCLogger.debug('No audio tracks on stream.')
    }

    if (v.length > 0) {
      webRTCLogger.debug('Adding audio track...')
      if (v.length > 1) {
        webRTCLogger.warn('More than one track of the same type on stream', v)
      }
      await this.transceivers.video.sender.replaceTrack(v[0])
      webRTCLogger.debug('Video track added')
    } else {
      webRTCLogger.debug('No video tracks on stream.')
    }

    if (a.length === 0 && v.length === 0) {
      webRTCLogger.warn('No audio or tracks on stream.')
    }
  }

  // public readonly setLocalStream = async (
  //   s: MediaStream
  // ): Promise<void> => {
  //   webRTCLogger.debug('Setting local stream to', s)
  //   this.localStream = s

  //   webRTCLogger.debug('Adding tracks from stream:', s)
  //   const a = s.getAudioTracks()[0]
  //   const v = s.getVideoTracks()[0]

  //   if (a) {
  //     webRTCLogger.log('Adding audio track', a)
  //     await this.transceivers.audio.sender.replaceTrack(a)
  //     this.localAudio = a
  //     webRTCLogger.debug('Audio track added')
  //   } else {
  //     webRTCLogger.warn('Did not find audio track on stream', s)
  //   }

  //   if (v) {
  //     webRTCLogger.log('Adding video track', v)
  //     await this.transceivers.video.sender.replaceTrack(v)
  //     this.localVideo = v
  //     webRTCLogger.debug('Video track added')
  //   } else {
  //     webRTCLogger.log('Did not find video track on stream', s)
  //   }
  // }

  // public get hasAudio (): boolean {
  //   return !!this.localAudio
  // }

  // public get hasVideo (): boolean {
  //   return !!this.localVideo
  // }

  private readonly createTransceivers = (): Transceivers => {
    return {
      audio: this.peerConnection.addTransceiver('audio'),
      video: this.peerConnection.addTransceiver('video'),
    }
  }

  private readonly addOwnListeners = (): void => {
    this.peerConnection.ontrack = this.onTrack
  }

  // private readonly addTracksFromLocalStream = (): void => {
  //   webRTCLogger.log(
  //     'Adding tracks to peer connection from local stream:',
  //     this.localStream
  //   )

  //   this.localStream.getTracks().forEach(track => {
  //     webRTCLogger.debug(`Using ${track.kind} device:`, track.label)
  //     webRTCLogger.debug('Adding track to peer connection...')
  //     this.peerConnection.addTrack(track, this.localStream)
  //   })
  // }

  private readonly updateOffer = async (muted?: boolean): Promise<void> => {
    webRTCLogger.debug('Creating local offer...')
    const offer = await this.peerConnection.createOffer()
    const { sdp } = offer

    if (!sdp) {
      webRTCLogger.error('No SDP in created offer. Offer:', offer)
      throw new Error('No SDP in created offer.')
    }

    await this.peerConnection.setLocalDescription(offer)
    this.signalling.offer(sdp, muted)
  }

  private readonly onTrack = (event: RTCTrackEvent): void => {
    webRTCLogger.error('OnTrack in Sending WebRTC peer connection', event)
  }
}
