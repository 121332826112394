/**
 * @file
 *   Setup prototype of amp metrik, override base window.goal function
 *   for sending TWO metriks in one goal:
 * ?    -> uses for hypothesis checking (better default (ya) or new (amp)?)
 *
 * Documentation:
 *   @link https://developers.amplitude.com/docs/javascript
 *
 * @since 06.07.21
 * @author Artem Shuvaev
 */

// #region Imports
import { defaultLogger } from '@/loggers'
import type { MetrikaGoal } from '@/types/__global'
import amplitude from 'amplitude-js'
// #endregion

/**
 * Get desktop version from user agent based on logic and hardcode splitting
 * @see index.html
 * @returns string version in format 'X.Y.Z'
 * @example
 *    getDesktopVersion() -> '1.0.14'
 */
const getDesktopVersion = (): string =>
  navigator.userAgent
    .split(' ')
    .find(el => el.startsWith('tada'))
    ?.split('/')[1] ?? '0.0.0'

/**
 * Setup additional metrik for Amplitude
 * ! ONLY with default metrika, not instead or without
 * @link https://developers.amplitude.com/docs/advanced-settings
 * @link https://developers.amplitude.com/docs/javascript
 * @todo remove this or default metrik after testing of prototype
 */
export const setup = (): void => {
  if (!window.FEATURES.is_testing || !window.FEATURES.metrika || !window.FEATURES.amplitude_api_key) {
    window.goal = window.yaGoal // if not use -> use default metrik only
    return
  }

  // setup the unique user data like copy as default metrik params
  const identify = new amplitude.Identify()
    .setOnce('node', window.FEATURES.installation_type)
    .set('team', window.currentTeamId) // must be not once cause of app launch sequence
    .setOnce('client', window.isElectron ? 'electron' : 'web')

  // version of desktop application
  window.isElectron && identify.setOnce('version', getDesktopVersion())

  try {
    amplitude.getInstance().init(window.FEATURES.amplitude_api_key)
    amplitude.getInstance().identify(identify)
  } catch (e) {
    defaultLogger.log('Empty td-amp setup find (warning)')
    return
  }

  /**
   * Override of base window.goal function for testing new amplitude.js metrik
   * @param target - MetrikaGoal param for target point
   * @param params - additional param for displaying in metrik
   */
  window.goal = (
    target: MetrikaGoal,
    params: Record<string, string | number>,
  ) => {
    try {
      amplitude.getInstance().logEvent(target, params)
    } catch {} // it's no reason to tell users/debuggers that we can't send info about them
    window.yaGoal(target, params) // has inner try-catch
  }

  defaultLogger.log('td-amp setup success!')
}
