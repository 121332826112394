(function () {
  /* eslint-disable no-extend-native */
  /* eslint-disable no-undef */

  require('intersection-observer')
  // require('webrtc-adapter')

  if (!('MediaRecorder' in window)) {
    window.MediaRecorder = require('audio-recorder-polyfill')
  }

  // if (!window.RTCPeerConnection) {
  //   window.RTCPeerConnection = window.webkitRTCPeerConnection || window.mozRTCPeerConnection
  // }

  // if (!window.navigator.getUserMedia) {
  //   window.navigator.getUserMedia = window.navigator.getUserMedia || window.navigator.webkitGetUserMedia || window.navigator.mozGetUserMedia
  // }

  if (!HTMLElement.prototype.matches) {
    if (HTMLElement.prototype.msMatchesSelector) HTMLElement.prototype.matches = HTMLElement.prototype.msMatchesSelector
  }

  if (!String.prototype.includes) {
    String.prototype.includes = function () {
      return String.prototype.indexOf.apply(this, arguments) !== -1
    }
  }

  if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: function (searchString, position) {
        position = position || 0
        return this.indexOf(searchString, position) === position
      },
    })
  }

  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      enumerable: false,
      value: function (obj) {
        const newArr = this.filter(function (el) {
          return el === obj
        })
        return newArr.length > 0
      },
    })
  }
})()
