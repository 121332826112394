import { JID } from '@tada-team/tdproto-ts'
import {
  UIMiddleColumn,
  UIModal,
  UIRightBar,
  UISize,
  UITaskDesk,
} from './types'

export const DEFAULT_CHATS_BAR_WIDTH = 320

export default class State {
  keepRightBarHidden = false

  modal: UIModal = {
    instance: '',
    payload: null,
    result: null,
  }

  middleColumn: UIMiddleColumn = {
    instance: 'chat',
    prevInstance: 'chat',
    payload: null,
    small: false,
  }

  chatsBarWidth = DEFAULT_CHATS_BAR_WIDTH

  rightBar: UIRightBar = {
    defaultInstance: 'tasks',
    instance: 'tasks',
    payload: null,
    secondaryInstance: '',
    secondaryPayload: '',
    floated: false,
    size: 420,
    hide: false,
    filtersAndSort: {
      section: undefined, // project UID
      assignees: [], // array of JIDs
      owners: [], // array of JIDs
      tags: [], // array of strings
      sort: null, // * sort typing @see utils.ts/sortTasks fn
      search: '', // string for searching
    },
  }

  taskDesk: UITaskDesk = {
    leftColumnHidden: false,
    active: false,
    compactCards: false,
    tabKey: null,
    filters: {
      assignee: [],
      owner: [],
      tag: [],
      section: [],
      department: [],
      created_gte: null,
      created_lte: null,
      done_gte: null,
      done_lte: null,
      deadline_gte: null,
      deadline_lte: null,
    },
    groups: null,
    draggedTaskData: {
      jid: null,
      from: null,
      to: null,
      columnKey: null,
    },
  }

  selectedMessagesIds: JID[] = []

  callWindowSize: UISize | null = null
}
