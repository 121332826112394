import State from './state'
import type {
  UISettings,
  UISettingsGlobal,
  UISettingsTaskDeskTabFilterKey,
  UISettingsTaskDeskTabSortingKey,
} from './types'

import type { ThemeName } from '@/themes'
import { uiStore } from '@/store'
import {
  UITaskGroup,
} from '@/store/modules/ui/types'
import { Getters as BaseGetters } from 'vuex-smart-module'

export default class Getters extends BaseGetters<State> {
  get uiSettings (): UISettings {
    return this.state.data
  }

  get language (): string | null {
    return this.state.language
  }

  get debugLevel () {
    return this.state.data.debugLevel ?? 5
  }

  sortingChatsType (direct: boolean): number {
    return this.state.data.sortingChatsType?.[direct ? 'direct' : 'group'] || 0
  }

  // lastOpenChat: state => state.uiSettings.data['last-chat'],

  get lastOpenTasksTab (): string | null {
    return this.state.data.lastOpenTasksTab
  }

  get theme (): ThemeName | '' {
    return this.state.data.theme
  }

  get previousTheme (): ThemeName | '' {
    return this.state.data.previousTheme
  }

  get isThemeNY (): boolean {
    return this.state.data.theme === 'ny'
  }

  get desktopAutodownloadFiles (): boolean {
    return this.state.data.desktopAutodownloadFiles
  }

  // // if someone gets an old config, it may break, so we use the default value
  get currentDeskFilterKey (): UISettingsTaskDeskTabFilterKey {
    return this.state.data.taskDesk.tabFilterKey || 'section'
  }

  get currentDeskSortingKey (): UISettingsTaskDeskTabSortingKey {
    return this.state.data.taskDesk.tabSortingKey || 'activity'
  }

  get taskDeskCurrentGroupsOrder () {
    const groupByKey = this.getters.currentDeskFilterKey
    return this.state.data.taskDesk.groupsOrder[groupByKey]
  }

  /**
   * @returns already ordered groups for current order
   */
  get alreadyOrderedGroups (): UITaskGroup[] {
    const currentOrder = this.getters.taskDeskCurrentGroupsOrder || []

    const alreadyOrderedGroups: UITaskGroup[] = []

    currentOrder.forEach(uid => {
      const group = uiStore.getters.currentDeskGroups?.find(g => g.key === uid)
      if (!group) return
      alreadyOrderedGroups.push(group)
    })
    return alreadyOrderedGroups
  }

  /**
   * @returns Get not ordered groups for current order
   */
  get notOrderedGroups (): UITaskGroup[] {
    const currentOrder = this.getters.taskDeskCurrentGroupsOrder || []

    const newGroups: UITaskGroup[] = []
    uiStore.getters.currentDeskGroups && uiStore.getters.currentDeskGroups.forEach(g => {
      const alreadyOrdered = currentOrder.includes(g.key)
      if (alreadyOrdered) return
      newGroups.push(g)
    })
    return newGroups
  }

  get globalSettings (): UISettingsGlobal {
    return this.state.globalSettings
  }

  globalSetting (settingName: keyof UISettingsGlobal) {
    return this.state.globalSettings[settingName]
  }
}
