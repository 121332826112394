import { defaultLogger } from '@/loggers'

type InactiveDetectorListener = (isActive: boolean) => unknown

class InactiveDetector {
  public readonly listeners: Array<InactiveDetectorListener> = []

  private timeoutId: number | null = null
  private acting = false
  private readonly idleTimeout: number

  constructor (idleTimeout = 15000) {
    this.idleTimeout = idleTimeout
  }

  public get isActive (): boolean {
    return this.timeoutId !== null
  }

  public readonly init = () => {
    window.addEventListener('mousemove', this.start)
    window.addEventListener('keypress', this.start)

    document.addEventListener('visibilitychange', this.visibilityChangeHandler)

    defaultLogger.debug('[InactiveDetector] Initialized...')
  }

  private readonly start = () => {
    if (this.timeoutId) {
      this.acting = true
      return
    }

    this.timeoutId = setTimeout(this.stop, this.idleTimeout)
    this.listeners.forEach(l => l(this.isActive))
  }

  private readonly stop = (force = false) => {
    this.timeoutId && clearTimeout(this.timeoutId)
    this.timeoutId = null

    this.listeners.forEach(l => l(this.isActive))
    if (!force && this.acting) {
      this.start()
      this.acting = false
    }
  }

  private readonly visibilityChangeHandler = () => {
    document.hidden ? this.stop(true) : this.start()
  }

  /**
   * Doesn't seem to be used at all. Check this before using.
   */
  public readonly flush = () => {
    this.stop()

    window.removeEventListener('mousemove', this.start)
    window.removeEventListener('keypress', this.start)
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler)
  }
}

export default new InactiveDetector()
