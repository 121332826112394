import { networkLogger } from '@/loggers'

const listeners: Map<string, Array<(params: any) => unknown>> = new Map()

export default {
  addListeners (ls: Record<string, (params: any) => unknown>) {
    Object.entries(ls).forEach(([label, callback]) => {
      if (typeof callback === 'function') {
        listeners.has(label) || listeners.set(label, [])
        listeners.get(label)!.push(callback) // ugh, typescript
      } else {
        networkLogger.error('Callback in socket listener should be a function.')
      }
    })
  },
  clearListeners () {
    listeners.clear()
  },
  emit (label: string, params?: unknown) {
    const subs = listeners.get(label)
    subs && subs.forEach(sub => {
      sub(params)
    })
  },
}
