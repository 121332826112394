
import { getDateWithDifference } from '@/api/v3/DataGenerator'
import parser from '@/components/Chat/ReplyArea/Emoji/Parser'
import i18n from '@/i18n'
import router from '@/router'
import store, {
  activeCallStore,
  chatsBarStore,
  contactsStore,
  groupsStore,
  loginStore,
  tasksStore,
  teamsStore,
  uiStore,
} from '@/store'
import * as Sentry from '@sentry/browser'
import { ChatType, IconData } from '@tada-team/tdproto-ts'
import type Fuse from 'fuse.js'
import linkify from 'linkifyjs/html'
import { date as quasarDate, format as quasarFormat } from 'quasar'
import { defaultLogger } from './loggers'

const { addToDate, formatDate, getDateDiff, subtractFromDate } = quasarDate
const { capitalize } = quasarFormat

function isNotNull (val: any): boolean {
  return val !== null && val !== undefined
}

function preventHtmlTags (s: string): string {
  return s ? s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;') : ''
}

export const formatTextWrappers = (value: string, noop?: boolean) => {
  return value
    .replace(/^\s*&gt;\s+(.+?)$/gm, noop ? '&gt; $1' : '<span class="quote">$1</span>')
    .replace(/```[\r\n]*([\s\S]+?)[\r\n]*```/g, noop ? '$1' : '<div class="pre">$1</div>')
    .replace(/`([^`\r\n]+?)`/g, noop ? '$1' : '<code>$1</code>')
}

function formatRegex (s: string): RegExp {
  return new RegExp('(^|\\s)' + s + '([^\\s' + s + '].*?[^\\s' + s + ']|[^\\s' + s + '])' + s + '(?=\\s|$|\\?|:|\\.|,|!)', 'g')
}

function format (s: string, noop: boolean, makeLinks = true, emoji = true, wrappers = true): string {
  if (wrappers) {
    s = formatTextWrappers(s, noop)
  }
  // FIXME: «прятать» контент, чтобы linkify не подсветил(о)
  s = s.replace(formatRegex('\\*'), noop ? '$1$2' : '$1<span class="bold">$2</span>')
  s = s.replace(formatRegex('/'), noop ? '$1$2' : '$1<span class="italic">$2</span>')
  s = s.replace(formatRegex('~'), noop ? '$1$2' : '$1<span class="strike">$2</span>')
  s = s.replace(formatRegex('_'), noop ? '$1$2' : '$1<span class="underline">$2</span>')
  s = s.replace(/&lt;(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+){0,1}([+-][0-2]\d:[0-5]\d|Z))&gt;/g, (match, p1) => {
    const date = new Date(p1)
    if (!date || isNaN(date.getTime())) {
      return match
    }
    return formatDate(date, 'DD.MM.YYYY, H:mm')
  })
  if (makeLinks && !noop && s.indexOf('.') !== -1) {
    s = linkify(s)
  }

  if (emoji) s = parseEmoji(s)

  return s
}

export function parseEmoji (str: string, sprite?: boolean): string {
  return parser.toImage(str, sprite)
}

/**
 * Prevents HTML tags and parses emoji
 * @param {string} value
 * @returns {string}
 */
export const transformEntityName = (value: string): string => {
  return value ? parseEmoji(preventHtmlTags(value)) : ''
}

/**
 * Simple regexp for parse markdown-style links
 * @param {string} link in format like (label)[http://r.ru]
 * @returns label from link
 * @example parseMarkDownLinkLabel('[label](link.ru)') => 'label'
 */
export const parseMarkdownLinkLabel = (link: string): string => {
  // eslint-disable-next-line no-useless-escape
  return replaceAll(link, /\[([^\]]+)\][^\)]+\)/g, '')
}

const isDatesEquals = (dateA: Date, dateB: Date): boolean => dateA.getDate() === dateB.getDate() && dateA.getMonth() === dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear()
const stringifyDate = (date: Date, currentYear: number): string => {
  const format: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    ...(date.getFullYear() !== currentYear ? { year: 'numeric' } : {}),
  }
  return new Intl.DateTimeFormat(i18n.locale, format).format(date)
}

const isLooksLikePhoneNumber = (value: string): boolean => {
  if (value.length < 2) return false

  for (let i = 0; i < value.length; i++) {
    const c = value[i]

    const isNotDigit = c < '0' || c > '9'
    if (isNotDigit && c !== '+' && c !== ' ' && c !== '(' && c !== ')' && c !== '-') return false
  }
  return true
}

const wrapAsHighlight = (value: string, start: number, end: number): string => {
  const beforeMatch = value.slice(0, start)
  const matchText = value.slice(start, end + 1)
  const afterMatch = value.slice(end + 1)
  return `${beforeMatch}<span class="o-highlighted">${matchText}</span>${afterMatch}`
}

/**
 * Prototype of text.replaceAll
 * @param str - base text
 * @param find - finding value
 * @param replace - replace finding value with this
 * @returns new text with replaced values
 * @todo mark as deprecated after browser version controls by metrics
 */
export const replaceAll = (str: string, find: string | RegExp, replace: string): string => str.split(find).join(replace)

const translitEntries: { [key: string]: string } = { а: 'a', е: 'e', о: 'o', с: 'c', х: 'x', ё: 'e' }
export const normalizeForFilter = (value: string): string => {
  Object.keys(translitEntries).forEach(rus => {
    const lat = translitEntries[rus]
    value = replaceAll(value, rus, lat)
  })
  return value
}

// regex = /(?:\+|\)|\(| )+/
const getBreakpointsRegExp = (value: string, filter: string, breakpoints: Array<string>) => {
  if (!breakpoints) return

  for (let i = breakpoints.length - 1; i >= 0; i--) {
    const breakpoint = breakpoints[i]
    const char = breakpoint.charAt(breakpoint.length - 1)
    const index = filter.indexOf(char)
    index >= 0 && breakpoints.splice(i, 1)
  }
  return new RegExp(`(?:${breakpoints.join('|')})+`, 'g')
}

const isBreakingMatch = (value: string, filter: string, breakpoints = ['\\+', '\\)', '\\(', '-', ' ']) => {
  if (!filter) return true

  if (filter.length > value.length) return false

  filter = filter.toLowerCase()
  filter = normalizeForFilter(filter)

  let valueCopy = value.toLowerCase()
  valueCopy = normalizeForFilter(valueCopy)

  const regex = getBreakpointsRegExp(value, filter, breakpoints)
  const purifiedFilter = regex ? filter.replace(regex, '') : filter
  const purifiedValue = regex ? valueCopy.replace(regex, '') : value

  const matchStartIndex = purifiedValue.indexOf(purifiedFilter)
  if (matchStartIndex < 0) return false

  const matchEndIndex = matchStartIndex + purifiedFilter.length - 1
  return { regex, matchStartIndex, matchEndIndex }
}

const highlightBreakingMatches = (value: string, filter: string, breakpoints = ['\\+', '\\)', '\\(', '-', ' ']) => {
  const matchData = isBreakingMatch(value, filter, breakpoints)
  if (typeof matchData === 'boolean') return value

  const { matchStartIndex, matchEndIndex, regex } = matchData

  if (!regex) return wrapAsHighlight(value, matchStartIndex, matchEndIndex)

  let matchStart = 0
  let matchEnd = 0

  let temp = 0
  for (let i = 0; i < value.length; i++) {
    const char = value[i]

    const valid = !regex.test(char)
    regex.lastIndex = 0

    if (matchStart === 0 && valid && temp === matchStartIndex) {
      matchStart = i
    }

    if (valid && temp === matchEndIndex) {
      matchEnd = i
      break
    }

    if (valid) temp += 1
  }

  if (matchEnd <= matchStart) return value

  return wrapAsHighlight(value, matchStart, matchEnd)
}

const highlightMatches = (value: string, filter: string): string => {
  if (isLooksLikePhoneNumber(filter)) return highlightBreakingMatches(value, filter)

  const valueMatching = normalizeForFilter(value.toLowerCase())
  filter = normalizeForFilter(filter.toLowerCase())

  const input = filter.toLowerCase()
  if (input.length > 0 && valueMatching.includes(input)) {
    const matchStart = valueMatching.indexOf(input)
    const matchEnd = matchStart + input.length - 1

    return wrapAsHighlight(value, matchStart, matchEnd)
  }
  return value
}

export const matchIndex = (a: string, b: string): number => {
  a = normalizeForFilter(a.toLowerCase())
  b = normalizeForFilter(b.toLowerCase())
  return a.indexOf(b)
}

export const isMatch = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return isLooksLikePhoneNumber(b) ? !!isBreakingMatch(a, b) : matchIndex(a, b) >= 0
}

const isObjectEmpty = (o: Record<string, any>, strict = false): boolean => {
  for (const p in o) {
    if (Object.prototype.hasOwnProperty.call(o, p)) return false
  }
  return !strict || JSON.stringify(o) === JSON.stringify({})
}

const clear = () => {
  const { localStorage } = window

  delete localStorage.team
  delete localStorage.chat
  delete localStorage.user
  delete localStorage.vuexstate
  delete localStorage.messages

  Object.keys(localStorage).forEach(key => {
    if (key.indexOf('hy.state') === 0) {
      delete localStorage[key]
    }
  })
}

export const isGUID = (str: any): boolean => {
  if (typeof str !== 'string' || str.length < 36) return false

  return /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i.test(str)
}

/**
 * Logout from app
 * @todo refactor
 * @param toLobby
 * @param move
 * @deprecated need to use router push with api
 */
export const logout = (toLobby = false, move = '/') => {
  clear()

  loginStore.actions.setLoginState(false)

  if (window.isElectron) {
    const ipc = window._ipc
    ipc && ipc.send('server-changer', { message: 'logout' })
  }

  const { location } = window
  // TODO: move to api/v3/index
  location.href = toLobby ? move : '/api/v4/auth/logout'
}

export const move = (uid?: string, chat?: string | null, force = false, callPopup = true) => {
  chat = chat ?? null
  clear()

  if (force) {
    router.push(uid ? (`/${uid}/` + (chat ? `chats/${chat}` : '')) : '/')
    return false
  }

  if (callPopup && activeCallStore.state.activeCall) {
    uiStore.actions.showModal({
      instance: 'universal-yes-no',
      payload: {
        title: i18n.t('calls.notifyCallBar.activeCall').toString(),
        text: i18n.t('calls.callAlerts.sureDisconnect').toString(),
        yesText: i18n.t('common.move').toString(),
        yes: async () => {
          move(uid, chat, force, false)
        },
      },
    })
    return
  }
  if (chat) {
    router.push({
      name: 'Chat',
      params: {
        teamId: uid ?? '',
        jid: chat,
      },
    })
  } else {
    router.push({
      name: 'Team',
      params: {
        teamId: uid ?? '',
      },
    })
  }

  return true
}

/**
 * Parse current window.location.pathname for finding routing milestones
 * @returns Object { teamid: uid, chatId: jid, type: 'group|dashboard'}
 * @example
 *  'https://server.ru/uid-123/chats/jid-1234'
 *   parseUrl() -> { teamId: 'uid-123', chatId: 'jid-1234', type: 'chats' }
 */
export const parseURL = () => {
  const path = window.location.pathname.substr(1)
  const data = path.split('/')

  const removeBackslashes = (str: string) => str ? str.replace(/\//g, '') : ''

  const teamId = removeBackslashes(data[0])
  const type = removeBackslashes(data[1])
  const chatId = removeBackslashes(data[2])

  return { teamId, chatId, type }
}

export const getSizeSuffixes = () => ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = getSizeSuffixes()
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
}

type SortableEntity = {
  displayName: string;
}

export const sortEntitiesByName = <E extends SortableEntity>(list: Array<E>): Array<E> => {
  if (!Array.isArray(list)) return list

  return list.sort((contactA, contactB) => {
    if (!contactA) return -1
    if (!contactB) return 1

    const nameA = contactA.displayName
    const nameB = contactB.displayName

    return nameA > nameB ? 1 : -1
  })
}

export const sortEntriesByChatType = (list: Array<any>): Array<any> => {
  if (!Array.isArray(list)) return list

  return list.sort((entry1, entry2) => {
    // потому что с бэка уже отсортированный, значит entry2 в сравнение - приоритетнее
    if (entry2.meta.chat_type === 'group') return 1
    if (entry1.meta.chat_type === 'group') return -1
    return 0
  },
  )
}

export const getChatType = (jid: string): ChatType => {
  const logToSentry = () => Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Warning)
    scope.setTag('tech debt', 'utils.getChatType')
    scope.setContext('chat', { jid })
    Sentry.captureException('Unexpected chat type detected.')
  })

  if (jid.length < 2) {
    logToSentry()
    throw new Error('Passed unexpected chat type.')
  }

  switch (jid.substr(0, 3)) {
    case 'th-':
      return 'thread'
  }

  switch (jid.substr(0, 2)) {
    case 'd-':
      return 'direct'
    case 't-':
      return 'task'
    case 'g-':
      return 'group'
    case 'm-':
      return 'meeting'
  }

  // should never reach the block below.
  // if it ever does - refactor completely this fn and how it is used
  logToSentry()
  throw new Error('Passed unexpected chat type.')
}

export const isChatDirect = (jid: string): boolean => {
  return getChatType(jid) === 'direct'
}

export const isWebRTCSupported = (): boolean => {
  return !!(
    navigator.mediaDevices?.getUserMedia ||
    navigator.getUserMedia ||
    (navigator as any).webkitGetUserMedia ||
    (navigator as any).mozGetUserMedia
  )
}

export const getChatPlaceholderName = ({ jid, type }: { jid: string; type?: ChatType }) => {
  type = type || getChatType(jid)
  if (!type) return ''

  switch (type) {
    case 'direct': return i18n.t('chatlist.unnamedContact').toString()
    case 'task': return i18n.t('chatlist.unnamedTask').toString()
    case 'group': return i18n.t('chatlist.unnamedGroup').toString()
    case 'meeting': return i18n.t('chatlist.unnamedGroup').toString()
    case 'thread': return i18n.t('chatlist.unnamedGroup').toString()
  }
}

export { isObjectEmpty, stringifyDate, isDatesEquals, isNotNull, preventHtmlTags, format, highlightMatches }

export const scrollIntoViewIfNeeded = function (el: HTMLElement, parentClass?: string) {
  parentClass = parentClass || 'scrollable-content'

  function findAncestor (el: HTMLElement | null, cls: string) {
    while ((el = el && el.parentElement) && !el.classList.contains(cls)) {
    } // tslint:disable-line
    return el
  }

  const parent = findAncestor(el, parentClass)
  if (!parent) return

  const parentComputedStyle = window.getComputedStyle(parent)
  const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'), 10)
  const parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'), 10)
  const overTop = el.offsetTop - parent.offsetTop < parent.scrollTop
  const overBottom = (el.offsetTop - parent.offsetTop + el.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight)
  const overLeft = el.offsetLeft - parent.offsetLeft < parent.scrollLeft
  const overRight = (el.offsetLeft - parent.offsetLeft + el.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth)
  const alignWithTop = overTop && !overBottom

  if (overTop || overBottom || overLeft || overRight) {
    el.scrollIntoView({ behavior: 'smooth', block: alignWithTop ? 'start' : 'end' })
  }
}

export function formatMessageContent (content: TADA.MessageContent) {
  const { type, text } = content
  if (!text) return

  switch (type) {
    case 'image':
      return `<span class="o-text-color">${capitalize(i18n.t('glossary.image').toString())}</span>`
    case 'file': {
      const { name } = content as TADA.MessageContentFile
      return `<span class="o-text-color">${capitalize(i18n.t('glossary.file').toString())}: ${name}</span>`
    }
    default: {
      const mainText = text.replace(/^\s*>.*$/gmi, '')
      return format(preventHtmlTags(mainText.trim() === '' ? text : mainText), true)
    }
  }
}

export const parseVersion = (version: string): Array<number> => {
  // expects ONLY integers and dots (.), returns an array of integers
  const versionArray = version.split('.').map(v => {
    try {
      const num = Number(v)
      return Number.isInteger(num) ? num : 0
    } catch (e) {
      defaultLogger.error('VERSION PARSE ERROR', e)
      return 0
    }
  })
  return versionArray
}

export const compareVersions = (v1: string, v2: string): boolean => {
  // compares two versions. returns true if v1 >= v2, false if else
  // false is intended to be 'bad', so pass v1 as current version and v2 as target
  const v1Arr = parseVersion(v1)
  const v2Arr = parseVersion(v2)

  if (v1Arr.length !== v2Arr.length) {
    defaultLogger.error('VERSION LENGTH MISMATCH')
    return false
  }

  for (let i = 0; i <= v1Arr.length; i++) {
    if (v1Arr[i] < v2Arr[i]) return false
    if (v1Arr[i] > v2Arr[i]) return true
  }

  return true
}

export const checkAndUpdate = async () => {
  const currentVersion = window.FEATURES.front_version
  const fileURL = location.origin + '/features.json'
  try {
    const res = await fetch(fileURL).then(r => r.json())
    const newVersion = res.front_version
    if (!compareVersions(currentVersion, newVersion)) {
      defaultLogger.warn('[Web App update] is available. Force reloading...')
      // it SEEMS to be deprecated, when it's not.
      // DO NOT remove 'true' arg
      location.reload(true)
    } else defaultLogger.warn('[Web App update] is not necessary.')
  } catch (e) {
    defaultLogger.error('Error fetching features during update check', e)
  }
}

export const getDefaultServer = () => {
  return 'https://web.tada.team'
}

export const isOnCustomServer = () => {
  return location.origin !== getDefaultServer()
}

export const openHeadless = (chatId: string, teamId?: string) => {
  if (!teamId && !teamsStore.state.currentTeamId) return
  window.open(`/${teamId ?? teamsStore.state.currentTeamId}/chats/${chatId}/headless`, '_blank')
}

export const sentryUpdateDefaultScope = (teamId?: string) => {
  const team = teamId && teamsStore.state.data[teamId]

  if (!team) return

  Sentry.configureScope(scope => {
    scope.setUser({
      id: team.me.jid,
      username: team.me.displayName,
      client: 'web',
    })
    scope.setContext(
      'team',
      {
        id: teamId,
        name: team.name,
      },
    )
  })
}

/**
 * Checks if a given message can be marked important.
 * @param message TADA.Message to check if it can be marked important
 */
export const canMarkMessageImportant = (message: TADA.Message): boolean => (
  message.sender === store.getters.getUserId ||
  store.getters.chat(message.chatId)?.canSetImportantAnyMessage
)

/**
 * Compares two dates.
 * @param date1 ISO string
 * @param date2 ISO string
 * @returns 1 if date1 is greater, -1 if smaller, 0 if equal
 */
export const compareDates = (date1: string, date2: string): (1 | 0 | -1) => {
  const ud1 = unifyDate(date1)
  const ud2 = unifyDate(date2)

  if (ud2 > ud1) return -1
  else if (ud1 > ud2) return 1
  else return 0
}

/**
 * Universal tasks sorter. Or so it seems. Takes tasks and key.
 * Somewhat of a port of task_handlers.go.
 * @param tasks tasks to sort
 * @param key sorting key to use. will default to created sort
 * @returns same tasks, but sorted, duh.
 */
export const sortTasks = (
  tasks: TADA.Task[],
  key: string,
): TADA.Task[] => {
  const createdSort = (
    t1: TADA.Task,
    t2: TADA.Task,
  ) => compareDates(t1.created, t2.created)

  let sortPredicate: (t1: TADA.Task, t2: TADA.Task) => (1 | -1 | 0)

  switch (key) {
    case 'deadline':
      sortPredicate = (t1, t2) => {
        if (t1.deadline) {
          return !t2.deadline
            ? -1
            : compareDates(t1.deadline, t2.deadline) || createdSort(t1, t2)
        } else {
          return !t2.deadline
            ? createdSort(t1, t2)
            : 1
        }
      }
      break
    case 'new':
      sortPredicate = (t1, t2) => createdSort(t2, t1)
      break
    case 'old':
      sortPredicate = (t1, t2) => createdSort(t1, t2)
      break
    case 'activity':
      sortPredicate = (t1, t2) => {
        const lmc1 = t1.lastMessage?.created
        const lmc2 = t2.lastMessage?.created
        if (lmc1) {
          return !lmc2
            ? -1
            : compareDates(lmc2, lmc1) || createdSort(t2, t1)
        } else return !lmc2 ? createdSort(t2, t1) : 1
      }
      break
    case 'num_unread':
      sortPredicate = (t1, t2) => {
        const diff = (t2.numUnread ?? 0) - (t1.numUnread ?? 0)
        if (diff > 0) return 1
        else if (diff < 0) return -1
        else return createdSort(t2, t1)
      }
      break
    case 'done':
      sortPredicate = (t1, t2) => {
        if (t1.done) {
          return !t2.done
            ? -1
            : compareDates(t2.done, t1.done) || createdSort(t2, t1)
        } else {
          return !t2.done
            ? createdSort(t2, t1)
            : 1
        }
      }
      break
    case 'importance':
      sortPredicate = (t1, t2) => {
        const i1 = t1.importance
        const i2 = t2.importance
        if (typeof i1 === 'number') {
          if (!(typeof i2 === 'number')) return -1
          let diff = i1 - i2
          teamsStore.getters.currentTeam.taskImportanceRev && (diff *= -1)
          if (diff > 0) return 1
          else if (diff < 0) return -1
          else return createdSort(t2, t1)
        } else {
          if (!(typeof i2 === 'number')) return createdSort(t2, t1)
          return 1
        }
      }
      break
    default:
      sortPredicate = (t1, t2) => createdSort(t1, t2)
  }

  return [...tasks].sort(sortPredicate)
}

/**
 * Extracts and loads chats that are not yet present locally.
 * @param messages Messages to extract and load missing chats from
 */
export const loadMissingChatsFromMessages = async (
  messages: TADA.Message[],
): Promise<void> => {
  // use Set containers to prevent duplicating GET server requests
  type chatIdsContainer = Set<string>
  const tasks: chatIdsContainer = new Set()
  const groups: chatIdsContainer = new Set()
  const directs: chatIdsContainer = new Set()
  const meetings: chatIdsContainer = new Set()
  const threads: chatIdsContainer = new Set()

  messages.forEach(m => {
    const chatId = m.chatId

    // do not load chats that are already present locally
    if (store.getters.chatExists(chatId)) return

    let container: chatIdsContainer
    // break by chat type because of different load methods
    switch (m.chatType) {
      case 'task':
        container = tasks
        break
      case 'group':
        container = groups
        break
      case 'direct':
        container = directs
        break
      case 'meeting':
        container = meetings
        break
      case 'thread':
        container = threads
        break
    }
    container && container.add(chatId)
  })

  tasks.forEach(id => tasksStore.actions.loadTask(id))
  groups.forEach(id => groupsStore.actions.loadGroup(id))
  directs.forEach(id => contactsStore.actions.loadContact(id))
  threads.forEach(id => chatsBarStore.actions.loadThreads(id))
}

/**
 * Finds internal app link in an array of available prefixes.
 * @param url URL string to find prefix in
 * @returns found prefix or undefined
 */
export const getMentionPrefix = (url: string): string | undefined => {
  let mentionPrefixes: string[] = window.FEATURES.app_schemes ?? []
  // extend app_schemes strings to url parts
  mentionPrefixes = mentionPrefixes.map(p => p + '://')

  return mentionPrefixes.find(p => url.startsWith(p))
}

/**
 * Naive attempt to unify any date to server precision level.
 * @param isoDate Date string to unify.
 * @returns ISO date string unified to server precision.
 * @throws When a potentially incorrect ISO string is passed.
 */
export const unifyDate = (isoDate: string): string => {
  // this is intentionally a naive check as we only really expect an ISO string
  if (!isoDate || !isoDate.endsWith('Z')) {
    throw new Error(
    `Incoming data does not look like a date: ${isoDate}`,
    )
  }

  const SERVER_ISO_DATE_LENGTH = 27

  const l = isoDate.length
  if (l === SERVER_ISO_DATE_LENGTH) return isoDate
  else if (l < SERVER_ISO_DATE_LENGTH) {
    return isoDate
      .slice(0, length - 1)
      .padEnd(SERVER_ISO_DATE_LENGTH - 1, '0') +
    'Z'
  } else return isoDate.slice(0, SERVER_ISO_DATE_LENGTH - 1) + 'Z'
}

type MessageId = string

/**
 * Returns a position of a message in sorted messages array
 * @param msg Message to get a position of.
 * @param existingMsgs Map of existing messages (sorted or unsorted).
 * @param orderedIds Array of sorted existing messages ids.
 * @returns Position, where a passed message should be in orderedIds.
 */
export const getOrderedMessageIndex = (
  msg: TADA.Message,
  existingMsgs: Record<MessageId, TADA.Message>,
  orderedIds: MessageId[],
): number => {
  // both lists are empty
  if (
    orderedIds.length === 0 &&
    Object.keys(existingMsgs).length === 0
  ) return 0

  if (orderedIds.length === 0) {
    throw new Error(
    `Ordered ids is empty but messages are present. Num msgs: ${Object.keys(existingMsgs).length}`,
    )
  }

  const result = orderedIds.findIndex(id => {
    const currM = existingMsgs[id]
    if (!currM) throw new Error(`Message not present in locale store: ${id}`)
    return compareDates(currM.created, msg.created) >= 0
  })

  // if not found -> it's newest -> return end of list
  return result === -1 ? orderedIds.length : result
}

/**
 * Formats seconds to human-readible format.
 * @param numSeconds number of seconds as integer
 * @returns human-readible string, e.g. 14:48:00
 */
export const formatHHMMSS = (numSeconds: number): string => {
  // TODO: cover with tests
  // TODO: this util may probably be simplified
  const showHours = numSeconds / 3600 >= 1
  const beginSlice = showHours ? 11 : 14
  const endSlice = showHours ? 8 : 5
  // EXAMPLE toISOString => `2011-10-05T14:48:00.000Z` => substr => `14:48:00`
  return new Date(numSeconds * 1000).toISOString().substr(beginSlice, endSlice)
}

/**
 * Date for display in format hh:mm or short month and day (18:00 or 2 may)
 * @param date date for formatting
 */
export const displayedDate = (date: Date):string => {
  const currentDate = getDateWithDifference()

  const today = (
    currentDate.getFullYear() === date.getFullYear() &&
    currentDate.getMonth() === date.getMonth() &&
    currentDate.getDate() === date.getDate()
  )

  const format: Intl.DateTimeFormatOptions = today
    ? { hour: 'numeric', minute: 'numeric' }
    : { month: 'short', day: 'numeric' }

  return new Intl.DateTimeFormat(i18n.locale, format).format(date)
}

export const getDefaultFuseOptions = <T>(
  keys: Array<Fuse.FuseOptionKey<T>>,
): Fuse.IFuseOptions<T> => ({
    includeScore: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    keys,
  })

export const isMessageEditable = (model: TADA.Message): boolean => {
  const { editableUntil } = model

  // TODO: Может вместо того, чтобы каждый раз вызывать getDateWithDifference('iso')
  // Записывать в store (vue'шный) время открытия чата и сравнивать с ним?
  return !!editableUntil && getDateWithDifference() <= new Date(editableUntil)
}

/**
 * Get url icons from entity icon data
 * @param iconData
 * @param large
 */
export const getIconUrl = (iconData: IconData, large?: boolean): string | undefined => {
  const { stub, sm, lg } = iconData

  if (!lg) {
    if (!sm) {
      if (!stub) throw new Error(`Nullish IconData from server: ${iconData}`)
      return stub
    } else {
      return sm.url
    }
  } else {
    if (large) return lg.url
    if (sm) return sm.url
    return lg.url
  }
}

/**
   * Get the time from date to current date
   * @param date date string
   */
export const getTimeFromNow = (date: Date): string | void => {
  const currentDate = Date.now()
  const intlTimeFormatter = new Intl.RelativeTimeFormat(
    i18n.locale,
    { style: 'long' },
  )

  const secondsDiff = getDateDiff(date, currentDate, 'seconds')
  if (Math.abs(secondsDiff) < 60) {
    return intlTimeFormatter.format(secondsDiff, 'seconds')
  }

  const minutesDiff = getDateDiff(date, currentDate, 'minutes')
  if (Math.abs(minutesDiff) < 60) {
    return intlTimeFormatter.format(minutesDiff, 'minute')
  }

  const hoursDiff = getDateDiff(date, currentDate, 'hours')
  if (Math.abs(hoursDiff) < 24) {
    return intlTimeFormatter.format(hoursDiff, 'hour')
  }

  const daysDiff = getDateDiff(date, currentDate, 'days')
  const isDateBeforeNow = secondsDiff < 0
  const monthLong = isDateBeforeNow
    ? getDateDiff(currentDate, subtractFromDate(currentDate, { month: 1 }), 'days')
    : getDateDiff(addToDate(currentDate, { month: 1 }), currentDate, 'days')
  if (Math.abs(daysDiff) < monthLong) {
    return intlTimeFormatter.format(daysDiff, 'day')
  }

  const monthDiff = getDateDiff(date, currentDate, 'months')
  if (Math.abs(monthDiff) < 12) {
    return intlTimeFormatter.format(monthDiff, 'month')
  }

  const yearsDiff = getDateDiff(date, currentDate, 'years')
  return intlTimeFormatter.format(yearsDiff, 'year')
}

export const isMaxTotal = (total: number): boolean => total === window.FEATURES.max_message_search_limit

export const getFormattedTotal = (total: number): string =>
  `${total}${isMaxTotal(total) ? '+' : ''}`

export const getAllowedFileExtensions = (): string[] => {
  const { FEATURES } = window
  if (FEATURES.file_extension_whitelist_priority) {
    const extensions = FEATURES.file_extension_whitelist ?? []
    return extensions.map(e => '.' + e)
  }
  return []
}

export const isNotUndefined = <T>(value: T | undefined): value is T => {
  return value !== undefined
}

export const stopTracksFromStream = (s?: MediaStream | null): void => {
  if (!s) return
  s.getTracks().forEach(t => t.stop())
}

export const hasCyrillic = (s: string): boolean => /[а-яА-ЯЁё]/.test(s)

export const getUserMedia = async (
  p: MediaStreamConstraints,
): Promise<MediaStream | undefined> => {
  if (window.isElectron) {
    if (p.video && window._electronFeatures?.includes('camDenied')) return
    if (p.audio && window._electronFeatures?.includes('micDenied')) return
  }
  return navigator.mediaDevices.getUserMedia(p)
}

export const parseDraft = (raw: string): string => {
  try {
    return JSON.parse(raw).ops
      .map((op: {insert: {emoji: string, mention: {type: string; sequence: string}}}) => op.insert.mention
        ? op.insert.mention.type + op.insert.mention.sequence
        : op.insert.emoji || op.insert,
      )
      .join('')
  } catch (e) {
    defaultLogger.warn('[parseDraft]', e)
  }

  return raw
}
