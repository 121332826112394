import api, { _fetch } from '@/api/v3'
import { DataAdapter } from '@/api/v3/DataAdapter'
import store, { groupsStore, teamsStore } from '@/store'
import {
  DELETE_CHAT,
  OPEN_CHAT_WITH_ERROR,
  UPDATE_CHAT,
} from '@/store/actionTypes'
import Getters from '@/store/modules/publicGroups/getters'
import Mutations from '@/store/modules/publicGroups/mutations'
import State from '@/store/modules/publicGroups/state'
import * as Sentry from '@sentry/browser'
import { Chat, JID } from '@tada-team/tdproto-ts'
import { Actions as BaseActions } from 'vuex-smart-module'
import router from '@/router'

export default class Actions extends BaseActions<
  State,
  Getters,
  Mutations,
  Actions
> {
  async join (id: JID): Promise<void> {
    this.mutations.setIsJoining(true)

    let group: Chat

    try {
      group = await api.publicGroups.join(id)
    } catch (e) {
      this.mutations.setIsJoining(false)
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('publicGroups', 'join')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to join to group')
      })
      return Promise.reject(e)
    }

    if (this.state.sneakpeekId === id) {
      this.mutations.removeSneakpeekId()
    }

    this.mutations.addJoinedId(id)

    // TODO: this is a migration helper for moving away from DataAdapter
    // to tdproto. Converts tdproto object to a simple DataAdapter object.
    store.dispatch(UPDATE_CHAT, DataAdapter.chat(group.toJSON()))
    groupsStore.actions.update(group)

    this.mutations.setIsJoining(false)
  }

  leaveSneakpeekedGroup (): void {
    const { sneakpeekId } = this.state
    if (!sneakpeekId) return

    // TODO: this is a migration helper for moving away from DataAdapter
    // to tdproto. Converts tdproto object to a simple DataAdapter object.
    store.dispatch(DELETE_CHAT, { chatId: sneakpeekId, type: 'group' })

    this.mutations.removeSneakpeekId()
  }

  async loadAll (): Promise<void> {
    let groups: Chat[]

    try {
      groups = await api.publicGroups.getAll()
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('publicGroups', 'get')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to get all public groups')
      })
      return Promise.reject(e)
    }

    this.mutations.setGroupList(groups)
  }

  async sneakpeekGroup (id: JID): Promise<void> {
    // TODO: this is a migration helper for moving away from DataAdapter
    // to tdproto. Converts tdproto object to a simple DataAdapter object.
    if (id === store.getters.currentChat) return

    let group = this.getters.item(id)

    if (!group) {
      this.mutations.setIsLoading(true)

      try {
        // TODO: this is a migration helper for moving away from DataAdapter
        // to tdproto. Converts tdproto object to a simple DataAdapter object.
        group = await apiGetGroup(id)
      } catch (e) {
        this.mutations.setIsLoading(false)

        // TODO: check and refactor
        const { status } = e as { status: number }

        if (status === 403 || status === 404) {
          // TODO: this is a migration helper for moving away from DataAdapter
          // to tdproto. Converts tdproto object to a simple DataAdapter object.
          store.dispatch(OPEN_CHAT_WITH_ERROR, { chatId: id, type: String(status) })
          return
        }

        Sentry.withScope(scope => {
          scope.setLevel(Sentry.Severity.Error)
          scope.setTag('publicGroups', 'get')
          scope.setContext('error', { e })
          Sentry.captureException('Failed to get group')
        })
        return Promise.reject(e)
      }

      this.mutations.setIsLoading(false)
    }

    if (!group.isPublic && !group.canSendMessage) {
      // TODO: this is a migration helper for moving away from DataAdapter
      // to tdproto. Converts tdproto object to a simple DataAdapter object.
      store.dispatch(OPEN_CHAT_WITH_ERROR, { chatId: id, type: '403' })

      this.actions.leaveSneakpeekedGroup()
      return
    }

    this.mutations.setSneakpeekId(id)

    // TODO: this is a migration helper for moving away from DataAdapter
    // to tdproto. Converts tdproto object to a simple DataAdapter object.
    const chat = DataAdapter.chat(group.toJSON())
    chat.isForSneakpeek = true

    // TODO: this is a migration helper for moving away from DataAdapter
    // to tdproto. Converts tdproto object to a simple DataAdapter object.
    store.dispatch(UPDATE_CHAT, chat)
    groupsStore.actions.update(group)
    router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: id,
      },
    })
  }

  flush (): void {
    this.actions.leaveSneakpeekedGroup()
    this.mutations.clearGroupList()
  }
}

// TODO: this is a migration helper for moving away from DataAdapter to tdproto.
async function apiGetGroup (id: string): Promise<Chat> {
  return Chat.fromJSON(await _fetch(api.groups.getURL(id)))
}
