import i18n from '@/i18n'

import FaviconManager from '@/favicon'
import {
  loginStore,
  teamsStore,
  uiSettingsStore,
  tasksStore,
} from '@/store'

export default function (store) {
  const getter = (_, getters) => {
    const {
      currentChat,
      chatsTotalUnreadNum,
      isSocketOpen,
      rosterLoadingStatus,
      'ui/isTaskDeskOpen': isTaskDeskOpen,
    } = getters

    return {
      // Made by an arrow function to prevent the calculation of fields before the smart modules are initialized
      currentChatName: () => (getters.entity(currentChat) || {}).displayName,
      unreadAmount: chatsTotalUnreadNum,
      unreadTasksCounter: tasksStore?.getters.unreadCounter,
      isSocketOpen,
      isTaskDeskOpen,
      rosterLoadingStatus,
      useTeamAvatarForFavicon: uiSettingsStore?.getters.globalSetting('useTeamAvatarForFavicon'),
    }
  }

  store.watch(getter, ({ currentChatName, unreadAmount, unreadTasksCounter, isSocketOpen, rosterLoadingStatus, isTaskDeskOpen }) => {
    if (!loginStore.state.isLoggedIn) {
      FaviconManager.setDefaultFavicon()
      return
    }

    const { getters } = store

    if (window.isHeadless) {
      unreadAmount = getters.chatNumUnread(getters.currentChat)
      unreadTasksCounter = 0
    }

    const indicator = (unreadAmount ? (unreadAmount > 99 ? '99+' : '' + unreadAmount) : '') + (unreadTasksCounter ? '*' : '')

    const prefix = indicator ? `(${indicator}) ` : ''

    let main = currentChatName()

    if (isTaskDeskOpen) {
      main = `${i18n.t('tasks.taskDesk.title')} - ${teamsStore.getters.currentTeam.name}`
    }

    const title = window.isElectron
      ? `${window.FEATURES.app_title} – ${prefix}${main || ''}`
      : `${prefix}${main || window.FEATURES.app_title}`

    document.title = title

    isSocketOpen && rosterLoadingStatus === 0
      ? FaviconManager.setUnreadMessagesFavicon(unreadAmount + unreadTasksCounter)
      : FaviconManager.setNoConnectionFavicon()
  })
}
