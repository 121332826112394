import { State } from './types'
import actions from './actions'
import mutations from './mutations'

import { Module } from 'vuex'

const module: Module<State, any> = {
  state: {
    data: null,
  },
  /**
   **NOT NEEDED TO REGISTER GETTERS HERE
   * @see [store:getters.js]
   */
  mutations,
  actions,
}

export default module
