import State from '@/store/modules/publicGroups/state'
import { Chat, JID } from '@tada-team/tdproto-ts'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  addJoinedId (payload: JID): void {
    this.state.wasJoined.push(payload)
  }

  clearGroupList (): void {
    this.state.list = []
    this.state.wasJoined = []
  }

  removeSneakpeekId (): void {
    this.state.sneakpeekId = null
  }

  setIsJoining (payload: boolean): void {
    this.state.isJoining = payload
  }

  setIsLoading (payload: boolean): void {
    this.state.isLoading = payload
  }

  setGroupList (payload: Chat[]): void {
    this.state.list = payload
    this.state.wasJoined = []
  }

  setSneakpeekId (payload: JID): void {
    this.state.sneakpeekId = payload
  }
}
