import Vue from 'vue'
import VueI18n, { LocaleMessage } from 'vue-i18n'

import ru from 'quasar/lang/ru'
import en from 'quasar/lang/en-us'
import { Quasar } from 'quasar'

import { defaultLogger } from '@/loggers'
import { uiSettingsStore } from '@/store'

Vue.use(VueI18n)

const i18n: VueI18n = new VueI18n({
  locale: localStorage.getItem('i18next') || getDefaultLanguage(),
  fallbackLocale: 'en',
  // Add default keys to translate the text before loading information about which dictionary should be lazily loaded
  messages: {
    en: {
      common: {
        stateLoadMe: 'Loading user information...',
      },
    },
    ru: {
      common: {
        stateLoadMe: 'Загрузка информации о пользователе...',
      },
    },
  },
  pluralizationRules: {
    /**
     * @param choice {number} selected index in `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} total number of options available
     * @returns final index for choosing the corresponding word variant
     */
    ru: (choice: number, choicesLength: number) => {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    },
  },
})

/**
 * Array loaded languages
 */
const loadedLanguages: string[] = []

/**
 * Setting quasar localization
 * @param language
 */
function setQuasarLocale (language: string) {
  if (language === 'ru') Quasar.lang.set(ru)
  else if (language === 'us') Quasar.lang.set(en)
}

/**
 * Should return only language (w/o region, i.e. ru, not ru-RU)
 */
export function getDefaultLanguage (): string {
  return 'ru'
}

/*
 * Get Vue-i18n instance
 */
export function getComponentInstance () {
  return i18n
}

/**
 * Changing language handler
 * @param lang locale
 */
export function changeLanguage (lang: string): Promise<string> {
  return new Promise(resolve => {
    uiSettingsStore.actions.setLanguageInSettings(lang)
    if (!loadedLanguages.includes(lang)) {
      return loadLanguage(lang).then(() => {
        setLocale(lang)
        return resolve(lang)
      })
    }
    setLocale(lang)
    return resolve(lang)
  })
}

/**
 * Add new lang dictionary
 * @param lang locale
 * @param namespace name of dictionary
 * @param resources translations
 */
export const addResources = (
  lang: string,
  namespace: string,
  resources: LocaleMessage,
) => {
  const message: VueI18n.LocaleMessageObject = {}
  message[namespace] = resources
  i18n.mergeLocaleMessage(lang, message)
}

/**
 * Language dictionary download handler
 * @param lang language
 */
const loadLanguage = (lang: string): Promise<void> => {
  return import(`@/locales/${lang}/index.ts`).then(modules => {
    i18n.mergeLocaleMessage(lang, modules)
    loadedLanguages.push(lang)
  }).catch(err => {
    defaultLogger.warn('[i18n.loadLanguage]', err)
  })
}

/**
 * Localization save handler
 * @param lang language
 */
const setLocale = (lang: string): void => {
  setQuasarLocale(lang)
  i18n.locale = lang
  localStorage.setItem('i18next', lang)
}

/**
 * Vue-i18n init function
 * @param lang
 */
export function init (lang?: string): Promise<string | void> {
  const language = lang || getDefaultLanguage()
  return new Promise(resolve => {
    if (loadedLanguages.includes(language)) {
      setLocale(language)
      return resolve(language)
    }
    return loadLanguage(language)
      .then(() => {
        resolve(language)
        setLocale(language)
      })
      .catch(err => {
        defaultLogger.warn('[i18n.init]', err)
      })
  })
}

export default i18n
