import { getDateWithDifference } from '@/api/v3/DataGenerator'
import CallAudioManager from '@/calls/CallAudioManager'
import { WebRTCVideoReceiver } from '@/calls/WebRTCVideoReceiver'
import { WebRTCVideoSender } from '@/calls/WebRTCVideoSender'
import { callsLogger } from '@/loggers'
import { callsStore, teamsStore } from '@/store'
import { getChatType, getUserMedia, stopTracksFromStream } from '@/utils'
import ws from '@/ws'
import {
  CallEvent,
  ClientCallBuzzCancelParams,
  ClientCallBuzzParams,
  ClientCallLeaveParams,
  ClientCallScreenShareParams,
  ClientCallSoundParams,
  ClientCallVideoParams,
  JID,
  OnlineCall,
  ServerCallAnswerParams,
  ServerCallRejectParams,
  ServerCallRestartParams,
  ServerCallScreenShareParams,
  ServerCallSdpParams,
  ServerCallTalkingParams,
} from '@tada-team/tdproto-ts'
import { Notify } from 'quasar'
import { Actions } from 'vuex-smart-module'
import {
  OUTPUT_VOLUME,
  TALKING_TIMEOUT,
  UI_DISPLAY_TYPE,
  UI_LG_LAYOUT_TYPE,
  UI_LG_RIGHT_BAR_DISPLAY_TYPE,
  UI_MD_DISPLAY_TYPE,
} from './defaults'
import Getters from './getters'
import {
  ActiveCall,
  CallDisplayType,
  CallMdDisplayType,
  CallUiLgLayoutType,
  CallUiLgRightBarDisplayType,
  UserMediaPreference,
} from './models'
import Mutations from './mutations'
import State from './state'

class ModuleActions extends Actions<
  State,
  Getters,
  Mutations,
  ModuleActions
> {
  async answerCall (p: {
    jid: JID,
    teamId?: string,
  }): Promise<void> {
    callsLogger.log(`Answering call ${p.jid}...`)

    CallAudioManager.stop('INCOMING')
    callsStore.actions.removeBuzzingCall(p.jid)

    await this.actions.createCall({
      ...p,
      teamId: p.teamId ?? teamsStore.getters.currentTeam.uid,
      isOutgoing: false,
      startBuzzing: false,
    })
  }

  async startCall (p: {
    jid: JID,
    startBuzzing: boolean,
  }): Promise<void> {
    callsLogger.log(`Starting call: ${p.jid}`)
    await this.actions.createCall({
      ...p,
      teamId: teamsStore.getters.currentTeam.uid,
      isOutgoing: true,
    })

    const isDirect = getChatType(p.jid) === 'direct'
    isDirect && CallAudioManager.play({ track: 'OUTGOING', loop: true })
  }

  async setUserMediaPreference (p: UserMediaPreference): Promise<void> {
    if (this.getters.activeCall.userMediaPreference !== 'unknown') {
      callsLogger.error('Can\'t set user media preference - already set before.')
      return
    }

    if (p === 'none' || p === 'unknown') {
      this.mutations.setUserMediaPreference(p)
      return
    }

    const c: MediaStreamConstraints = {}
    if (p === 'audio') c.audio = true
    else if (p === 'video') c.video = true
    else if (p === 'both') c.video = c.audio = true

    try {
      await this.actions.addUserMediaToPC(c)
    } catch (e) {
      callsLogger.warn('Could not add user media to add to call', e, c)
      if (c.audio) this.actions.setAudioMuted(true)
      if (c.video) this.actions.setVideoMuted(true)
      return
    }

    if (c.audio) this.actions.setAudioMuted(false)
    if (c.video) this.actions.setVideoMuted(false)
  }

  async startScreenshareOnBrowser (): Promise<void> {
    if (window.isElectron) {
      throw new Error('startScreenshareOnBrowser() method does not support Electron')
    }

    let stream: MediaStream

    try {
      stream = await navigator.mediaDevices.getDisplayMedia({ video: true })
    } catch (e) {
      callsLogger.warn('Could not get display media to add to call', e)
      return Promise.reject(e)
    }

    await this.actions.setScreenshareStream(stream)
  }

  async setScreenshareStream (stream: MediaStream): Promise<void> {
    stream.getVideoTracks()[0].onended = () => {
      if (this.state.activeCall?.isScreensharing) this.actions.stopScreenshare()
    }

    await this.actions.useTracksFromStream(stream)
    this.actions.setVideoMuted(false)
    this.mutations.setIsScreensharing(true)
    this.actions.updateScreenshareStateOnServer(true)
  }

  stopScreenshare (): void {
    this.actions.setVideoMuted(true)
    this.mutations.setIsScreensharing(false)
    this.actions.updateScreenshareStateOnServer(false)

    const c = this.getters.activeCall
    const s = c.localStreams.video
    stopTracksFromStream(s)
    this.actions.setLocalStream({ t: 'video', s: null })
    c.webRTCSender.removeVideoTrack()
  }

  /**
   * Invites other users to current active call.
   * @param memberJids array of member JIDs or nothing to invite everyone
   */
  inviteMembers (memberJids?: string[]) {
    const c = this.getters.activeCall

    const packet = new ClientCallBuzzParams(c.jid, memberJids ?? []).toJSON()
    ws.send('client.call.buzz', packet)
  }

  toggleAddMembersDisplay (): void {
    /**
     * LG and MD should always stay in sync. Defaulting to state from MD
     */
    const curr = this.state.uiMdDisplayType

    if (curr === 'ADD_MEMBERS') {
      this.actions.setUiMdDisplayType(this.state.uiMdDisplayTypePrev)
      this.actions.setUiLgRightBarDisplayType(this.state.uiLgRightBarDisplayTypePrev)
    } else {
      this.actions.setUiMdDisplayType('ADD_MEMBERS')
      this.actions.setUiLgRightBarDisplayType('ADD_MEMBERS')
    }
  }

  setUiLgRightBarDisplayType (type: CallUiLgRightBarDisplayType): void {
    this.mutations.setUiLgRightBarDisplayType(type)
  }

  async toggleAudio (): Promise<void> {
    callsLogger.log('Toggling user audio...')
    const c = this.getters.activeCall

    const targetMuteState = !c.isAudioMuted
    callsLogger.debug('Target mute state:', targetMuteState)

    const isStreaming = c.localStreams.audio
    callsLogger.debug('Already streaming:', isStreaming)

    const needToAddMedia = !targetMuteState && !isStreaming
    callsLogger.debug('Need to add user media:', needToAddMedia)

    needToAddMedia && await this.actions.addUserMediaToPC({ audio: true })

    const s = c.localStreams.audio
    s && s.getAudioTracks().forEach(t => (t.enabled = !targetMuteState))
    this.actions.setAudioMuted(targetMuteState)
  }

  async toggleVideo (): Promise<void> {
    callsLogger.log('Toggling user Video...')
    const c = this.getters.activeCall

    const targetMuteState = !c.isVideoMuted
    callsLogger.debug('Target mute state:', targetMuteState)

    const isStreaming = c.localStreams.video
    callsLogger.debug('Already streaming:', isStreaming)

    const needToAddMedia = !targetMuteState && !isStreaming
    callsLogger.debug('Need to add user media:', needToAddMedia)

    needToAddMedia && await this.actions.addUserMediaToPC({ video: true })

    const s = c.localStreams.video
    s && s.getVideoTracks().forEach(t => (t.enabled = !targetMuteState))
    // this.mutations.setMuted({ mute: targetMuteState, t: 'video' })
    // this.actions.updateVideoMuteStateOnServer(targetMuteState)
    this.actions.setVideoMuted(targetMuteState)
  }

  handleNewPeer (p: ServerCallSdpParams): void {
    const call = this.state.activeCall
    if (!call) {
      callsLogger.log('No active call to handle new client')
      return
    }

    if (call.type === 'video_multistream') {
      this.mutations.addMultistreamJids(p.jids)

      const { webRTCReceiver } = call.multistream
      if (webRTCReceiver) {
        webRTCReceiver.updateJSEP(p.jsep)

        return
      }
    }

    const receivingWebRTC = new WebRTCVideoReceiver({
      callJid: p.jid,
      callUid: p.uid,
      callType: call.type,
      iceConfig: callsStore.getters.iceConfig,
      jsep: p.jsep,
      onStateChange: s => { console.log(s) },
      onFatalFailure: r => { console.log(r) },
      onTrack: this.actions.onTrackHandler,
    })
    this.mutations.addWebRTCReceiver(receivingWebRTC)
  }

  handleTalkingEvent (ev: ServerCallTalkingParams): void {
    const c = this.state.activeCall

    if (ev.jid !== c?.jid) {
      callsLogger.log(
        'Received TALKING event for a different active call. That\'s OK.\n',
        'Event:', ev, '\n',
        'Active call:', c,
      )
      return
    }

    const { actor, talking } = ev

    if (talking) {
      this.mutations.setTalkingMember({ actor, isTalking: true })

      /**
       * Whenever someone is talking, set it back to false after a timeout.
       *
       * "Wow!" - one might say.
       * Receiving socket event for the same actor with talking = false is not
       * guaranteed. Sockets are unstable and may lose packets. Server is not
       * 100% bulletproof either. At the same time, one may not be talking
       * forever.
       */
      setTimeout(
        () => this.mutations.setTalkingMember({ actor, isTalking: false }),
        TALKING_TIMEOUT,
      )
    } else {
      this.mutations.setTalkingMember({ actor, isTalking: false })
    }
  }

  toggleOutputSound () {
    const c = this.getters.activeCall
    const v = c.outputVolume === 1 ? 0 : 1

    this.mutations.setOutputVolume(v)
  }

  handleRestartEvent (ev: ServerCallRestartParams) {
    callsLogger.warn(
      'Unexpected RESTART event.\n',
      'Event:', ev,
    )

    const call = this.state.activeCall
    if (call?.jid !== ev.jid) {
      callsLogger.warn(
        'Server requested to restart a call that was not active\n',
        'Local call:', call, '\n',
        'Server event:', ev, '\n',
        'Doing nothing.',
      )
    }
  }

  handleRejectEvent (ev: ServerCallRejectParams): void {
    this.actions.onUnexpectedCallEnd(ev.reason)
  }

  handleAnswerEvent (ev: ServerCallAnswerParams) {
    const c = this.state.activeCall

    if (ev.jid !== c?.jid) {
      callsLogger.log(
        'Received answer event for a different or non-existing call.\n',
        'Call:', c, '\n',
        'Event:', ev,
      )
      return
    }

    const { uid, onliners, jsep } = ev

    this.mutations.setUid(uid)
    this.mutations.setConnectedMembers(onliners ?? [])

    const webRTC = c.webRTCSender
    if (!webRTC) {
      throw new Error(`Received STATE event, but local webRTC is: ${webRTC}`)
    }

    webRTC.onRemoteSDP(jsep)
  }

  handleStateEvent (ev: CallEvent) {
    const c = this.state.activeCall

    if (ev.jid !== c?.jid) {
      callsLogger.debug(
        'JID in state event does not match active call jid. That\'s OK.\n',
        'Data:', ev, '\n',
        'Active call:', c,
      )
      return
    }

    if (ev.finish) {
      callsLogger.log('Received finish in state event.', ev.finish)
      this.actions.endCall()
      return
    }

    const { timestamp, start, onliners, uid } = ev
    this.mutations.setLastUpdate(timestamp)
    this.mutations.setConnectedMembers(onliners ?? [])
    this.mutations.setUid(uid)

    callsLogger.log('Call connected at:', start)

    if (start && c.start !== start) {
      this.mutations.setStart(start ?? null)
      this.actions.setupTimeLengthUpdater()

      this.mutations.setLifecycleState('CONNECTED')

      if (
        c.isLoud &&
        getChatType(c.jid) !== 'direct'
      ) this.actions.inviteMembers()
    }

    if (ev.start) {
      callsLogger.log('Received START in STATE event. Stopping sound.')
      CallAudioManager.stop('OUTGOING')
    }
  }

  setUiDisplayType (type: CallDisplayType): void {
    const goingFullscreen = type === 'fullscreen'
    if (goingFullscreen && !!this.getters.activePresenter) {
      this.actions.setUiLgLayoutType('PRESENTER')
    }

    this.mutations.setUiDisplayType(type)
  }

  setUiLgLayoutType (type: CallUiLgLayoutType): void {
    this.mutations.setUiLgLayoutType(type)
  }

  toggleMembersDisplayType (): void {
    const target = this.state.membersDisplayType === 'CARDS'
      ? 'LIST'
      : 'CARDS'
    this.mutations.setMembersDisplayType(target)
  }

  endCall (): void {
    this.actions.setUiDisplayType(null)

    const c = this.state.activeCall
    if (!c) {
      callsLogger.log('Tried to end non-existing active call. That\'s OK.')
      return
    }

    c.webRTCSender.disconnect(
      'Normal disconnect. Either user or server event with finish',
    )

    c.webRTCReceivers.forEach(r => {
      r.disconnect('Normal disconnect. Either user or server event with finish')
    })

    if (c.multistream.webRTCReceiver) c.multistream.webRTCReceiver.disconnect()

    c.timeInterval && clearInterval(c.timeInterval)

    const streams = Object.values(c.localStreams)
    streams.forEach(stopTracksFromStream)
    this.mutations.setActiveCall(null)
    this.mutations.clearMultistreamJids()
    callsStore.actions.removeBuzzingCall(c.jid)
    callsStore.actions.removeCall(c.jid)
    CallAudioManager.play({ track: 'END' })

    /**
     * Reset call UI params. Every new call should start with default view.
     *
     * Call may end on, e.g. ADD_MEMBERS view.
     * Next call should not start there.
     */
    this.actions.resetCallDisplayOptions()

    /**
     * TODO!: server, WTF? This logic seems broken, but it works
     * and server kindly asked not to touch it for now. Ugh...
     */
    const isGroup = this.getters.activeCallChatType !== 'direct'
    if (isGroup && c.isOutgoing) {
      const packet = new ClientCallBuzzCancelParams(c.jid).toJSON()
      ws.send('client.call.buzzcancel', packet)
    }
    const packet = new ClientCallLeaveParams(c.jid, 'im outta here').toJSON()
    ws.send('client.call.leave', packet)
  }

  onServerCallScreenshare (p: ServerCallScreenShareParams): void {
    const c = this.state.activeCall
    if (c?.jid !== p.callJid) {
      callsLogger.debug('Server screenshare event for inactive call. It\'s OK.')
      return
    }

    if (!p.screenshareEnabled) {
      callsLogger.debug('Screenshare was disabled. Nothing to do.')
      this.mutations.setPresenter({ type: 'server', presenter: null })
      return
    }

    this.mutations.setPresenter({ type: 'server', presenter: p.actorJid })

    /**
     * No need to change display type if user is not in fullscreen.
     * Layout change should be handled by display-type state-machine
     * on user-triggered display type change.
     */
    if (this.state.uiDisplayType !== 'fullscreen') return

    /**
     * No need to change display type if presenter is somehow not connected yet.
     */
    if (!c.connectedMembers.some(m => m.jid === p.actorJid)) return

    this.actions.setUiLgLayoutType('PRESENTER')
  }

  pinMember (p: JID): void {
    this.mutations.setPresenter({ type: 'user', presenter: p })
  }

  private async createCall (p: {
    jid: string;
    teamId: string;
    startBuzzing: boolean;
    isOutgoing: boolean;
  }): Promise<void> {
    callsLogger.log(`Creating call ${p.jid}`)
    this.actions.endCall()
    await callsStore.actions.setIceConfig()
    const callType = callsStore.getters.preferredCallType
    if (!callType) {
      callsLogger.log('No preferred call type to handle new client')
      return
    }

    let audioStream: MediaStream | undefined
    try {
      audioStream = await getUserMedia({ audio: true })
    } catch (e) {
      callsLogger.warn('Could not get audio stream to add to call', e)
    }

    const webRTCSender = new WebRTCVideoSender({
      callJid: p.jid,
      callType,
      iceConfig: callsStore.getters.iceConfig,
      muted: !audioStream,
      onStateChange: this.mutations.setLifecycleState,
      onFatalFailure: this.actions.onUnexpectedCallEnd,
    })

    // TODO: move to DataGenerator? Probably useless to do so.
    const newCall: ActiveCall = {
      jid: p.jid,
      type: callType,
      localStreams: {
        audio: null,
        video: null,
      },
      outputVolume: OUTPUT_VOLUME,
      isOutgoing: p.isOutgoing,
      isScreensharing: false,
      isVideoMuted: true,
      isAudioMuted: !audioStream,
      uid: null,
      talkingMembers: [],
      connectedMembers: [],
      maxConnectedMembersCount: 0,
      lastStateUpdate: 0,
      start: null,
      timeInterval: null,
      length: 0,
      lifecycleState: 'WAIT_FOR_CONNECTION',
      isLoud: p.startBuzzing,
      remoteStreams: [],
      webRTCSender,
      webRTCReceivers: [],
      userMediaPreference: audioStream ? 'audio' : 'unknown',
      presenter: {
        fromServer: null,
        pinnedByUser: null,
      },
      multistream: {
        webRTCReceiver: null,
        audio: [],
        video: [],
      },
    }

    this.mutations.setCamDenied(false)
    this.mutations.setMicDenied(!audioStream)
    this.mutations.setActiveCall(newCall)

    if (audioStream) {
      try {
        await webRTCSender.useTracksFromStream(audioStream)
      } catch (e) {
        callsLogger.error('Could not add tracks from stream.')
        return Promise.reject(e)
      }

      this.actions.setLocalStream({ t: 'audio', s: audioStream })
    }

    /**
     * Create a call straight away.
     * This allows to display an ongoing call in the interface
     * (e.g. chat list items where the call is happening, headers, etc.)
     */
    const onlineCall = new OnlineCall('video', p.jid, '')
    callsStore.actions.createOrUpdateCall(onlineCall)

    this.mutations.setLifecycleState('CONNECTING')
    this.actions.setUiDisplayType('bar')
  }

  private updateScreenshareStateOnServer (enabled: boolean): void {
    const c = this.getters.activeCall
    const p = new ClientCallScreenShareParams(c.jid, enabled)
    ws.send('client.call.screenshare', p.toJSON())
  }

  private setAudioMuted (mute: boolean): void {
    this.mutations.setMuted({ mute, t: 'audio' })
    this.actions.updateAudioMuteStateOnServer(mute)
  }

  private setVideoMuted (mute: boolean): void {
    this.mutations.setMuted({ mute, t: 'video' })
    this.actions.updateVideoMuteStateOnServer(mute)
  }

  /**
   * Gets user media of specified constraints and adds it to peer connection.
   */
  private async addUserMediaToPC (p: MediaStreamConstraints): Promise<void> {
    if (window.isElectron) {
      const { _electronFeatures } = window
      const camDenied = p.video && _electronFeatures?.includes('camDenied')
      const micDenied = p.audio && _electronFeatures?.includes('micDenied')

      if (camDenied) this.mutations.setCamDenied(true)
      if (micDenied) this.mutations.setMicDenied(true)

      if (camDenied || micDenied) {
        return Promise.reject(new Error('OS user media access error'))
      }
    }

    let s: MediaStream
    try {
      s = await navigator.mediaDevices.getUserMedia(p)
    } catch (e) {
      callsLogger.warn('Could not get user media to add to call', e)
      if (p.audio) this.mutations.setMicDenied(true)
      if (p.video) this.mutations.setCamDenied(true)
      return Promise.reject(e)
    }

    if (p.audio && this.state.micDenied) this.mutations.setMicDenied(false)
    if (p.video && this.state.camDenied) this.mutations.setCamDenied(false)

    const c = this.state.activeCall
    if (!c) {
      callsLogger.log('Tried to add user media to non-existing call.')
      stopTracksFromStream(s)
      return
    }

    try {
      await c.webRTCSender.useTracksFromStream(s)
    } catch (e) {
      callsLogger.error('Could not add tracks from stream.')
      return Promise.reject(e)
    }

    if (p.video) this.actions.setLocalStream({ t: 'video', s })
    if (p.audio) this.actions.setLocalStream({ t: 'audio', s })
  }

  async useTracksFromStream (s: MediaStream) {
    const c = this.state.activeCall
    if (!c) {
      callsLogger.log('Tried to add user media to non-existing call.')
      stopTracksFromStream(s)
      return
    }

    try {
      await c.webRTCSender.useTracksFromStream(s)
    } catch (e) {
      callsLogger.error('Could not add tracks from stream.')
      return Promise.reject(e)
    }

    this.actions.setLocalStream({ t: 'video', s })
  }

  private updateAudioMuteStateOnServer (isMuted: boolean): void {
    const n = 'client.call.sound'
    const c = this.getters.activeCall
    const p = new ClientCallSoundParams(c.jid, isMuted).toJSON()
    ws.send(n, p)
  }

  private updateVideoMuteStateOnServer (isMuted: boolean): void {
    const n = 'client.call.video'
    const c = this.getters.activeCall
    const p = new ClientCallVideoParams(!isMuted, c.jid).toJSON()
    ws.send(n, p)
  }

  private onUnexpectedCallEnd (reason: string): void {
    Notify.create({
      message: reason,
      color: 'negative',
      textColor: 'white',
      position: 'top',
      timeout: 7000,
      actions: [{ icon: 'fas fa-close', color: 'white' }],
      badgeClass: 'hidden',
    })

    this.actions.endCall()
  }

  private setupTimeLengthUpdater (): void {
    const c = this.getters.activeCall
    const s = c.start
    if (!s) {
      callsLogger.warn('Couldn\'t start time updater on non-existing time.', s)
      return
    }

    const startTime = new Date(s).getTime()
    const serverTime = getDateWithDifference('ts')
    const callTime = serverTime - startTime

    /**
     * Clear interval in case one already existed.
     * This may happen in the event of call.start changing during same call.
     */
    const existingInterval = c.timeInterval
    existingInterval && clearInterval(existingInterval)

    this.mutations.updateLength(Math.round(callTime / 1000))

    const interval = setInterval(this.actions.incrementCallLength, 1000)
    this.mutations.setTimeInterval(interval)
  }

  private incrementCallLength (): void {
    const c = this.getters.activeCall

    const length = c.length + 1
    this.mutations.updateLength(length)
  }

  private resetCallDisplayOptions (): void {
    this.actions.setUiDisplayType(UI_DISPLAY_TYPE)
    this.actions.setUiMdDisplayType(UI_MD_DISPLAY_TYPE)
    this.actions.setUiLgLayoutType(UI_LG_LAYOUT_TYPE)
    this.actions.setUiLgRightBarDisplayType(UI_LG_RIGHT_BAR_DISPLAY_TYPE)
  }

  private onTrackHandler (p: { event: RTCTrackEvent, jid: JID }): void {
    const { event, jid } = p

    callsLogger.log(
      'Handling remote track event:\n',
      'Track:', event.track, '\n',
      'Streams:', event.streams,
    )
    callsLogger.debug('Event:', event)

    const call = this.state.activeCall
    if (!call) return

    if (call.type === 'video_multistream') {
      const stream = new MediaStream([event.track])
      switch (event.track.kind) {
        case 'audio': call.multistream.audio.push(stream); break
        case 'video': call.multistream.video.push(stream); break
      }
      return
    }

    event.streams.forEach(stream => {
      this.mutations.setRemoteStream({ stream, jid })
    })
  }

  /**
   * Changes what is currently displayed in the MD call window
   * @param type what to display in MD call window
   */
  private setUiMdDisplayType (type: CallMdDisplayType): void {
    this.mutations.setUiMdDisplayType(type)
  }

  private setLocalStream (
    p: { t: 'audio' | 'video', s: MediaStream | null },
  ): void {
    const c = this.getters.activeCall

    /**
     * Stop existing stream if it exists before replacing it.
     */
    const { t } = p
    let existing: MediaStream | null = null
    if (t === 'audio') {
      existing = c.localStreams.audio
    } else if (t === 'video') {
      existing = c.localStreams.video
    }
    stopTracksFromStream(existing)

    this.mutations.setLocalStream(p)
  }
}

export default ModuleActions
