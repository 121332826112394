import Getters from '@/store/modules/meetings/getters'
import Mutations from '@/store/modules/meetings/mutations'
import State from '@/store/modules/meetings/state'
import { Actions as BaseActions } from 'vuex-smart-module'
import store, { teamsStore } from '@/store'
import {
  MeetingsGetRequest,
  MeetingsGetRequestJSON,
  MeetingsCountResponse,
  MeetingsDatesResponse,
  PaginatedMeetings,
  ChatCounters,
  DeletedChat,
  Meeting,
  MeetingsCreateRequest,
  MeetingsUpdateRequest,
  MeetingsUpdateCellRequest,
  ServerMeetingCellUpdatedParams,
} from '@tada-team/tdproto-ts'
import { meetings } from 'td-api'
import * as Sentry from '@sentry/browser'
import { DataAdapter } from '@/api/v3/DataAdapter'
import * as actionTypes from '@/store/actionTypes'
import { MeetingDialogTemplate } from '@/store/modules/meetings/types'

const meetingsGetRequest =
  (p: { start?: Date, end?: Date, members?: string[] }): MeetingsGetRequest => {
    const { start, end, members } = p
    const startDate = start ?? new Date(2000, 0, 1)
    const endDate = end ?? new Date(2100, 0, 1)
    const params: MeetingsGetRequestJSON = {
      date_from: startDate.toISOString(),
      date_to: endDate.toISOString(),
      members_jids: members?.toString() ?? teamsStore.getters.currentTeam.me.jid,
      team_uuid: teamsStore.getters.currentTeam.uid,
    }
    return MeetingsGetRequest.fromJSON(params)
  }

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  /**
   * Action for setting meeting dialog active state
   * @param value
   */
  setIsMeetingDialogActive (value: boolean): void {
    this.mutations.setIsMeetingDialogActive(value)
  }

  /**
   * Action for opening meeting dialog
   * @param p
   */
  openMeetingDialog (p: { meeting?: Meeting, template?: MeetingDialogTemplate }): void {
    const { meeting, template } = p
    if (meeting) this.mutations.setEditingMeeting(meeting)
    if (template) this.mutations.setMeetingDialogTemplate(template)
    this.actions.setIsMeetingDialogActive(true)
  }

  /**
   * Action for close meeting dialog
   */
  closeMeetingDialog (): void {
    this.actions.setIsMeetingDialogActive(false)
    this.actions.setEditingMeeting(null)
    this.mutations.setMeetingDialogTemplate({})
  }

  /**
   * Action for setting selected meeting id at the right column
   * @param uuid meeting uuid
   */
  setSelectedMeetingUUID (uuid: string | null): void {
    this.mutations.setSelectedMeetingUUID(uuid)
  }

  /**
   * Action to set the record of the edited metting
   * @param meeting
   */
  setEditingMeeting (meeting: Meeting | null): void {
    this.mutations.setEditingMeeting(meeting)
  }

  /**
   * Action for setting the state to display the number of meetings this week
   * @param count
   */
  setMeetingsCount (count: number): void {
    this.mutations.setMeetingsCount(count)
  }

  /**
   * Action for setting the state to display dates on the calendar
   * @param dates
   */
  setMeetingsDates (dates: string[]): void {
    this.mutations.setMeetingsDates(dates)
  }

  /**
   * Action for loading meetings for display in the calendar
   * @param p
   */
  async loadMeetings (p: {
    startDate: string,
    endDate: string,
    membersJids?: string[],
  }): Promise<void> {
    const { startDate, endDate, membersJids } = p
    let response: PaginatedMeetings

    this.mutations.setIsMeetingsLoading(true)
    try {
      const { list } = meetings
      const params: MeetingsGetRequestJSON = {
        date_from: startDate,
        date_to: endDate,
        members_jids: membersJids?.toString(),
        team_uuid: teamsStore.getters.currentTeam.uid,
      }
      response = await list(MeetingsGetRequest.fromJSON(params))
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'load meetings')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    const meetingsResult = response.objects

    // Updated chat store
    meetingsResult.forEach(m => {
      store.dispatch(actionTypes.UPDATE_CHAT, DataAdapter.chat(m.toJSON()))
      this.mutations.addOrUpdateLoadedMeeting(m)
    })

    this.mutations.setMeetings(meetingsResult)
    this.mutations.setIsMeetingsLoading(false)
  }

  /**
   * Action for loading meetings for display in the right column
   * @param p
   */
  async loadMeetingsInstances (p: {
    startDate: string,
    endDate: string,
    membersJids?: string[],
    limit?: number,
    offset?: number
  }): Promise<void> {
    const { startDate, endDate, offset, limit, membersJids } = p
    let response: PaginatedMeetings

    if (this.state.instancesOffset &&
      offset &&
      (this.state.instancesOffset === offset || this.state.instancesCount <= offset)
    ) return

    if (this.state.isMeetingsLoading) return

    this.mutations.setInstancesOffset(offset ?? 0)
    this.mutations.setIsMeetingsLoading(true)
    try {
      const { instancesList } = meetings
      const params: MeetingsGetRequestJSON = {
        offset,
        limit,
        date_from: startDate,
        date_to: endDate,
        members_jids: membersJids?.toString(),
        team_uuid: teamsStore.getters.currentTeam.uid,
      }
      response = await instancesList(MeetingsGetRequest.fromJSON(params))
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'load meetings')
        Sentry.captureException(e)
      })
      this.mutations.setIsMeetingsLoading(false)
      return Promise.reject(e)
    }
    const meetingsResult = response.objects
    this.mutations.setInstancesCount(response.count)

    // Updated chat store
    meetingsResult.forEach(m => {
      store.dispatch(actionTypes.UPDATE_CHAT, DataAdapter.chat(m.toJSON()))
      this.mutations.addOrUpdateLoadedMeeting(m)
    })

    if (!offset) {
      this.mutations.setMeetingsInstances(meetingsResult)
    } else {
      meetingsResult.forEach(m => this.mutations.createOrUpdateMeetingInstance(m))
    }
    this.mutations.setIsMeetingsLoading(false)
  }

  /**
   * Action for loading meeting record by meeting id
   * @param meetingId
   */
  async loadMeeting (meetingId: string): Promise<void> {
    let meeting: Meeting
    if (this.state.loadedMeetings[meetingId]) return
    try {
      const { getById } = meetings
      meeting = await getById(meetingId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'load meeting')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    this.mutations.addOrUpdateLoadedMeeting(meeting)
  }

  /**
   * Action for loading meetings count on the current week
   */
  async loadMeetingsCount (): Promise<void> {
    this.mutations.setIsMeetingsCountLoading(true)
    let response: MeetingsCountResponse
    try {
      const { getCount } = meetings
      const date = new Date()
      const startDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay() + 1,
        0,
        0,
        0,
      )
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7,
        0,
        0,
        -1,
      )
      response = await getCount(meetingsGetRequest({ start: date, end: endDate }))
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'load counts')
        Sentry.captureException(e)
      })
      this.mutations.setIsMeetingsCountLoading(false)
      return Promise.reject(e)
    }

    this.actions.setMeetingsCount(response.countCells)
    this.mutations.setIsMeetingsCountLoading(false)
  }

  /**
   * Action for loading meetings dates
   * @param members
   */
  async loadMeetingsDates (members?: string[]): Promise<void> {
    let response: MeetingsDatesResponse
    try {
      const { getDates } = meetings
      response = await getDates(meetingsGetRequest({ members }))
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'load dates')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    this.actions.setMeetingsDates(response.dates)
  }

  /**
   * Action for removed meeting by meeting id
   * @param jid
   */
  async removeMeeting (jid: string): Promise<void> {
    try {
      const { deleteMeeting } = meetings
      await deleteMeeting(jid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'meeting removed')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.removeCalendarMeeting(jid)
    this.mutations.removeMeetingInstance(jid)
    this.mutations.removeLoadedMeeting(jid)
  }

  /**
   * Action for create meeting
   * @param request
   */
  async createMeeting (request: MeetingsCreateRequest): Promise<void> {
    let meeting: Meeting
    try {
      const { create } = meetings
      meeting = await create(request)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'create meeting')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.createOrUpdateMeetingInstance(meeting)
    this.mutations.addOrUpdateLoadedMeeting(meeting)
    this.mutations.createOrUpdateCalendarMeetings(meeting)
  }

  /**
   * Action for updated meeting instance
   * @param request
   */
  async updateMeeting (request: MeetingsUpdateRequest): Promise<void> {
    let meeting: Meeting
    try {
      const { update } = meetings
      meeting = await update(request.meetingId, request)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'update meeting')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.createOrUpdateMeetingInstance(meeting)
    this.mutations.addOrUpdateLoadedMeeting(meeting)
    this.mutations.createOrUpdateCalendarMeetings(meeting)
  }

  /**
   * Action for update cell meeting on the calendar
   * @param p
   */
  async updateMeetingCell (p: {
    meetingJid: string,
    request: MeetingsUpdateCellRequest
  }): Promise<void> {
    const { meetingJid, request } = p
    let meeting: Meeting
    try {
      const { updateCell } = meetings
      meeting = await updateCell(meetingJid, request)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('meetings', 'update meeting cell')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.createOrUpdateMeetingCell({
      meeting,
      oldStartAt: request.cellStartOldDate,
    })

    this.mutations.updateMeetingsInstance({
      meeting,
      oldStartAt: request.cellStartOldDate,
    })
  }

  /**
   * Sets the filter state for the right column to show all meetings
   * or only upcoming meetings
   * @param val
   */
  setIsAllMeetings (val: boolean): void {
    this.mutations.setIsAllMeetings(val)
  }

  // ws handlers

  /**
   * Action for update count meetings on the current week
   * @param counters
   */
  serverMeetingsCountersUpdated (counters: ChatCounters[]): void {
    this.mutations.serverMeetingsCountersUpdated(counters)
  }

  /**
   * Action for removing meeting instance
   * @param chats
   */
  serverMeetingsRemoved (chats: DeletedChat[]): void {
    chats.forEach(c => {
      this.mutations.removeLoadedMeeting(c.jid)
    })
    this.mutations.serverMeetingsRemoved(chats)
  }

  /**
   * Action for updating meeting instance
   * @param meetings
   */
  serverMeetingsUpdated (meetings: Meeting[]): void {
    meetings.forEach(meeting => {
      if (!meeting.jid) return
      this.mutations.createOrUpdateMeetingInstance(meeting)
      this.mutations.addOrUpdateLoadedMeeting(meeting)
      this.mutations.createOrUpdateCalendarMeetings(meeting)
    })
  }

  /**
   * Action for updating meeting cell on the calendar
   * @param params
   */
  serverMeetingCellUpdated (params: ServerMeetingCellUpdatedParams): void {
    this.mutations.serverMeetingCellUpdate(params)
  }
}
