import type { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/layouts/TheMain/index.vue'),
    children: [
      {
        path: '',
        name: 'Main',
        component: () => import('@/components/MiddleColumn/index.vue'),
        props: {
          isChatNotSelected: true,
        },
      },
    ],
  },
  /**
   * Good routes below!
   * Login page
   */
  {
    path: '/login',
    component: () => import('@/layouts/TheLogin/index.vue'),
    meta: { requiresAuth: false, type: 'Login' },
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/components/Login/index.vue'),
      },
    ],
  },
  /** Page for choosing mobile/web version on mobile screen
   * Proxying only from Login page
   */
  {
    path: '/mobile',
    meta: { requiresAuth: false, type: 'Mobile' },
    component: () => import('@/layouts/TheLogin/index.vue'),
    children: [
      {
        path: '',
        name: 'Mobile',
        component: () => import('@/components/Mobile/index.vue'),
      },
    ],
  },
  {
    path: '/:teamId',
    meta: { requiresAuth: true, type: 'Team' },
    component: () => import('@/layouts/TheMain/index.vue'),
    props: route => ({ routeTeamId: route.params.teamId }),
    children: [
      {
        path: '',
        name: 'Team',
        component: () => import('@/components/MiddleColumn/index.vue'),
        props: {
          isChatNotSelected: true,
        },
      },
    ],
  },
  {
    path: '/:teamId/chats/:jid/:headless?', // optional headless
    meta: {
      type: 'Chat',
      requiresAuth: true,
    },
    component: () => import('@/layouts/TheMain/index.vue'),
    props: route => ({
      routeTeamId: route.params.teamId,
      headless: route.params.headless,
    }),
    children: [
      {
        path: '',
        name: 'Chat',
        component: () => import('@/components/MiddleColumn/index.vue'),
      },
    ],
  },
  {
    path: '/:teamId/dashboard/:groupBy',
    meta: {
      type: 'Dashboard',
      requiresAuth: true,
    },
    component: () => import('@/layouts/TheMain/index.vue'),
    props: route => ({
      isHideRightBar: true,
      routeTeamId: route.params.teamId,
    }),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/components/MiddleColumn/index.vue'),
      },
    ],
  },
  {
    path: '/:teamId/calendar/:contactId?',
    component: () => import('@/layouts/TheMain/index.vue'),
    props: {
      isCalendar: true,
      isHideRightBar: true,
    },
    children: [
      {
        path: '',
        name: 'Calendar',
        component: () => import('@/components/Calendar/index.vue'),
      },
    ],
  },
  /**
   * Onboarding part
   * uses nesting routes with container
   */
  {
    path: '/onboarding',
    meta: { requiresAuth: true, type: 'Onboarding' },
    component: () => import('@/layouts/TheLogin/index.vue'),
    children: [
      {
        path: '',
        name: 'Onboarding',
        component: () => import('@/components/Onboarding/index.vue'),
        children: [
          /**
           * Main teams managment page
           */
          {
            name: 'Teams',
            path: 'view',
            component: () => import('@/components/Onboarding/TheTeamsView.vue'),
          },
          /**
           * Invitations for user to teams
           */
          {
            name: 'Invitations',
            path: 'invitations',
            component: () => import('@/components/Onboarding/TheInvitationsView.vue'),
          },
          /**
           * Page for adding to new created team
           */
          {
            name: 'AddToTeam',
            path: 'invites',
            component: () => import('@/components/Onboarding/TheAddToTeamView.vue'),
          },
          /**
           * Create new team page
           */
          {
            name: 'CreateTeam',
            path: 'team/:edit?', // optional
            component: () => import('@/components/Onboarding/TheTeamsCreationView.vue'),
          },
          /**
           * Set user profile info: first/second/last name, avatar, etc
           */
          {
            name: 'UserProfile',
            path: 'profile',
            component: () => import('@/components/Onboarding/TheUserProfileView.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:teamId/blocked',
    props: route => ({
      routeTeamId: route.params.teamId,
      headless: route.params.headless,
    }),
    component: () => import('@/layouts/TheError/index.vue'),
    children: [{
      path: '',
      name: 'Blocked',
      component: () => import('@/components/Blocking/index.vue'),
    }],
  },
  /**
   * All 404 proxying to Main page (Entry.vue)
   */
  {
    path: '*',
    name: '404',
    redirect: { name: 'Main' },
  },
]

export default routes
