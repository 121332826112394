import log, { LogLevelDesc } from 'loglevel'

const { is_testing: isTesting } = window.FEATURES || {}
const isProd = process.env.NODE_ENV === 'production'
const defaultLevel: LogLevelDesc = isTesting ? 'debug' : isProd ? 'silent' : 'info'

export const callsLogger = log.getLogger('CALLS')
export const defaultLogger = log.getLogger('DEFAULT')
export const electronLogger = log.getLogger('ELECTRON')
export const loginLogger = log.getLogger('LOGIN')
export const networkLogger = log.getLogger('NETWORK')
export const routerLogger = log.getLogger('ROUTER')
export const updatesLogger = log.getLogger('UPDATES')
export const userMediaLogger = log.getLogger('USER_MEDIA')
export const webRTCLogger = log.getLogger('WEBRTC')

export const meetingDialogLogger = log.getLogger('MEETING_DIALOG')

// TODO: remove replyLogger and related code
// after fixing the message loss when replying bug (#9069)
export const replyLogger = log.getLogger('REPLY')

// TODO: remove supportTasksLogger and related code
// after fixing the opening support task bug (#9362)
// MR: https://gitlab.tada.team/tadateam/tada_front/-/merge_requests/215
export const supportTasksLogger = log.getLogger('SUPPORT_TASKS')

const loggers = [
  callsLogger,
  defaultLogger,
  electronLogger,
  loginLogger,
  networkLogger,
  replyLogger,
  routerLogger,
  supportTasksLogger,
  updatesLogger,
  userMediaLogger,
  webRTCLogger,
  meetingDialogLogger,
]

const styleLoggerName = (name: string | symbol) => {
  return [`%c[${String(name)}]`, 'color: green;']
}

// redefine original logger method to output "[LOGGER_NAME] log data"
loggers.forEach(logger => {
  const originalFactory = logger.methodFactory
  logger.methodFactory = function (methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName)
    return function (...messages: unknown[]) {
      messages.unshift(...styleLoggerName(loggerName))
      rawMethod(...messages)
    }
  }
})

export const setLoggersLevel = (level: LogLevelDesc) => {
  loggers.forEach(logger => logger.setLevel(level))
  supportTasksLogger.setLevel('info')
}

setLoggersLevel(defaultLevel)
