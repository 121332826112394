import { Getters } from 'vuex-smart-module'
import { Integration, IntegrationKind } from '@tada-team/tdproto-ts'
import State from './state'

class ModuleGetters extends Getters<State> {
  get integrationsByUid (): Record<string, Integration> {
    /**
     * Object.fromEntries breaks strong typings, so use simple loop.
     */
    const r: Record<string, Integration> = {}
    this.state.data.forEach(i => {
      if (!i.uid) return
      r[i.uid] = i
    })
    return r
  }

  get integrationsKindByKind (): Record<string, IntegrationKind> {
    /**
     * Object.fromEntries breaks strong typings, so use simple loop.
     */
    const r: Record<string, IntegrationKind> = {}
    this.state.kinds.forEach(k => (r[k.kind] = k))
    return r
  }
}

export default ModuleGetters
