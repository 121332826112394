import Vue from 'vue'

// its works, dont worry!
import '@/polyfills'

import { defaultLogger } from '@/loggers'
import { setup } from '@/setup'
import { addResources, init } from '@/i18n'
import setupElectron from '@/electron'
import router from '@/router'

import api from '@/api/v3'
import {
  loginStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
  billingStore,
} from '@/store'
import ThemeManager from '@/themes'

import { Team, UserWithMe } from '@tada-team/tdproto-ts'

const isTesting = window.FEATURES.is_testing
Vue.config.devtools = isTesting || process.env.NODE_ENV !== 'production'
isTesting && defaultLogger.info(
  `${window.FEATURES.app_title}${isTesting ? ' [testing]' : ''}: ${process.env
    .BUILD_REVISION || '?'}, Vue: ${Vue.version}`,
)

const storeFirebaseToken = (devices: Array<any>) => {
  defaultLogger.debug('Storing firebase token...')
  devices = devices || []

  const tokenKey = 'sentFirebaseMessagingToken'
  const deviceIdKey = 'device_id'

  try {
    const deviceId = localStorage.getItem(deviceIdKey)
    defaultLogger.debug('Device ID from local storage:', deviceId)
    const device = deviceId && devices.filter(d => d.device_id === deviceId)[0]
    if (device) {
      defaultLogger.debug('Found device:', { ...device })
      localStorage.setItem(tokenKey, device.notification_token)
    } else {
      defaultLogger.debug('Did not find device.')
      localStorage.removeItem(deviceIdKey)
      localStorage.removeItem(tokenKey)
    }
  } catch (e) {
    defaultLogger.warn('[storeFirebaseToken]', e)
  }
}

const prepareTermsFromFeatures = () => {
  const terms = window.FEATURES.terms
  const namespaces: Record<string, any> = {}
  const langNameLength = 2

  Object.entries(terms).forEach(([key, value]) => {
    // process language
    const lang = key.slice(0, langNameLength).toLowerCase()
    if (!Object.prototype.hasOwnProperty.call(namespaces, lang)) {
      namespaces[lang] = {}
    }

    let termName = key.slice(langNameLength)
    termName = termName[0].toLowerCase() + termName.slice(1) // decapitalize

    namespaces[lang][termName] = value
  })

  const namespace = 'featuresTerms'
  Object.entries(namespaces).forEach(([lang, resources]) => {
    addResources(lang, namespace, resources)
  })
}

/**
 * Try load ME with cookie auth, try setup application and else go to Login screen
 */
export const initialHook = async () => {
  try {
    await ThemeManager.setupFeaturesTheme()

    setupElectron()
    setup()

    const {
      teams,
      devices,
      account,
      defaultLang: language,
      teamAccount,
    } = await checkIfAlreadyLoggedIn()

    language && uiSettingsStore.actions.setLanguage({ language })

    await init(language)

    const badProfile = !!teams[0]?.badProfile
    await loginStore.actions.setBadProfileStatus(badProfile)

    if (window.FEATURES.terms) {
      defaultLogger.info(
        '[initApp]',
        'Found some server-side translations:',
        window.FEATURES.terms,
      )
      prepareTermsFromFeatures()
    } else {
      defaultLogger.info(
        '[initApp]',
        'No server-provided tranlsation are present',
      )
    }

    storeFirebaseToken(devices)

    const uiSettings = await api.settings.load()
    const recordTeams = Object.fromEntries(teams.map((t: Team) => [t.uid, t]))
    await uiStore.actions.setupUiSettings()
    await uiSettingsStore.actions.setupUISettingData(uiSettings)
    await billingStore.actions.setBillingAccount(account)
    await billingStore.actions.setBillingTeamAccount(teamAccount)
    await uiSettingsStore.actions.setupTeams({
      teams: recordTeams,
    })
    await teamsStore.actions.setupTeams(recordTeams)
    loginStore.actions.setLoginState(true)
  } catch (e) {
    await init()
    window.FEATURES.terms && prepareTermsFromFeatures()
    await router.push({ name: 'Login' })
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkIfAlreadyLoggedIn = async (): Promise<UserWithMe> => {
  defaultLogger.log('Checking if user is already logged in...')
  try {
    defaultLogger.log('Trying to load Me...')
    return await api.loadMe()
  } catch (e) {
    defaultLogger.log('Could not load Me.')

    if (window.FEATURES.auth_by_kerberos) {
      defaultLogger.log('Attempting Kerberos auth...')
      return await api.authByKerberos()
    }

    return Promise.reject(e)
  }
}
