import State from '@/store/modules/dataImports/state'
import { Getters as BaseGetters } from 'vuex-smart-module'
import { ImportProgressData } from './types'

export default class Getters extends BaseGetters<State> {
  /**
   * Get import loading status by id
   * @param uid import uid
   */
  importFinished (uid: string): boolean {
    const importAction = this.state.data[uid]
    if (!importAction) return false
    const { total, num } = importAction
    return total > 0 && total === num
  }

  progressData (uid: string): ImportProgressData {
    const i = this.state.data[uid]

    if (!i) {
      return {
        value: 0,
        badge: '0 / 0',
      }
    }

    const { num, total } = i
    return {
      value: num / total || 0,
      badge: `${num} / ${total}`,
    }
  }
}
