import { Node } from '@tada-team/tdproto-ts'
import { Mutations } from 'vuex-smart-module'
import State from './state'

class ModuleMutations extends Mutations<State> {
  setAll (nodes: Node[]): void {
    this.state.nodes = nodes
  }
}

export default ModuleMutations
