import { defaultLogger, setLoggersLevel } from '@/loggers'
import api from '@/api/v3'
import ThemeManager from '@/themes'
import { Actions as BaseActions } from 'vuex-smart-module'
import Getters from './getters'
import State from './state'
import Mutations from './mutations'
import type {
  UISettingsGlobal,
  UISettingData,
  UISettings,
  UISettingsTaskDeskTabFilterKey,
  UISettingsTaskDeskTabSortingKey,
} from './types'
import {
  UITaskGroup,
} from '@/store/modules/ui/types'
import store, {
  contactsStore,
  teamsStore,
} from '@/store'
import type { ThemeName } from '@/themes'
import { DataAdapter } from '@/api/v3/DataAdapter'
import { Chat } from '@tada-team/tdproto-ts'
import { LogLevelDesc } from 'loglevel'

const UI_SETTINGS_SAVE_LATENCY = 3000

let buffer: Partial<UISettings> = {}
let saveTimeoutId: number | null = null

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  save (payload: any) { // UI_SETTINGS_SAVE
    api.settings.save(payload)
  }

  saveSettings ({ data, instant = false }: { data: Partial<UISettings>, instant?: boolean }) { // SAVE_SETTINGS
    defaultLogger.info('Saving UI settings:', data)
    defaultLogger.debug('This save may be delayed, see further logs')
    if (!data) return

    const currentTeamId = teamsStore.state.currentTeamId
    if (!currentTeamId) return

    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId)
      saveTimeoutId = null
    }

    this.actions.setUISettingData({ currentTeamId, data })

    buffer = { ...buffer, ...data }
    const saveFn = () => {
      defaultLogger.debug('Actual Ui settings save api request', buffer)
      this.actions.save({
        [`settings-${currentTeamId}`]: { ...this.state.data, ...buffer },
      })
      buffer = {}
    }

    if (instant) {
      saveFn()
    } else {
      saveTimeoutId = setTimeout(saveFn, UI_SETTINGS_SAVE_LATENCY)
    }
  }

  setUISettingData ({ currentTeamId, data }: {
    currentTeamId: string, data: Partial<UISettings>
  }) {
    if (this.state.uiSettingData[`settings-${currentTeamId}`]) {
      this.mutations.setUISettingData({
        id: `settings-${currentTeamId}`,
        data: { ...this.state.uiSettingData[`settings-${currentTeamId}`], ...data },
      })
    } else {
      this.mutations.setUISettingData({
        id: `settings-${currentTeamId}`,
        data: { ...this.state.defaultTeamSettings, ...data },
      })
    }
  }

  addUISettingsData ({ uiSettings, language }: {
    uiSettings?: UISettings,
    language?: string
  }) {
    this.mutations.addData({ uiSettings, language })
  }

  setTabFilter (key: UISettingsTaskDeskTabFilterKey) { // TASK_DESK_SET_TAB_FILTER
    // if team not use task importance - set filter to default 'section'
    if (!teamsStore.getters.currentTeam.useTaskImportance && key === 'importance') key = 'section'
    this.mutations.setTaskDeskTabFilterKey(key)
    const data = {
      taskDesk: {
        ...this.state.data.taskDesk,
        tabFilterKey: key,
      },
    }
    this.actions.saveSettings({ data })
  }

  setTaskDeskTabSorting (key: UISettingsTaskDeskTabSortingKey) { // TASK_DESK_SET_TAB_SORTING
    this.mutations.setTaskDeskTabSortingKey(key)
    const data = {
      taskDesk: {
        ...this.state.data.taskDesk,
        tabSortingKey: key,
      },
    }
    this.actions.saveSettings({ data })
  }

  setDesktopAutodownloadFiles (value: boolean): void { // UI_SET_DEKSTOP_AUTODOWNLOAD_FILES
    this.mutations.setDesktopAutodownloadFiles(value)
    const data = { desktopAutodownloadFiles: value }
    this.actions.saveSettings({ data, instant: true })
  }

  setTaskDeskColumnOrder ({ orderedGroups, movedColumnKey }: {
    orderedGroups: UITaskGroup[], movedColumnKey: string
  }) { // UI_TASK_DESK_SET_COLUMN_ORDER
    const draggedIndex = orderedGroups.findIndex(g => g.key === movedColumnKey)
    if (draggedIndex === -1) return

    const oldOrder = this.getters.taskDeskCurrentGroupsOrder

    // put all newly ordered groups keys into an arr
    // only include in the final order:
    // groups after (to the right) of and including dragged item
    // groups before (to the left) that were ordered before
    const orderedGroupsIds = orderedGroups
      .map(g => g.key)
      .filter((key, i) => i >= draggedIndex || oldOrder.includes(key))

    const groupsOrder = {
      ...this.state.data.taskDesk.groupsOrder,
      [this.getters.currentDeskFilterKey]: orderedGroupsIds,
    }
    this.mutations.setTaskDeskColumnOrder(groupsOrder)
    const data = { taskDesk: { ...this.state.data.taskDesk, groupsOrder } }
    this.actions.saveSettings({ data, instant: true })
  }

  setReordableNotification (shown: boolean | undefined = true): void { // UI_TASK_DESK_SET_REORDABLE_NOTIFICATION
    this.mutations.setReordableNotification(shown)
    const data = {
      taskDesk: {
        ...this.state.data.taskDesk,
        reordableNotificationShown: shown,
      },
    }
    this.actions.saveSettings({ data, instant: true })
  }

  saveGlobalSettings () { // SAVE_GLOBAL_SETTINGS
    this.actions.save({ global_settings: this.state.globalSettings })
  }

  clearTeamSettings () { // UI_CLEAR_TEAM_SETTINGS
    const currentTeamId = teamsStore.state.currentTeamId
    if (!currentTeamId) return

    const teamFieldName = `settings-${currentTeamId}`

    defaultLogger.info('Save-clearing UI settings', teamFieldName)
    // ToDo посмотреть че приходит по запросу изменения настроек и если че сделать await и записывать то, что приходит с сервера
    const clearedSettings = this.state.defaultTeamSettings
    this.actions.save({
      [teamFieldName]: clearedSettings,
    })

    this.actions.addUISettingsData({ uiSettings: clearedSettings })
  }

  clearAllSettings () { // UI_CLEAR_ALL_SETTINGS
    defaultLogger.info('Force clearing ALL UI settings...')
    Object.keys(this.state.uiSettingData)
      .forEach(k => this.actions.save({ [k]: null }))
  }

  async setLanguageInSettings (language: string) { // SET_LANGUAGE_IN_SETTINGS
    if (!language || this.getters.language === language) return

    // api.saveMe({ default_lang: language })
    let me = store.getters.getUserId
    // TODO remove while loop
    while (!me) {
      // user profile may not be initialized yet
      await new Promise(resolve => setTimeout(resolve, 3000))
      me = store.getters.getUserId
    }
    await contactsStore.actions.editContact({
      jid: me,
      params: { default_lang: language },
    })
    this.actions.setLanguage({ language })
  }

  setupUISettingData (data: UISettingData) { // SETUP_UI_SETTINGS
    this.mutations.setupUISettingData(data)

    if (data && data.global_settings) {
      this.mutations.setGlobalSettings(data.global_settings)
    }
  }

  setGlobalSettings (data: UISettingsGlobal) { // UI_SET_GLOBAL_SETTINGS
    if (!data) return
    this.mutations.setGlobalSettings(data)
    this.actions.saveGlobalSettings()
  }

  setLanguage ({ language }: { language: string}) { // UI_SETTINGS_SET_LANGUAGE
    this.actions.addUISettingsData({ language })
  }

  initTeam ({ teamId }: { teamId: string }) { // INIT_TEAM
    console.log('initTeam')
    defaultLogger.info('Initializing team in ui settings', teamId)

    const correctCurrentTeam = this.state.uiSettingData && this.state.uiSettingData.current_team === teamId
    defaultLogger.debug('Correct current team', correctCurrentTeam)

    correctCurrentTeam || this.actions.save({ current_team: teamId }) // Нужно для онбоардинга

    if (!this.state.uiSettingData) {
      defaultLogger.warn('Temp (uiSettings object) is null or undefined')
      return
    }
    this.mutations.setData(this.state.uiSettingData[`settings-${teamId}`])
    this.mutations.setUISettingCurrentTeam(teamId)

    setLoggersLevel(this.getters.debugLevel ?? 3)

    const uiTeamSettings = this.state.uiSettingData[`settings-${teamId}`]

    // set server theme and do nothing else
    const serverTheme = teamsStore.state.data[teamId]?.theme
    if (serverTheme) {
      // TODO remove this any
      ThemeManager.setServerTheme(DataAdapter.theme(serverTheme) as any)
      return
    }

    const theme = uiTeamSettings?.theme
    if (theme) {
      // TODO remove this any
      ThemeManager.setTheme(theme as any)
    }
  }

  // TODO: refactor - create a 'desired' ui-settings API layout and make changes there
  /*
   * Убирает из uisettings все лишнее, оставляя только поля current_team, global_settings, language и
   * поля, отвечающиее за настройки существующих команд (settings-%uid%)
   */
  setupTeams ({ teams = {} }: {
    teams: {
      [k: string]: any
    }
  }) { // SETUP_TEAMS
    if (!Object.keys(this.state.uiSettingData).length) {
      defaultLogger.warn('Temp (uiSettings object) is null or undefined')
      return
    }

    if (!teams) return

    const toRemove: {
      [k: string]: null
    } = {}
    let count = 0

    const teamIds = Object.keys(teams)
    const fieldsToPreserve = [
      'current_team',
      'global_settings',
      'language',
    ]
    Object.keys(this.state.uiSettingData).forEach(key => {
      if (
        fieldsToPreserve.includes(key) ||
        teamIds.some(uid => key === `settings-${uid}`)
      ) return

      count++
      toRemove[key] = null
    })

    if (count > 0) {
      defaultLogger.debug(`${count} field(s) was removed from uiSettings`, toRemove)
      this.actions.save(toRemove)
    }
  }

  setDebuglevel (newLevel: LogLevelDesc) { // UI_SETTINGS_SET_DEBUGLEVEL
    this.mutations.setDebuglevel(newLevel)
    const data = { debugLevel: newLevel }
    this.actions.saveSettings({ data, instant: true })
    setLoggersLevel(newLevel)
  }

  themeChanged ({ to }: { to: ThemeName }) { // THEME_CHANGED
    defaultLogger.info('Detected theme chage THEME_CHAGED to', to)

    const theme = to
    const previousTheme = this.state.data.theme
    const data: Partial<UISettings> = { theme }

    // automatic NY theme switcher
    // TODO: remove when NY is over (or maybe not)
    if (previousTheme === 'ny') {
      defaultLogger.debug('Previous was New Year...')
      const triedNYThemes = [...this.state.data.triedNYThemes]
      if (!triedNYThemes.includes(2021)) {
        defaultLogger.debug('...the user had not seen it', triedNYThemes)
        triedNYThemes.push(2021)
        data.triedNYThemes = triedNYThemes
        this.mutations.setTriedNYThemes(triedNYThemes)
      }
    }

    if (previousTheme !== theme) {
      defaultLogger.debug('Previous theme is not the same, updating it...')
      data.previousTheme = previousTheme
      this.mutations.setPreviousTheme(previousTheme)
    }
    defaultLogger.debug('Updating current theme', theme)
    this.mutations.setCurrentTheme(theme)
    this.actions.saveSettings({ data, instant: true })
  }

  updateTaskDraft (draft: Partial<Chat>) { // UI_UPDATE_TASK_DRAFT
    let taskDraft: Partial<Chat> | null = {}
    if (draft) {
      const fieldsToUpdate: Partial<keyof Chat>[] = [
        'assignee',
        'complexity',
        'deadline',
        'description',
        'gentime',
        'importance',
        'items',
        'jid',
        'linkedMessages',
        'observers',
        'owner',
        'parents',
        'isPublic',
        'section',
        'tags',
      ]
      taskDraft = Object.fromEntries(fieldsToUpdate.map(f => [f, draft[f]]))
    } else {
      taskDraft = null
    }

    this.mutations.updateTaskDraft(taskDraft)
    this.actions.saveSettings({ data: { taskDraft } })
  }

  /**
   * Saves last used tasks tab key (right column bar with tasks)
   * @param {string} tabKey Task tab to save.
   */
  updateLastOpenTasksTab (tabKey: string) { // UI_UPDATE_LAST_OPEN_TASKS_TAB
    this.mutations.setLastOpenTasksTab(tabKey)
    this.actions.saveSettings({ data: { lastOpenTasksTab: tabKey } })
  }

  // /**
  //  * Changes whether or not to use experimental tasks tabs design.
  //  * @param {boolean} bool Enable or disable experimental design.
  //  */
  updateExperimentalTasksTabDesign (value: boolean) { // UI_UPDATE_EXPERIMENTAL_TASKS_TABS_DESIGN
    this.mutations.setExperimentalTasksTabsDesign(value)
    this.actions.saveSettings({ data: { experimentalTasksTabsDesign: value } })
  }

  setChatSortingType ({ direct, value }: { direct: boolean, value: number}) { // SORT_CHATS_TYPE
    if (direct === undefined) {
      direct = true
    }

    if (value === undefined) {
      value = this.getters.sortingChatsType(direct)
    }

    this.mutations.setChatSortingType({ direct, value })

    const data = { sortingChatsType: this.state.data.sortingChatsType }
    this.actions.saveSettings({ data })
  }
}
