import { publicGroupsStore, contactsStore } from '@/store'
import State from '@/store/modules/groups/state'
import { getChatPlaceholderName, getIconUrl, isNotUndefined } from '@/utils'
import { Chat, Contact, JID } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'

export default class Getters extends BaseGetters<State> {
  get list (): Chat[] {
    return Object.keys(this.state.data)
      .map(id => this.state.data[id])
      .filter(isNotUndefined)
  }

  membersCount (id: JID): number {
    return this.state.data[id]?.numMembers ?? 0
  }

  members (id: JID): Contact[] {
    return (this.state.data[id]?.members ?? [])
      .map(entry => contactsStore.getters.contact(entry.jid))
      .filter(isNotUndefined)
  }

  admins (id: JID): Contact[] {
    return (this.state.data[id]?.members ?? [])
      .filter(entry => entry.status === 'admin')
      .map(entry => contactsStore.getters.contact(entry.jid))
      .filter(isNotUndefined)
  }

  displayName (id: JID): string {
    const g = this.state.data[id] ?? publicGroupsStore.getters.item(id)
    return g?.displayName ??
      getChatPlaceholderName({ jid: '', type: 'group' })
  }

  icon (id: JID, large?: boolean): string | undefined {
    const icons = this.state.data[id]?.icons

    if (icons) return getIconUrl(icons, large)
  }

  isInGroup (groupId: JID, contactId: JID): boolean {
    return (
      this.state.data[groupId]?.members?.some(
        item => item.jid === contactId,
      ) ?? false
    )
  }

  isNotificationsEnabled (id: JID): boolean {
    return this.state.data[id]?.notificationsEnabled ?? false
  }

  description (id: JID): string {
    const g = this.state.data[id]
    return g?.description ?? ''
  }
}
