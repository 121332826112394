import api from '@/api/v3'
import Getters from '@/store/modules/reminds/getters'
import Mutations from '@/store/modules/reminds/mutations'
import State from '@/store/modules/reminds/state'
import { ChatId, RemindId } from '@/store/modules/reminds/types'
import * as Sentry from '@sentry/browser'
import { ISODateTimeString, Remind } from '@tada-team/tdproto-ts'
import { Actions as BaseActions } from 'vuex-smart-module'

interface CreateRemindPayload {
  chat: ChatId
  comment?: string
  fireAt: ISODateTimeString
}

export default class Actions extends BaseActions<
  State,
  Getters,
  Mutations,
  Actions
> {
  async create (payload: CreateRemindPayload): Promise<Remind> {
    // eslint-disable-next-line camelcase
    const { chat, comment, fireAt: fire_at } = payload
    let remind: Remind

    try {
      remind = await api.reminds.create({ chat, comment, fire_at })
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('reminds', 'create')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to create Remind.')
      })
      return Promise.reject(e)
    }

    this.mutations.updateReminds([remind])
    return remind
  }

  async delete (payload: RemindId): Promise<Remind> {
    let remind: Remind

    try {
      remind = await api.reminds.delete(payload)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('reminds', 'delete')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to delete Remind.')
      })
      return Promise.reject(e)
    }

    this.mutations.deleteReminds([remind.uid])
    return remind
  }

  async loadReminds (): Promise<void> {
    this.mutations.startLoading()
    let reminds: Remind[]

    try {
      reminds = await api.reminds.getAll()
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('reminds', 'get')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to get Reminds.')
      })
      this.mutations.stopLoading()
      return Promise.reject(e)
    }

    this.mutations.stopLoading()
    this.mutations.updateReminds(reminds)
  }
}
