import api from '@/api/v3'
import store, {
  dataImportsStore,
  uiStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import Getters from '@/store/modules/tasks/getters'
import Mutations from '@/store/modules/tasks/mutations'
import State from '@/store/modules/tasks/state'
import * as Sentry from '@sentry/browser'
import { JID, TaskItem } from '@tada-team/tdproto-ts'
import { Actions as BaseActions } from 'vuex-smart-module'

export default class Actions extends BaseActions<
  State,
  Getters,
  Mutations,
  Actions
> {
  async changeStatus ({ jid, status }: {
    jid: string
    status?: string
  }): Promise<TADA.Task> {
    const task = this.state.data[jid]
    if (!task) throw new Error('Task does not exists in state.')

    const newStatus = status || (task.taskStatus === 'new' ? 'done' : 'new')
    const res = await api.tasks.edit(jid, { task_status: newStatus })

    this.mutations.addTask({ jid, task: res })

    return res
  }

  // TODO: fix types after migration to tdproto
  async create (params: any): Promise<void> {
    const task = await api.tasks.create(params)
    this.mutations.addTask({ jid: task.jid, task })
    uiStore.actions.taskDeskTaskUpdated({ task })
  }

  /**
   * Makes a request to create a new rule. Saves it to vuex afterwards.
   * @param {TADA.TasksColorsRule} rule rule to create.
   * @returns {TADA.TasksColorsRule} created rule.
   */
  async createColorsRule (
    rule: Partial<TADA.TasksColorsRule>,
  ): Promise<TADA.TasksColorsRule> {
    const mappedRule = mapTasksColorsRule(rule)
    let createdRule: TADA.TasksColorsRule
    try {
      createdRule = await api.tasksColorsRules.create(mappedRule)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTags({ entity: 'tasksColorsRules', method: 'create' })
        scope.setContext('rule', { rule })
        scope.setContext('mappedRule', { mappedRule })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to create tasks colors rule.')
      })
      return Promise.reject(error)
    }

    this.mutations.createColorsRule(createdRule)

    return createdRule
  }

  async delete (jid: JID): Promise<void> {
    try {
      await api.tasks.delete(jid)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTags({ entity: 'tasks', method: 'delete' })
        scope.setContext('chat id', { jid })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to remove task.')
      })
      return Promise.reject(error)
    }
    this.mutations.remove(jid)
  }

  /**
   * Makes a request to delete an existing rule. Deletes it in vuex afterwards.
   * @param {string} uid rule uid to delete.
   * @returns {void}
   */
  async deleteColorsRule (uid: string): Promise<void> {
    try {
      await api.tasksColorsRules.delete(uid)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTags({ entity: 'tasksColorsRules', method: 'delete' })
        scope.setContext('uid', { uid })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to delete tasks colors rule.')
      })
      return Promise.reject(error)
    }
    this.mutations.deleteColorsRule(uid)
  }

  async edit ({ jid, task, early = false }: {
    jid: JID
    task: any // TODO: fix types after migration to tdproto
    early: boolean
  }): Promise<TADA.Task> {
    const existsTask = this.state.data[jid]
    if (!existsTask) throw new Error('Task does not exists in state.')

    const baseTask = { ...existsTask }
    const request: Partial<typeof task> = Object.fromEntries(
      Object.entries({ ...baseTask, ...task }).filter(
        item => baseTask.changeableFields.includes(item[0]),
      ),
    )

    // TODO: fix types after migration to tdproto
    if (request.items) {
      request.items = request.items.map((item: any) => ({
        uid: item.uid,
        text: item.text,
        checked: item.checked,
        sort_ordering: item.sortOrdering,
      }))
    }

    // Save edited task object locally without waiting for server
    // important for perceived feeling of immediate interface
    // (toggles, quick settings, etc.)
    if (early) {
      // TODO: create a method in DataGenerator to remove this ugly conversion?
      // or better yet - use tdProto!
      if (task.task_status) {
        task.taskStatus = task.task_status
        delete task.task_status
      }
      if (task.pinned_sort_ordering) {
        task.pinnedSortOrdering = task.pinned_sort_ordering
        delete task.pinned_sort_ordering
      }
      this.mutations.addTask({ jid, task })

      const taskTemp = this.state.data[jid]
      taskTemp && uiStore.actions.taskDeskTaskUpdated({
        task: taskTemp,
      })
    }

    const res = await api.tasks.edit(jid, request)

    if (
      res.gentime > baseTask.gentime ||
      res.baseGentime > baseTask.baseGentime
    ) {
      this.mutations.addTask({ jid, task: res })

      // Prevent updating taskDesk if received task card is being dragged atm
      const currentDragData = store.state.uiSettings?.taskDesk?.draggedTaskData
      const isBeingDragged = currentDragData?.jid === res.jid
      if (!isBeingDragged) {
        uiStore.actions.taskDeskTaskUpdated({ task: res })
      }
    } else {
      console.warn(
        'GENTIME mismatch',
        jid,
        baseTask.gentime,
        baseTask.baseGentime,
        res.gentime,
        res.baseGentime,
      )

      if (early) {
        this.mutations.addTask({ jid, task: baseTask })
        uiStore.actions.taskDeskTaskUpdated({ task: baseTask })
      }
    }

    return res
  }

  async import (tasks: TADA.Task[]): Promise<string> {
    const actionObj = await api.tasks.startImport(tasks)
    const uid = actionObj.action
    this.mutations.addImport(uid)
    dataImportsStore.mutations.addImport(uid)
    return uid
  }

  /**
   * Requests a task draft from server.
   * Takes linkedMessage, cloneJid, item as params fields.
   * @returns parsed task draft or rejected promise.
   */
  async loadDraft ({ linkedMessages, cloneJid, item }: {
    linkedMessages?: string[]
    cloneJid?: string
    item?: string
  }): Promise<TADA.Task> {
    try {
      return await api.tasks.draft(linkedMessages, cloneJid, item)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTags({ entity: 'tasks', method: 'draft' })
        scope.setContext('chat id', { jid: 'draft' })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to get task draft.')
      })
      return Promise.reject(error)
    }
  }

  /**
   * Loads a single task from server, saves it locally.
   * Takes task jid as a parameter.
   * @returns parsed loaded task or rejected promise.
   */
  async loadTask (jid: JID): Promise<TADA.Task> {
    let chat: TADA.Chat
    let task: TADA.Task
    try {
      const res = await api.tasks.get(jid)
      chat = res.chat
      task = res.task
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTags({ entity: 'tasks', method: 'get' })
        scope.setContext('chat id', { jid })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to get task.')
      })
      return Promise.reject(error)
    }

    this.mutations.addTask({ jid: task.jid, task })
    store.dispatch(actionTypes.UPDATE_CHAT, chat)

    return task
  }

  /**
   * Loads tasks from server that satisfy provided params, saves locally.
   * @param params Object with server requirest field-values.
   * @returns a promise that resolves to parsed loaded tasks array.
   */
  async loadTasks (params: Record<string, unknown>): Promise<TADA.Task[]> {
    // unless specified, set 'unread_first' to current user setting value
    if (!('unread_first' in params)) {
      params.unread_first = store.getters.profile.unreadFirst
    }

    let chats: TADA.Chat[]
    let tasks: TADA.Task[]
    try {
      const res = await api.tasks.getMany(params)
      chats = res.chats
      tasks = res.tasks
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTags({ entity: 'tasks', method: 'getMany' })
        scope.setContext('params', params)
        scope.setContext('error', { error })
        Sentry.captureException('Failed to get tasks.')
      })
      return Promise.reject(error)
    }

    this.mutations.addTasks(tasks)
    chats.forEach(item => store.dispatch(actionTypes.UPDATE_CHAT, item))

    return tasks
  }

  /**
   * Gets all rules from server, updates store with them.
   * @returns {TADA.TasksColorsRule[]} an array of received rules.
   */
  async setupColorsRules (): Promise<TADA.TasksColorsRule[]> {
    let rules: TADA.TasksColorsRule[]
    try {
      rules = await api.tasksColorsRules.getAll()
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTags({ entity: 'tasksColorsRules', method: 'getAll' })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to get tasks colors rules.')
      })
      return Promise.reject(error)
    }

    this.mutations.setupColorsRules(rules)

    return rules
  }

  /**
   * Makes a request to update an existing rule. Updates it in vuex afterwards.
   * @param {TADA.TasksColorsRule} rule rule to update.
   * @returns {TADA.TasksColorsRule} updated rule.
   */
  async updateColorsRule (
    rule: Partial<TADA.TasksColorsRule>,
  ): Promise<TADA.TasksColorsRule> {
    if (!rule.uid) throw new Error('Need `uid` for updating rule.')

    const mappedRule = mapTasksColorsRule(rule)
    let updatedRule
    try {
      updatedRule = await api.tasksColorsRules.edit(rule.uid, mappedRule)
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Critical)
        scope.setTags({ entity: 'tasksColorsRules', method: 'update' })
        scope.setContext('rule', { rule })
        scope.setContext('mappedRule', { mappedRule })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to update tasks colors rule.')
      })
      return Promise.reject(error)
    }

    this.mutations.updateColorsRule(updatedRule)

    return updatedRule
  }

  async setupColors (teamId: JID): Promise<void> {
    const tasksColors = await api.tasksColors.load(teamId)
    this.mutations.setupColors(tasksColors)
  }

  async setupCount (): Promise<void> {
    const count = await api.tasks.getCount()
    this.mutations.setCount(count)
  }

  async setupStatuses (): Promise<void> {
    const statuses = await api.tasks.getStatuses()
    this.mutations.setStatuses(statuses)
  }

  /**
   * Gets all tasks tabs from server, updates store with them.
   * @returns {TADA.TaskTab[]} an array of received tabs.
   */
  async setupTabs (): Promise<TADA.TaskTab[]> {
    let tabs: TADA.TaskTab[] = []
    try {
      tabs = await api.tasks.tabs()
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal)
        scope.setTags({ entity: 'tasks', method: 'tabs' })
        scope.setContext('tabs', { tabs })
        scope.setContext('error', { error })
        Sentry.captureException('Failed to get tasks tabs.')
      })
      return Promise.reject(error)
    }

    this.mutations.setupTabs(tabs)

    return tabs
  }

  // TODO: fix types after migration to tdproto
  updateByMessages (messages: any) { // by server.message.updated
    Object.keys(messages.data).forEach(jid => {
      const msg = messages.data[jid]

      if (
        this.state.data[msg.chatId] &&
        msg.isLast &&
        msg.chatId.startsWith('t-')
      ) {
        this.mutations.addTask({ jid: msg.chatId, task: { lastMessage: msg } })
      }
    })
  }

  /**
   * Makes a request to update existing rules. Updates them in vuex afterwards.
   * @param {TADA.TasksColorsRule[]} rules rules to update.
   * @returns {TADA.TasksColorsRule[]} updated rules.
   */
  async updateColorsRules (
    rules: Partial<TADA.TasksColorsRule>[],
  ): Promise<TADA.TasksColorsRule[]> {
    // Rules to update before making a server request
    const earlyUpdateRules: Partial<TADA.TasksColorsRule>[] = []

    // Server-ready rules to send in request
    const serverReadyRules: Array<Record<string, unknown>> = []

    // Attempt to convert every rule to server-readable object
    // on successful conversion - add to both arrays
    rules.forEach(item => {
      const serverReadyRule = mapTasksColorsRule(item)
      if (!serverReadyRule) return
      earlyUpdateRules.push(item)
      serverReadyRules.push(serverReadyRule)
    })

    // Updated rules locally ASAP. Makes a seamless drag-drop impression
    this.mutations.updateColorsRules(earlyUpdateRules)

    // Make a bunch of server requests, wait for them to resolve
    const updatedRules = await Promise.all(
      serverReadyRules.map(item => api.tasksColorsRules.edit(item.uid as string, item)), // TODO: typecast
    )

    // Update rules locally once again.
    // In case they are not the same as in early update
    this.mutations.updateColorsRules(updatedRules)

    return updatedRules
  }

  updateItems ({ jid, items }: { jid: JID, items: TaskItem[] }): void {
    const task = this.state.data[jid]
    if (task) task.items = items
  }
}

/**
 * Helper util to convert client-style field names into server-style.
 * Clears passed rule from unknown fields.
 * Maps known fields to server-readible fields.
 * @param {Partial<TADA.TasksColorsRule>} rule Rule to map to server-readible
 * @returns an object with mapped rule or null if no passed fields are known
 */
function mapTasksColorsRule (
  rule: Partial<TADA.TasksColorsRule>,
): Record<string, unknown> | null {
  const mapper = {
    colorIndex: 'color_index',
    description: 'description',
    priority: 'priority',
    section: 'section',
    sectionEnabled: 'section_enabled',
    tags: 'tags',
    tagsEnabled: 'tags_enabled',
    taskComplexity: 'task_complexity',
    taskImportance: 'task_importance',
    taskImportanceEnabled: 'task_importance_enabled',
    taskStatus: 'task_status',
    taskUrgency: 'task_urgency',
    taskUrgencyEnabled: 'task_urgency_enabled',
    uid: 'uid',
  }

  const mappedRule: Record<string, unknown> = {}
  Object.keys(rule).forEach(key => {
    const serverKey = mapper[key as keyof typeof mapper]
    serverKey && (mappedRule[serverKey] = rule[key as keyof typeof mapper])
  })

  return Object.keys(mappedRule).length === 0 ? null : mappedRule
}
