import { Tag } from '@tada-team/tdproto-ts'
import { Getters } from 'vuex-smart-module'
import State from './state'

class ModuleGetters extends Getters<State> {
  item (id: string): Tag | undefined {
    return this.state.tags.find(item => item.uid === id)
  }

  get names (): string[] {
    return this.state.tags.map(item => item.name)
  }
}

export default ModuleGetters
