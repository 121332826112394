import { OnlineContact, JID, Contact } from '@tada-team/tdproto-ts'

export default class State {
  onlineIds: Array<OnlineContact> = []

  afkJids: Array<JID> = []

  data: Record<string, Contact> = {}

  imports: Array<JID> = []
}
