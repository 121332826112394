import ResizableInstance from './ResizableInstance'
import { ctx } from './index'

export const enum Target {
  ANCHOR = 'anchor',
  DOCUMENT = 'document'
}

export default class EventManager {
  private handlers: { [target: string]: { [key: string]: any } }

  private tempSize: number | null
  private startSize: number | null
  private startOffset: number | null

  private eventProperty: string

  constructor (public context: ResizableInstance) {
    this.tempSize = null
    this.startSize = null
    this.startOffset = null

    this.eventProperty = ''

    this.handlers = {
      [Target.ANCHOR]: {
        mousedown: (event: MouseEvent) => {
          this.startOffset = (event as any)[this.eventProperty]
          this.toggleResizing(true)

          if (event.stopPropagation) { event.stopPropagation() }
          if (event.preventDefault) { event.preventDefault() }
          event.cancelBubble = true

          return false
        },
      },
      [Target.DOCUMENT]: {
        mouseup: () => {
          this.toggleResizing(false)
        },
        mousemove: (event: MouseEvent) => {
          const delta = (event as any)[this.eventProperty] - (this.startOffset || 0)

          const size = (this.startSize || 0) + delta * (context.options.startEdge ? -1 : 1)
          context.setCurrentSize(size, false)

          this.tempSize = size
        },
        mouseout: (event: MouseEvent) => {
          const from = event.relatedTarget || (event as any).toElement
          if (!from || from.nodeName === 'HTML') {
            this.toggleResizing(false)
          }
        },
      },
    }
  }

  public toggleResizing = (on: boolean) => {
    const activeContext = (window as any)[ctx] as ResizableInstance | null
    if (activeContext) {
      const { events } = activeContext

      activeContext.toggleDraggingAnchorState(false)
      activeContext.flushCursorOverlayElement()

      if (events.tempSize !== null) {
        const elementSize = activeContext.setCurrentSize(events.tempSize, true)
        events.tempSize = null

        activeContext.maxSizeOverflowCorrection(elementSize)
      }

      events.startSize = null
      events.startOffset = null

      events.flushTarget(Target.DOCUMENT)
      delete (window as any)[ctx]
    }

    const { options } = this.context
    if (on) {
      (window as any)[ctx] = this.context
      this.attachEvents(Target.DOCUMENT)

      this.tempSize = this.startSize = (this.context.currentSize || null)

      this.context.toggleDraggingAnchorState(true)
      this.context.appendCursorOverlayElement()

      if (options.onResizeStarted) {
        options.onResizeStarted(this.context)
      }
    } else {
      if (options.onResizeStopped) {
        options.onResizeStopped(this.context)
      }
    }
  }

  public attachEvents = (target: Target) => {
    const targetSubject = this.getTargetSubject(target)
    if (!targetSubject) { return }

    const map = this.handlers[target] as any
    if (!map) { return }

    Object.keys(map).forEach(eventName => {
      const handler = map[eventName]
      targetSubject.addEventListener(eventName, handler)
    })
  }

  public flushTarget = (target: Target | string) => {
    const map = (this.handlers as any)[target]
    if (!map) { return }

    const targetSubject = this.getTargetSubject(target)
    if (!targetSubject) { return }

    Object.keys(map).forEach(eventName => {
      targetSubject.removeEventListener(eventName, map[eventName])
    })
  }

  public flush = () => {
    this.toggleResizing(false)

    Object.keys(this.handlers).forEach(this.flushTarget)
  }

  public setupEventProperties = () => {
    this.eventProperty = `client${this.context.options.isColumnFlexDirection ? 'Y' : 'X'}`
  }

  private getTargetSubject = (target: string): HTMLElement | Document | null => {
    switch (target) {
      case Target.ANCHOR: return this.context.anchorElement
      case Target.DOCUMENT: return document
      default: return null
    }
  }
}
