import createPersistedState from 'vuex-persistedstate'
import objectPath from 'object-path'

export default () => createPersistedState({
  key: 'vuexstate',
  paths: [
    'profile.data',
    'contacts.data',
    'groups.data',
  ],
  setState: (key, state) => {
    const clientIsReady = state.launch && !state.launch.isClientLoading
    clientIsReady && window.localStorage.setItem(key, JSON.stringify(state))
  },
  reducer: (state, paths) => {
    const desiredState = paths.reduce((substate, path) => {
      objectPath.set(substate, path, objectPath.get(state, path))
      return substate
    }, {})
    return desiredState
  },
})
