import State from './state'
import { Getters } from 'vuex-smart-module'
import { isInDevMode } from '@/envHelpers'

class ModuleGetters extends Getters<State> {
  /**
   * Getter for background image on login screens
   * @todo remove hardcode links
   * @todo remove typecasting for FEATURES
   * TODO remove typecasting for FEATURES
   */
  get backgroundStyles (): Partial<CSSStyleDeclaration> {
    let url: string = (window.FEATURES as any).web_login_background ?? ''
    if (isInDevMode()) {
      url = 'http://localhost:8002/api/v4/login-background/web'
    }
    return {
      backgroundImage: `url(${url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }
  }
}

export default ModuleGetters
