import State from '@/store/modules/teams/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import { TeamUnread, Team, ChatType, Contact } from '@tada-team/tdproto-ts'
import Vue from 'vue'
import * as Sentry from '@sentry/browser'

export default class Mutations extends BaseMutations<State> {
  /**
   * Set current team id in store
   * @param teamId
   */
  setCurrentTeamId (teamId: string): void {
    window.currentTeamId = teamId
    this.state.currentTeamId = teamId
  }

  /**
   * Update team unread object
   * @param payload
   */
  updateTeamUnread (payload: { teamId: string, unread: TeamUnread }): void {
    const { teamId, unread } = payload
    if (this.state.data[teamId]) {
      this.state.data[teamId].unread = unread
    } else {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Warning)
        scope.setTag('team', 'Updating unread data for a non-existent team')
        scope.setContext('teamId', { teamId })
      })
    }
  }

  /**
   * Delete team by id
   * @param teamId
   */
  deleteTeam (teamId: string): void {
    Vue.delete(this.state.data, teamId)
  }

  /**
   * Add new team to teams store data
   * @param team
   */
  addTeam (team: Team): void {
    const teamRecord: Record<string, Team> = {}
    teamRecord[team.uid] = team
    this.state.data = { ...this.state.data, ...teamRecord }
  }

  /**
   * Update team data
   * @param team
   */
  updateTeam (team: Team): void {
    const currentTeam = this.state.data[team.uid]
    const teamJson = { ...currentTeam.toJSON(), ...team.toJSON() }
    this.state.data[team.uid] = Team.fromJSON(teamJson)
  }

  /**
   * Setup teams data
   * @param teams
   */
  setupTeams (teams: Record<string, Team>): void {
    this.state.data = teams
  }

  /**
   * Setting new team icon
   * @param payload
   */
  setTeamIcon (payload: { teamId: string, icon: string }): void {
    const { teamId, icon } = payload
    if (this.state.data[teamId]) {
      this.state.data[teamId].icons.sm.url = icon
    }
  }

  /**
   * Setting the minimized left menu settings for the team profile
   * @param payload
   */
  changeMeShortView (payload: { chatType: ChatType, isShort: boolean }): void {
    const { chatType, isShort } = payload
    const currentTeamId = this.state.currentTeamId

    if (!currentTeamId) throw Error('Do not have an initialized current team')

    const contact = this.state.data[currentTeamId]?.me
    switch (chatType) {
      case 'task':
        contact.taskShortView = isShort
        break
      case 'group':
        contact.groupShortView = isShort
        break
      case 'direct':
        contact.contactShortView = isShort
        break
      default:
        break
    }
  }

  /**
   * Update contact profile in the current team
   * @param contact
   */
  updateContactInTeam (contact: Contact): void {
    const currentTeamId = this.state.currentTeamId

    if (currentTeamId && this.state.data[currentTeamId]?.me.jid === contact.jid) {
      this.state.data[currentTeamId].me = Contact.fromJSON(contact.toJSON())
    }
  }
}
