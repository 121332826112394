import { Chat } from '@tada-team/tdproto-ts'

export const allChatsFilter = (c: Chat) => !c.hidden && c.chatType !== 'thread'

export const directChatsFilter = (c: Chat) => (
  c.chatType === 'direct' && !c.hidden
)

export const groupChatsFilter = (c: Chat) => (
  c.chatType === 'group' && !c.hidden
)

export const hiddenChatsFilter = (c: Chat) => c.hidden

export const unreadOnlyChatsFilter = (c: Chat) => !c.hidden && c.numUnread

export const threadChatsFilter = (c: Chat) => c.chatType === 'thread'
