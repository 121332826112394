import {
  contactsStore,
  groupsStore,
  meetingsStore,
  publicGroupsStore,
  tasksStore,
  threadsStore,
} from '@/store'
import chatsGetters from '@/store/modules/chat/getters'
import contactsGetters from '@/store/modules/contacts/getters'
import dataImportsGetters from '@/store/modules/dataImports/getters'
import groupsGetters from '@/store/modules/groups/getters'
import profileGetters from '@/store/modules/profile/getters'
import sectionsGetters from '@/store/modules/sections/getters'
import tasksGetters from '@/store/modules/tasks/getters'
import teamsGetters from '@/store/modules/teams/getters'
import socketGetters from '@/ws/store/getters'

export default {
  isClientLoading: state => state.launch.isClientLoading,
  rosterLoadingStatus: state => state.launch.rosterLoadingStatus,
  serverTimeDifference: state => state.launch.timeDifference,

  entity: () => id =>
    (contactsStore && contactsStore.getters.contact(id)) ||
    (groupsStore && groupsStore.state.data[id]) ||
    (meetingsStore && meetingsStore.state.loadedMeetings[id]) ||
    (tasksStore && tasksStore.state.data[id]) ||
    (threadsStore && threadsStore.state.data[id]),
  entityExists: state => id =>
    Object.keys({
      ...state.tasks.data,
      ...state.contacts.data,
      ...state.groups.data,
    }).includes(id) ||
    publicGroupsStore.getters.exists(id) ||
    !!meetingsStore.state.loadedMeetings[id],
  entityList: () => {
    const list = [...groupsStore.getters.list]
    contactsStore && list.push(...contactsStore.getters.contactList)
    return list
  },

  maxUploadSizeFeature: () => (window.FEATURES.max_upload_mb || 20) * 1024 * 1024,
  readonlyGroupsFeature: () => window.FEATURES.readonly_groups || false,
  maxMessageLength: () => (window.FEATURES.max_message_length - 100),
  taskMessagesEnabled: () => window.FEATURES.task_messages || false,

  // TODO: remove unused
  ...contactsGetters,
  ...groupsGetters,
  ...profileGetters,
  ...chatsGetters,
  ...teamsGetters,
  ...socketGetters,
  ...tasksGetters,
  ...sectionsGetters,
  ...dataImportsGetters,
}
