import { createMessageReaction, createReaction } from '@/api/v3/DataGenerator'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { defaultLogger } from '@/loggers'
import store from '@/store'
import Messages from '@/store/messages'
import { MyReactions } from '@tada-team/tdproto-ts'
import { reactions } from 'td-api'

class Reactions {
  private data: MyReactions

  constructor () {
    this.data = new MyReactions([], [])
  }

  async loadAll () {
    try {
      this.data = await reactions.my()
    } catch (e) {
      defaultLogger.error('[Reactions.loadAll] Error while load reactions:', e)
    }
  }

  getList () {
    return this.data
  }

  commitReaction (messageId: string, reactionName: string, remove: boolean) {
    remove
      ? reactions.remove(window.currentTeamId, messageId, reactionName)
      : reactions.add(window.currentTeamId, messageId, reactionName)
  }

  toggleMessageReaction ({ chatId, messageId, reactionName }: { chatId: string; messageId: string; reactionName: string }) {
    const message = Messages.getMessage(chatId, messageId)
    if (!message) return

    const { getUserId } = store.getters
    let reactionIndex = -1
    let senderIndex = -1

    for (let i = 0; i < message.reactions.length; i++) {
      const { name, details } = message.reactions[i]
      if (reactionName !== name) continue

      reactionIndex = i
      for (let j = 0; j < details.length; j++) {
        const { sender } = details[j]
        if (sender !== getUserId) continue

        senderIndex = j
        break
      }

      if (senderIndex >= 0) break
    }

    if (reactionIndex < 0) {
      const messageReaction = createMessageReaction(reactionName)
      message.reactions.push(messageReaction)
    } else {
      const messageReaction = message.reactions[reactionIndex]

      if (senderIndex < 0) {
        const reaction = createReaction(reactionName)
        messageReaction.counter += 1
        messageReaction.details.push(reaction)
      } else {
        messageReaction.counter -= 1
        messageReaction.details.splice(senderIndex, 1)

        messageReaction.counter === 0 && (message.reactions.splice(reactionIndex, 1))
      }
    }

    ChatEventBus.$emit(Events.TOGGLE_MESSAGE_REACTION, { chatId, message })
    this.commitReaction(messageId, reactionName, senderIndex >= 0)
  }
}

export default new Reactions()
