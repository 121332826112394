import { CallDisplayType, CallMdDisplayType, CallMembersDisplayType, ActiveCall, CallUiLgLayoutType, Presenter } from './models'
import { Mutations } from 'vuex-smart-module'
import { JID } from '@tada-team/tdproto-ts'
import State from './state'

class ModuleMutations extends Mutations<State> {
  setActiveCall (call: State['activeCall']): void {
    this.state.activeCall = call
  }

  addWebRTCReceiver (r: ActiveCall['webRTCReceivers'][number]): void {
    const call = this.state.activeCall
    if (!call) return

    call.type === 'video_multistream'
      ? call.multistream.webRTCReceiver = r
      : call.webRTCReceivers.push(r)
  }

  addMultistreamAudioStream (p: ActiveCall['multistream']['audio'][number]) {
    const call = this.state.activeCall
    if (call) call.multistream.audio.push(p)
  }

  addMultistreamVideoStream (p: ActiveCall['multistream']['video'][number]) {
    const call = this.state.activeCall
    if (call) call.multistream.video.push(p)
  }

  addMultistreamJids (p: JID[]) {
    this.state.jids.push(...p)
  }

  clearMultistreamJids () {
    this.state.jids = []
  }

  setUiDisplayType (type: CallDisplayType): void {
    this.state.uiDisplayTypePrev = this.state.uiDisplayType
    this.state.uiDisplayType = type
  }

  setUiMdDisplayType (type: CallMdDisplayType) {
    this.state.uiMdDisplayTypePrev = this.state.uiMdDisplayType
    this.state.uiMdDisplayType = type
  }

  setUiLgLayoutType (type: CallUiLgLayoutType): void {
    this.state.uiLgLayoutTypePrev = this.state.uiLgLayoutType
    this.state.uiLgLayoutType = type
  }

  setMembersDisplayType (type: CallMembersDisplayType) {
    this.state.membersDisplayType = type
  }

  /**
   * Updates active call length with a given value.
   * Essentially the same as updateActiveCall, but does not update
   * the entire call object, which saves a bunch of performance.
   * @param length new call length
   */
  updateLength (length: number): void {
    if (!this.state.activeCall) return
    this.state.activeCall.length = length
  }

  setTalkingMember (p: { actor: string, isTalking: boolean }): void {
    const c = this.state.activeCall
    if (!c) return

    const { actor, isTalking } = p

    /**
     * Keep talking members in order of when they started talking.
     * Last member to start talking - first member in the array.
     */
    const i = c.talkingMembers.indexOf(actor)
    if (i !== -1) c.talkingMembers.splice(i, 1)
    if (isTalking) c.talkingMembers.unshift(actor)
  }

  setRemoteStream (p: { stream: MediaStream, jid: JID }) {
    const call = this.state.activeCall
    if (!call) return
    const i = call.remoteStreams.findIndex(rs => rs.jid === p.jid)
    i === -1
      ? call.remoteStreams.push(p)
      : call.remoteStreams.splice(i, 1, p)
  }

  setUiLgRightBarDisplayType (type: State['uiLgRightBarDisplayType']) {
    this.state.uiLgRightBarDisplayType = type
  }

  setMuted (p: { mute: boolean, t: 'audio' | 'video' }): void {
    const c = this.state.activeCall
    if (!c) return

    const { t, mute } = p

    t === 'audio' && (c.isAudioMuted = mute)
    t === 'video' && (c.isVideoMuted = mute)
  }

  setLocalStream (p: { t: 'audio' | 'video', s: MediaStream | null }): void {
    const c = this.state.activeCall
    if (!c) return

    const { t, s } = p

    t === 'audio' && (c.localStreams.audio = s)
    t === 'video' && (c.localStreams.video = s)
  }

  setIsScreensharing (p: ActiveCall['isScreensharing']): void {
    const c = this.state.activeCall
    if (!c) return

    c.isScreensharing = p
  }

  setUserMediaPreference (p: ActiveCall['userMediaPreference']): void {
    const c = this.state.activeCall
    if (!c) return

    c.userMediaPreference = p
  }

  setOutputVolume (p: ActiveCall['outputVolume']): void {
    const c = this.state.activeCall
    if (!c) return

    c.outputVolume = p
  }

  setUid (p: ActiveCall['uid']): void {
    const c = this.state.activeCall
    if (!c) return

    c.uid = p
  }

  setConnectedMembers (p: ActiveCall['connectedMembers']): void {
    const c = this.state.activeCall
    if (!c) return

    c.connectedMembers = p

    if (c.maxConnectedMembersCount < p.length) {
      c.maxConnectedMembersCount = p.length
    }
  }

  setLastUpdate (p: ActiveCall['lastStateUpdate']): void {
    const c = this.state.activeCall
    if (!c) return

    c.lastStateUpdate = p
  }

  setStart (p: ActiveCall['start']): void {
    const c = this.state.activeCall
    if (!c) return

    c.start = p
  }

  setTimeInterval (p: ActiveCall['timeInterval']): void {
    const c = this.state.activeCall
    if (!c) return

    c.timeInterval = p
  }

  setLifecycleState (p: ActiveCall['lifecycleState']): void {
    const c = this.state.activeCall
    if (!c) return

    c.lifecycleState = p
  }

  setPresenter (p: { type: 'server' | 'user', presenter: Presenter }): void {
    const c = this.state.activeCall
    if (!c) return

    const { type, presenter } = p
    if (type === 'server') c.presenter.fromServer = presenter
    else if (type === 'user') c.presenter.pinnedByUser = presenter
  }

  setCamDenied (v: boolean): void {
    this.state.camDenied = v
  }

  setMicDenied (v: boolean): void {
    this.state.micDenied = v
  }
}

export default ModuleMutations
