import { isInDevMode, isTestingE2E } from '@/envHelpers'

const getWebsocketHost = () => {
  const { location } = window
  let host = location.host
  let protocol = location.protocol === 'https:' ? 'wss' : 'ws'

  if (isInDevMode() || isTestingE2E()) {
    return { host, protocol }
  }

  const { host: featuresHost } = window.FEATURES
  if (featuresHost) {
    const secure = featuresHost.indexOf('https') === 0

    host = featuresHost.substr(secure ? 8 : 7)
    protocol = secure ? 'wss' : 'ws'
    return { host, protocol }
  }

  return { host, protocol }
}

export const getWebsocketUrl = (lang: string, requestRoster?: boolean, afk?: boolean, team?: string, timezone?: string) => {
  const { host, protocol } = getWebsocketHost()

  let result = `${protocol}://${host}/messaging/${team || window.currentTeamId}/?lang=${lang}`
  if (requestRoster) {
    result += '&sendme=server.roster'
  }
  if (afk) {
    result += '&afk'
  }
  if (timezone) {
    result += `&timezone=${timezone}`
  }
  return result
}
