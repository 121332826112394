import { GetterTree } from 'vuex'
import { State } from './types'
import { contactsStore } from '@/store'

// type {profile: State} because profile use as module `profile` in root getters
// and it declareted there
const getters: GetterTree<{profile: State}, any> = {
  profile: state => state.profile.data || {},
  profileId: (_, { profile }) => profile.jid,
  unreadFirst: (_, { profile }) => profile.unreadFirst ?? false,

  isAdminProfile: (_, { profile }) => profile.status === 'admin',
  isOwnerProfile: (_, { profile }) => profile.status === 'owner',
  isProfileHasRights: (_, getters) =>
    getters.isAdminProfile || getters.isOwnerProfile,

  getUserId: (_, { profile }) => profile.jid,

  canManageTasksColorsRules: (_, { profileId }) => {
    // ! DEBT: yes, this needs refactoring deeper in the app
    // store.state.profile gets updated like 5 times at app initial load
    // and no, can_manage_rules is not present there
    // however can_manage_rules is fine in roster
    // so pulling the ID from 'profile' and permission from 'contacts'
    // also contact(..) may be null, at least when changing teams
    const user = contactsStore.getters.contact(profileId)
    return user && user.canManageColorRules
  },

  /**
   * Is 2FA enabled on this user
   *  @returns {boolean} is TFA enabled
   */
  isActiveTFA: (state: { profile: State }) =>
    state.profile.data?.auth2faEnabled ?? false,
  // can user discard password by email code, only if he use email security and confirm it
  canDiscardPass: (state: { profile: State }) =>
    state.profile.data?.auth2faStatus === 'confirmed',
}
export default getters
