// import api from '@/api/v3'
import { defaultLogger } from '@/loggers'

import BaseNotification, { Type } from '@/notifications/BaseNotification'
import Firebase from '@/notifications/Firebase'
import Safari from '@/notifications/Safari'
import Electron from '@/notifications/Electron'

import { isPushSupported } from '@/notifications/Utils'

let instance: BaseNotification
let callOnGranted: Array<() => unknown> | null = null

export const init = () => {
  if (!isPushSupported()) {
    defaultLogger.warn('[notifications.init] Push notifications is not supported')
    return
  }

  const { isElectron } = window as any
  const isSafari = 'safari' in window

  const Ctor = isElectron ? Electron : (isSafari ? Safari : Firebase)
  instance = new Ctor()
  instance.init()
}

export const subscribe = async (force?: boolean) => {
  if (!isPushSupported()) {
    defaultLogger.warn('[notifications.subscribe] Push notifications is not supported')
    return
  }

  if (!instance) {
    defaultLogger.warn('[notifications.subscribe] Push instance is not initialized')
    return
  }

  await instance.subscribe({ callOnGranted, force })

  if (instance.getType() !== Type.SAFARI) {
    callOnGranted = null
  }
}

export const unsubscribe = async () => {
  if (!instance) {
    defaultLogger.warn('[notifications.unsubscribe] Push instance is not initialized')
    return
  }
  await instance.unsubscribe()
}

export const onGranted = (fn: () => unknown) => {
  if (!callOnGranted) callOnGranted = []
  callOnGranted.push(fn)
}
