import store, {
  callsStore,
  chatsBarStore,
  loginStore,
  teamsStore,
  uiSettingsStore,
} from '@/store'
import api from '@/api/v3'
import * as Utils from '@/ws/Utils'
import Emitter from '@/ws/emitter'
import PacketQueue from '@/ws/PacketQueue'
import EventHandlers from '@/ws/EventHandlers'
import { mutationTypes } from '@/ws/store'
import InactiveDetector from '@/electron/InactiveDetector'
import i18n from '@/i18n'

import { networkLogger, defaultLogger } from '@/loggers'
import { checkAndUpdate } from '@/utils'

const START_TRYOUT_DELAY = 1000
const MAX_TRYOUT_DELAY = 16000
const SERVER_PING_DELAY = 30000

function getQueue (length: number) {
  const array: any = []

  array.push = function (...args: any[]) {
    this.length >= length && this.shift()
    return Array.prototype.push.apply(this, args)
  }

  return array
}

const pingLog = getQueue(5)
const wsErrorLog = getQueue(5)

class Socket {
  private instance: WebSocket | null
  private reconnectionDelayTimeout: number | null
  private reconnectionTryoutTimeout: number | null
  private lastReceivedMesssageTimeout: number | null
  private tryoutDelay: number

  private queue: PacketQueue

  constructor () {
    this.instance = null
    this.reconnectionDelayTimeout = null
    this.reconnectionTryoutTimeout = null
    this.lastReceivedMesssageTimeout = null
    this.tryoutDelay = START_TRYOUT_DELAY

    this.queue = new PacketQueue({
      prefix: 'CP',
      capacity: 50,
      keyDescriptor: { 'client.chat.lastread': 'jid' },
    })

    window.addEventListener('beforeunload', this.destroy)
    /**
     * Not sure why these were ever needed at all since we have 'close' handler
     * that will go on and try to reconnect anyway.
     *
     * Leaving these here for now, just in case something goes wrong.
     */
    // window.addEventListener('online', this.startTryingReconnect)
    // window.addEventListener('offline', this.close)

    const isTesting = (window.FEATURES || {}).is_testing
    if (isTesting) {
      window.addEventListener('keydown', (e: KeyboardEvent) => {
        e.ctrlKey && e.key === '`' && this.close('Testing (manual) closing')
      })
    }
  }

  public connect = () => {
    store.commit(mutationTypes.SOCKET_CLOSED_BY_CLIENT_STATE, false)
    networkLogger.debug('[WebSocket.connect] Initiate')

    this.clearDelayTimeout()
    this.clearTryoutTimeout()
    this.clearPingTimeout()

    this.destroy()

    const active = InactiveDetector.isActive
    const url = Utils.getWebsocketUrl(
      uiSettingsStore.getters.language || i18n.locale || 'ru',
      false,
      !active,
      undefined,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    )
    this.instance = new WebSocket(url)

    Emitter.addListeners(EventHandlers)

    this.instance.addEventListener('open', this.handleOpen)
    this.instance.addEventListener('message', this.handleMessage)
    this.instance.addEventListener('close', this.handleClose)
    this.instance.addEventListener('error', this.handleError as any)
  }

  public startTryingReconnect = () => {
    this.clearDelayTimeout()
    this.clearTryoutTimeout()

    this.reconnectionDelayTimeout = window.setTimeout(this.tryToReconnect, 100)
  }

  public send = (name: string, body?: any, confirmId?: string) => {
    if (!loginStore.state.isLoggedIn) return
    if (this.instance === null || this.instance.readyState === WebSocket.CONNECTING) {
      const state = this.instance ? this.instance.readyState : 'inactive'
      networkLogger.warn(`[WebSocket.send] Trying to send data through "${state}" socket`)
      this.addToQueue(name, body)
      return
    }

    const logDebug = name === 'client.confirm' || name === 'client.ping'
    logDebug
      ? networkLogger.debug('Confirming in socket:', name, body)
      : networkLogger.info(name, body)

    const packet = { event: name, confirm_id: confirmId, params: body }
    try {
      const data = JSON.stringify(packet)
      this.instance.send(data)
    } catch (e) {
      networkLogger.warn('[WebSocket.send] Sending packet error:', e)
    }
  }

  public allowMessaging = async () => {
    try {
      const count = await this.queue.flush((packet: any) => {
        const { name, body } = packet
        this.send(name, body)
      })
      networkLogger.debug(`[WebSocket.allowMessaging] ${count} packet(s) was flushed from queue`)

      Emitter.emit('start')
    } catch (e) {
      const { reason, count } = e
      networkLogger.warn(`[WebSocket.allowMessaging] ${reason}, but ${count} packets was flushed`)
    }
  }

  public close = (reason: string | Event) => {
    const message = typeof reason === 'string' ? reason : reason.type
    if (!this.instance) {
      networkLogger.warn(`[WebSocket.close] Trying to close already closed socket, reason: ${message || '-'}`)
      return
    }
    networkLogger.debug(`[WebSocket.close] Socket going to be closed manually, reason: ${message || '-'}`)
    store.commit(mutationTypes.SOCKET_CLOSED_BY_CLIENT_STATE, true)
    this.instance.close()
    callsStore.actions.clearCalls()
  }

  public getInstance = (): WebSocket | null => {
    return this.instance as WebSocket
  }

  private handleOpen = () => {
    networkLogger.info('[WebSocket.handleOpen] Socket open')

    Emitter.emit('open')
    this.lastReceivedMesssageTimeout = window.setTimeout(() => this.onPingTimeout(), SERVER_PING_DELAY)
  }

  private handleMessage = (e: MessageEvent) => {
    try {
      const { confirm_id: confirmId, event, params } = JSON.parse(e.data)
      if (confirmId) {
        networkLogger.debug(
          'Server requested confirm:',
          confirmId,
          event,
          params,
        )
        this.send('client.confirm', { confirm_id: confirmId })
      }

      if (event !== 'server.confirm') {
        networkLogger.debug(event, params)
      }

      this.clearPingTimeout()
      this.lastReceivedMesssageTimeout = window.setTimeout(() => this.onPingTimeout(), SERVER_PING_DELAY)

      Emitter.emit(event, params)
    } catch (e) {
      networkLogger.warn('[WebSocket.handleMessage] Incorrect data:', e)
    }
  }

  private handleClose = (event: CloseEvent) => {
    this.instance = null
    this.clearPingTimeout()

    if (!event.wasClean) {
      networkLogger.warn(`[WebSocket.handleClose] Connection terminated [${event.code}]: ${event.reason || '-'}`)
      Emitter.emit('error')

      // если за последнюю минуты были как минимум 3 успешные попытки пингануть
      // сервер и при этом были 3 неудачные попытки подлючиться по ws, то нужно
      // показать предупреждение о баге с расширениями прокси
      const now = Date.now()
      if (event.code === 1006 &&
        wsErrorLog.filter((l: any) => now - l.time < 60 * 1000).length >= 3 &&
        pingLog.filter((l: any) => now - l.time < 60 * 1000).length >= 3) {
        console.error('Chrome proxy bug detected')

        const message = i18n.t('errors.chromeWsErrorHTML').toString()

        const el = document.querySelector('#loading-splash-screen .state-label')

        if (el) {
          el.innerHTML = message
        }
      }
    } else {
      networkLogger.info('[WebSocket.handleClose] Socket closed')
    }

    Emitter.emit('close')
    Emitter.clearListeners()

    this.startTryingReconnect()
  }

  private handleError = (event: ErrorEvent) => {
    networkLogger.warn(`[WebSocket.handleError] ${event.message}|${event.error}`)
    this.clearPingTimeout()

    wsErrorLog.push({ time: Date.now() })
  }

  private onPingTimeout = (repeated = false) => {
    this.clearPingTimeout()

    if (!repeated) {
      this.send('client.ping', null, '' + Math.random())
      this.lastReceivedMesssageTimeout = window.setTimeout(() => this.onPingTimeout(true), SERVER_PING_DELAY)
      return
    }

    this.close('No packets received from server')
  }

  private destroy = () => {
    if (this.instance === null) return

    this.instance.onclose = function () {
      return false
    }
    store.commit(mutationTypes.SOCKET_CLOSED_BY_CLIENT_STATE, true)
    this.instance.close()
  }

  private tryToReconnect = async () => {
    networkLogger.debug('[WebSocket.tryToReconnect] Initiate')

    try {
      await api.ping(true)
      pingLog.push({ time: Date.now() })
      checkAndUpdate()
      this.connect()

      const teamId = window.currentTeamId || teamsStore.state.currentTeamId
      if (teamId) {
        chatsBarStore.mutations.reset()
        chatsBarStore.actions.load(teamId)
        chatsBarStore.actions.loadThreads()
      }
    } catch (e) {
      this.tryoutDelay = Math.min(MAX_TRYOUT_DELAY, this.tryoutDelay * 2)
      defaultLogger.warn(`[WebSocket.tryToReconnect] No connection, auto reconnect tryout after ${Math.floor(this.tryoutDelay / 1000)}s`)

      this.reconnectionTryoutTimeout = window.setTimeout(this.tryToReconnect, this.tryoutDelay)
    }
  }

  private clearDelayTimeout = () => {
    if (this.reconnectionDelayTimeout !== null) {
      clearTimeout(this.reconnectionDelayTimeout)
      this.reconnectionDelayTimeout = null
    }
  }

  private clearTryoutTimeout = () => {
    this.tryoutDelay = START_TRYOUT_DELAY
    if (this.reconnectionTryoutTimeout !== null) {
      clearTimeout(this.reconnectionTryoutTimeout)
      this.reconnectionTryoutTimeout = null
    }
  }

  private clearPingTimeout = () => {
    this.lastReceivedMesssageTimeout && window.clearTimeout(this.lastReceivedMesssageTimeout)
    this.lastReceivedMesssageTimeout = null
  }

  private addToQueue = (name: string, body: any) => {
    networkLogger.debug(`<${name}>`, body)
    this.queue.addPacket({ name, body })
  }
}

export default new Socket()
