import Vue from 'vue'
import VueRouter, { RawLocation, Route, NavigationFailure } from 'vue-router'
import routes from './routes'
import { beforeEachGuard } from './guards'
import { routerLogger } from '@/loggers'

/**
 * Redefining the push method to get rid of garbage in the console with duplicate path
 */
class TDRouter extends VueRouter {
  push (location: RawLocation): Promise<Route> {
    return super.push(location).catch((reason: NavigationFailure) =>
      new Promise((resolve, reject) => {
        if (reason.name === 'NavigationDuplicated') {
          return routerLogger.warn('Router warning push: ', reason.message)
        }
        reject(reason)
      }))
  }
}

Vue.use(TDRouter)

const router = new TDRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(beforeEachGuard)
export default router
