import i18n from '@/i18n'

import InactiveDetector from '@/electron/InactiveDetector'
import store, {
  contactsStore,
  groupsStore,
  teamsStore,
} from '@/store'
import router from '@/router'
import { Platform, format } from 'quasar'

const { capitalize } = format

const NOTIFICATION_LIFETIME = 5000

const normalizePayload = message => {
  if (!message) return
  if (message.normalized) return message

  const { chatId, content, sender, linkedMessages } = message
  const { getters } = store

  const title = getters.chatName(chatId)
  const icon = getters.chatIcon(chatId)

  let body = content.text
  if (!body && linkedMessages.length > 0) {
    body = i18n.tc('glossary.message_count', linkedMessages.length)
  }
  body = body || capitalize(i18n.tc('glossary.message', 1))

  const result = { tag: chatId, title, icon, body }

  if (sender !== chatId) {
    Object.assign(result, { subtitle: contactsStore.getters.contactDisplayName(sender) })
  }

  if (content.type === 'image') {
    Object.assign(result, { image: content.previewURL })
  }

  return result
}

const handleNotificationClick = ({ chatId, teamId }) => {
  // if the message came from this team, then open chat
  // else open another team
  if (teamsStore.state.currentTeamId === teamId) {
    router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: chatId,
      },
    })
  } else {
    // if the command identifier did not come, then we exit.
    // This can happen on older versions of the electron.
    if (!teamId) return
    teamsStore.actions.changeTeam({ teamId, chatId })
  }
}

const TYPE_ELECTRON_NOTIFICATIONS = 'Electron Notifications'
const TYPE_HTML5_NOTIFICATION_API = 'HTML5 Notification API'

const methods = {
  [TYPE_HTML5_NOTIFICATION_API]: function (data) {
    const { tag: chatId, title } = data

    data.subtitle && (data.body = `${data.subtitle}: ${data.body}`)
    const notification = new Notification(title, data)

    const closeTimeoutId = setTimeout(() => {
      notification && notification.close()
    }, NOTIFICATION_LIFETIME)

    notification.onclick = () => {
      handleNotificationClick({ chatId })
      clearTimeout(closeTimeoutId)

      notification && notification.close()

      const { _ipc } = window
      _ipc && _ipc.send('notification-click')
    }
  },
  [TYPE_ELECTRON_NOTIFICATIONS]: function (data) {
    const { _ipc } = window
    _ipc && _ipc.send('notify', { data })
    // play standard sound on the mac os
    if (!Platform.is.mac) {
      (new Audio('/assets/temp-test-notify.mp3')).play()
    }
  },
  none () {
  },
}

const notifier = {
  init () {
    this.defaultNotificationType = localStorage.getItem('notification_api') ||
      (window.isElectron ? TYPE_ELECTRON_NOTIFICATIONS : TYPE_HTML5_NOTIFICATION_API)
    this.changeNotificationAPI()

    this.isTesting = !!localStorage.getItem('notification_testing')
  },
  changeNotificationAPI (type) {
    type = type || this.defaultNotificationType
    if (!methods[type] || type === this.currentNotificationType) return

    if (type !== TYPE_HTML5_NOTIFICATION_API) {
      const { _ipc } = window
      if (_ipc) {
        this.notificationClickHandler = (event, data) => {
          handleNotificationClick(data)
        }
        _ipc.on('notification-click', this.notificationClickHandler)
      }
    } else {
      const { _ipc } = window
      _ipc && this.notificationClickHandler && _ipc.removeListener('notification-click', this.notificationClickHandler)
    }

    this.currentNotificationType = type
    localStorage.setItem('notification_api', type)
  },
  getAvailableTypes () {
    return methods ? Object.keys(methods) : []
  },
  getCurrentType () {
    return this.currentNotificationType
  },
  push (message) {
    if (!this.isEnableFor(message)) return

    const method = methods[this.currentNotificationType]
    if (!method) return

    const data = normalizePayload(message)
    if (!data) return

    method(data)
  },
  isEnableFor (message) {
    if (this.isTesting) return true

    // if (!window.isElectron && !window.FEATURES.is_testing) return false
    if (InactiveDetector.isActive && document.hasFocus()) return false
    // if (Notification.permission !== 'granted') return false
    // if (localStorage.getItem('allowed_notifications') !== 'true' && !document.body.classList.contains('franz')) return false
    if (!(window.isElectron || document.body.classList.contains('franz'))) return false

    const { chatId, chatType, sender } = message
    const { getters } = store
    if (getters.getUserId === sender) return false

    if (chatType === 'group') {
      if (!getters.profile.groupNotificationsEnabled) return false
      if (!groupsStore.getters.isNotificationsEnabled(chatId)) return false
    }

    return true
  },
}

export default notifier
