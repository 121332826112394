import State from './state'
import { Getters } from 'vuex-smart-module'
import { InvitableUser } from '@tada-team/tdproto-ts'
import { sortEntitiesByName } from '@/utils'

class ModuleGetters extends Getters<State> {
  get externalSorted (): InvitableUser[] {
    return sortEntitiesByName([...this.state.externalUsers])
  }

  get internalSorted (): InvitableUser[] {
    return sortEntitiesByName([...this.state.internalUsers])
  }
}

export default ModuleGetters
