import i18n from '@/i18n'
import { Platform } from 'quasar'

import { electronLogger } from '@/loggers'
import store, {
  uiStore,
  uiSettingsStore,
} from '@/store'
import DOMUtils from '@/utils/DOM'
import { formatBytes, preventHtmlTags } from '@/utils'

const DOWNLOAD_LIMIT = (window?.FEATURES?.max_upload_mb ?? 700) * 1024 * 1024 / 10 // TODO вынести в настройки

const ipc = (window as any)._ipc
const isDownloadManagerEnabled = () => window._electronFeatures?.includes('downloadManager')

interface FileOptions {
  url: string;
  fileId: string;
  name: string;
  senderJid: string | null;
  force?: boolean;
}

interface FileData {
  name: string;
  size?: number;
  processing?: boolean;
  mediaURL: string;
  senderJid: string | null;
}

let filesQuery: Array<FileOptions> = []
let nowInProgress: Array<string> = []

export const downloadFileQuery = () => {
  filesQuery.forEach(notifyDownloadManager)
  filesQuery = []
}

/**
 * Функция отсылает 'download-manager-on-file' ивент с параметрами файла и флагом force (форсирование)
 * Файл форсированно загружается при клике по нему и при включенной автозагрузке
 * Нефорсированная загрузка информирует электрон о файле, в ответ электрон посылает на клиент данные,
 * если файл уже загружен
 * @param FileOptions
 */
const notifyDownloadManager = ({ url, fileId, name, senderJid, force = false }: FileOptions) => {
  if (nowInProgress.includes(fileId)) {
    ipc && ipc.send(
      'download-manager-on-file',
      { url, fileId, name, force: false },
    )
    return
  }

  // если не форсируем загрузку и это НАШ файл - интересуемся, есть ли в загрузках, но не скачиваем, если нет
  if (!force && senderJid === store.getters.getUserId) {
    ipc && ipc.send(
      'download-manager-on-file',
      { url, fileId, name, force },
    )
    return
  }

  force = uiSettingsStore.getters.desktopAutodownloadFiles || force
  if (!force && filesQuery.every(file => file.fileId !== fileId)) {
    // дубликаты по именам все равно будут скачаны, даже если файлы одинаковые.
    // ибо иначе хер его знает что может произойти, если одинаковые названия у разных типов файлов
    // сортировать стоит только по fileId - он уникален для каждой загрузки.
    // сравнивать name, hash и тд - дело бэкенда, вернет одинаковые fileId - скачаем один раз
    filesQuery.push({ url, fileId, name, senderJid, force: true })
  }

  // если forse===false -> файл не скачается, но электрон проверит его наличие и вернет
  // информацию о нем, если он загружен в ивент 'download-manager-on-file-progress' data.downloaded
  ipc && ipc.send(
    'download-manager-on-file',
    { url, fileId, name, force },
  )
}

const handleFileClick = (event: Event, { url, fileId, senderJid, name }: FileOptions) => {
  if (!isDownloadManagerEnabled()) return
  event.preventDefault()

  if (nowInProgress.includes(fileId)) return

  const el = document.querySelector(`a[data-file-id='${fileId}']`)
  if (!el) return

  const autoDownload = el.getAttribute('data-auto-download')
  if (autoDownload !== 'true') {
    notifyDownloadManager({ url, fileId, name, senderJid, force: true })
    el.setAttribute('data-auto-download', 'true')
    return
  }

  ipc && ipc.send(
    'download-manager-on-file-preview',
    { url, fileId, name },
  )
}

ipc && ipc.on('download-manager-on-file-progress', (event: Event, data: any) => {
  const el = document.querySelector(`a[data-file-id='${data.fileId}']`)
  if (!el) return
  const sizeEl = el.querySelector('.size') as HTMLElement
  if (!sizeEl) return

  const url = el.getAttribute('href')
  const name = el.getAttribute('download')

  const downloadedAlready = el.querySelectorAll('.file-btn').length > 0

  if (downloadedAlready) return

  !nowInProgress.includes(data.fileId) && nowInProgress.push(data.fileId)

  if (data.progress) {
    // &#8239; = неразрывный пробел
    sizeEl.innerHTML = data.progress + '&#8239;%'
    return
  }

  sizeEl.innerHTML = ''

  if (data.error) {
    const retryEl = DOMUtils.createElement(
      'span',
      { class: 'file-error-retry' },
      i18n.t('chattape.file.retry').toString(),
    )
    retryEl.addEventListener('click', () => {
      electronLogger.error('retry 2click')
      ipc && ipc.send(
        'download-manager-on-file',
        { url, fileId: data.fileId, name, force: true },
      )
    })
    sizeEl.appendChild(DOMUtils.createElement(
      'div',
      { class: 'file-error' },
      // DOMUtils.createTextNode(data.error)
      retryEl,
      preventHtmlTags(data.error.message || data.error),
    ))
    return
  }

  if (data.downloaded) {
    // удаляем из очереди только после того, как файл успешно загрузился.
    // удаляем только по fileId. Имя может быть не уникальным!
    filesQuery = filesQuery.filter(file => file.fileId !== data.fileId)
    nowInProgress = nowInProgress.filter(fileId => fileId !== data.fileId)

    const openFile = DOMUtils.createElement(
      'span',
      { class: 'file-btn' },
      i18n.t('chattape.file.open').toString(),
    )
    openFile.addEventListener('click', (event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      console.log('click, open')
      ipc && ipc.send(
        'download-manager-on-file-open',
        { url, fileId: data.fileId, name },
      )
    })
    sizeEl.appendChild(openFile)

    const showInFolderText = Platform.is.mac
      ? i18n.t('chattape.file.showInFinder').toString()
      : i18n.t('chattape.file.showInFolder').toString()
    const showInFolder = DOMUtils.createElement('span', { class: 'file-btn' }, showInFolderText)
    showInFolder.addEventListener('click', (event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      electronLogger.log('click, show')
      ipc && ipc.send(
        'download-manager-on-file-show-in-folder',
        { url, fileId: data.fileId, name },
      )
    })
    sizeEl.appendChild(showInFolder)
  }
})

ipc && ipc.on('download-manager-on-error', (event: Event, data: any) => {
  electronLogger.error('download-manager-on-error', event, data)

  uiStore.actions.showModal({
    instance: 'universal-yes-no',
    payload: { title: 'File download error', noText: i18n.t('common.close').toString(), text: data.error || data.message || data.toString() },
  })
})

export const createFileUpload = ({ name, size, processing, mediaURL, senderJid }: FileData) => {
  electronLogger.warn(size, DOWNLOAD_LIMIT)
  const autoDownload = size ? size < DOWNLOAD_LIMIT : false

  const url = mediaURL
  const fileId = url.match(/\/u\/([0-9a-f]*)\//)![1]

  const arrowIconElement = DOMUtils.createElement('i', { class: 'fas fa-arrow-down' })
  const downloadMarkElement = DOMUtils.createElement('div', { class: 'download-mark' }, arrowIconElement)
  const iconElement = DOMUtils.createElement('div', { class: 'file-chat' })

  const nameElement = DOMUtils.createElement('div', { class: 'name ellipsis' }, name || '-')

  const sizeElement = DOMUtils.createElement(
    'div',
    { class: 'o-interline size' },
    formatBytes(size || 0),
  )

  const detailsElements = [sizeElement]

  if (processing) {
    const processingElement = DOMUtils.createElement(
      'div',
      { class: 'o-interline size q-ml-sm text-capitalize' },
      i18n.t('common.processing').toString(),
    )
    detailsElements.push(processingElement)
  }

  const detailsContainer = DOMUtils.createElement(
    'div',
    { class: 'row' },
    ...detailsElements,
  )

  const infoElement = DOMUtils.createElement('div', { class: 'info ellipsis' }, nameElement, detailsContainer)

  const a = DOMUtils.createElement('a', {
    class: 'file o-no-default-a',
    target: '_blank',
    download: name,
    href: url,
    'data-file-id': fileId,
    'data-auto-download': autoDownload ? 'true' : 'false',
  }, downloadMarkElement, iconElement, infoElement)

  a.addEventListener('click', (e: MouseEvent) => handleFileClick(e, { url, fileId, senderJid, name }))

  autoDownload && notifyDownloadManager({ url, fileId, name, senderJid, force: false })

  return a
}

export const createFileContent = (content: TADA.MessageContentFile, sender: string): HTMLElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, size, text, processing, mediaURL } = content
  return createFileUpload({ name, size, processing, mediaURL, senderJid: sender })
}
