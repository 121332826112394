import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import InactiveDetector from '@/electron/InactiveDetector'
import Notifier from '@/electron/Notifier'
import { defaultLogger, electronLogger } from '@/loggers'
import store from '@/store'
import * as actionTypes from '@/store/actionTypes'

export interface DesktopCapturerSource {
  // Docs: https://electronjs.org/docs/api/structures/desktop-capturer-source
  id: string
  name: string
}

interface GetUserMediaConstraints {
  audio: boolean
  video: {
    mandatory: {
      chromeMediaSource: string
      chromeMediaSourceId: string
    }
  }
}

type GetUserMedia = (c: GetUserMediaConstraints) => Promise<MediaStream>

// import { changeServerDialog } from '@/components/Modals/Default/Dialog/Presets'

const listenServerChanger = () => {
  if (!window._ipc) return

  /**
   * Temporarily disable.
   * In fact, it had been disabled for god knows how long.
   */
  // window._ipc.on('server-changer', (event, payload) => {
  window._ipc.on('server-changer', () => {
    // payload.message === 'force-change-server-dialog' && changeServerDialog()
  })
}

export const changeServer = (buffer: { server: string, phone?: string }) => {
  const server = buffer.server
  if (!server) {
    electronLogger.error('Electron change server: no server is specified in buffer', buffer)
    return
  }
  const message = 'change-server'
  const data = { server, buffer }
  const payload = { message, data }
  if (!window._ipc) return
  window._ipc.send('server-changer', payload)
}

const ipcRenderer = window._ipc

export const fetchDesktopCapturerSources = (): Promise<DesktopCapturerSource[]> => new Promise((resolve, reject) => {
  if (!ipcRenderer) throw new Error('IPC not loaded')

  const timeout = 1000
  let timer = 0

  const handler = (e: Event, payload: any) => {
    clearTimeout(timer)

    const sources = payload as DesktopCapturerSource[]
    if (!sources.length) {
      const err = new Error('Desktop capture sources missing')
      electronLogger.warn(err.message)
      throw err
    }

    resolve(sources)
  }

  ipcRenderer.once('desktop-captures-sources', handler)

  timer = setTimeout(() => {
    ipcRenderer.removeListener('desktop-captures-sources', handler)
    reject(new Error('Timeout wait desktop capture sources'))
  }, timeout)

  ipcRenderer.send('desktop-capturer-request', {})
})

export const getStreamByDesktopCapturerSourceId = async (sourceId: string) => {
  return await (navigator.mediaDevices.getUserMedia as GetUserMedia)({
    audio: false,
    video: {
      mandatory: {
        chromeMediaSource: 'desktop',
        chromeMediaSourceId: sourceId,
      },
    },
  })
}

const requestAppFeatures = (): void => {
  const { _ipc, FEATURES } = window
  if (!_ipc || !FEATURES) return

  _ipc.send(
    'features-check',
    { features: FEATURES },
  )
}

const enableAppFeatures = (features: string[]) => {
  electronLogger.debug('Enabling electron app features...')

  window._electronFeatures = features
  if (!features.includes('customBuild')) {
    features.includes('serverChanger') && listenServerChanger()
  }
}

const listenAppFeatures = (): void => {
  const { _ipc } = window
  if (!_ipc) return

  _ipc.on('features-check', (event, payload) => {
    electronLogger.log('Received electron features:', payload)

    const { features } = payload
    if (!Array.isArray(features)) {
      electronLogger.warn('Unexpected electron features.', features)
      return
    }

    enableAppFeatures(features)
  })
}

const setupAppFeatures = () => {
  listenAppFeatures()
  requestAppFeatures()
}

export default function () {
  try {
    // эти штуки нужны (будут) и в вебе, надо как-то вынести
    InactiveDetector.init()
    let afkTimer: number | null
    window.FEATURES.afk_age > 0 && InactiveDetector.listeners.push(active => {
      if (active) {
        if (afkTimer) {
          window.clearTimeout(afkTimer)
          afkTimer = null
        }
        defaultLogger.log('SEND AFK', false)
        ChatEventBus.$emit(Events.READ_MESSAGES_IN_ACTIVE_CHAT)
        store.dispatch(actionTypes.SOCKET_SET_ACTIVITY, { afk: false })
      } else if (!afkTimer) {
        afkTimer = window.setTimeout(() => {
          defaultLogger.log('SEND AFK', true)
          store.dispatch(actionTypes.SOCKET_SET_ACTIVITY, { afk: true })
        }, document.hidden ? 0 : window.FEATURES.afk_age * 1000)
      }
    })

    // if (!window.isElectron && !window.FEATURES.is_testing) return

    Notifier.init()

    if (window.isElectron) {
      setupAppFeatures()
    }
  } catch (e) {
    electronLogger.warn('[Electron] Init error:', e)
  }
}
