export const isPixelUnit = (value: string) => value.lastIndexOf('px') === value.length - 2
export const toNumber = (value: string | null): number => value === null ? 0 : parseFloat(value)

export const preventSizeFloating = (element: HTMLElement, computedStyle?: CSSStyleDeclaration) => {
  const isGrow = +(computedStyle?.flexGrow || 1)
  if (isGrow) { element.style.flexGrow = '0' }

  const isShrink = +(computedStyle?.flexShrink || 1)
  if (isShrink) { element.style.flexShrink = '0' }
}
