/* eslint-disable no-use-before-define */
import * as Utils from './Utils'
import ContextStorage, { Item } from './ContextStorage'
import EventManager, { Target } from './EventHandlers'

export interface Options {
  isDisabled: boolean;

  key: string;
  minSize: number;
  maxSize: number;
  preventReflow: boolean;
  anchorThickness: number;

  anchorClass?: string;
  anchorZIndex: number;
  inertiaOffset?: number;

  // Computable if undefined (and preventReflow = false)
  startEdge?: boolean;
  defaultSize?: number;
  isColumnFlexDirection?: boolean;

  // Hooks
  onReady?: (context: ResizableInstance) => void;
  onResizeStopped?: (context: ResizableInstance) => void;
  onResizeStarted?: (context: ResizableInstance) => void;
  onSizeChanged?: (value: number, context: ResizableInstance) => void;
  onSizeChanging?: (value: number, context: ResizableInstance) => void;
  onDestroyed?: (context: ResizableInstance) => void;
}

const ANCHOR_CLASS = 'fresize-anchor'
const ANCHOR_SOURCE_ATTR = 'data-anchor'

/*
  TODOs:
  - 1) row/column flex directions
  - 2) max/min sizes
  3) vendor prefixes?
  - 4) Start/End anchor edge
*/
export default class ResizableInstance {
  public anchorElement: HTMLElement | null
  public cursorOverlayElement: HTMLElement | null
  public events: EventManager

  public currentSize?: number

  private storage: ContextStorage
  private isInTransition: boolean

  constructor (public element: HTMLElement, public options: Options) {
    this.anchorElement = null
    this.cursorOverlayElement = null
    this.isInTransition = false

    this.storage = new ContextStorage(this)
    this.events = new EventManager(this)

    const startSize = this.getStoredSize() || this.options.defaultSize
    if (startSize !== undefined) {
      this.setCurrentSize(startSize, true)
      Utils.preventSizeFloating(this.element)
    }
  }

  public prepare = () => {
    if (!this.options.preventReflow) {
      const elementStyle = window.getComputedStyle(this.element)

      if (this.options.isColumnFlexDirection === undefined) {
        const { parentElement: parent } = this.element
        const value = parent ? window.getComputedStyle(parent).flexDirection === 'column' : false
        this.options.isColumnFlexDirection = value
      }

      if (this.currentSize === undefined) {
        const basis = elementStyle.flexBasis
        const isPixel = basis ? Utils.isPixelUnit(basis) : false

        const { isColumnFlexDirection: isColumn } = this.options
        const size = Utils.toNumber(isPixel ? basis : elementStyle[isColumn ? 'height' : 'width'])
        this.setCurrentSize(size, true)

        Utils.preventSizeFloating(this.element, elementStyle)
      }

      this.setupAnchorEdge()

      if (elementStyle.position === 'static') {
        this.element.style.position = 'relative'
      }
    } else {
      this.options.isColumnFlexDirection = false
    }

    this.events.setupEventProperties()
    this.appendAnchorElement()

    if (this.options.onReady) {
      this.options.onReady(this)
    }
  }

  public update = () => {
    this.setupAnchorEdge(true)
  }

  public flush = (emitEvent = true) => {
    this.events.flush()

    this.handleTransitionEnd()
    this.flushAnchorElement()

    if (emitEvent && this.options.onDestroyed) {
      this.options.onDestroyed(this)
    }
  }

  public setCurrentSize = (value: number, store?: boolean): number => {
    const { inertiaOffset, minSize, maxSize } = this.options

    this.currentSize = Math.min(Math.max(value, minSize), maxSize)

    let elementSize = this.currentSize
    if (inertiaOffset && maxSize < Infinity && value > maxSize) {
      const outOffset = (value - maxSize)
      const slowdownFactor = 5 / (maxSize / value)

      elementSize = Math.min(this.currentSize + (outOffset / slowdownFactor), this.currentSize + inertiaOffset)
      this.element.style.flexBasis = `${elementSize}px`
    } else {
      this.element.style.flexBasis = elementSize + 'px'
    }

    if (store) {
      this.storage.save(Item.SIZE, this.currentSize)

      if (this.options.onSizeChanged) {
        this.options.onSizeChanged(this.currentSize, this)
      }
    } else {
      if (this.options.onSizeChanging) {
        this.options.onSizeChanging(this.currentSize, this)
      }
    }
    return elementSize
  }

  public maxSizeOverflowCorrection = (currentElementSize: number) => {
    const { maxSize, inertiaOffset } = this.options

    if (!inertiaOffset || currentElementSize < maxSize || this.isInTransition) { return }

    this.isInTransition = true

    this.element.addEventListener('transitionend', this.handleTransitionEnd)
    this.element.style.transition = 'flex-basis 0.15s ease'

    this.setCurrentSize(maxSize)
  }

  public toggleActiveState = (disable: boolean) => {
    this.options.isDisabled = disable

    if (disable) {
      this.flush(false)
      return
    }
    this.appendAnchorElement()
  }

  public toggleDraggingAnchorState = (active?: boolean) => {
    if (this.anchorElement === null) { return }

    const className = 'dragging'
    if (active) {
      this.anchorElement.classList.add(className)
    } else {
      this.anchorElement.classList.remove(className)
    }
  }

  public appendCursorOverlayElement = () => {
    this.flushCursorOverlayElement()

    if (this.anchorElement === null) { return }

    const { anchorZIndex, isColumnFlexDirection } = this.options

    const cursorOverlayElement = document.createElement('div')
    const cursorOverlayStyle = {
      position: 'fixed',
      zIndex: (anchorZIndex - 1) + '',
      cursor: `${isColumnFlexDirection ? 'ns' : 'ew'}-resize`,
      top: '0',
      left: '0',
      width: `${window.innerWidth}px`,
      height: `${window.innerHeight}px`,
    }
    Object.assign(cursorOverlayElement.style, cursorOverlayStyle)

    this.cursorOverlayElement = this.element.insertBefore(cursorOverlayElement, this.anchorElement)
  }

  public flushCursorOverlayElement = () => {
    if (this.cursorOverlayElement === null) { return }

    this.element.removeChild(this.cursorOverlayElement)
    this.cursorOverlayElement = null
  }

  private handleTransitionEnd = () => {
    this.isInTransition = false
    this.element.style.transition = ''

    this.element.removeEventListener('transitionend', this.handleTransitionEnd)
  }

  private setupAnchorEdge = (affectAnchorElement?: boolean) => {
    const value = !this.element.nextElementSibling

    if (this.options.startEdge === value) { return }

    this.options.startEdge = false

    if (affectAnchorElement) {
      this.appendAnchorElement()
    }
  }

  private getStoredSize = (): number | undefined => {
    const value = this.storage.get(Item.SIZE)
    if (!value) { return }

    const numberValue = Utils.toNumber(value)
    if (isNaN(numberValue)) { return }

    return numberValue
  }

  private appendAnchorElement = () => {
    if (this.options.isDisabled) { return }

    const { isColumnFlexDirection, anchorThickness, anchorClass, startEdge, key, anchorZIndex } = this.options

    const anchorElement = document.createElement('div')

    const offset = -(anchorThickness / 2)

    const anchorStyle = {
      position: 'absolute',
      cursor: `${isColumnFlexDirection ? 'ns' : 'ew'}-resize`,
      userSelect: 'none',
      zIndex: anchorZIndex + '',

      [isColumnFlexDirection ? 'left' : 'top']: '0',
      [isColumnFlexDirection ? (startEdge ? 'bottom' : 'top') : (startEdge ? 'right' : 'left')]: 'auto',
      [isColumnFlexDirection ? (startEdge ? 'top' : 'bottom') : (startEdge ? 'left' : 'right')]: `${offset}px`,
      [isColumnFlexDirection ? 'right' : 'bottom']: '0',
      [isColumnFlexDirection ? 'height' : 'width']: anchorThickness + 'px',
      [isColumnFlexDirection ? 'width' : 'height']: '100%',
    }
    Object.assign(anchorElement.style, anchorStyle)

    anchorElement.setAttribute('draggable', 'false')
    anchorElement.setAttribute(ANCHOR_SOURCE_ATTR, key)
    anchorElement.className = ANCHOR_CLASS

    if (anchorClass) {
      anchorElement.className += ' '
      anchorElement.className += Array.isArray(anchorClass)
        ? anchorClass.join(' ')
        : anchorClass
    }

    this.flushAnchorElement()

    this.anchorElement = this.element.appendChild(anchorElement)
    this.events.attachEvents(Target.ANCHOR)
  }

  private flushAnchorElement = () => {
    if (!this.anchorElement) { return }

    this.events.flushTarget(Target.ANCHOR)
    this.element.removeChild(this.anchorElement)

    this.anchorElement = null
  }
}
