import State from '@/store/modules/reminds/state'
import { RemindId } from '@/store/modules/reminds/types'
import { Remind } from '@tada-team/tdproto-ts'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  deleteReminds (payload: RemindId[]): void {
    payload.forEach(item => deleteRemind(this.state, item))
  }

  updateReminds (payload: Remind[]): void {
    payload.forEach(item => {
      deleteRemind(this.state, item.uid)
      this.state.list.push(item)
    })
  }

  startLoading (): void {
    this.state.isLoading = true
  }

  stopLoading (): void {
    this.state.isLoading = false
  }
}

function deleteRemind (state: State, uid: RemindId): void {
  const index = state.list.findIndex(item => item.uid === uid)
  index > -1 && state.list.splice(index, 1)
}
