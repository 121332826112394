import { OnlineCall, ServerCallBuzzParams } from '@tada-team/tdproto-ts'

class ModuleState {
  calls: Record<string, OnlineCall> = {}
  incomingBuzzingCalls: Record<string, ServerCallBuzzParams> = {}
  externalBuzz: ServerCallBuzzParams | null = null
  iceConfig: RTCConfiguration = {
    iceServers: [],
    iceTransportPolicy: 'relay',
  }
}

export default ModuleState
