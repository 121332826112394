import Vue from 'vue'
import union from 'lodash.union'
import difference from 'lodash.difference'
import actions from '@/store/modules/chat/actions'
import * as mutationTypes from '@/store/modules/chat/mutationTypes'
import * as actionTypes from '@/store/actionTypes'
import Messages from '@/store/messages'
import { defaultLogger } from '@/loggers'

const getDefaultState = () => {
  return {
    currentChat: null,
    currentChatErrorType: null, // null | '403' | '404'
    lastChat: null,
    previousOpenChat: null,

    currentActiveMessageData: null,
  }
}

export default {
  modules: {
    directChats: {},
    groupChats: {},
    taskChats: {},
    meetingChats: {},
    threadChats: {},
  },
  state: getDefaultState(),
  mutations: {
    [mutationTypes.CHATS_CURRENT_CHAT] (state, { chatId }) {
      state.currentChat = chatId
    },
    [mutationTypes.CHAT_SET_CURRENT_ACTIVE_MESSAGE] (state, data) {
      state.currentActiveMessageData = data
    },
    [mutationTypes.CHATS_SET_LAST_CHAT] (state, { chatId, previousOpenChat }) {
      state.lastChat = chatId
      state.previousOpenChat = previousOpenChat
    },
    [mutationTypes.CHATS_REMOVE_CHAT] (state, { chatId, type }) {
      if (state.lastChat === chatId) state.lastChat = null
      if (state.previousOpenChat === chatId) state.previousOpenChat = null

      const container = state[`${type}Chats`]
      if (!container) {
        defaultLogger.error(`[mutationTypes.CHATS_REMOVE_CHAT]: There is no container for "${type}" chat type`)
        return
      }
      Vue.delete(container, chatId)
    },
    [mutationTypes.CHAT_UPDATE_CHAT] (state, { chatType, chatId, updatedChat }) {
      const container = state[`${chatType}Chats`]

      Vue.set(container, chatId, updatedChat)
    },
    //! TODO: whenever you have a day (or a week) off - feel free to rewrite
    //! this chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId]
    //! to something remotely better
    [mutationTypes.CHATS_SETUP_CHAT_BOTCOMMANDS] (state, { chatId, commands, type = 'botcommands' }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      chat && Vue.set(chat, type, commands)
    },
    [mutationTypes.CHATS_TOGGLE_CHAT_EXCLUDED_STATE] (state, { chatId, exclude }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      chat && Vue.set(chat, 'hidden', exclude)
    },
    [mutationTypes.CHATS_TOGGLE_CHAT_PIN_STATE] (state, { chatId, pinned }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      chat && Vue.set(chat, 'pinned', pinned)
    },
    [mutationTypes.CHAT_SET_STARTING_MESSAGE_ID] (state, { chatId, messageId }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      chat && (chat.startingMessageId = messageId)
    },
    [mutationTypes.CHAT_SET_NUM_UNREAD] (state, { chatId, numUnread, numUnreadNotices = 0 }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      if (!chat) return

      chat.numUnread = numUnread >= 0 ? numUnread : 0
      chat.numUnreadNotices = numUnreadNotices
    },
    [mutationTypes.CHAT_SET_LAST_ACTIVITY] (state, { chatId, lastActivity }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      if (!chat || !lastActivity || chat.lastActivity >= lastActivity) return
      // do not assign it directly - lastActivity: string | undefined
      Vue.set(chat, 'lastActivity', lastActivity)
    },
    [mutationTypes.CHAT_SET_LAST_MESSAGE] (state, { chatId, lastMessage }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      chat && Vue.set(chat, 'lastMessage', lastMessage)
    },
    [mutationTypes.CHAT_SET_LAST_READ_MESSAGE_ID] (state, { chatId, messageId }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      chat && (chat.lastReadMessageId = messageId)
    },
    [mutationTypes.CHAT_OTHER_MESSAGE_COMPOSING] (state, { chatId, composing, actorId, audio }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      if (chat) {
        Vue.set(chat, 'composingIds', (composing ? union : difference)(chat.composingIds, [actorId]))
        if (audio) {
          Vue.set(chat, 'audioComposingIds', (composing ? union : difference)(chat.audioComposingIds, [actorId]))
        }
      }
    },
    [mutationTypes.CHAT_MESSAGE_COMPOSING] (state, { chatId, composing }) {
      const chat = state.groupChats[chatId] || state.directChats[chatId] || state.taskChats[chatId] || state.meetingChats[chatId] || state.threadChats[chatId]
      if (chat) {
        chat.messageComposing = composing
      }
    },
    [mutationTypes.CHAT_SET_ERROR_TYPE] (state, type) {
      state.currentChatErrorType = type
    },
  },
  actions: {
    [actionTypes.RESET_STORE] ({ state }) {
      Object.assign(state, getDefaultState())
      state.directChats = {}
      state.groupChats = {}
      state.taskChats = {}
      state.meetingChats = {}
      state.threadChats = {}

      Messages.deleteAll()
    },
    ...actions,
  },
}
