import { webRTCLogger } from '@/loggers'
import { JID, JSEP } from '@tada-team/tdproto-ts'
import { WebRTCBase, WebRTCBaseConstructorParams } from './WebRTCBase'

type WebRTCVideoReceiverConstructorParams = WebRTCBaseConstructorParams & {
  jsep: JSEP;
  callUid: string;
  onTrack: (p: { event: RTCTrackEvent, jid: JID }) => unknown
}

export class WebRTCVideoReceiver extends WebRTCBase {
  private remoteJSEP: JSEP
  private readonly callUid: string
  private readonly onTrackExternalHandler: (p: { event: RTCTrackEvent, jid: JID }) => unknown

  constructor (p: WebRTCVideoReceiverConstructorParams) {
    super(p)

    const { jsep, callUid, onTrack } = p
    this.remoteJSEP = jsep
    this.callUid = callUid
    this.onTrackExternalHandler = onTrack

    this.addOwnListeners()
    this.updateAnswer()
  }

  public updateJSEP = (jsep: JSEP): void => {
    this.remoteJSEP = jsep
    this.updateAnswer()
  }

  private readonly addOwnListeners = (): void => {
    this.peerConnection.ontrack = this.onTrack
  }

  private readonly updateAnswer = async (): Promise<void> => {
    webRTCLogger.debug('Creating offer')
    await this.onRemoteSDP(this.remoteJSEP)

    const answer = await this.peerConnection.createAnswer({
      iceRestart: true,
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    })

    if (!answer.sdp) {
      webRTCLogger.error('No SDP in created answer. Answer:', answer)
      throw new Error('No SDP in created answer.')
    }

    await this.peerConnection.setLocalDescription(answer)
    this.signalling.answer({ sdp: answer.sdp, uid: this.callUid })
  }

  private readonly onTrack = (event: RTCTrackEvent): void => {
    webRTCLogger.log('Received a track in Receiving WebRTC peer connection')
    const streams = event.streams

    if (streams.length < 1) {
      webRTCLogger.error('No streams in onTrack event:', streams)
      return
    }
    this.onTrackExternalHandler({ event, jid: this.callJid })
  }

  private readonly onRemoteSDP = async (jsep: JSEP): Promise<void> => {
    webRTCLogger.log('Handling remote SDP of type:', jsep.type)
    webRTCLogger.debug('JSEP:', jsep)

    const { type, sdp } = jsep

    if (type !== 'offer') {
      webRTCLogger.warn('Remote JSEP of invalid type in VideoReceiver:', type)
      this.tryToReconnect()
      return
    }

    await this.peerConnection.setRemoteDescription({ type, sdp })
    webRTCLogger.debug('Remote SDP set.')
  }
}
