import DOMUtils, { prepend } from '@/utils/DOM'
import store, {
  teamsStore,
  uiSettingsStore,
} from '@/store'

const enum FaviconType {
  DEFAULT = 'favicon.ico?r=2',
  NOCONNECTION = 'noconnection.png?r=2',
  UNREAD = 'unread.png?r=2'
}

class FaviconManager {
  state: number | null
  nUnread: number | null

  favicon: HTMLElement | null
  path = '/assets/favicons/td'

  constructor () {
    this.state = null
    this.nUnread = null

    this.favicon = null
  }

  init () {
    const elements = document.querySelectorAll('head link[rel~="icon"]')
    elements.forEach(DOMUtils.removeElement)

    this.setDefaultFavicon()
  }

  setFavicon (type: FaviconType) {
    let url = `${this.path}/${type}`
    if ((window as any).isHeadless && type !== FaviconType.NOCONNECTION) {
      const jid = store.getters.currentChat
      const entity = store.getters.entity(jid)
      url = entity?.icon || url
    }

    const useTeamAvatarForFavicon = uiSettingsStore.getters.globalSetting('useTeamAvatarForFavicon')
    if (useTeamAvatarForFavicon && type !== FaviconType.NOCONNECTION) {
      const icon = teamsStore.state.currentTeamId && teamsStore.getters.teamIconURL(teamsStore.state.currentTeamId)
      url = icon || url
    }

    const isPNG = type.indexOf('.png') >= 0
    if (this.favicon) {
      this.favicon.setAttribute('href', url)
      isPNG
        ? this.favicon.setAttribute('type', 'image/png')
        : this.favicon.removeAttribute('type')
      return
    }

    const attrs = { rel: 'icon', href: url }
    if (isPNG) {
      Object.assign(attrs, { type: 'image/png' })
    }
    this.favicon = DOMUtils.createElement('link', attrs)
    const head = document.querySelector('head')
    head && prepend(head, this.favicon)
  }

  setDefaultFavicon () {
    if (this.state === 0) return

    this.setFavicon(FaviconType.DEFAULT)

    // ?: why did we REALLY need this?
    // this.state = 0
  }

  setNoConnectionFavicon () {
    if (this.state === -1) return

    this.setFavicon(FaviconType.NOCONNECTION)

    // ?: why did we REALLY need this?
    // this.state = -1
  }

  setNewMessagesIcon () {
    if (this.state === 1) return

    this.setFavicon(FaviconType.UNREAD)

    // ?: why did we REALLY need this?
    // this.state = 1
  }

  sendUnreadsCountToMainProcess (count: number) {
    if (this.nUnread === count) return

    const { _ipc } = window as any
    _ipc && _ipc.send('unread-count', { count })

    this.nUnread = count
  }

  setUnreadMessagesFavicon (count: number) {
    if (window.isElectron) return this.sendUnreadsCountToMainProcess(count)

    count > 0
      ? this.setNewMessagesIcon()
      : this.setDefaultFavicon()
  }
}

const instance = new FaviconManager();
(window as any).f = instance
export default instance
