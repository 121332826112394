import store from '@/store'
import { Chat } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'
import {
  allChatsFilter,
  directChatsFilter,
  groupChatsFilter,
  hiddenChatsFilter,
  threadChatsFilter,
  unreadOnlyChatsFilter,
} from './filters'
import { byActivity, byName } from './sorts'
import State from './state'
import { SortFunction } from './types'

export default class Getters extends BaseGetters<State> {
  get sortFunction (): SortFunction {
    const { unreadFirst } = store.getters
    switch (this.state.sortType) {
      case 'activity': return (a, b) => byActivity(a, b, unreadFirst)
      case 'name': return (a, b) => byName(a, b, unreadFirst)
      default: throw new Error('Unexpected sort type')
    }
  }

  get allChats (): Chat[] {
    const { sortFunction } = this.getters
    return this.state.chats.filter(allChatsFilter).sort(sortFunction)
  }

  get directChats (): Chat[] {
    const { sortFunction } = this.getters
    return this.state.chats.filter(directChatsFilter).sort(sortFunction)
  }

  get groupChats (): Chat[] {
    const { sortFunction } = this.getters
    return this.state.chats.filter(groupChatsFilter).sort(sortFunction)
  }

  get hiddenChats (): Chat[] {
    const { sortFunction } = this.getters
    return this.state.chats.filter(hiddenChatsFilter).sort(sortFunction)
  }

  get unreadOnlyChats (): Chat[] {
    const { sortFunction } = this.getters
    return this.state.chats.filter(unreadOnlyChatsFilter).sort(sortFunction)
  }

  get threadChats (): Chat[] {
    const { sortFunction } = this.getters
    return this.state.chats.filter(threadChatsFilter).sort(sortFunction)
  }

  get currentTabChats () {
    const { tab } = this.state

    if (tab === 'all') return this.getters.allChats
    if (tab === 'direct') return this.getters.directChats
    if (tab === 'group') return this.getters.groupChats
    if (tab === 'hidden') return this.getters.hiddenChats
    if (tab === 'unreadOnly') return this.getters.unreadOnlyChats
    if (tab === 'comments') return this.getters.threadChats

    throw new Error(`Unexpected tab: ${tab}`)
  }

  get currentTabIsEmpty () {
    return !this.getters.currentTabChats.length
  }
}
