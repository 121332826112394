import i18n from '@/i18n'
import store, {
  chatsBarStore,
  contactsStore,
  sectionsStore,
  tasksStore,
  uiSettingsStore,
} from '@/store'
import { isNotUndefined } from '@/utils'
import * as Sentry from '@sentry/browser'
import { JID, TaskStatus } from '@tada-team/tdproto-ts'
import { format } from 'quasar'
import { Actions as BaseActions } from 'vuex-smart-module'
import { SECONDARY_RIGHT_BAR_INSTANCES_NAMES } from './defaults'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'
import {
  UIDraggedTaskData,
  UIMiddleColumn,
  UIModal,
  UIPayload,
  UIRightBar,
  UIRightBarFiltersAndSort,
  UITaskDeskFilters,
  UITaskGroup,
} from './types'

const { capitalize } = format

const CHATS_BAR_WIDTH_KEY = 'UI_CHATS_BAR_WIDTH'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  setupUiSettings (): void {
    const keepRightBarHidden = localStorage.getItem('keepRightBarHidden') === 'true'
    this.mutations.setRightBarHidden(keepRightBarHidden)

    const chatsBarWidth = localStorage.getItem(CHATS_BAR_WIDTH_KEY)
    if (chatsBarWidth) this.mutations.setChatsBarWidth(Number(chatsBarWidth))
  }

  setChatsBarWidth (value: number) {
    this.mutations.setChatsBarWidth(value)
    localStorage.setItem(CHATS_BAR_WIDTH_KEY, String(value))
  }

  resetChatsBarWidth () {
    this.mutations.resetChatsBarWidth()
    localStorage.removeItem(CHATS_BAR_WIDTH_KEY)
  }

  setRightBarHidden (value: boolean) {
    this.mutations.setRightBarHidden(value)

    try {
      localStorage.setItem('keepRightBarHidden', value + '')
    } catch (e) {
      console.warn(`[SWITCH_RIGHT_BAR_INSTANCE err]: ${e}`)
    }
  }

  toggleMessageSelection ({ messageId, add }: { messageId: JID, add: boolean }) { // TOGGLE_MESSAGE_SELECTION
    const index = this.getters.selectedMessagesIds.indexOf(messageId)
    const isExists = index >= 0
    if (isExists === add) return

    add
      ? this.mutations.addSelectedMessage(messageId)
      : this.mutations.removeSelectedMessage(index)
  }

  setRightBarFloatedMode (match: boolean) { // HANDLE_MEDIA_MATCH
    this.mutations.setRightBarFloatedMode(match)
  }

  switchRightBarInstance (p: Partial<UIRightBar> | undefined) { // SWITCH_RIGHT_BAR_INSTANCE
    const isSecondary = (cmp: string) => SECONDARY_RIGHT_BAR_INSTANCES_NAMES.includes(cmp)

    /**
     * OMFG what is all this :(
     * TODO: refactor
     */

    if (p && ('hide' in p)) {
      this.actions.setRightBarHidden(!!p.hide)

      if (this.state.keepRightBarHidden) {
        p.instance = ''
      }
    }

    if (p?.instance) {
      if (isSecondary(p.instance)) {
        p = {
          secondaryInstance: p.instance,
          secondaryPayload: p.payload,
        }
      } else {
        p.secondaryInstance = ''
        p.secondaryPayload = ''
      }
    } else {
      if (this.state.rightBar.secondaryInstance) {
        p ||= {
          secondaryInstance: '',
          payload: '',
        }
      } else {
        p ||= {
          instance: this.state.keepRightBarHidden
            ? ''
            : this.state.rightBar.instance || this.state.rightBar.defaultInstance,
          payload: '',
        }
      }
    }

    this.mutations.setRightBarData(p)
  }

  toggleTaskDesk (payload: { active: boolean}) { // TOGGLE_TASK_DESK
    if (!payload) return

    const { active } = payload
    if (active) {
      this.actions.switchMiddleColumnInstance({ instance: 'task-desk' })
    } else if (this.getters.isTaskDeskOpen) {
      const chatJid = store.getters.currentChat
      const isTask = chatJid && chatJid.startsWith('t-')
      const isMeeting = chatJid && chatJid.startsWith('m-')
      if (isTask) {
        this.actions.switchMiddleColumnInstance({ instance: 'full-task' })
      } else if (isMeeting) {
        this.actions.switchMiddleColumnInstance({ instance: 'Meeting' })
      } else {
        this.actions.resetMiddleColumnInstance()
      }
    }

    this.mutations.setTaskDeskData({ active })
  }

  switchMiddleColumnInstance (payload: Partial<UIMiddleColumn>) { // SWITCH_MIDDLE_COLUMN_INSTANCE
    this.mutations.setMiddleColumnInstance(payload)
  }

  resetMiddleColumnInstance (resetToPrev = false) { // RESET_MIDDLE_COLUMN_INSTANCE
    const { instance, prevInstance } = this.state.middleColumn
    if (instance === 'chat') return

    // reset to previous value or default value
    this.actions.switchMiddleColumnInstance({ instance: resetToPrev ? prevInstance : 'chat' })
  }

  showModal (payload: Partial<UIModal>) { // SHOW_MODAL
    this.actions.setModalInstance(payload)
  }

  hideModal () { // HIDE_MODAL
    const { currentModalPayload: payload } = this.getters

    const previousInstance = payload && (payload as any).previousInstance
    if (previousInstance) {
      this.actions.showModal({
        instance: String(previousInstance),
        payload: (payload as any).previousPayload as UIPayload,
      })
      return
    }

    this.actions.setModalInstance({ instance: '', payload: null })
  }

  setModalInstance ({ instance, payload }: Partial<UIModal>): void { // UI_MODAL_WINDOW_INSTANCE
    this.mutations.setModal({ instance, payload, result: this.state.modal.result })
    this.mutations.setMiddleColumn({ small: false })
  }

  setModalResult (result: string | null) { // SET_MODAL_RESULT
    this.mutations.setModalResult(result)
  }

  taskDeskSetFilters (filters: UITaskDeskFilters) { // TASK_DESK_SET_FILTERS
    this.mutations.setTaskDeskData({ filters })
  }

  setTaskDragData (payload: Partial<UIDraggedTaskData>): void {
    this.mutations.setTaskDragData(payload)
  }

  toggleLeftColumn (payload: boolean) { // TOGGLE_LEFT_COLUMN
    this.mutations.setTaskDeskData({ leftColumnHidden: payload })
  }

  setTaskDeskCompactView (bool: boolean) { // SET_TASK_DESK_COMPACT_VIEW
    this.mutations.setTaskDeskData({ compactCards: bool })
  }

  taskDeskTaskUpdated ({ task }: { task: TADA.Task }) { // TASK_DESK_TASK_UPDATED
    const isMatchedByFilters = (task: TADA.Task) => {
      const filters = this.getters.currentDeskFilters
      const groupByKey = uiSettingsStore.getters.currentDeskFilterKey

      if (groupByKey !== 'status' && task.taskStatus === 'done') return false

      if (filters.assignee &&
        filters.assignee.length > 0 &&
        filters.assignee.indexOf(task.assignee) === -1
      ) return false

      if (filters.owner &&
        filters.owner.length > 0 &&
        filters.owner.indexOf(task.owner) === -1
      ) return false

      if (
        filters.section?.length > 0 &&
        !filters.section.includes(String(task.section))
      ) return false

      if (filters.tag &&
        filters.tag.length > 0 &&
        task.tags.every(t => !filters.tag.includes(t))
      ) return false

      // ? need to write in future when add filters for importance

      return true
    }

    if (!this.state.taskDesk.groups) return

    let group = null
    const groups = this.state.taskDesk.groups

    // This whole bit is ugly AF, but you gotta do what you gotta do when
    // some logic is server-side and some is client-side.
    // What this does is basically adds new (missing) columns to taskdesk
    // when a task with such 'column' appears (created/ edited).

    switch (store.state.uiSettings.data.taskDesk.tabFilterKey) {
      case 'section': {
        const sectionId = task.section
        // if task desk already has a column for this task section
        if (groups.some(g => g.meta.section === sectionId)) break

        const tasks = Object
          .values(tasksStore.state.data)
          .filter(isNotUndefined)
          .filter(t => t.section === sectionId)
          .map(task => ({ jid: task.jid, num: task.num }))

        const title = (
          (sectionId && sectionsStore.getters.sectionNameByUid(sectionId)) ||
          capitalize(i18n.t('common.withoutProject').toString())
        )

        group = {
          groupByKey: 'section',
          // yeah, I know it's weird, but that's what a 'no section' column
          // gets after being processed when received from server :(
          key: sectionId ?? '{}',
          meta: { section: sectionId },
          tasks,
          title,
        }
        break
      }
      case 'status': {
        const statusName = task.taskStatus
        // if task desk already has a column for this task status
        if (groups.some(g => (g.meta.status as TaskStatus).name === statusName)) break

        const tasks = Object
          .values(tasksStore.state.data)
          .filter(isNotUndefined)
          .filter(t => t.taskStatus === statusName)
          .map(task => ({ jid: task.jid, num: task.num }))

        const status = (tasksStore.state.statuses)
          .find(s => s.name === statusName)

        if (status) {
          group = {
            groupByKey: 'status',
            key: status.uid as string,
            meta: { status: TaskStatus.fromJSON(status.toJSON()) },
            tasks,
            title: status.title,
          }
        }
        break
      }
      case 'assignee': {
        const assigneeId = task.assignee
        // if task desk already has a column for this task assignee
        if (groups.some(g => g.key === assigneeId)) break

        const tasks = Object
          .values(tasksStore.state.data)
          .filter(isNotUndefined)
          .filter(t => t.assignee === assigneeId)
          .map(task => ({ jid: task.jid, num: task.num }))

        group = {
          groupByKey: 'assignee',
          key: assigneeId,
          meta: { jid: assigneeId },
          tasks,
          title: contactsStore.getters.contactDisplayName(assigneeId),
        }
        break
      }
      case 'owner': {
        const ownerId = task.owner
        // if task desk already has a column for this task owner
        if (groups.some(g => g.key === ownerId)) break

        const tasks = Object
          .values(tasksStore.state.data)
          .filter(isNotUndefined)
          .filter(t => t.owner === ownerId)
          .map(task => ({ jid: task.jid, num: task.num }))

        group = {
          groupByKey: 'owner',
          key: ownerId,
          meta: { jid: ownerId },
          tasks,
          title: contactsStore.getters.contactDisplayName(ownerId),
        }
        break
      }
      case 'tag': {
        const tags = task.tags

        if (tags.length > 0) {
          tags.forEach(tag => {
            // if there is already a column for tag in a given task
            if (groups.some(g => g.meta.tag === tag)) return

            const tasks = Object
              .values(tasksStore.state.data)
              .filter(isNotUndefined)
              .filter(task => task.tags.includes(tag))
              .map(task => ({ jid: task.jid, num: task.num }))

            group = {
              groupByKey: 'tag',
              key: tag,
              meta: { tag },
              tasks,
              title: tag,
            }
          })
        } else if (groups.every(g => g.meta.tag !== undefined)) {
          // if task has no tags, but there is no 'without tags' column
          const tasks = Object
            .values(tasksStore.state.data)
            .filter(isNotUndefined)
            .filter(t => t.tags.length === 0)
            .map(task => ({ jid: task.jid, num: task.num }))

          const title = capitalize(i18n.t('common.withoutTags').toString())

          group = {
            groupByKey: 'tag',
            key: '{}',
            meta: {},
            tasks,
            title,
          }
        }
        break
      }
      case 'importance': {
        !task.importance && (task.importance = null)
        const { importance } = task

        const key = importance?.toString() || '{}'

        // if task desk already has a column for this importance level
        if (groups.some(g => g.key === key)) break

        const tasks = Object
          .values(tasksStore.state.data)
          .filter(isNotUndefined)
          .filter(t => t.importance === importance)
          .map(task => ({ jid: task.jid, num: task.num }))

        const title = importance
          ? store.getters.taskImportanceLabel({ importance })
          : i18n.t('tasks.filters.noImportance')

        group = {
          groupByKey: 'importance',
          key: key,
          meta: { importance },
          tasks,
          title,
        }
      }
    }
    group && this.mutations.createTaskDeskGroup(group)

    groups.forEach(group => {
      group.tasks = group.tasks.filter(t => t.jid !== task.jid)

      if (!isMatchedByFilters(task)) {
        return
      }

      if (
        group.meta.section !== undefined &&
        group.meta.section === task.section
      ) {
        this.mutations.setTaskDeskTask({
          key: group.key,
          task: { jid: task.jid },
        })
      }

      if (group.groupByKey === 'tag') {
        const addTaskToGroup = group.meta.tag === undefined
          ? task.tags.length === 0
          : task.tags.includes(String(group.meta.tag))
        addTaskToGroup && this.mutations.setTaskDeskTask({
          key: group.key,
          task: { jid: task.jid },
        })
      }

      if (
        group.groupByKey === 'owner' &&
        group.meta.jid === task.owner
      ) {
        this.mutations.setTaskDeskTask({
          key: group.key,
          task: { jid: task.jid },
        })
      }

      if (
        group.groupByKey === 'assignee' &&
        group.meta.jid === task.assignee
      ) {
        this.mutations.setTaskDeskTask({
          key: group.key,
          task: { jid: task.jid },
        })
      }

      if (
        group.groupByKey === 'status' &&
        // TODO: remove temporary solution
        (group.meta.status as { name?: string})?.name as string === task.taskStatus
      ) {
        this.mutations.setTaskDeskTask({
          key: group.key,
          task: { jid: task.jid },
        })
      }

      if (
        group.groupByKey === 'importance' &&
        group.meta.importance === task.importance
      ) {
        this.mutations.setTaskDeskTask({
          key: group.key,
          task: { jid: task.jid },
        })
      }
    })
  }

  taskDeskSetGroup ({ groups }: { groups: UITaskGroup[] }) { // TASK_DESK_SET_GROUPS
    this.mutations.setTaskDeskGroups(groups)
  }

  updateRightBarTasksSortFilters<T extends keyof UIRightBarFiltersAndSort> (
    p: { key: T; value: UIRightBarFiltersAndSort[T] },
  ): void { // UI_UPDATE_RIGHT_BAR_TASKS_SORT_FILTERS
    const { key, value } = p
    const allowedKeys = ['assignees', 'owners', 'section', 'tags', 'sort', 'search']
    if (!allowedKeys.includes(key)) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('tasks', 'filters')
        Sentry.captureException(`Passed wrong key for sorting/filtering: ${key} for value ${value} `)
      })
    }
    this.mutations.updateRightBarTasksSortFilters(p)
  }

  deleteTaskDeskGroup (uid: JID) { // UI_TASK_DESK_SECTION_DELETED
    this.mutations.deleteTaskDeskGroup(uid)
  }

  /**
     * Set new value for key filter or sort
     * Key should be 'assignees' | 'owners' | 'section' | 'tags' | 'sort' | 'search'
     * @see state for values types
     */
  resetRightBarTasksSortFilters () { // UI_RESET_RIGHT_BAR_TASKS_SORT_FILTERS
    this.mutations.resetRightBarTasksSortFilters()
  }

  deleteTaskDeskTask ({ jid }: { jid: JID}) { // TASK_DESK_TASK_DELETED
    if (this.state.taskDesk.groups) return

    this.mutations.deleteTaskDeskTask(jid)
  }

  openChat () { // OPEN_CHAT
    chatsBarStore.mutations.clearSearch()
    this.mutations.clearSelectedMessages()

    this.getters.isTaskDeskOpen && this.actions.toggleTaskDesk({ active: false })
  }

  clearSelectedMessages () { // UNSELECT_ALL_MESSAGES
    this.mutations.clearSelectedMessages()
  }
}
