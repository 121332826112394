import State from '@/store/modules/calendar/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import { ViewMode } from '@/store/modules/calendar/types'

export default class Mutations extends BaseMutations<State> {
  changeViewMode (mode: ViewMode) {
    this.state.viewMode = mode
  }

  setIsCalendarWasOpenBefore (val: boolean) {
    this.state.isCalendarWasOpenBefore = val
  }
}
