import Actions from '@/store/modules/calendar/actions'
import Getters from '@/store/modules/calendar/getters'
import Mutations from '@/store/modules/calendar/mutations'
import State from '@/store/modules/calendar/state'
import { Module } from 'vuex-smart-module'

const calendarModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default calendarModule
