import Actions from '@/store/modules/contacts/actions'
import Getters from '@/store/modules/contacts/getters'
import Mutations from '@/store/modules/contacts/mutations'
import State from '@/store/modules/contacts/state'
import { Module } from 'vuex-smart-module'

const contactsModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default contactsModule
