import { InvitableUser } from '@tada-team/tdproto-ts'
import { Mutations } from 'vuex-smart-module'
import State from './state'

class ModuleMutations extends Mutations<State> {
  setAllInternal (users: InvitableUser[]): void {
    this.state.internalUsers = users
  }

  setAllExternal (users: InvitableUser[]): void {
    this.state.externalUsers = users
  }
}

export default ModuleMutations
