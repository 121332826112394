// Field `is_archive` is omitted in each model
import Messages from '@/store/messages'
import {
  CodeTFA,
  CodeWithInfoTFA,
  InfoTFA,
} from '@/store/modules/profile/types'
import ThemeManager from '@/themes'
import {
  IconData,
  SingleIcon,
  Subtask,
  TaskCounters,
  TaskItem,
  UploadPreview,
} from '@tada-team/tdproto-ts'

export const getRandomIconPlaceholder = (entity: string): string => {
  return ThemeManager.getDefaultAvatar(entity)
}

export const DataAdapter = {
  tasksColorsRules: (raw: any): Array<TADA.TasksColorsRule> => raw ? raw.map(
    (rawRule: any) => DataAdapter.tasksColorsRule(rawRule),
  ) : [],
  tasksColorsRule: (raw: any): TADA.TasksColorsRule => ({
    colorIndex: raw.color_index,
    description: raw.description ?? null,
    priority: raw.priority,
    section: raw.section ?? null,
    sectionEnabled: raw.section_enabled ?? false,
    tags: raw.tags ?? [],
    tagsEnabled: raw.tags_enabled ?? false,
    taskComplexity: raw.task_complexity ?? null,
    taskImportance: raw.task_importance ?? null,
    taskImportanceEnabled: raw.task_importance_enabled ?? false,
    taskStatus: raw.task_status ?? null,
    taskUrgency: raw.task_urgency ?? null,
    taskUrgencyEnabled: raw.task_urgency_enabled ?? false,
    uid: raw.uid,
  }),
  twoFactorAuthInfo: (raw: any): InfoTFA => ({
    enabled: raw.enabled,
    recoveryStatus: raw.recovery_status,
  }),
  twoFactorAuthCode: (raw: any): CodeTFA => ({
    codeValidUntil: new Date(raw.code_valid_until),
    nextCodeAt: new Date(raw.next_code_at),
    codeLength: raw.code_length,
    email: raw.email ?? '',
  }),
  twoFactorAuthEmailConfirm: (raw: any): CodeWithInfoTFA => ({
    ...DataAdapter.twoFactorAuthInfo(raw),
    ...DataAdapter.twoFactorAuthCode(raw),
  }),
  /**
   * !TODO refactor
   * must use AutocompleteEntry instead of command
   * or change Autocomplete module types
   */
  chatlink: (raw: any): TADA.BotCommand => ({
    displayName: raw.key,
    args: raw.args || [],
    description: raw.title,
    help: raw.help || null,
    meta: raw.meta || {},
    key: raw.key,
    title: raw.title,
  }),
  chatlinks: (raw: any): Array<TADA.BotCommand> => raw ? raw.map((rawBotCommand: any) => DataAdapter.chatlink(rawBotCommand)) : [],

  /**
   * Attempts to retrieve icon from provided data based on provided constraints.
   * @param icons IconData containing at least some icons.
   * @param large Attempt to retrieve large icon.
   * @param urlOnly Only return URL to an icon instead of icon object.
   * @returns Icon object or URL to an icon.
   */
  icon: (
    icons: IconData, large = true, urlOnly = true,
  ): (
      string | SingleIcon
    ) => {
    const { stub, sm, lg } = icons

    if (!lg) {
      if (!sm) {
        if (!stub) throw new Error(`Nullish IconData from server: ${icons}`)
        return stub
      } else {
        return urlOnly ? sm.url : sm
      }
    } else {
      if (large) return urlOnly ? lg.url : lg
      if (sm) return urlOnly ? sm.url : sm
      return urlOnly ? lg.url : lg
    }
  },
  chat: (raw: any): TADA.Chat => ({
    audioComposingIds: [],
    baseGentime: raw.base_gentime,
    canDeleteAnyMessage: raw.can_delete_any_message ?? false,
    canSendMessage: raw.can_send_message || false,
    canSetImportantAnyMessage: raw.can_set_important_any_message || false,
    cantSendMessageReason: raw.cant_send_message_reason || (raw.chat_type === 'thread' ? 'thread' : null),
    chatId: raw.jid,
    chatType: raw.chat_type,
    composingIds: [],
    created: raw.created,
    draft: raw.draft,
    draftNum: raw.draft_num || -1,
    gentime: raw.gentime,
    hidden: raw.hidden,
    isGroup: raw.chat_type === 'group',
    lastActivity: raw.last_activity,
    lastMessage: raw.last_message && DataAdapter.message(raw.last_message),
    lastReadMessageId: raw.last_read_message_id,
    numUnread: raw.num_unread,
    numUnreadNotices: raw.num_unread_notices,
    pinned: raw.pinned || false,
    pinnedSortOrdering: raw.pinned_sort_ordering || null,
    displayName: raw.display_name ?? '',
  }),
  chats: (raw: any): Array<TADA.Chat> => raw ? raw.map((rawChat: any) => DataAdapter.chat(rawChat)) : [],
  task: (raw: any): TADA.Task => {
    // if (!raw.changeable_fields) console.warn(raw)
    const full = raw.description !== undefined
    return ({
      assignee: raw.assignee,
      baseGentime: raw.base_gentime,
      canCall: raw.can_call || false,
      canDelete: raw.can_delete || false,
      canDeleteAnyMessage: raw.can_delete_any_message ?? false,
      canSendMessage: raw.can_send_message || false,
      canSetImportantAnyMessage: raw.can_set_important_any_message || false,
      cantSendMessageReason: raw.cant_send_message_reason || null,
      changeableFields: raw.changeable_fields || [],
      chatType: raw.chat_type,
      collapsed: raw.collapsed,
      colorIndex: raw.color_index === undefined ? null : raw.color_index,
      complexity: raw.complexity || null,
      countersEnabled: raw.counters_enabled || false,
      created: raw.created,
      deadline: raw.deadline || null,
      deadlineExpired: raw.deadline_expired || false,
      description: raw.description, // undef in short
      displayName: `#${raw.display_name}`,
      done: raw.done,
      draft: raw.draft,
      feed: raw.feed ?? false,
      full,
      gentime: raw.gentime,
      icon: raw.icons.sm ? SingleIcon.fromJSON(raw.icons.sm) : (raw.icons.stub || ''),
      icons: IconData.fromJSON(raw.icons),
      importance: raw.importance || null,
      items: raw.items ? raw.items.map(TaskItem.fromJSON) : [],
      jid: raw.jid,
      largeIcon: raw.icons.lg ? SingleIcon.fromJSON(raw.icons.lg) : (raw.icons.stub || ''),
      lastActivity: raw.last_activity,
      lastMessage: raw.last_message && DataAdapter.message(raw.last_message),
      lastReadMessageId: raw.last_read_message_id,
      linkedMessages: raw.linked_messages
        ? raw.linked_messages.map((raw: any) => typeof raw === 'string'
          ? raw
          : DataAdapter.message(raw))
        : [], // undef in short
      notificationsEnabled: raw.notifications_enabled || false,
      num: raw.num,
      numCheckedItems: raw.num_checked_items || 0,
      numItems: raw.num_items || 0,
      numUnread: raw.num_unread || 0,
      numUnreadNotices: raw.num_unread_notices || 0,
      observers: raw.observers || [],
      owner: raw.owner,
      parents: (raw.parents ?? []).map(Subtask.fromJSON),
      pinned: raw.pinned || false,
      pinnedSortOrdering: raw.pinned_sort_ordering || null,
      public: raw.public || false,
      section: raw.section || null,
      tabs: raw.tabs,
      tags: raw.tags || [],
      taskStatus: raw.task_status,
      title: raw.title,
      uploads: raw.uploads?.map(DataAdapter.upload),
    })
  },
  taskData: (raw: any): { chat: TADA.Chat; task: TADA.Task } => ({
    chat: DataAdapter.chat(raw),
    task: DataAdapter.task(raw),
  }),
  tasks: (raw: any): { chats: Array<TADA.Chat>; tasks: Array<TADA.Task> } => {
    const chats: Array<TADA.Chat> = []
    const tasks: Array<TADA.Task> = []
    raw.forEach((rawTaskData: any) => {
      const { chat, task } = DataAdapter.taskData(rawTaskData)
      chats.push(chat)
      tasks.push(task)
    })
    return { chats, tasks }
  },
  taskTab: (raw: any): TADA.TaskTab => ({
    filters: raw.filters,
    hideEmpty: raw.hide_empty,
    key: raw.key,
    pagination: raw.pagination,
    showCounter: raw.show_counter,
    sort: raw.sort,
    title: raw.title,
    unreadTasks: raw.unread_tasks.map(TaskCounters.fromJSON),
  }),
  taskTabs: (raw: any): Array<TADA.TaskTab> => {
    return raw ? raw.map(DataAdapter.taskTab) : []
  },
  contact: (raw: any): TADA.Contact => {
    const result: TADA.Contact = {
      altSend: raw.alt_send || false,
      asteriskMention: raw.asterisk_mention || false,
      auth2faEnabled: raw.auth_2fa_enabled ?? false,
      auth2faStatus: raw.auth_2fa_status ?? 'unconfirmed',
      botName: raw.botname,
      canAddToGroup: raw.can_add_to_group,
      canAddToTeam: raw.can_add_to_team || false,
      canCall: raw.can_call,
      canCreateGroup: raw.can_create_group || false,
      canCreateTask: raw.can_create_task || false,
      canDelete: raw.can_delete && !raw.is_archive,
      canDeleteAnyMessage: raw.can_delete_any_message ?? false,
      canImportTasks: raw.can_import_tasks || false,
      canJoinPublicGroups: raw.can_join_public_groups || false,
      canJoinPublicTasks: raw.can_join_public_tasks || false,
      canManageColorRules: raw.can_manage_color_rules || false,
      canManageIntegrations: raw.can_manage_integrations || false,
      canManageSections: raw.can_manage_sections || false,
      canSendMessage: raw.can_send_message || false,
      cantSendMessageReason: raw.cant_send_message_reason || null,
      canImportChats: raw.can_import_chats,
      changeableFields: raw.changeable_fields || [],
      chatType: raw.chat_type,
      customFields: raw.custom_fields || null,
      defaultLang: raw.default_lang || 'ru',
      directShortView: raw.contact_short_view,
      displayName: raw.display_name,
      email: raw.contact_email,
      familyName: raw.family_name,
      givenName: raw.given_name,
      groupNotificationsEnabled: raw.group_notifications_enabled,
      groupShortView: raw.group_short_view,
      icon: DataAdapter.icon(raw.icons, false) || getRandomIconPlaceholder('contact'),
      icons: IconData.fromJSON(raw.icons),
      isArchive: raw.is_archive,
      isBot: !!raw.botname,
      isGroup: false,
      jid: raw.jid,
      largeIcon: DataAdapter.icon(raw.icons, true),
      lastActivity: raw.last_activity,
      node: raw.node,
      patronymic: raw.patronymic || null,
      phone: raw.contact_phone,
      role: raw.role,
      section: raw.section || null,
      sections: raw.sections || [],
      shortName: raw.short_name,
      status: raw.status,
      taskNotificationsEnabled: raw.task_notifications_enabled,
      taskShortView: raw.task_short_view,
      unreadFirst: raw.unread_first ?? false,
    }
    result.largeIcon = result.largeIcon || result.icon

    return result
  },
  contacts: (raw: any): Array<TADA.Contact> => raw ? raw.map((rawContact: any) => DataAdapter.contact(rawContact)) : [],
  upload: (raw: any): TADA.Upload => ({
    contentType: raw.content_type,
    created: raw.created,
    description: raw.description,
    name: raw.name,
    preview: raw.preview ? UploadPreview.fromJSON(raw.preview) : null,
    processing: raw.processing ?? false,
    size: raw.size,
    uid: raw.uid,
    url: raw.url,
  }),
  // TODO: [TS] Transform typed: content, links
  message: (raw: any): TADA.Message => ({
    messageId: raw.message_id,

    content: raw.content,
    sender: raw.from,
    created: raw.created,
    edited: raw.edited,
    editableUntil: raw.editable_until,
    hasPreviews: raw.has_previews,

    chatType: raw.chat_type,
    chatId: raw.chat,
    pushText: raw.push_text,

    isStandalone: false,
    read: raw.read,
    received: raw.received,
    isFirst: raw.is_first,
    isLast: raw.is_last,
    state: TADA.MessageState.NORMAL,
    linkedMessages: raw.linked_messages ? raw.linked_messages.map((raw: any) => DataAdapter.message(raw)) : [],
    links: raw.links || [],
    reactions: raw.reactions || [],
    replyTo: raw.reply_to ? DataAdapter.message(raw.reply_to) : null,

    important: raw.important,
    notice: raw.notice,
    silently: raw.silently,
    nopreview: raw.nopreview,
    markup: raw.markup ?? [],

    _debug: raw._debug || null,
    num: raw.num,
    prev: raw.prev,

    // Unused yet? Same as `chatId`
    recipient: raw.to,
    uploads: raw.uploads ? raw.uploads.map(DataAdapter.upload) : [],

    threadJID: raw.thread_jid ? raw.thread_jid : undefined,
    threadMessagesCount: raw.thread_messages_count,
  }),
  filterMessage: (raw: any): TADA.Message => {
    const message = Object.assign({}, DataAdapter.message(raw), { isStandalone: true })
    Messages.addStandaloneMessage(message.chatId, message)
    return message
  },
  messages: (raw: any, cap = -1): { data: { [messageId: string]: TADA.Message }; orderIds: Array<string> } => {
    cap >= 0 && cap < raw.length && raw.splice(0, raw.length - cap)

    const data: { [messageId: string]: TADA.Message } = {}
    const orderIds: Array<string> = []
    raw.forEach((rawMessage: any) => {
      const message = DataAdapter.message(rawMessage)
      const { messageId } = message

      data[messageId] = message
      orderIds.push(messageId)
    })
    return { data, orderIds }
  },
  user: (raw: any): TADA.User => ({
    displayName: raw.display_name,
    uid: raw.uid,
    icons: IconData.fromJSON(raw.icons),
  }),
  iceConfig: (raw: any): RTCConfiguration => ({
    iceServers: raw.ice_servers,
    iceTransportPolicy: raw.ice_transport_policy,
  }),
  users: (raw: any): Array<TADA.User> => raw.map((rawUser: any) => DataAdapter.user(rawUser)),
  theme: (raw: any): TADA.Theme => ({
    '--accent-color': raw.AccentColor,
    '--accent-hover-color': raw.AccentHoverColor,
    '--bg-color': raw.BgColor,
    '--bg-hover-color': raw.BgHoverColor,
    '--main-accent': raw.MainAccent,
    '--main-accent-hover': raw.MainAccentHover,
    '--main-light-accent': raw.MainLightAccent,
    '--main-link': raw.MainLink,
    '--muted-text-color': raw.MutedTextColor,
    '--text-color': raw.TextColor,
    '--text-on-accent-color': raw.TextOnAccentHoverColor,
  }),
}
