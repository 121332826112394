import i18n from '@/i18n'
import { contactsStore, teamsStore } from '@/store'
import State from '@/store/modules/tasks/state'
import { isNotUndefined } from '@/utils'
import { Contact, JID, TaskColor, TaskCounters } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'

const NUM_ICONS_FOR_TASK_IMPORTANCE = 5
const DEFAULT_CARD_COLORS = TaskColor.fromJSON({
  dark: '#b9b9b9',
  light: '#ffffff',
  regular: '#F9f9f9',
})

export default class Getters extends BaseGetters<State> {
  canChangeStatus (jid: JID): boolean | undefined {
    return this.state.data[jid]?.changeableFields.includes('task_status')
  }

  canDelete (jid: JID): boolean | undefined {
    return this.state.data[jid]?.canDelete
  }

  // TODO: not REALLY true.
  // Changing task checklist, for example, is not REALLY editing the task.
  canEdit (jid: JID): boolean | undefined {
    const t = this.state.data[jid]
    return t?.changeableFields && t.changeableFields.length > 0
  }

  cardColors (jid?: JID): TaskColor | undefined {
    const userDefinedColors = jid ? this.getters.color(jid) : undefined
    return userDefinedColors ?? DEFAULT_CARD_COLORS
  }

  color (jid: JID): TaskColor | undefined {
    const task = this.state.data[jid]
    if (task && task.colorIndex) return this.state.colors[task.colorIndex]
  }

  complexityLabel (jid: JID): string {
    const prefix = i18n.t('glossary.complexity_short')
    const task = this.state.data[jid]
    const complexity = task?.complexity ?? i18n.t('common.no')
    return `${prefix} ${complexity}`
  }

  get count (): number {
    return Math.max(this.state.count, Object.keys(this.state.data).length)
  }

  displayName (jid: JID): string | undefined {
    const t = this.state.data[jid]
    return t && `#${t.displayName}`
  }

  get hasExtraStatuses (): boolean {
    return this.state.statuses.length > 2
  }

  get hasMissedDeadlines (): boolean {
    return Object
      .values(this.state.data)
      .some(task => task?.deadlineExpired === true)
  }

  isClosed (jid: JID): boolean {
    const t = this.state.data[jid]
    return t?.taskStatus === 'done'
  }

  importanceLabel (importance?: number | null, usePrefix = false): string {
    const label = importance ?? i18n.t('common.no')
    if (!usePrefix) return `${label}`
    const prefix = i18n.t('glossary.importance_short')
    return `${prefix} ${label}`
  }

  get importanceOptions (): number[] {
    /*
     * Takes team importance values from min to max and
     * returns them in order of increasing importance
     */
    const min = teamsStore.getters.currentTeam?.taskImportanceMin
    const max = teamsStore.getters.currentTeam?.taskImportanceMax
    if (!min || !max) return []

    const rev = teamsStore.getters.currentTeam?.taskImportanceRev
    const options: number[] = []
    for (
      let i = rev ? min : max;
      rev ? i <= max : i >= min;
      rev ? i++ : i--
    ) options.push(i)

    return options
  }

  get importanceUseIcons (): boolean {
    return (
      this.getters.importanceOptions.length ===
      NUM_ICONS_FOR_TASK_IMPORTANCE
    )
  }

  /**
   * Get locally available members of a given task.
   * @param {string} jid Task jid to return members for.
   * @returns {Contact[]} member objects for a given task.
   */
  members (jid: JID): Contact[] {
    return (this.getters.membersJids(jid) ?? [])
      .map(j => contactsStore.getters.contact(j))
      .filter(isNotUndefined)
  }

  membersJids (jid: JID): string[] {
    const t = this.state.data[jid]
    return t ? [...new Set([t.owner, t.assignee, ...t.observers])] : []
  }

  get pinned (): TADA.Task[] {
    const tasks = Object.values(this.state.data) as TADA.Task[]
    return tasks.filter(item => item.pinned)
  }

  statusLabel (jid: JID): string | undefined {
    const task = this.state.data[jid]
    if (task) return this.getters.statusTitle(task.taskStatus)
  }

  statusTitle (name: string): string | undefined {
    return this.state.statuses.find(item => item.name === name)?.title
  }

  statusTitleByUid (uid?: string): string | null {
    if (!uid) return null
    return this.state.statuses.find(item => item.uid === uid)?.title ?? null
  }

  /**
   * All unread tasks, both available locally and on the server.
   * @returns {Array<TADA.Task | TaskCounters>}
   */
  get unread (): Array<TADA.Task | TaskCounters> {
    return [
      ...this.getters.unreadInStore,
      ...this.getters.unreadNotInStore,
    ]
  }

  /**
   * Total number of unread tasks (not messages inside them!)
   * @returns {number}
   */
  get unreadCounter (): number {
    return this.getters.unread.length
  }

  /**
   * Unread tasks counters by task tab.
   * @returns {Record<string, number>} A tab key to unread tasks number object
   */
  get unreadCountersByTab (): Record<string, number> {
    const r = Object.fromEntries(
      Object.values(this.state.tabs).map(tab => {
        // only leave tasks that are not available locally
        // locally available tasks are factored in later (see below)
        const tasks = tab.unreadTasks.filter(t => {
          return this.getters.unreadNotInStore.some(ut => ut.jid === t.jid)
        })
        return [tab.key, tasks.length]
      }),
    )

    // factor in tasks with unreads that are available locally
    Object.values(this.getters.unreadInStore).forEach(t => {
      t.tabs.forEach(key => (r[key] += 1))
    })

    return r
  }

  /**
   * All tasks on client that have unread messages.
   * @returns {TADA.Task[]} an array of tasks
   */
  get unreadInStore (): TADA.Task[] {
    const tasks = Object.values(this.state.data) as TADA.Task[]
    return tasks.filter(t => !!t.numUnread)
  }

  /**
   * All tasks with unread messages at time when tasks/tabs was loaded.
   * Warning! This is basically static. Returned tasks may have been read
   * a while ago. This is never updated unless on explicit GET task/tabs.
   * @returns {TaskCounters[]} array of TaskConters
   */
  get unreadInTabs (): TaskCounters[] {
    const result: TaskCounters[] = []

    // all unique tasks with unreads from tasks/tabs
    Object.values(this.state.tabs).forEach(tab => {
      tab.unreadTasks.forEach(t => {
        result.every(item => item.jid !== t.jid) && result.push(t)
      })
    })

    return result
  }

  /**
   * All tasks with notices, both available locally and on the server.
   * @returns {Array<TADA.Task | TaskCounters>}
   */
  get unreadNoticed (): Array<TADA.Task | TaskCounters> {
    return this.getters.unread.filter(t => !!t.numUnreadNotices)
  }

  /**
   * Total number of tasks (not messages inside them!) with unread notices
   * @returns {number}
   */
  get unreadNoticedCounter (): number {
    return this.getters.unreadNoticed.length
  }

  /**
   * All tasks on client that have unread notices.
   * @returns {TADA.Task[]} an array of tasks
   */
  get unreadNoticedInStore (): TADA.Task[] {
    return this.getters.unreadInStore.filter(t => !!t.numUnreadNotices)
  }

  /**
   * Tasks with unread notices that are not yet on the client.
   * These are TaskCounters, received in tasks/tabs, but not yet loaded locally.
   * This is done to prevent having a task with notices in TaskTabs,
   * when all notices in this task are read after TaskTabs were loaded.
   * @returns {TaskCounters[]} array of TaskCounters
   */
  get unreadNoticedNotInStore (): TaskCounters[] {
    return this.getters.unreadNotInStore.filter(t => !!t.numUnreadNotices)
  }

  /**
   * Counters of tasks with unread notices by task tab.
   * @returns {Record<string, number>} A tab key to unread tasks number object
   */
  get unreadNoticesByTab (): Record<string, number> {
    const r = Object.fromEntries(
      Object.values(this.state.tabs).map(tab => {
        // only leave tasks that are not available locally
        // locally available tasks are factored in later (see below)
        const tasks = tab.unreadTasks.filter(t => {
          return this.getters.unreadNoticedNotInStore.some(
            ut => ut.jid === t.jid,
          )
        })
        return [tab.key, tasks.length]
      }),
    )

    // factor in tasks with unreads that are available locally
    Object.values(this.getters.unreadNoticedInStore).forEach(t => {
      t.tabs.forEach(key => (r[key] += 1))
    })

    return r
  }

  /**
   * Tasks with unread messages that are not yet on the client.
   * These are TaskCounters, received in tasks/tabs, but not yet loaded locally.
   * This is done to prevent having an unread task in TaskTabs,
   * when all messages in this task are read after TaskTabs were loaded.
   * @returns {TaskCounters[]} array of TaskCounters
   */
  get unreadNotInStore (): TaskCounters[] {
    return this.getters.unreadInTabs.filter(task => (
      this.getters.unreadInStore.every(item => item.jid !== task.jid) &&
      !this.state.data[task.jid]
    ))
  }
}
