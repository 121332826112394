import { getDateWithDifference } from '@/api/v3/DataGenerator'
import State from '@/store/modules/contacts/state'
import { Contact, JID, OnlineContact } from '@tada-team/tdproto-ts'
import Vue from 'vue'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  addOrUpdateContacts (contacts: Contact[]) {
    for (const item of contacts) this.state.data[item.jid] = item
  }

  /**
   * Setting new contacts
   * @param contacts
   */
  setContacts (contacts: Record<string, Contact>): void {
    this.state.data = contacts
  }

  /**
   * Set new online contacts list objects
   * @param contacts
   */
  addOnline (contacts: Array<OnlineContact>): void {
    this.state.onlineIds = contacts
    this.state.afkJids = contacts.filter(c => c.afk).map(c => c.jid)

    const lastActivity = getDateWithDifference('iso')
    contacts.forEach(data => {
      const { jid } = data
      const contact = this.state.data[jid]
      contact && Vue.set(contact, 'lastActivity', lastActivity)
    })
  }

  /**
   * Add new contact into contacts object
   * @param contact
   */
  addContact (contact: Contact): void {
    const newContactRecord: Record<string, Contact> = {}
    newContactRecord[contact.jid] = contact
    this.state.data = { ...this.state.data, ...newContactRecord }
  }

  /**
   * Add new contacts into contacts object
   * @param contacts
   */
  addContacts (contacts: Contact[]): void {
    this.state.data = {
      ...this.state.data,
      ...Object.fromEntries(contacts.map(c => ([c.jid, c]))),
    }
  }

  /**
   * Update contact into all contacts
   * @param contact
   */
  updateContact (contact: Contact): void {
    const oldContactJSON = this.state.data[contact.jid]?.toJSON()
    const updatedContactJSON = contact.toJSON()
    this.state.data[contact.jid] = Contact.fromJSON({ ...oldContactJSON, ...updatedContactJSON })
  }

  /**
   * Remove a contact from the contacts list
   * @param contactId
   */
  deleteContact (contactId: JID): void {
    Vue.delete(this.state.data, contactId)
  }

  /**
   * Add imported user id
   * @param contactId
   */
  addImport (contactId: JID): void {
    this.state.imports.push(contactId)
  }
}
