import Actions from '@/store/modules/sections/actions'
import Getters from '@/store/modules/sections/getters'
import Mutations from '@/store/modules/sections/mutations'
import State from '@/store/modules/sections/state'
import { Module } from 'vuex-smart-module'

const sectionsModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default sectionsModule
