// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './style.css'

const ctx = '@@ScrollBar'
const minThumbHeight = 50

const createScrollBar = theme => {
  const track = document.createElement('div')
  track.id = 'custom-scroll-bar'
  track.className = theme ? 'scroll-bar-' + theme : 'default'

  const wrapper = document.createElement('div')
  wrapper.className = 'thumb-wrapper'

  const thumb = document.createElement('div')
  thumb.className = 'scroll-bar-thumb'

  wrapper.appendChild(thumb)
  track.appendChild(wrapper)

  return { track, thumb }
}

const addAllEvents = function () {
  const directiveContext = this
  const { rootElement, track, thumb, animationEvent, animationFunction } = directiveContext

  directiveContext.trackMouseDown = events.trackMouseDown.bind(directiveContext)
  directiveContext.thumbMouseDown = events.thumbMouseDown.bind(directiveContext)
  directiveContext.updateThumb = events.updateThumb.bind(directiveContext)
  directiveContext.scrollByWheel = events.scrollByWheel.bind(directiveContext)

  track.addEventListener('mousedown', directiveContext.trackMouseDown)
  thumb.addEventListener('mousedown', directiveContext.thumbMouseDown)

  rootElement.addEventListener('scroll', directiveContext.updateThumb)

  rootElement.addEventListener('DOMMouseScroll', directiveContext.scrollByWheel)
  rootElement.addEventListener('mousewheel', directiveContext.scrollByWheel)

  if (animationFunction && animationEvent.length > 0) {
    rootElement.addEventListener(animationEvent, animationFunction)
  }

  window.addEventListener('resize', directiveContext.updateThumb)
}

const block = function () {
  this.isBlocked = true
}

const unblock = function () {
  this.isBlocked = false
  this.scrollIsInProgress = false
  this.scrollingEnd = null
  this.isGoingUp = false
}

const removeAllEvents = function () {
  const directiveContext = this
  const { rootElement, track, thumb, animationEvent, animationFunction } = directiveContext

  track.removeEventListener('mousedown', directiveContext.trackMouseDown)
  thumb.removeEventListener('mousedown', directiveContext.thumbMouseDown)

  rootElement.removeEventListener('scroll', directiveContext.updateThumb)

  rootElement.removeEventListener('DOMMouseScroll', directiveContext.scrollByWheel)
  rootElement.removeEventListener('mousewheel', directiveContext.scrollByWheel)

  if (animationFunction && animationEvent.length > 0) {
    rootElement.removeEventListener(animationEvent, animationFunction)
  }

  window.removeEventListener('resize', directiveContext.updateThumb)

  clearDragging.call(directiveContext)
}

const dragStart = function (event) {
  event.stopImmediatePropagation()

  const directiveContext = this

  directiveContext.handleDrag = events.handleDrag.bind(directiveContext)
  directiveContext.handleDragEnd = events.handleDragEnd.bind(directiveContext)

  document.addEventListener('mousemove', directiveContext.handleDrag)
  document.addEventListener('mouseup', directiveContext.handleDragEnd)
}

const clearDragging = function () {
  const directiveContext = this

  document.removeEventListener('mousemove', directiveContext.handleDrag)
  document.removeEventListener('mouseup', directiveContext.handleDragEnd)
}

const events = {
  updateThumb () {
    const {
      scrollHeight: rootScrollHeight,
      clientHeight: rootHeight,
      scrollTop: rootOffset,
    } = this.rootElement
    const trackHeight = this.track.clientHeight

    const thumbHeight = getThumbHeight(rootHeight, rootScrollHeight, trackHeight)
    const thumbY = rootOffset / (rootScrollHeight - rootHeight) * (trackHeight - thumbHeight)

    const isVisible = rootScrollHeight > rootHeight
    if (this.preIsVisible !== isVisible) {
      this.thumb.style.visibility = this.track.style.visibility = isVisible ? 'visible' : 'hidden'
      this.preIsVisible = isVisible
    }

    if (this.preThumbHeight !== thumbHeight) {
      this.thumb.style.height = thumbHeight + 'px'
      this.preThumbHeight = thumbHeight
    }
    this.thumb.style.transform = 'translateY(' + thumbY + 'px)'
  },
  thumbMouseDown (event) {
    event.preventDefault()

    const directiveContext = this

    dragStart.call(directiveContext, event)

    const { target, clientY } = event
    const { offsetHeight } = target
    const { top } = target.getBoundingClientRect()
    directiveContext.previousPageY = offsetHeight - (clientY - top)
  },
  handleDrag (event) {
    const directiveContext = this
    const { rootElement, track, previousPageY } = directiveContext

    if (previousPageY) {
      const { clientY } = event
      const { top: trackTop } = track.getBoundingClientRect()
      const thumbHeight = getThumbHeight(rootElement.clientHeight, rootElement.scrollHeight, track.clientHeight)
      const clickPosition = thumbHeight - previousPageY
      const offset = -trackTop + clientY - clickPosition
      rootElement.scrollTop = getScrollTopByOffset(rootElement.clientHeight, rootElement.scrollHeight, track.clientHeight, offset)
    }
    return false
  },
  handleDragEnd () {
    const directiveContext = this

    directiveContext.previousPageY = 0
    clearDragging.call(directiveContext)
  },
  trackMouseDown (event) {
    event.preventDefault()

    const directiveContext = this

    directiveContext.scrollIsInProgress = false
    directiveContext.scrollingEnd = null

    const { rootElement, track } = directiveContext

    const { target, clientY } = event
    const { top: targetTop } = target.getBoundingClientRect()
    const thumbHeight = getThumbHeight(rootElement.clientHeight, rootElement.scrollHeight, track.clientHeight)
    const offset = Math.abs(targetTop - clientY) - thumbHeight / 2
    rootElement.scrollTop = getScrollTopByOffset(rootElement.clientHeight, rootElement.scrollHeight, track.clientHeight, offset)
  },
  scrollByWheel (event) {
    event.preventDefault()
    event.stopPropagation()

    const directiveContext = this
    const { rootElement } = directiveContext

    let delta = 0
    if (event.wheelDelta) delta = event.wheelDelta / 120
    else if (event.detail) delta = -event.detail / 3

    if (directiveContext.scrollingEnd === null) {
      directiveContext.scrollingEnd = rootElement.scrollTop
    }
    directiveContext.scrollingEnd -= 50 * delta
    directiveContext.isGoingUp = delta > 0

    if (!directiveContext.scrollIsInProgress && !directiveContext.isBlocked) {
      directiveContext.scrollIsInProgress = true
      directiveContext.scrollWithAnimation()
    }
  },
}

const scrollWithAnimation = function () {
  const directiveContext = this
  if (!directiveContext.scrollIsInProgress || directiveContext.isBlocked) return

  const { rootElement } = directiveContext

  const scrollTop = rootElement.scrollTop
  const step = Math.round((directiveContext.scrollingEnd - scrollTop) / directiveContext.wheelScrollSmoothStep)
  if (
    scrollTop <= 0 ||
    scrollTop >= rootElement.scrollHeight - rootElement.clientHeight ||
    (directiveContext.isGoingUp && step > -1) ||
    (!directiveContext.isGoingUp && step < 1)
  ) {
    directiveContext.scrollIsInProgress = false
    directiveContext.scrollingEnd = null
  }
  rootElement.scrollTop = scrollTop + step

  window.requestAnimFrame(directiveContext.scrollWithAnimation)
}

const createEventFunction = event => {
  return function () {
    const directiveContext = this
    const { track, animationTime, theme } = directiveContext

    if (!track) {
      clearTimeout(directiveContext.animationTimeoutId)
      directiveContext.animationTimeoutId = -1
    }

    if (directiveContext.animationTimeoutId === -1) {
      track.classList.add('scroll-bar-' + theme + '-' + event)
      directiveContext.animationTimeoutId = setTimeout(() => {
        track.classList.remove('scroll-bar-' + theme + '-' + event)
        clearTimeout(directiveContext.animationTimeoutId)
        directiveContext.animationTimeoutId = -1
      }, animationTime)
    }
  }
}

const attach = function () {
  if (this.attached) return

  const directiveContext = this
  const { rootElement, theme } = directiveContext

  directiveContext.rootParent = rootElement.parentElement
  const { track, thumb } = createScrollBar(theme)

  directiveContext.track = track
  directiveContext.thumb = thumb

  if (directiveContext.rootParent.childNodes.length === 0) {
    directiveContext.rootParent.appendChild(track)
  } else {
    directiveContext.rootParent.insertBefore(track, directiveContext.rootParent.childNodes[0])
  }

  addAllEvents.call(directiveContext)
  events.updateThumb.call(directiveContext)

  directiveContext.scrollWithAnimation = scrollWithAnimation.bind(directiveContext)

  directiveContext.attached = true
}

const detach = function () {
  if (!this.attached) return

  const directiveContext = this
  const { track } = directiveContext

  track.parentNode.removeChild(track)
  removeAllEvents.call(directiveContext)

  directiveContext.attached = false
}

export default {
  inserted (el, binding) {
    const theme = binding.value
    if (!theme || theme.length === 0 || theme === 'none') return

    el[ctx] = {
      rootElement: el,
      rootParent: null,
      theme: theme,
      track: null,
      thumb: null,

      previousPageY: null,
      isGoingUp: true,
      scrollingEnd: null,
      wheelScrollSmoothStep: 10,
      scrollIsInProgress: false,
      scrollWithAnimation: null,

      animationTime: 500,
      animationTimeoutId: -1,
      animationEvent: '',
      animationFunction: null,

      attached: false,

      isBlocked: false,
      block,
      unblock,
    }

    const modifiers = binding.modifiers
    for (const modifier in modifiers) {
      if (Object.prototype.hasOwnProperty.call(modifiers, modifier)) {
        const dashIndex = modifier.indexOf('-')

        if (modifier.substring(dashIndex + 1) === 'transition') {
          const transitionName = modifier.substring(0, dashIndex)

          el[ctx].animationEvent = transitionName
          el[ctx].animationFunction = createEventFunction(transitionName).bind(el[ctx])
        }
      }
    }

    attach.call(el[ctx])
  },
  unbind (el) {
    if (el && el[ctx]) {
      detach.call(el[ctx])
    }
  },
}

const getThumbHeight = (rootHeight, rootScrollHeight, trackHeight) => {
  const thumbHeight = rootHeight / rootScrollHeight * trackHeight
  const result = (thumbHeight << 0)
  return Math.max(result === thumbHeight ? result : result + 1, minThumbHeight)
}

const getScrollTopByOffset = (rootHeight, rootScrollHeight, trackHeight, offset) => {
  const thumbHeight = getThumbHeight(rootHeight, rootScrollHeight, trackHeight)
  return offset / (trackHeight - thumbHeight) * (rootScrollHeight - rootHeight)
}
