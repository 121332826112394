import { Mutations } from 'vuex-smart-module'
import { OnlineCall, ServerCallBuzzParams } from '@tada-team/tdproto-ts'
import State from './state'
import Vue from 'vue'

class ModuleMutations extends Mutations<State> {
  createOrUpdateCall (call: OnlineCall): void {
    this.state.calls = { ...this.state.calls, [call.jid]: call }
  }

  removeCall (jid: string): void {
    Vue.delete(this.state.calls, jid)
  }

  createOrUpdateBuzzingCall (call: ServerCallBuzzParams) {
    this.state.incomingBuzzingCalls = {
      ...this.state.incomingBuzzingCalls,
      [call.jid]: call,
    }
  }

  removeBuzzingCall (jid: string) {
    Vue.delete(this.state.incomingBuzzingCalls, jid)
  }

  setBuzzingCalls (calls: Record<string, ServerCallBuzzParams>) {
    this.state.incomingBuzzingCalls = calls
  }

  setCalls (callsMap: Record<string, OnlineCall>) {
    this.state.calls = callsMap
  }

  setExternalCall (call: ServerCallBuzzParams | null) {
    this.state.externalBuzz = call
  }

  setIceConfig (cfg: RTCConfiguration) {
    this.state.iceConfig = cfg
  }
}

export default ModuleMutations
