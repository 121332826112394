import api from '@/api/v3'
import { DataAdapter } from '@/api/v3/DataAdapter'
import HTTP, { createCancelTokenSource } from '@/api/v3/HTTP'
import { defaultLogger, networkLogger } from '@/loggers'
import {
  chatsBarStore,
  contactsStore,
  groupsStore,
  nodesStore,
  remindsStore,
  rootStore,
  sectionsStore,
  tagsStore,
  tasksStore,
  teamsStore,
  uiSettingsStore,
  uiStore,
  billingStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import Reactions from '@/store/messages/reactions'
import {
  UISettingsTaskDeskTabFilterKey,
} from '@/store/modules/uiSettings/types'

import { parseURL } from '@/utils'
import { setSplashScreenOverlayState } from '@/utils/DOM'
import Socket from '@/ws'
import { Team } from '@tada-team/tdproto-ts'
import { CancelTokenSource } from 'axios'
import { ActionTree, MutationTree } from 'vuex'

const SET_CLIENT_LOADING_STATUS = 'SET_CLIENT_LOADING_STATUS'
const SET_ROSTER_LOADING_STATUS = 'SET_ROSTER_LOADING_STATUS'
const SET_TIME_DIFFERENCE = 'SET_TIME_DIFFERENCE'

let cancelTokenSource: CancelTokenSource | null = null
let rosterReloadingTimeout: number | null = null

// window.addEventListener('keydown', (e) => {
//   if (e.ctrlKey && e.keyCode === 49 && cancelTokenSource) {
//     cancelTokenSource.cancel('Operation canceled by the user.')
//     cancelTokenSource = null
//   }
// })

const enum RosterLoadingStatus {
  IDLE = 0,
  LOADING = 1,
  ERROR = -1
}

interface State {
  rosterLoadingStatus: number;
  isClientLoading: boolean;
  timeDifference: 0;
}

const state: State = {
  rosterLoadingStatus: 0,
  isClientLoading: true,
  timeDifference: 0,
}

export default {
  state,
  actions: {
    async [actionTypes.LOAD_ROSTER] ({ dispatch, getters, commit }) {
      defaultLogger.debug('[actionTypes.LOAD_ROSTER] Initiate')

      if (rosterReloadingTimeout !== null) {
        clearTimeout(rosterReloadingTimeout)
        rosterReloadingTimeout = null
      }

      const firstInit = getters.isClientLoading
      const teamId = window.currentTeamId || teamsStore.state.currentTeamId
      if (!teamId) {
        defaultLogger.warn('[actionTypes.LOAD_ROSTER] "teamId" is not specified')
        return
      }

      if (cancelTokenSource === null) {
        cancelTokenSource = createCancelTokenSource()
      } else {
        cancelTokenSource.cancel('New roster request initiated')
        cancelTokenSource = null
      }

      HTTP.setCancelTokenSource(cancelTokenSource)
      try {
        commit(SET_ROSTER_LOADING_STATUS, RosterLoadingStatus.LOADING)

        setSplashScreenOverlayState({ key: 'stateLoadRoster' })

        // use to ui settings store
        uiSettingsStore.actions.initTeam({ teamId })
        teamsStore.actions.initTeam(teamId)

        // Federation nodes
        const nodesSupported = !!window.FEATURES.multi_nodes
        if (nodesSupported) nodesStore.actions.loadAll()

        await contactsStore.actions.setContacts()

        const groups = await api.groups.getAll()
        groupsStore.actions.setup(Object.fromEntries(groups.map(g => [g.jid, g])))

        const directChats = await api.chats.getAll('direct')

        const groupChats = groups.map(group => DataAdapter.chat(group.toJSON()))
        const chats = Object.fromEntries([...directChats, ...groupChats].map(g => [g.chatId, g]))
        dispatch(actionTypes.SETUP_CHATS, { chats }).then(() => {
          chatsBarStore.mutations.setRosterIsReady(true)
        })

        const maxGentime = Math.max(
          -1,
          ...Object
            .values(tasksStore.state.data as Record<string, TADA.Task>)
            .map(task => Math.max(task.gentime, task.baseGentime)),
        )
        if (maxGentime !== -1) {
          /**
           * Try to get all tasks, that are updated after our last updated task
           * IF there is tasks already in store
           * if count >= 200 it is reason to reload page because of changes number
           */
          tasksStore.actions.loadTasks({ gentime_gt: maxGentime }).then(
            tasks => tasks.length >= 200 && location.reload(),
          )
        }

        const isSingleGroupTeam = teamsStore.getters.isSingleGroupTeam(teamsStore.state.currentTeamId ?? '')
        if (!isSingleGroupTeam) {
          tasksStore.actions.setupColors(teamId)
          tasksStore.actions.setupTabs()
          tasksStore.actions.setupColorsRules()
          tagsStore.actions.getTags()
          sectionsStore.actions.setSections() // contact-sections setup
          tasksStore.actions.setupCount()
          tasksStore.actions.setupStatuses()
          // todo move to store
          api.tasks.sections().then(r => {
            r.forEach(section => sectionsStore.actions.updateSection({ section, chatType: 'task' }))
          })
        }

        // hide right bar instance - bodge for single-group teams
        if (isSingleGroupTeam && uiStore.getters.currentRightBarInstance === 'tasks') {
          uiStore.actions.switchRightBarInstance(
            { instance: '' },
          )
        }

        remindsStore.actions.loadReminds()

        setSplashScreenOverlayState({ key: 'stateLoadTime' })
        await dispatch(actionTypes.SETUP_TIME_DIFFERENCE)

        // await Messages.restoreMessages(chats)

        Reactions.loadAll()

        if (!firstInit) {
          const loadMe = async () => {
            const { teams, defaultLang: language, account, teamAccount } = await api.loadMe()
            const recordTeams = Object.fromEntries(teams.map((t: Team) => [t.uid, t]))
            language && uiSettingsStore.actions.setLanguage({ language })
            uiSettingsStore.actions.setupTeams({ teams: recordTeams })
            teamsStore.actions.setupTeams(recordTeams)
            billingStore.actions.setBillingAccount(account)
            billingStore.actions.setBillingTeamAccount(teamAccount)
          }
          loadMe()
        }

        commit(SET_ROSTER_LOADING_STATUS, RosterLoadingStatus.IDLE)
      } catch (e) {
        networkLogger.warn('[actionTypes.LOAD_ROSTER]', e)
        commit(SET_ROSTER_LOADING_STATUS, RosterLoadingStatus.ERROR)
      }
      HTTP.setCancelTokenSource(cancelTokenSource = null)

      if (getters.rosterLoadingStatus === RosterLoadingStatus.ERROR) {
        rosterReloadingTimeout = window.setTimeout(() => {
          dispatch(actionTypes.LOAD_ROSTER)
        }, 3000,
        )
        return
      }

      Socket.allowMessaging()

      if (firstInit) {
        dispatch(actionTypes.START_CLIENT)
      } else {
        const { teamId, chatId, type } = parseURL()

        if (teamId === window.currentTeamId) {
          if (type === 'dashboard') {
            uiSettingsStore.actions.setTabFilter(chatId as UISettingsTaskDeskTabFilterKey || 'section')
            uiStore.actions.toggleTaskDesk({ active: true })
          }
        } else {
          /*
           * currentChat the identifier comes when changing the team caused by clicking on the push
           * lastOpenChat id comes from ui-settings.
           * If it is not there - user had just created a new team,
           * in such case use the first chat available on this team
          */
          const chatId = getters.currentChat || (getters.lastOpenChat ?? getters.chatsIds[0])
          dispatch(actionTypes.OPEN_CHAT, { chatId, isReopen: !!getters.currentChat })
          uiStore.actions.openChat()
        }
      }
      await rootStore.actions.setIsAppReady(true)
    },
    async [actionTypes.SETUP_TIME_DIFFERENCE] ({ commit }) {
      try {
        const time = await api.time() as any
        if (!time) return

        const date = new Date()
        const serverDate = new Date(time)

        const difference = serverDate.getTime() - date.getTime()
        commit(SET_TIME_DIFFERENCE, difference)
      } catch (e) {
        defaultLogger.warn('[actionTypes.SETUP_TIME_DIFFERENCE]: Unable to setup time difference', e)
      }
    },
    [actionTypes.START_CLIENT] ({ commit }) {
      const { chatId, type } = parseURL()
      if (type === 'dashboard') {
        uiSettingsStore.actions.setTabFilter(chatId as UISettingsTaskDeskTabFilterKey || 'section')
        uiStore.actions.toggleTaskDesk({ active: true })
      }
      commit(SET_CLIENT_LOADING_STATUS, false)
    },
  } as ActionTree<State, any>,
  mutations: {
    [SET_ROSTER_LOADING_STATUS] (state, value) {
      state.rosterLoadingStatus = value
    },
    [SET_CLIENT_LOADING_STATUS] (state, status) {
      state.isClientLoading = status
    },
    [SET_TIME_DIFFERENCE] (state, value) {
      state.timeDifference = value
    },
  } as MutationTree<State>,
}
