import { getChatType, parseMarkdownLinkLabel } from '@/utils'
import * as actionTypes from '@/store/actionTypes'
import Messages from '@/store/messages'
import Socket from '@/ws'

export const mutationTypes = {
  SOCKET_CONNECTION_STATE: 'SOCKET_CONNECTION_STATE',
  SOCKET_READY_STATE: 'SOCKET_READY_STATE',
  SOCKET_RECONNECTING_STATUS: 'SOCKET_RECONNECTING_STATUS',
  SOCKET_CLOSED_BY_CLIENT_STATE: 'SOCKET_CLOSED_BY_CLIENT_STATE',
}

export default {
  state: {
    isSocketOpen: false,
    isSocketClosedByClient: false,
    isSocketReady: false,
  },
  mutations: {
    [mutationTypes.SOCKET_CONNECTION_STATE] (state, value) {
      state.isSocketOpen = value
    },
    [mutationTypes.SOCKET_CLOSED_BY_CLIENT_STATE] (state, value) {
      state.isSocketClosedByClient = value
    },
    [mutationTypes.SOCKET_READY_STATE] (state, value) {
      state.isSocketReady = value
    },
  },
  actions: {
    [actionTypes.SOCKET_SET_ACTIVITY] (_, { afk }) {
      window.debugAFK && (document.title = `afk: ${afk}`)
      Socket.send('client.activity', {
        afk,
      })
    },
    [actionTypes.SOCKET_EDIT_MESSAGE] ({ rootGetters }, { chatId, message, deleteMessage }) {
      if (!rootGetters.isSocketOpen) return

      if (deleteMessage) {
        Socket.send('client.message.deleted', {
          message_id: deleteMessage,
        })
        return
      }

      Socket.send('client.message.updated', {
        to: chatId,
        message_id: message.messageId,
        important: message.important,
        nopreview: message.nopreview,
        content: message.content,
        linked_messages: message.linkedMessages?.map(m => m.messageId),
        replyTo: message.replyTo?.messageId || null,
        uploads: message.uploads?.map(u => u.uid),
      })
    },
    [actionTypes.SOCKET_SEND_MESSAGE] ({ rootGetters }, { chatId, message }) {
      /**
       * replace markdown links from content to simple text before server resend updated message
       * with markup for good style
       * reason - Messages.addUndeliveredMessage display full markdown links (incorrect)
       * ? another way - do api.linkscheck before display, but it is ugly and intricate
       */
      const messageCopy = { ...message }
      messageCopy.content = {
        ...messageCopy.content,
        text: parseMarkdownLinkLabel(message.content.text),
      }

      Messages.addUndeliveredMessage(chatId, messageCopy)

      window.goal(getChatType(chatId) + '_message')

      let { linkedMessages, uploads } = message
      linkedMessages = linkedMessages?.map(m => m.messageId)
      uploads = uploads?.map(u => u.uid)

      rootGetters.isSocketOpen && Socket.send('client.message.updated', {
        to: chatId,
        content: message.content,
        message_id: message.messageId,
        linked_messages: linkedMessages,
        reply_to: message.replyTo?.messageId ?? null,
        uploads: uploads,
        old_style_attachment: !window.FEATURES.message_uploads,
      }, `m${message.messageId}_${chatId}`)
    },
    [actionTypes.SOCKET_SEND_MESSAGE_COMPOSING] ({ rootGetters }, { chatId, composing, audio = false }) {
      rootGetters.isSocketOpen && Socket.send('client.chat.composing', {
        jid: chatId,
        composing: composing,
        is_audio: audio,
      })
    },
    [actionTypes.SOCKET_SEND_LAST_READ_MESSAGE] (_, { chatId, messageId }) {
      const name = 'client.chat.lastread'
      const body = {
        jid: chatId,
        last_read_message_id: messageId || null,
      }
      Socket.send(name, body)
    },
  },
}
