import { ActionTree } from 'vuex'
import {
  State,
  ChangePassTFA,
  CreatePassTFA,
  InfoTFA,
  CodeTFA,
  CodeWithInfoTFA,
} from './types'
import * as actionTypes from '@/store/actionTypes'
import * as mutationTypes from '@/store/modules/profile/mutationTypes'
import ThemeManager from '@/themes'
import api from '@/api/v3'
import {
  SentryWrongStatusTFA,
  SentryBackendWrongEnabledTFA,
} from '@/utils/sentry'

const actions: ActionTree<State, any> = {
  [actionTypes.UPDATE_CONTACT] ({ commit, getters }, { contact }) {
    const { jid: contactId } = contact
    contactId === getters.getUserId && commit(
      mutationTypes.PROFILE_ADD_DATA,
      { profile: contact },
    )
  },
  [actionTypes.SETUP_PROFILE] ({ commit }, { profile }) {
    commit(mutationTypes.PROFILE_ADD_DATA, { profile })
  },
  [actionTypes.THEME_CHANGED] ({ commit, getters }) {
    const { profile } = getters
    const icon = ThemeManager.getDefaultAvatar('contact')

    const { icon: currentIcon } = profile
    const update = ThemeManager.needToSetAvatar(currentIcon)
    update && commit(mutationTypes.PROFILE_SET_PROFILE_ICON, icon)
  },

  // ============= TFA PART ========================== //
  /**
   * Set 2 factor auth value for user
   */
  [actionTypes.TFA_SET_STATUS] (
    { commit },
    payload: { enabled: boolean; recoveryStatus: TADA.RecoveryStatus },
  ) {
    commit(mutationTypes.TFA_SET_STATUS, payload)
  },
  /**
   * Load and save TwoFactorAuth status
   * @returns success/error
   */
  async [actionTypes.TFA_GET_STATUS] ({ commit }): Promise<boolean> {
    let result: InfoTFA
    try {
      result = await api.twoFactorAuth.get()
    } catch (e) {
      if (e.status === 500) return false
      return SentryBackendWrongEnabledTFA()
    }
    commit(mutationTypes.TFA_SET_STATUS, result)
    return result.enabled
  },
  /**
   * Change current password to new with hint
   * @returns success/error
   */
  async [actionTypes.TFA_CHANGE_PASS] (
    ctx,
    { newPass, oldPass, repeatPass, hint = '' }: ChangePassTFA,
  ): Promise<boolean> {
    if (!ctx.getters.isActiveTFA) return SentryWrongStatusTFA()

    const r = await api.twoFactorAuth.changePassword({
      new_password: newPass,
      new_password_repeat: repeatPass,
      hint: hint,
      password: oldPass,
    })
    if (r.enabled) {
      return true
    } else {
      return SentryBackendWrongEnabledTFA()
    }
  },
  /**
   * Remove current password
   * @returns success/error
   */
  async [actionTypes.TFA_REMOVE_PASS] (
    ctx,
    currentPass: string,
  ): Promise<boolean> {
    if (!ctx.getters.isActiveTFA) return SentryWrongStatusTFA()

    const r = await api.twoFactorAuth.deletePassword(currentPass)
    if (!r.enabled) {
      ctx.commit(mutationTypes.TFA_SET_STATUS, r)
      return true
    } else {
      return SentryBackendWrongEnabledTFA()
    }
  },
  /**
   * Check that pass is correct (for Auth prefered)
   * @returns success/error
   */
  async [actionTypes.TFA_CHECK_PASS] (
    ctx,
    currentPass: string,
  ): Promise<boolean> {
    if (!ctx.getters.isActiveTFA) return SentryWrongStatusTFA()

    const r = await api.twoFactorAuth.checkPass(currentPass)
    if (r.enabled) {
      return true
    } else {
      return SentryBackendWrongEnabledTFA()
    }
  },
  /**
   * Create new password
   * @returns success/error
   */
  async [actionTypes.TFA_CREATE_PASS] (
    ctx,
    { newPass, repeatPass, hint = '' }: CreatePassTFA,
  ): Promise<boolean> {
    if (ctx.getters.isActiveTFA) return SentryWrongStatusTFA(false)

    const r = await api.twoFactorAuth.setPassword({
      new_password: newPass,
      new_password_repeat: repeatPass,
      hint: hint,
    })
    ctx.commit(mutationTypes.TFA_SET_STATUS, r)
    return r.recoveryStatus === 'unconfirmed'
  },
  /**
   * Set new email, or 2fa decline email recovering
   * can replace old email with those new email! (UPDATE)
   * @param payload if not email then decline email for recovering
   * @returns Info or CodeWithInfo / Error
   */
  async [actionTypes.TFA_SET_EMAIL] (
    { commit },
    payload: { password: string; email?: string },
  ): Promise<InfoTFA | CodeWithInfoTFA> {
    let r: InfoTFA | CodeWithInfoTFA | null = null
    if (!payload.email) {
      r = await api.twoFactorAuth.declineMail(payload.password)
    } else {
      const params = {
        password: payload.password,
        email: payload.email ?? '',
      }
      r = await api.twoFactorAuth.setMail(params)
    }
    commit(mutationTypes.TFA_SET_STATUS, r)
    return r
  },
  /**
   * Confirm email after setting with password and code
   * @param payload
   * @returns success/error
   */
  async [actionTypes.TFA_CONFIRM_EMAIL] (
    { commit },
    payload: { email: string; password: string; code: string },
  ): Promise<boolean> {
    const r = await api.twoFactorAuth.confirmMail(payload)

    commit(mutationTypes.TFA_SET_STATUS, r)
    return !!r
  },
  /**
   * Confirm email after setting with password and code
   * * ONLY ON LOGIN SCREEN
   * @param token - after sms inputs
   * @returns CodeTFA info / Error
   */
  async [actionTypes.TFA_SEND_DISCARD_CODE_LOGIN] (
    _,
    token: string,
  ): Promise<CodeTFA> {
    return await api.twoFactorAuth.sendDiscardCodeLogin(token)
  },
  /**
   * Discard password if forgot by code after send_discard_code and token from sms input
   * * ONLY ON LOGIN SCREEN
   * @param token - after sms inputs
   * @returns success/error
   */
  async [actionTypes.TFA_DISCARD_PASS_LOGIN] (
    _,
    { code, token }: { code: string; token: string },
  ): Promise<boolean> {
    await api.twoFactorAuth.forceDiscardByCodeLogin(code, token)
    return true
  },
  /**
   * Send code if you forgot the password from TFA inside the account
   * @returns CodeTFA info / Error
   */
  async [actionTypes.TFA_SEND_DISCARD_CODE] (): Promise<CodeTFA> {
    return await api.twoFactorAuth.sendDiscardCode()
  },
  /**
   * Discard pass from TFA by code from email inside the account
   * @param code - code from email
   * @returns success/error
   */
  async [actionTypes.TFA_DISCARD_PASS] (ctx, code: string): Promise<boolean> {
    await api.twoFactorAuth.forceDiscardByCode(code)
    const payload: InfoTFA = {
      enabled: false,
      recoveryStatus: 'unknown',
    }
    ctx.commit(mutationTypes.TFA_SET_STATUS, payload)
    return true
  },
}

export default actions
