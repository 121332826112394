export const START_CLIENT = 'START_CLIENT'

export const RESET_STORE = 'RESET_STORE'

export const LOAD_ROSTER = 'LOAD_ROSTER'
export const SETUP_PROFILE = 'SETUP_PROFILE'
export const SETUP_CHATS = 'SETUP_CHATS'
export const SETUP_TIME_DIFFERENCE = 'SETUP_TIME_DIFFERENCE'

export const LOAD_MESSAGES = 'LOAD_MESSAGES'
export const LOAD_NEWER_MESSAGES = 'LOAD_NEWER_MESSAGES'
export const LOAD_NEWER_MESSAGES_REQUEST = 'LOAD_NEWER_MESSAGES_REQUEST'
export const LOAD_NEWER_MESSAGES_SUCCESS = 'LOAD_NEWER_MESSAGES_SUCCESS'

export const LOAD_OLDER_MESSAGES = 'LOAD_OLDER_MESSAGES'
export const LOAD_OLDER_MESSAGES_REQUEST = 'LOAD_OLDER_MESSAGES_REQUEST'
export const LOAD_OLDER_MESSAGES_SUCCESS = 'LOAD_OLDER_MESSAGES_SUCCESS'

export const LOAD_LAST_MESSAGES = 'LOAD_LAST_MESSAGES'
export const LOAD_LAST_MESSAGES_REQUEST = 'LOAD_LAST_MESSAGES_REQUEST'
export const LOAD_LAST_MESSAGES_SUCCESS = 'LOAD_LAST_MESSAGES_SUCCESS'

export const SOCKET_ON_MESSAGE_READ = 'SOCKET_ON_MESSAGE_READ'
export const SOCKET_ON_OTHER_MESSAGE_COMPOSING = 'SOCKET_ON_OTHER_MESSAGE_COMPOSING'
export const SOCKET_ON_CHAT_COUNTERS = 'SOCKET_ON_CHAT_COUNTERS'
export const SOCKET_ON_CONFIRM_MESSAGE = 'SOCKET_ON_CONFIRM_MESSAGE'
export const SOCKET_ON_LAST_READ_MESSAGE = 'SOCKET_ON_LAST_READ_MESSAGE'
export const SOCKET_ON_PROCESSING = 'SOCKET_ON_PROCESSING'
export const SOCKET_SEND_LAST_READ_MESSAGE = 'SOCKET_SEND_LAST_READ_MESSAGE'
export const SOCKET_SEND_MESSAGE = 'SOCKET_SEND_MESSAGE'
export const SOCKET_EDIT_MESSAGE = 'SOCKET_EDIT_MESSAGE'
export const SOCKET_SEND_MESSAGE_COMPOSING = 'SOCKET_SEND_MESSAGE_COMPOSING'
export const SOCKET_SET_ACTIVITY = 'SOCKET_SET_ACTIVITY'
export const SOCKET_CLIENT_CALL_REJECT = 'SOCKET_CLIENT_CALL_REJECT'

export const CHANGE_CHAT_LAST_MESSAGE = 'CHANGE_CHAT_LAST_MESSAGE'

export const TFA_GET_STATUS = 'TFA_GET'
export const TFA_SET_STATUS = 'TFA_SET_STATUS'
export const TFA_CREATE_PASS = 'TFA_CREATE_PASS'
export const TFA_CHANGE_PASS = 'TFA_CHANGE_PASS'
export const TFA_REMOVE_PASS = 'TFA_REMOVE_PASS'
export const TFA_CHECK_PASS = 'TFA_CHECK_PASS'
export const TFA_SET_EMAIL = 'TFA_SET_EMAIL'
export const TFA_CONFIRM_EMAIL = 'TFA_CONFIRM_EMAIL'
export const TFA_DISCARD_PASS_LOGIN = 'TFA_DISCARD_PASS_LOGIN'
export const TFA_SEND_DISCARD_CODE_LOGIN = 'TFA_SEND_DISCARD_CODE_LOGIN'
export const TFA_SEND_DISCARD_CODE = 'TFA_SEND_DISCARD_CODE'
export const TFA_DISCARD_PASS = 'TFA_DISCARD_PASS'

export const OPEN_CHAT = 'OPEN_CHAT'
export const RESET_CHAT = 'RESET_CHAT'
export const OPEN_CHAT_WITH_ERROR = 'OPEN_CHAT_WITH_ERROR'
export const CHAT_OPENED = 'CHAT_OPENED'
export const LOAD_CHAT_COMMANDS = 'LOAD_CHAT_COMMANDS'
export const PUSH_STATE = 'PUSH_STATE'

export const LOAD_CHAT = 'LOAD_CHAT'
export const UPDATE_CHAT = 'UPDATE_CHAT'
export const DELETE_CHAT = 'DELETE_CHAT'

export const UPDATE_CONTACT = 'UPDATE_CONTACT'

export const TOGGLE_CHAT_EXCLUDED_STATE = 'TOGGLE_CHAT_EXCLUDED_STATE'
export const TOGGLE_CHAT_PIN_STATE = 'TOGGLE_CHAT_PIN_STATE'

export const REMOVE_UNDELIVERED_MESSAGE = 'REMOVE_UNDELIVERED_MESSAGE'
export const THEME_CHANGED = 'THEME_CHANGED'

export const SET_CURRENT_ACTIVE_MESSAGE_DATA = 'SET_CURRENT_ACTIVE_MESSAGE_DATA'
export const UI_UPDATE_RIGHT_BAR_TASKS_SORT_FILTERS = 'UI_UPDATE_RIGHT_BAR_TASKS_SORT_FILTERS'
export const UI_RESET_RIGHT_BAR_TASKS_SORT_FILTERS = 'UI_RESET_RIGHT_BAR_TASKS_SORT_FILTERS'

export const TASK_DESK_TASK_DELETED = 'TASK_DESK_TASK_DELETED'
export const TOGGLE_LEFT_COLUMN = 'TOGGLE_LEFT_COLUMN'
export const UI_SETTINGS_SAVE = 'UI_SETTINGS_SAVE'

export const CHAT_MARK_MESSAGE_IMPORTANT = 'CHAT_MARK_MESSAGE_IMPORTANT'

export const INTEGRATIONS_SETUP = 'INTEGRATIONS_SETUP'
export const INTEGRATIONS_CREATE = 'INTEGRATIONS_CREATE'
export const INTEGRATIONS_EDIT = 'INTEGRATIONS_EDIT'
export const INTEGRATIONS_DELETE = 'INTEGRATIONS_DELETE'
