import Actions from '@/store/modules/root/actions'
import Getters from '@/store/modules/root/getters'
import Mutations from '@/store/modules/root/mutations'
import State from '@/store/modules/root/state'
import { Module } from 'vuex-smart-module'

const rootModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default rootModule
