import { isInDevMode, isTestingE2E } from '@/envHelpers'
import { defaultLogger } from '@/loggers'
import {
  AuthJSON,
  Chat,
  ChatJSON,
  Contact,
  ContactJSON,
  Country,
  CountryJSON,
  GroupMembership,
  GroupMembershipJSON,
  IconData,
  Integration,
  IntegrationJSON,
  Integrations,
  IntegrationsJSON,
  InvitableUser,
  JID,
  Node,
  PushDevice,
  Remind,
  RemindJSON,
  Section,
  SectionJSON,
  Tag,
  TaskColor,
  TaskColorJSON,
  TaskItem,
  TaskStatus,
  TaskStatusJSON,
  Team,
  TeamJSON,
  TeamStatus,
  UserWithMe,
  UserWithMeJSON,
} from '@tada-team/tdproto-ts'
import type { CancelToken } from 'axios'
import { uid } from 'quasar'
import { DataAdapter } from './DataAdapter'
import HTTP, { BodyType } from './HTTP'
import Pagination from './Pagination'
import { twoFactorAuth } from './TwoFactorAuth'
import { getListPublicStatuses } from './PublicStatus'

export const _fetch = (
  url: string,
  formData?: any | null,
  method = 'GET',
  bodyType?: string | null,
  raw?: boolean,
  cancelToken?: CancelToken,
) => {
  return HTTP.fetch({ url, method, data: formData, bodyType: bodyType as BodyType, cancelToken })
}

const proxy = isInDevMode() || isTestingE2E()
export const getPrefix = (version = 3) => (proxy ? '' : window.FEATURES.host) + (version ? `/api/v${version}` : '')
const getTeamPrefix = (version = 3, team = window.currentTeamId) => `${getPrefix(version)}/teams/${team}`
const getChatPrefix = (jid?: string, team?: string) => `${getTeamPrefix(4, team)}/chats/` + (jid ? `${jid}/` : '')

const uploads = {
  getURL: (id: string) => `${getTeamPrefix(4)}/` + (id ? `messages/${id}/` : 'upload'),
  send: (id: string, file: Blob) => _fetch(uploads.getURL(id), { file }, 'POST', null, true),

  /**
   * POSTs a file to server.
   * @param file A file Blob to upload to server.
   * @param cancelToken Axios cancel token
   * @returns a parsed upload object
   */
  create: (file: Blob, cancelToken?: CancelToken) => _fetch(
    `${getTeamPrefix(4)}/uploads/`,
    { file },
    'POST',
    null,
    false,
    cancelToken,
  ).then(DataAdapter.upload),
}

const calls = {
  getURL: (jid: string) => `${getChatPrefix(jid)}call/`,
  get: (jid: string) => _fetch(calls.getURL(jid)),
  edit: (jid: string, params: any) => _fetch(calls.getURL(jid), params, 'PUT'),
}

const integrations = {
  getURL: (id: string | null, team?: string) => `${getTeamPrefix(3, team)}/integrations` + (id ? `/${id}/` : '/'),

  /**
   * Loads integration data from server.
   * @returns parsed integrations data
   */
  getAll: () => _fetch(integrations.getURL(null)).then(
    (v: IntegrationsJSON) => Integrations.fromJSON(v),
  ),

  /**
   * Takes an integration object. POSTs it to server.
   * @param integration Data to create integration with.
   * @returns parsed created integration object.
   */
  create: (integration: IntegrationJSON) => _fetch(
    integrations.getURL(null),
    integration,
    'POST',
    'JSON',
  ).then((v: IntegrationJSON) => Integration.fromJSON(v)),

  /**
   * Loads an integration from server using provided uid.
   * @param uid Integration uid to load.
   * @returns Parsed integration object.
   */
  get: (uid: string) => _fetch(integrations.getURL(uid)).then(
    (v: IntegrationJSON) => Integration.fromJSON(v),
  ),

  /**
   * Takes an integration object. PUTs it to server.
   * @param uid UID of an integration to edit.
   * @param params Data to edit integration with.
   * @return parsed updated integration object.
   */
  edit: (uid: string, params: Partial<IntegrationJSON>) => _fetch(
    integrations.getURL(uid),
    params,
    'PUT',
  ).then((v: IntegrationJSON) => Integration.fromJSON(v)),

  delete: (id: string) => _fetch(integrations.getURL(id), null, 'DELETE'),
}

const teams = {
  getURL: (id?: string) => `${getPrefix(4)}/teams` + (id ? `/${id}/` : '/'),
  getIconsURL: (teamId: string) => teams.getURL(teamId) + 'icons/',
  create: (params: any) => _fetch(teams.getURL(), params, 'POST', 'JSON').then(Team.fromJSON),
  get: (id: string) => _fetch(teams.getURL(id)).then((r: TeamJSON) => Team.fromJSON(r)),
  delete: (id: string) => _fetch(teams.getURL(id), null, 'DELETE'),
  edit: (id: string, params: any) => _fetch(teams.getURL(id), params, 'PUT').then(Team.fromJSON),
  setIcon: (teamId: string, file: Blob) => _fetch(teams.getIconsURL(teamId), { file }, 'POST').then(IconData.fromJSON),
  deleteIcon: (teamId: string) => _fetch(teams.getIconsURL(teamId), null, 'DELETE'),
  memoryUsage: (teamId?: string) => _fetch(teams.getURL(teamId || window.currentTeamId) + 'usage'),

  activeUsers: (teamId?: string, months = 1) => _fetch(teams.getURL(teamId || window.currentTeamId) + 'stats/daily/active-users?months=' + months),
  totalUsers: (teamId?: string, months = 1) => _fetch(teams.getURL(teamId || window.currentTeamId) + 'stats/daily/total-users?months=' + months),
  newUsers: (teamId?: string, months = 1) => _fetch(teams.getURL(teamId || window.currentTeamId) + 'stats/daily/new-users?months=' + months),
  newMessages: (teamId?: string, months = 1) => _fetch(teams.getURL(teamId || window.currentTeamId) + 'stats/daily/new-messages?months=' + months),
  newCalls: (teamId?: string, months = 1) => _fetch(teams.getURL(teamId || window.currentTeamId) + 'stats/daily/new-calls?months=' + months),
}

const roster = {
  getURL: (jid: string | null, team?: string) => `${getTeamPrefix(4, team)}/roster` + (jid ? `/${jid}` : '/'),
  hide: (jid: string) => _fetch(getChatPrefix(jid), null, 'DELETE'),
  pin: (jid: string, pinned: boolean, pinnedSortOrdering: number) => _fetch(getChatPrefix(jid), { pinned, pinned_sort_ordering: pinnedSortOrdering }, 'PUT'),
}

const tasksColors = {
  getURL: (teamId?: string) => `${getTeamPrefix(4, teamId)}/tasks/colors/`,
  load: (teamId: string) => _fetch(tasksColors.getURL(teamId)).then((r: { colors: TaskColorJSON[] }) => r.colors.map(TaskColor.fromJSON)),
}

const tasksColorsRules = {
  getURL: (teamId?: string) => `${getTeamPrefix(4, teamId)}/tasks/colors/rules`,

  getAll: () => _fetch(
    tasksColorsRules.getURL(),
  ).then((r: any) => DataAdapter.tasksColorsRules(r)),

  get: (ruleId: string) => _fetch(
    tasksColorsRules.getURL() + '/' + ruleId, null, 'GET',
  ).then((r: any) => DataAdapter.tasksColorsRule(r)),

  create: (params: any) => _fetch(
    tasksColorsRules.getURL(), params, 'POST', 'JSON',
  ).then((r: any) => DataAdapter.tasksColorsRule(r)),

  edit: (ruleId: string, params: any) => _fetch(
    tasksColorsRules.getURL() + '/' + ruleId, params, 'PUT',
  ).then((r: any) => DataAdapter.tasksColorsRule(r)),

  delete: (ruleId: string) => _fetch(
    tasksColorsRules.getURL() + '/' + ruleId, null, 'DELETE',
  ),
}

const contacts = {
  getURL: (id: string | null, team?: string) => `${getTeamPrefix(3, team)}/contacts` + (id ? `/${id}/` : '/'),
  getIconsURL: (contactId: string, teamId: string) => contacts.getURL(contactId, teamId) + 'icons/',
  add: (teamId: string, params: any) => _fetch(contacts.getURL(null, teamId), params, 'POST', 'JSON').then(Contact.fromJSON),
  addMany: (teamId: string, params: any) => _fetch(contacts.getURL(null, teamId), params, 'POST', 'JSON').then((r: ContactJSON[]) => r.map(Contact.fromJSON)),
  getAll: (team?: string) => _fetch(contacts.getURL(null, team)).then((r: ContactJSON[]) => r.map(Contact.fromJSON)),
  get: (id: string) => _fetch(contacts.getURL(id)).then(Contact.fromJSON),
  delete: (id: string, teamId: string) => _fetch(contacts.getURL(id, teamId), null, 'DELETE'),
  edit: (id: string, params: any, teamId?: string) => _fetch(contacts.getURL(id, teamId), params, 'PUT').then(Contact.fromJSON),
  setIcon: (contactId: string, file: Blob, teamId: string) => _fetch(contacts.getIconsURL(contactId, teamId), { file }, 'POST').then(IconData.fromJSON),
  deleteIcon: (contactId: string, teamId: string) => _fetch(contacts.getIconsURL(contactId, teamId), null, 'DELETE'),
  getImportURL: () => contacts.getURL(null) + 'import/',
  getImportTemplateURL: () => contacts.getImportURL() + 'xlsx',
  uploadImportTemplate: (file: Blob) => _fetch(
    contacts.getImportTemplateURL(), { file }, 'POST',
  ),
  startImport: (contactList: Array<any>) => _fetch(
    contacts.getImportURL(), { contacts: contactList }, 'POST',
  ),
  sections: () => _fetch(contacts.getURL(null) + 'sections')
    .then((raw: SectionJSON[]): Section[] => raw ? raw.map(Section.fromJSON) : []),
}

// Секции
const sections = {
  getURL: (id?: string, type = 'contact') => `${getTeamPrefix()}/${type}-sections/` + (id ? `${id}/` : ''),
  // Список и создание
  getAll: (type?: string) => _fetch(sections.getURL(undefined, type)).then((data: SectionJSON[]) => data.map(Section.fromJSON)),
  // TODO: change params to s: Section and in fetch to s.toJSON()
  create: (params: any, type?: string) => _fetch(sections.getURL(undefined, type), params, 'POST', 'JSON').then(Section.fromJSON),
  // Изменение и удаление
  get: (id: string, type?: string) => _fetch(sections.getURL(id, type)).then(Section.fromJSON),
  delete: (id: string, type?: string) => _fetch(sections.getURL(id, type), null, 'DELETE'),
  edit: (id: string, params: any, type?: string) => _fetch(sections.getURL(id, type), params, 'PUT').then(Section.fromJSON),
  // Перемещение
  getMoveURL: (id: string, otherId: string, direction = 'after') => sections.getURL(id) + `move-${direction}/${otherId}/`,
  move: (id: string, otherId: string, direction?: string) => _fetch(sections.getMoveURL(id, otherId, direction), {}, 'POST', 'JSON'),
  moveAfter: (id: string, otherId: string) => sections.move(id, otherId),
  moveBefore: (id: string, otherId: string) => sections.move(id, otherId, 'before'),
}

const ws = {
  getURL: (eventName: string, team?: string) => `${getTeamPrefix(3, team)}/ws/${eventName}`,
  rejectCall: (jid: string, team?: string) => _fetch(
    ws.getURL('client.call.reject', team),
    { jid },
    'POST',
    'JSON',
  ), // todo add uid
}

const chats = {
  getURL: (id?: string) =>
    `${getTeamPrefix(4)}/chats/` +
    (id ? `${id}/` : ''),

  getUrlThread: (id?: string) => `${getTeamPrefix(4)}/threads/` + id,
  /**
   * Loads single chat by jid. Parses chat object out of it.
   * Optionally parses task/ group if available
   * @param id chat jid to load
   */
  get: (id: string) =>
    _fetch(chats.getURL(id)).then(r => {
      const chat = DataAdapter.chat(r)
      const { chatType } = chat
      let task = null
      let group = null
      let thread = null
      if (chatType === 'task') {
        task = DataAdapter.task(r)
      } else if (chatType === 'group') {
        group = Chat.fromJSON(r)
      } else if (chatType === 'thread') {
        thread = Chat.fromJSON(r)
      }
      return { chat, task, group, thread }
    }),
  getAll: (chatType?: string) =>
    _fetch(
      chats.getURL() + (chatType ? `?chat_type=${chatType}` : ''),
    ).then((r: any) =>
      r.objects?.length > 0 ? DataAdapter.chats(r.objects) : [],
    ),

  /**
   * Marks given message in a given chat important.
   * @param chatId JID of a chat.
   * @param messageId JID of a message to mark.
   * @param important Mark on unmark.
   * @returns Parsed message object.
   */
  markMessageImportant: (
    chatId: string,
    messageId: string,
    important: boolean,
  ) =>
    _fetch(
      chats.getURL(chatId) + `messages/${messageId}/`,
      { important },
      'POST',
    ).then(DataAdapter.message),

  createThread: (
    messageId: string,
  ) => _fetch(
    chats.getUrlThread(messageId),
    {},
    'POST',
  ).then(DataAdapter.chat),

  getAllThreads: () => _fetch(`${getTeamPrefix(4)}/comments/`).then(
    (r: any) => r.objects?.length > 0 ? r.objects.map(Chat.fromJSON) : [],
  ),
}

const groups = {
  getURL: (id?: string) => `${getTeamPrefix()}/groups/` + (id ? `${id}/` : ''),

  /**
   * @param groupId group jid
   * @param memberId contact jid
   * @return API URL for the specified group and member
   */
  getMembersURL: (groupId: string, memberId?: string) => {
    return groups.getURL(groupId) + 'members/' + (memberId ? `${memberId}/` : '')
  },

  getIconsURL: (groupId: string) => groups.getURL(groupId) + 'icons/',
  /**
   * Loads a Group list from server
   * @returns {Promise<Chat[]>} Array of parsed Group objects
   */
  getAll: (): Promise<Chat[]> => _fetch(groups.getURL()).then((data: ChatJSON[]) => data.map(Chat.fromJSON)),

  /**
   * Loads a single group from server by provided jid.
   * @param jid Group jid to load.
   * @returns a promise that resolves to a parsed chat object
   */
  get: (id: string): Promise<Chat> => _fetch(groups.getURL(id)).then(Chat.fromJSON),
  create: (params: any): Promise<Chat> => _fetch(groups.getURL(), params, 'POST', 'JSON').then(Chat.fromJSON),
  delete: (id: string): Promise<void> => _fetch(groups.getURL(id), null, 'DELETE'),
  edit: (id: string, params: any): Promise<Chat> => _fetch(groups.getURL(id), params, 'PUT').then(Chat.fromJSON),
  setIcon: (groupId: string, file: Blob) => _fetch(groups.getIconsURL(groupId), { file }, 'POST'),
  deleteIcon: (groupId: string): Promise<Chat> => _fetch(groups.getIconsURL(groupId), null, 'DELETE'),
  getMembers: (groupId: string): Promise<GroupMembership[]> => _fetch(groups.getMembersURL(groupId))
    .then((data: { members: GroupMembershipJSON[] }) => data.members.map(GroupMembership.fromJSON)),
  getMember: (groupId: string, memberId: string): Promise<GroupMembership> => _fetch(groups.getMembersURL(groupId, memberId))
    .then(GroupMembership.fromJSON),

  /**
   * Adds a new member to the specified group in the current team.
   * Server should act on CREATE || UPDATE basis
   * @param groupId group jid to add to
   * @param memberId contact jid to add
   * @param status group member status to add with
   */
  addMember: (groupId: string, memberId: string, status = 'member') => _fetch(
    groups.getMembersURL(groupId), { jid: memberId, status }, 'POST', 'JSON',
  ).then(GroupMembership.fromJSON),

  getMemberStatus: (groupId: string, memberId: string) => _fetch(groups.getMembersURL(groupId, memberId)),
  deleteMember: (groupId: string, memberId: string) => _fetch(groups.getMembersURL(groupId, memberId), null, 'DELETE'),
  setMemberStatus: (groupId: string, memberId: string, status: TeamStatus) => _fetch(groups.getMembersURL(groupId, memberId), { status }, 'PUT').then(GroupMembership.fromJSON),
  /**
   * Get list of groups where you can invite the contact by jid
   * @param memberId member jid to add
   */
  getGroupsForInviting: (memberId: string) => _fetch(groups.getURL() + 'invitable/' + memberId).then((data: ChatJSON[]) => data.map(Chat.fromJSON)),
}

const publicGroups = {
  getURL: () => `${getTeamPrefix(4)}/groups/public`,

  /**
   * Loads a Group list from server
   * @returns {Promise<Chat[]>} Array of parsed Group objects
   */
  getAll: (): Promise<Chat[]> => _fetch(publicGroups.getURL())
    .then((data: ChatJSON[]) => data.map(Chat.fromJSON)),

  /**
   * Joins current user to a public Group
   * @param {JID} jid JID to load
   * @returns {Promise<Chat>} Parsed Group object
   */
  join: (jid: JID): Promise<Chat> =>
    _fetch(`${publicGroups.getURL()}/${jid}/join`, null, 'POST', 'JSON')
      .then(Chat.fromJSON),
}

const toQueryString = (obj: any) => Object.keys(obj).filter((k: any) => obj[k] !== null).map((k: any) =>
  `${encodeURIComponent(k)}=${encodeURIComponent(Array.isArray(obj[k]) ? obj[k].join(',') : obj[k])}`,
).join('&')
const tasks = {
  getURL: (jid?: string) => `${getTeamPrefix(4)}/tasks/` + (jid ? `${jid}/` : ''),
  create: (params: any) => _fetch(tasks.getURL(), params, 'POST', 'JSON').then((r: any) => DataAdapter.task(r)),

  /**
   * Requests a task draft from server with given params.
   * @param messageIds messages to create a task from
   * @param cloneJid task ID to clone a task from
   * @param itemUid task item ID to create a task from
   * @returns parsed task object
   */
  draft: (messageIds?: Array<string>, cloneJid?: string, itemUid?: string) => _fetch(
    tasks.getURL() + 'draft',
    {
      linked_messages: messageIds || [],
      clone: cloneJid || '',
      item: itemUid || '',
    },
    'POST',
    'JSON',
  ).then(DataAdapter.task),

  /**
   * Gets a single task by provided jid.
   * @param jid Task jid to load.
   * @returns a promise that resolves to a parsed data object { task, chat }
   */
  get: (jid: string) => _fetch(tasks.getURL(jid)).then(DataAdapter.taskData),

  /**
   * Gets tasks that satisfy provided params.
   * @param param Object, containing request field params.
   * @returns a promise that resolves to an object of parsed tasks data { tasks, chats }
   */
  getMany: (params: Record<string, unknown>) => _fetch(
    tasks.getURL() + '?' + toQueryString(params), null, 'GET',
  ).then((r: any) => DataAdapter.tasks(r.objects)),

  getByTab: (tab: string, params: any = {}) => _fetch(tasks.getURL() + `tabs/${tab}?` + toQueryString(params), null, 'GET').then((r: any) => {
    if (r.objects) {
      const { tasks, chats } = DataAdapter.tasks(r.objects)
      r.objects = tasks
      r.chats = chats
      return r
    }
    return DataAdapter.task(r)
  }),
  delete: (jid: string) => _fetch(tasks.getURL(jid), null, 'DELETE'),
  edit: (jid: string, params: any) => _fetch(tasks.getURL(jid), params, 'PUT').then((r: any) => DataAdapter.task(r)),
  getAll: ({ limit = 200, offset = 0, status, withChats }: { limit?: number; offset?: number; status?: string; withChats: boolean }) => _fetch(
    tasks.getURL() + `?limit=${limit}&offset=${offset}` + (status ? `&task_status=${status}` : ''),
  ).then((r: any) => ({
    count: r.count,
    limit: r.limit,
    offset: r.offset,
    objects: withChats ? DataAdapter.tasks(r.objects) : DataAdapter.tasks(r.objects).tasks,
  })),
  getCount: () => _fetch(tasks.getURL() + '?limit=0&task_status=any&exclude_task_status=done').then((r: any) => r.count),
  removeObserver: (taskJid: string, obsJid: string) => _fetch(tasks.getURL(taskJid) + 'observers/' + obsJid, null, 'DELETE'),
  filter: (parameters: any, limit = 50) => new Pagination({
    url: `${tasks.getURL()}`,
    parameters,
    limit,
    adapter: 'task',
  }),
  // groupBy: (tab: String, groupBy: String) => _fetch(tasks.getURL() + `tabs/${tab}/groupby/${groupBy}`),
  groupBy: (groupBy: string, filters: any) => _fetch(tasks.getURL() + `groupby/${groupBy}?` + toQueryString(filters)),

  getItemUrl: (jid: string, uid?: string) => `${tasks.getURL(jid)}items/` + (uid ? `${uid}/` : ''),
  addItem: (jid: string, params: any) =>
    _fetch(tasks.getItemUrl(jid), params, 'POST')
      .then(TaskItem.fromJSON),
  editItem: (jid: string, uid: string, params: any) =>
    _fetch(tasks.getItemUrl(jid, uid), params, 'PUT')
      .then(TaskItem.fromJSON),
  deleteItem: (jid: string, uid: string) => _fetch(tasks.getItemUrl(jid, uid), null, 'DELETE'),
  getImportURL: () => tasks.getURL() + 'import/',
  getImportTemplateXLSXURL: () => tasks.getImportURL() + 'xlsx',
  uploadImportTemplateXLSX: (file: Blob) => _fetch(
    tasks.getImportTemplateXLSXURL(), { file }, 'POST',
  ),
  uploadImportTemplateTrello: (file: Blob) => _fetch(
    tasks.getImportURL() + 'trello', { file }, 'POST',
  ),
  startImport: (taskList: Array<any>) => _fetch(
    tasks.getImportURL(), { tasks: taskList }, 'POST',
  ),
  getExportURL: () => tasks.getURL() + 'export/xlsx',
  getStatuses: () => _fetch(tasks.getURL() + 'statuses')
    .then((raw: TaskStatusJSON[]) => raw ? raw.map(TaskStatus.fromJSON) : []),
  tabs: () => _fetch(tasks.getURL() + 'tabs')
    .then((r: any) => DataAdapter.taskTabs(r)),
  sections: () => _fetch(tasks.getURL() + 'sections')
    .then((raw: SectionJSON[]) => raw ? raw.map(Section.fromJSON) : []),
}

const tags = {
  getURL: () => `${getTeamPrefix()}/tags`,
  get: (): Promise<Tag[]> => _fetch(tags.getURL())
    .then((data: any) => data.map(Tag.fromJSON)),
}

const reminds = {
  getURL: () => `${getTeamPrefix()}/reminds`,

  /**
   * Loads a Remind list from server
   * @returns {Promise<Remind[]>} Array of parsed Remind objects
   */
  getAll: (): Promise<Remind[]> => _fetch(reminds.getURL())
    .then((data: RemindJSON[]) => data.map(Remind.fromJSON)),

  /**
   * Loads a Remind data from server using UID
   * @param {string} uid UID to load
   * @returns {Promise<Remind>} Parsed Remind object
   */
  get: (uid: string): Promise<Remind> =>
    _fetch(`${reminds.getURL()}/${uid}`)
      .then(Remind.fromJSON),

  /**
   * Creates a new Remind and returns its data
   * @param params Data to create Remind
   * @returns {Promise<Remind>} Parsed Remind object
   */
  create: (params: Omit<RemindJSON, 'uid'>): Promise<Remind> =>
    _fetch(reminds.getURL(), params, 'POST', 'JSON')
      .then(Remind.fromJSON),

  /**
   * Deletes a Remind using UID and returns its data
   * @param {string} uid UID to delete
   * @returns {Promise<Remind>} Parsed Remind object
   */
  delete: (uid: string): Promise<Remind> =>
    _fetch(`${reminds.getURL()}/${uid}`, null, 'DELETE')
      .then(Remind.fromJSON),
}

const messages = {
  getURL: () => `${getTeamPrefix(4)}/messages/`,
  getLoadLastURL: (chatId: string, limit = 200) => {
    return getChatPrefix(chatId) + `messages/?limit=${limit}`
  },
  getLoadDirectionalURL: (
    chatId: string,
    from: string,
    type: 'old' | 'new',
    include = false,
    limit = 200,
  ) => {
    const dir = `?${type}_from${include ? '_inc' : ''}=${from}`
    return getChatPrefix(chatId) + 'messages/' + dir + `&limit=${limit}`
  },
  audiomsg: (chatId: string, data: FormData) => {
    return _fetch(`${messages.getURL()}${chatId}/?type=audiomsg`, data, 'POST')
  },
  getLoadAroundURL: (chatId: string, around: string, limit = 200) => {
    const url = getChatPrefix(chatId) + 'messages/' + `?around=${around}`
    return url + `&limit=${limit}`
  },
  filter: (parameters: any, limit = 50) => new Pagination({
    url: `${messages.getURL()}`,
    parameters, // { chat, hasUpload },
    limit,
    adapter: 'filterMessage',
  }),
}

const chatlinks = {
  getURL: (jid: string, type: string, markdown = true) =>
    `${getChatPrefix(jid)}${type}/` + (markdown ? '?markdown=true' : ''),
  commands: (jid: string) =>
    _fetch(chatlinks.getURL(jid, 'botcommands', false), null, 'GET').then((r: any) =>
      DataAdapter.chatlinks(r),
    ),
  at: (jid: string) =>
    _fetch(
      chatlinks.getURL(jid, 'atlinks'),
      null,
      'GET',
    ).then((r: any) => DataAdapter.chatlinks(r)),
  sharp: (jid: string) =>
    _fetch(
      chatlinks.getURL(jid, 'sharplinks'),
      null,
      'GET',
    ).then((r: any) => DataAdapter.chatlinks(r)),
}

const devices = {
  getURL: (id?: string) => `${getPrefix(4)}/devices/` + (id ? `${id}/` : ''),
  getAll: () => _fetch(devices.getURL()),
  get: (id: string) => _fetch(devices.getURL(id)).then(PushDevice.fromJSON),
  create: (params: any) => _fetch(devices.getURL(), params, 'POST', 'JSON').then(PushDevice.fromJSON),
  delete: (id: string) => _fetch(devices.getURL(id), null, 'DELETE'),
  edit: (id: string, params: any) => _fetch(devices.getURL(id), params, 'PUT').then(PushDevice.fromJSON),
}

const settings = {
  getURL: () => `${getPrefix(4)}/ui-settings/web`,
  load: () => _fetch(settings.getURL()),
  save: (data: any) => _fetch(settings.getURL(), data, 'PUT'),
}

const help = {
  getURL: () => `${getTeamPrefix()}/help/`,
  send: (email: string, text: string) => _fetch(help.getURL(), { email, text }, 'POST', 'JSON'),
}

const invitations = {
  getURL: (id?: string) => `${getTeamPrefix()}/invitations/` + (id ? `${id}/` : ''),
  getAll: () => _fetch(invitations.getURL()),
  create: (params?: any) => _fetch(invitations.getURL(), params || {}, 'POST', 'JSON'),
  delete: (uid: string) => _fetch(invitations.getURL(uid), null, 'DELETE'),
  join: (token: string) =>
    _fetch(`${getPrefix()}/join/${token}/`, {}, 'POST', 'JSON').then(Team.fromJSON),
  info: (token: string) => _fetch(`${getPrefix()}/join/${token}/preview`),
}

const calendarIntegration = {
  getICS: () => _fetch(`${getPrefix(4)}/ics`),
}

const nodes = {
  getURL: () => `${getPrefix(4)}/nodes`,
  getAll: (): Promise<Node[]> => _fetch(nodes.getURL()).then(r => r.map(Node.fromJSON)),
}

const invitableUsers = {
  getURL: () => `${getTeamPrefix(4)}/invitable-users`,
  getExternal: (excludeTeam?: string): Promise<InvitableUser[]> => {
    let url = invitableUsers.getURL() + '/from-other-nodes'
    if (excludeTeam) url += `?excludeteam=${excludeTeam}`
    const handler = (r: any) => r.map(InvitableUser.fromJSON)
    return _fetch(url).then(handler)
  },
  getInternal: (excludeTeam?: string): Promise<InvitableUser[]> => {
    let url = invitableUsers.getURL() + '/from-other-teams'
    if (excludeTeam) url += `?excludeteam=${excludeTeam}`
    const handler = (r: any) => r.map(InvitableUser.fromJSON)
    return _fetch(url).then(handler)
  },
}

const loadMe = (): Promise<UserWithMe> => HTTP
  .fetch({ url: `${getPrefix()}/me/` }, false)
  .then((r: UserWithMeJSON) => UserWithMe.fromJSON(r))

const authByKerberos = async (): Promise<UserWithMe> => {
  const r: AuthJSON = await HTTP.fetch(
    { url: `${getPrefix(4)}/auth/kerberos/set-cookie/` },
    false,
  )
  return UserWithMe.fromJSON(r.me)
}

/**
 * Get theme from server, may be not reason without flag (custom_theme) from FEATURES
 * @see themes.ts-setupFeaturesTheme
 * @returns Dict with keys like `brand_color` and values like `#FFFFFF`
 */
export const serverTheme = (): Promise<Record<'web_base', Record<string, string>>> =>
  _fetch(`${getPrefix(4)}/theme`)

export default {
  getTurnConfig: () =>
    _fetch(`${getPrefix(4)}/turn/config`).then((r: any) => DataAdapter.iceConfig(r)).catch(error => console.log(error)),

  getCountries: () =>
    _fetch(`${getPrefix()}/countries`).then((raw: Array<CountryJSON>) =>
      raw.map(Country.fromJSON),
    ),
  time: () => _fetch(`${getPrefix()}/time`),
  // eslint-disable-next-line camelcase
  login: (p: { phone: string, token_version?: string, token?: string }) =>
    _fetch(`${getPrefix(4)}/auth/sms/send-code`, p, 'POST', 'JSON'),
  auth: (code: string, phone: string) => {
    const deviceId = uid()
    defaultLogger.debug('Generating device ID in auth API call', deviceId)
    window.localStorage.setItem('device_id', deviceId)

    const params = {
      phone,
      code,
      device_id: deviceId,
      type: 'web',
      name: navigator.userAgent.toLowerCase(),
    }
    return _fetch(`${getPrefix(4)}/auth/sms/get-token`, params, 'POST', 'JSON')
  },
  authByPassword: (username: string, password: string) => {
    const params = {
      username,
      password,
    }
    return _fetch(
      `${getPrefix(4)}/auth/password/set-cookie`,
      { ...params, insecure_cookie: proxy },
      'POST',
      'JSON',
    )
  },
  cookieAuth: (code: string, phone: string) =>
    _fetch(
      `${getPrefix(4)}/auth/sms/set-cookie/`,
      {
        code,
        phone,
        insecure_cookie: proxy,
      },
      'POST',
      'JSON',
    ),
  saveMe: (params: any) => _fetch(`${getPrefix()}/me/`, params, 'PUT', 'JSON'),
  ping: (messaging: boolean) => {
    return _fetch(
      messaging
        ? `${proxy ? '' : window.FEATURES.host}/messaging/ping`
        : `${getPrefix()}/ping/`,
    )
  },
  stickers: () => {
    return _fetch(`${getTeamPrefix()}/stickers`)
  },
  saveDraft: (chat: string, draft: string, revision: number) =>
    _fetch(`${getChatPrefix(chat)}draft/`, { draft, revision }, 'POST', 'JSON'),
  linkscheck: (chat: string, draft: string) =>
    _fetch(
      `${getChatPrefix(chat)}linkscheck?markdown=true`,
      { draft },
      'POST',
      'JSON',
    ),
  getPrefix,
  serverTheme,
  loadMe,
  authByKerberos,

  twoFactorAuth,
  teams,
  chats,
  messages,
  contacts,
  sections,
  groups,
  publicGroups,
  tasks,
  tags,
  reminds,
  devices,
  settings,
  nodes,
  invitableUsers,
  uploads,
  help,
  chatlinks,
  roster,
  tasksColors,
  tasksColorsRules,
  calls,
  integrations,
  invitations,
  calendarIntegration,
  getListPublicStatuses,
  ws,
}
