import Actions from '@/store/modules/dataImports/actions'
import Getters from '@/store/modules/dataImports/getters'
import Mutations from '@/store/modules/dataImports/mutations'
import State from '@/store/modules/dataImports/state'
import { Module } from 'vuex-smart-module'

const dataImportsModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default dataImportsModule
