import State from '@/store/modules/dataImports/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import { ImportData } from './types'

export default class Mutations extends BaseMutations<State> {
  /**
   * Add import data
   * @param uid import uid
   */
  addImport (uid: string): void {
    const i = {
      uid,
      num: 0,
      total: 0,
      errorMessages: [],
    }
    this.state.data = { ...this.state.data, [uid]: i }
  }

  /**
   * Set import data
   * @param p import uid
   */
  setImport (importData: ImportData): void {
    const { uid } = importData
    this.state.data[uid] = importData
  }
}
