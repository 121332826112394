import i18n from '@/i18n'
import store, { contactsStore, teamsStore, uiSettingsStore, draftsStore } from '@/store'
import {
  Chat,
  Contact,
  PaginatedChats,
  ServerChatDeletedParams,
  ServerChatLastreadParams,
  ServerChatUpdatedParams,
  ServerMessageUpdatedParams,
} from '@tada-team/tdproto-ts'
import { ChatListRequestParams, list } from 'td-api/lib/api/old/chats'
import { Actions as BaseActions } from 'vuex-smart-module'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'
import { TeamId } from './types'
import api from '@/api/v3'

export default class Actions extends BaseActions<
  State,
  Getters,
  Mutations,
  Actions
> {
  async load (teamId: TeamId) {
    if (this.state.isLoaded || this.state.isLoading) return

    const params: ChatListRequestParams = {}
    params.chatType = ['direct', 'group']
    params.hidden = 'any'
    params.lang = uiSettingsStore.getters.language || i18n.locale || 'ru'
    params.mixContacts = true
    params.sort = '-last_message'

    const chats: Chat[] = []
    const contacts: Contact[] = []

    this.mutations.setIsLoading(true)

    while (true) {
      let res: PaginatedChats
      try {
        res = await list(teamId, params)
      } catch (e) {
        this.mutations.setIsLoading(false)
        return Promise.reject(e)
      }

      chats.push(...res.objects)
      if (res.contacts?.length) contacts.push(...res.contacts)
      if (res.count <= (res.limit + res.offset)) break
      params.limit = res.limit
      params.offset = res.offset + res.limit
    }

    this.mutations.setIsLoading(false)
    this.mutations.setIsLoaded(true)

    if (contacts.length) contactsStore.mutations.addOrUpdateContacts(contacts)
    if (chats.length) this.mutations.addOrUpdateChats(chats)

    await draftsStore.actions.onChatsBarLoaded(chats)
  }

  async changeShortView (isShort: boolean) {
    teamsStore.mutations.changeMeShortView({ chatType: 'direct', isShort })
    teamsStore.mutations.changeMeShortView({ chatType: 'group', isShort })
    await contactsStore.actions.editContact({
      jid: store.getters.getUserId,
      params: {
        contact_short_view: isShort,
        group_short_view: isShort,
      },
    })
  }

  onServerChatDeleted ({ chats }: ServerChatDeletedParams) {
    const list = chats.filter(onlyDirectOrGroupOrThread)
    if (list.length) this.mutations.removeChats(list.map(item => item.jid))
  }

  onServerChatLastread ({ chats }: ServerChatLastreadParams) {
    const list: Chat[] = []
    const filtered = chats.filter(onlyDirectOrGroupOrThread)

    for (const item of filtered) {
      const chat = this.state.chats.find(c => c.jid === item.jid)?.toJSON()
      if (chat) list.push(Chat.fromJSON({ ...chat, ...item.toJSON() }))
    }

    if (list.length) this.mutations.addOrUpdateChats(list)
  }

  onServerChatUpdated ({ chats }: ServerChatUpdatedParams) {
    const list = chats.filter(item => (
      item.chatType === 'direct' ||
      (
        item.chatType === 'group' &&
        item.members?.some(member => member.jid === store.getters.profileId)
      )
    ))
    if (list.length) this.mutations.addOrUpdateChats(list)
  }

  onServerMessageUpdated (p: ServerMessageUpdatedParams) {
    const list: Chat[] = []
    const filtered = p.messages.filter(m => onlyDirectOrGroupOrThread(m) && m.isLast)

    for (const item of filtered) {
      const chat = this.state.chats.find(c => c.jid === item.chat)?.toJSON()
      if (!chat) continue

      list.push(Chat.fromJSON({ ...chat, last_message: item.toJSON() }))
    }

    if (list.length) this.mutations.addOrUpdateChats(list)

    this.actions.onServerChatLastread(
      new ServerChatLastreadParams(p.badge, p.chatCounters, p.teamUnread),
    )
  }

  async loadThreads () {
    try {
      const threadChats = await api.chats.getAllThreads()
      this.mutations.addOrUpdateChats(threadChats)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

function onlyDirectOrGroupOrThread ({ chatType }: Pick<Chat, 'chatType'>) {
  return chatType === 'direct' || chatType === 'group' || chatType === 'thread'
}
