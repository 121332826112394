import Getters from '@/store/modules/chatImport/getters'
import Mutations from '@/store/modules/chatImport/mutations'
import State from '@/store/modules/chatImport/state'
import { Actions as BaseActions } from 'vuex-smart-module'
import { teamsStore } from '@/store'
import * as Sentry from '@sentry/browser'
import {
  ServerProcessingParams,
  ParserUploadArchiveResponse,
  ParserGetMappedUsersResponse,
  MappedUser,
  ParserMapUsersRequest,
  ParserGenerateChatsResponse,
  ParserGetStateResponse, ParseState,
} from '@tada-team/tdproto-ts'
import { messengers } from 'td-api'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  resetStore (): void {
    this.mutations.resetStore()
  }

  openChatImportDialog (): void {
    this.mutations.setIsChatImportDialogOpen(true)
  }

  closeChatImportDialog (): void {
    this.mutations.setIsChatImportDialogOpen(false)
  }

  clearLoading (): void {
    this.mutations.setFileName('')
    this.mutations.setParserState('not_found')
    this.mutations.setLoadDone(false)
    this.mutations.setLoadingProgress(0)
    this.mutations.setIsLoading(false)
    this.mutations.clearProcessingActions()
  }

  async loadState (): Promise<void> {
    let response: ParserGetStateResponse
    try {
      response = await messengers.getState(teamsStore.getters.currentTeam.uid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('telegramImport', 'load archive status')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    const {
      state,
      actionType,
      action,
      message,
      progress,
      hasError,
      body,
      archiveName,
    } = response
    this.mutations.setFileName(archiveName)
    this.mutations.setParserState(state)
    this.mutations.setLoadDone(state !== 'not_found')
    if (state === 'not_found') return
    const params = new ServerProcessingParams(action ?? '', hasError, message ?? '', progress ?? 0, 100, actionType, body ?? '')
    this.mutations.addOrUpdateProcessingAction(params)
  }

  async removeArchive (): Promise<void> {
    try {
      await messengers.deleteArchive(teamsStore.getters.currentTeam.uid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('telegramImport', 'delete archive')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    await this.actions.clearLoading()
  }

  async cancelImportFile (): Promise<void> {
    if (this.state.isLoading) {
      await this.actions.abortLoadFile()
    } else {
      await this.actions.removeArchive()
    }
    await this.actions.clearLoading()
  }

  abortLoadFile (): void {
    this.state.abortController.abort()
    this.mutations.updateAbortController()
  }

  async uploadFile (file: File): Promise<void> {
    if (this.state.isLoading) return
    let response: ParserUploadArchiveResponse
    try {
      const data = new FormData()
      const signal = this.state.abortController.signal
      data.append('file', file)
      this.mutations.setIsLoading(true)
      response = await messengers.sendArchive(teamsStore.getters.currentTeam.uid, data, event => {
        this.mutations.setLoadingProgress(event.loaded / event.total)
        this.mutations.setFileSize(event.total)
        this.mutations.setLoadingSize(event.loaded)
      }, signal)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('telegramImport', 'load file')
        Sentry.captureException(e)
      })
      this.mutations.setHasLoadError(true)
      return Promise.reject(e)
    }
    const params = new ServerProcessingParams(response.processingAction, false, '', 0, 100, response.actionType)
    this.mutations.addOrUpdateProcessingAction(params)
    this.mutations.setLoadDone(true)
    this.mutations.setParserState('unpacking')
    this.mutations.setIsLoading(false)
    this.mutations.setFileName(file.name)
    this.mutations.setHasLoadError(false)
  }

  async loadMappedUsers (): Promise<void> {
    let mappedUsers: ParserGetMappedUsersResponse
    this.mutations.setMappingUsersLoading(true)
    try {
      mappedUsers = await messengers.getMapUsers(teamsStore.getters.currentTeam.uid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('telegramImport', 'load mapped users')
        Sentry.captureException(e)
      })
      this.mutations.setMappingUsersLoading(false)
      return Promise.reject(e)
    }
    this.mutations.setMappedUsers(mappedUsers.users)
    this.mutations.setMappingUsersLoading(false)
    this.mutations.setChatName(mappedUsers.chatName)
  }

  async matchUsers (users: MappedUser[]): Promise<void> {
    if (this.state.isMatching) return
    this.mutations.setIsMatching(true)
    try {
      const request = new ParserMapUsersRequest(users)
      await messengers.matchUsers(teamsStore.getters.currentTeam.uid, request)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('telegramImport', 'match users')
        Sentry.captureException(e)
      })
      this.mutations.setIsMatching(false)
      return Promise.reject(e)
    }
    this.mutations.setIsMatching(false)
  }

  async generateChat (): Promise<void> {
    let response: ParserGenerateChatsResponse
    try {
      response = await messengers.generateChats(teamsStore.getters.currentTeam.uid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('telegramImport', 'match users')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    const params = new ServerProcessingParams(response.processingAction, false, '', 0, 100, response.actionType)
    this.actions.onProcessing(params)
    this.mutations.setParserState('generating')
  }

  setParseState (p: ParseState): void {
    this.mutations.setParserState(p)
  }

  onProcessing (params: ServerProcessingParams): void {
    this.mutations.addOrUpdateProcessingAction(params)
  }
}
