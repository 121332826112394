import { WebRTCVideoReceiver } from '@/calls/WebRTCVideoReceiver'
import { WebRTCVideoSender } from '@/calls/WebRTCVideoSender'
import { CallOnliner, CallType, IconData, JID } from '@tada-team/tdproto-ts'

export type UserMediaPreference =
  | 'unknown'
  | 'audio'
  | 'video'
  | 'both'
  | 'none'

export type CallLifecycleState =
  | 'WAIT_FOR_CONNECTION'
  | 'CONNECTING'
  | 'CONNECTED'
  | 'RECONNECTING'
  | 'ENDED'

export type RemoteStream = {
  jid: JID;
  stream: MediaStream;
}

export type Presenter = JID | null

export type ActiveCall = {
  uid: string | null;
  jid: JID;
  type: CallType;

  isAudioMuted: boolean;
  isVideoMuted: boolean;
  isScreensharing: boolean;

  /**
   * Talking members in order of when they started talking.
   * Last one to start talking - first in this array.
   */
  talkingMembers: JID[];
  connectedMembers: CallOnliner[];
  maxConnectedMembersCount: number;

  outputVolume: number;

  /**
   * Timestamp of latest state event
   */
  lastStateUpdate: number;

  start: string | null;
  /**
   * Call length updater timeInterval
   */
  timeInterval: number | null;
  /**
   * Call length in seconds.
   */
  length: number;

  lifecycleState: CallLifecycleState;

  isLoud: boolean | null;
  isOutgoing: boolean;

  webRTCSender: WebRTCVideoSender;
  webRTCReceivers: Array<WebRTCVideoReceiver>;

  remoteStreams: RemoteStream[];
  localStreams: {
    audio: MediaStream | null;
    video: MediaStream | null;
  }
  // localTrackVideo: MediaStreamTrack | null;
  // localTrackAudio: MediaStreamTrack | null;

  userMediaPreference: UserMediaPreference;

  presenter: {
    fromServer: Presenter;
    pinnedByUser: Presenter;
  }

  multistream: {
    webRTCReceiver: WebRTCVideoReceiver | null;
    audio: MediaStream[];
    video: MediaStream[];
  }
}

/**
 * Determines how which call window will be displayed.
 */
export type CallDisplayType =
  | 'card'
  | 'bar'
  | 'fullscreen'
  | null

export type CallDisplayTypeCode =
  | 'SM'
  | 'MD'
  | 'LG'

/**
 * Determines what will be displayed inside medium (MD) sized call window.
 */
export type CallMdDisplayType =
  | 'CALL'
  | 'ADD_MEMBERS'

export type CallMembersDisplayType =
  | 'CARDS'
  | 'LIST'

export type CallUiLgRightBarDisplayType =
  | 'ADD_MEMBERS'
  | null

export type CallUiLgLayoutType =
  | 'GRID'
  | 'PRESENTER'

export class CallEntity {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    readonly name: string,
    readonly icons: IconData,
    readonly muted: boolean,
    readonly videoEnabled: boolean,
    readonly jid: string,
    readonly role: string,
  ) {}
}
