import i18n from '@/i18n'

import { uiSettingsStore } from '@/store'
import { logout } from '@/utils'
// import { defaultLogger } from '@/loggers'

const handleError = ({ status, response }: { status: number; response: any }) => {
  const { error, details } = response
  switch (status) {
    case 401:
      logout(false)
      break
    default:
      return { error, details }
  }
}

export const makeXHRequest = ({ url, data, resolve, reject, method = 'GET', timeout = 120000 }: { url: string; data?: any; resolve: any; reject: any; method?: string; timeout?: number }) => {
  if (!url) {
    reject({ error: 'Не указан URL' })
    return
  }

  const xhr = new XMLHttpRequest()

  xhr.onload = () => {
    if (xhr.readyState !== 4) return

    const status = xhr.status
    if (status === 500) {
      return reject({ error: 'Системная ошибка' })
    }

    let response
    try {
      response = JSON.parse(xhr.responseText)
    } catch (e) {
      return reject({ error: e, response: xhr.responseText })
    }

    switch (xhr.status) {
      case 200: {
        const { result } = response
        resolve(result)
        break
      }
      default: {
        const error = handleError({ status, response })
        reject(error)
      }
    }
  }
  xhr.ontimeout = e => reject(e)
  xhr.onerror = e => reject(e)

  xhr.open(method, url, true)
  xhr.timeout = timeout || 0
  xhr.setRequestHeader('lang', uiSettingsStore.getters.language || i18n.locale || 'ru')

  xhr.send(data)

  return xhr
}

// export const makeRequest = function ({ url, data, method = 'GET' }: { url: string, data?: any, method?: string }) {
//   const instance = this as any
//   return new Promise((resolve, reject) => {
//     this.xhr = makeXHRequest({ url, data, resolve, reject, method })
//   })
// }
