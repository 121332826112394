import { defaultLogger } from '@/loggers'
import api from '@/api/v3'

import BaseNotification, { Type, SubscribeParameters, Permission } from '@/notifications/BaseNotification'
import { allowNotifications, isNotificationsAllowed } from '@/notifications/Utils'
import { uid } from 'quasar'

const WEB_PUSH_ID = window.FEATURES.safari_push_id

interface SafariPermissionData {
  permission: Permission;
  deviceToken: string;
}

export default class Safari extends BaseNotification {
  endpoint: string | null = null
  callOnGranted?: Array<() => unknown> | null = null

  init () {
    this.endpoint = location.origin

    defaultLogger.debug('[Safari.init]', { endpoint: this.endpoint, id: WEB_PUSH_ID })

    this.getPermission() === Permission.GRANTED &&
    isNotificationsAllowed() &&
    this.subscribe({ callOnGranted: null })
  }

  async subscribe ({ callOnGranted, force }: SubscribeParameters) {
    this.callOnGranted = callOnGranted

    try {
      const { pushNotification } = (window as any).safari
      const permissionData = pushNotification.permission(WEB_PUSH_ID) as SafariPermissionData
      this.checkRemotePermission(permissionData, !!force)
    } catch (error) {
      defaultLogger.warn('[Safari.subscribe]', error)
    }
  }

  async unsubscribe () {
    if (!isNotificationsAllowed()) {
      defaultLogger.debug('[Safari.unsubscribe] Уведомления уже выключены')
      return
    }

    const deviceId = localStorage.getItem('device_id')
    if (!deviceId) {
      defaultLogger.debug('[Safari.unsubscribe] Уведомлений и не было (localStorage[\'device_id\'] is null)')
      return
    }

    const token = localStorage.getItem('sentFirebaseMessagingToken')
    if (!token) {
      defaultLogger.debug('[Safari.unsubscribe] Уведомлений и не было (localStorage[\'sentFirebaseMessagingToken\'] is null)')
      return
    }

    await api.devices.delete(deviceId)
    allowNotifications(false)
    localStorage.removeItem('device_id')
  }

  checkRemotePermission (permissionData: SafariPermissionData, force: boolean) {
    const { permission, deviceToken } = permissionData

    defaultLogger.debug('[Safari.checkRemotePermission]', permissionData)

    switch (permission) {
      case Permission.DEFAULT: {
        try {
          const { pushNotification } = (window as any).safari
          pushNotification.requestPermission(
            this.endpoint + '/push',
            WEB_PUSH_ID, {},
            (data: SafariPermissionData) => this.checkRemotePermission(data, force),
          )
        } catch (error) {
          defaultLogger.warn('[Safari.checkRemotePermission(DEFAULT)]', error)
        }
        break
      }
      case Permission.GRANTED: {
        allowNotifications(true)

        this.editDevice({ token: deviceToken, force })

        if (this.callOnGranted) {
          this.callOnGranted.forEach(fn => fn())
          this.callOnGranted.splice(0, this.callOnGranted.length)
        }
        break
      }
      case Permission.DENIED: {
        allowNotifications(false)
        break
      }
    }
  }

  async editDevice ({ token, force }: { token: string; force?: boolean }) {
    if (!isNotificationsAllowed() && force === false) {
      defaultLogger.debug('[Safari.editDevice] Уведомления выключены')
      return
    }

    if (this.isTokenCorrect(token) && !force) {
      defaultLogger.debug('[Safari.editDevice] Токен уже отправлен на сервер.')
      return
    }

    defaultLogger.debug('[Safari.editDevice] Отправка токена на сервер...', force)

    const device = {
      active: true,
      allowed_notifications: true,
      name: 'SAFARI:' + window.navigator.userAgent,
      notification_token: token,
      type: 'safari',
      data_pushes: false,
    }

    let deviceId = localStorage.getItem('device_id')
    try {
      if (deviceId) {
        await api.devices.edit(deviceId, device)
        force && allowNotifications(true)
        defaultLogger.warn('[Safari.editedDevice]', deviceId)
      } else {
        deviceId = uid()

        Object.assign(device, { device_id: deviceId })
        defaultLogger.warn('[Safari.createdDevice]', deviceId)
        const r = await api.devices.create(device)

        localStorage.setItem('device_id', r.deviceId)
        allowNotifications(true)
      }
    } catch (error) {
      defaultLogger.warn('[Safari.editDevice]', error)
    }

    this.storeToken(token)
  }

  getPermission () {
    try {
      const permissionData = (window as any).safari.pushNotification.permission(WEB_PUSH_ID)
      return permissionData.permission
    } catch (e) {
      defaultLogger.warn('[Safari.getPermission]', e)
      return false
    }
  }

  isTokenCorrect (token: string) {
    return localStorage.getItem('sentFirebaseMessagingToken') === token
  }

  storeToken (token: string | null) {
    if (!token) {
      localStorage.removeItem('sentFirebaseMessagingToken')
      return
    }
    localStorage.setItem('sentFirebaseMessagingToken', token)
  }

  getType () {
    return Type.SAFARI
  }
}
