import Actions from '@/store/modules/threads/actions'
import Getters from '@/store/modules/threads/getters'
import Mutations from '@/store/modules/threads/mutations'
import State from '@/store/modules/threads/state'
import { Module } from 'vuex-smart-module'

const threadsModule = new Module({
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
})

export default threadsModule
