import { Actions } from 'vuex-smart-module'
import * as Sentry from '@sentry/browser'
import api from '@/api/v3'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'
import { InvitableUser } from '@tada-team/tdproto-ts'

export default class ModuleActions extends Actions<
  State,
  Getters,
  Mutations,
  ModuleActions
> {
  async loadAllExternal (excludeTeam?: string): Promise<void> {
    let users: InvitableUser[]

    try {
      users = await api.invitableUsers.getExternal(excludeTeam)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('nodes', 'get')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to get nodes.')
      })
      return Promise.reject(e)
    }

    this.mutations.setAllExternal(users)
  }

  async loadAllInternal (excludeteam?: string): Promise<void> {
    let users: InvitableUser[]

    try {
      users = await api.invitableUsers.getInternal(excludeteam)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('nodes', 'get')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to get nodes.')
      })
      return Promise.reject(e)
    }

    this.mutations.setAllInternal(users)
  }
}
