import State from './state'
import { Mutations } from 'vuex-smart-module'
import { AuthOption } from './types'
import { Country } from '@tada-team/tdproto-ts'

class ModuleMutations extends Mutations<State> {
  setLoginState (value: boolean) {
    this.state.isLoggedIn = value
  }

  setBadProfileStatus (value: boolean) {
    this.state.isBadProfile = value
  }

  setCountries (value: Array<Country>) {
    this.state.countries = value
  }

  setAuthOptions (value: Array<AuthOption>) {
    this.state.authOptions = value
  }

  setCurrentAuthOption (value: AuthOption) {
    this.state.currentAuthOption = value
  }

  setMobileSeenState (value: boolean) {
    this.state.seenMobile = value
  }

  pushAcceptedInviteUid (value: string) {
    this.state.acceptedInvitesUids.push(value)
  }
}

export default ModuleMutations
