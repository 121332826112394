import Getters from '@/store/modules/drafts/getters'
import Mutations from '@/store/modules/drafts/mutations'
import State from '@/store/modules/drafts/state'
import { Actions as BaseActions } from 'vuex-smart-module'
import { Draft } from '@/store/modules/drafts/types'
import { chatsBarStore, teamsStore } from '@/store'
import { Chat, DeletedChat } from '@tada-team/tdproto-ts'
import draftsSync from './sync'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  $init (): void {
    draftsSync.watch(() => this.state.namespace, draft => this.actions.updateManyByLocalStorage([draft]))
  }

  addDraft (draft: Draft): void {
    this.mutations.updateMany([draft])
    chatsBarStore.mutations.updateDrafts([draft])

    draftsSync.push(this.state.namespace, draft)
  }

  forgetDraft ({ id }: { id: string }): void {
    this.mutations.deleteMany([id])
    chatsBarStore.mutations.updateDrafts([{ id, context: '', revision: Date.now() }])

    draftsSync.forget(this.state.namespace, id)
  }

  updateManyByLocalStorage (drafts: Draft[]): void {
    const updateDrafts = drafts.filter(draft => {
      const stored = this.state.data[draft.id]
      return !(stored && stored.revision >= draft.revision)
    })

    if (!updateDrafts.length) return

    this.mutations.updateMany(updateDrafts)
    chatsBarStore.mutations.updateDrafts(updateDrafts)
  }

  updateManyByServer (drafts: Draft[]): void {
    if (!drafts.length) return

    const namespace = this.state.namespace
    const queuedDrafts = draftsSync.all(namespace)

    const updateDrafts = drafts.filter(draft => {
      const queuedDraft = queuedDrafts.find(d => d.id === draft.id)
      if (!queuedDraft) return true

      if (queuedDraft.revision <= draft.revision) {
        draftsSync.forget(this.state.namespace, draft.id)
        return true
      }

      return false
    })

    if (!updateDrafts.length) return

    this.mutations.updateMany(updateDrafts)
    chatsBarStore.mutations.updateDrafts(updateDrafts)
  }

  onChatDeleted (chatId: string): void {
    this.mutations.deleteMany([chatId])
    draftsSync.forget(this.state.namespace, chatId)
  }

  onServerChatsDeleted (chats: DeletedChat[]): void {
    const ids = chats.map(chat => chat.jid)
    if (!ids.length) return

    this.mutations.deleteMany(ids)
    draftsSync.forget(this.state.namespace, ...ids)
  }

  async onChatsBarLoaded (chats: Chat[]): Promise<void> {
    this.mutations.setNamespace(`me:${teamsStore.getters.currentTeam.me.jid}|draft:`)

    const drafts: Record<string, Draft | undefined> = {}

    // Load from Chats
    for (const chat of chats) {
      if (chat.draft === undefined || chat.draftRevision === undefined) continue

      drafts[chat.jid] = {
        id: chat.jid,
        context: chat.draft,
        revision: chat.draftRevision,
      }
    }

    // Load from Queue
    const queuedDrafts = draftsSync.all(this.state.namespace)
    for (const queuedDraft of queuedDrafts) {
      const currentDraft = drafts[queuedDraft.id]
      if (!currentDraft) continue

      if (queuedDraft.revision < currentDraft.revision) {
        draftsSync.forget(this.state.namespace, queuedDraft.id)
        continue
      }

      drafts[queuedDraft.id] = queuedDraft
    }

    const updateDrafts = Object.values(drafts).filter((draft): draft is Draft => !!draft)
    if (updateDrafts.length) {
      this.mutations.updateMany(updateDrafts)
      chatsBarStore.mutations.updateDrafts(updateDrafts)
    }
  }
}
