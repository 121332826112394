import ResizableInstance from './ResizableInstance'
import { ctx } from './index'

export const enum Item {
  SIZE = 'size'
}

export default class {
  public context: ResizableInstance

  constructor (context: ResizableInstance) {
    this.context = context
  }

  public get = (item: Item): string | null => {
    try {
      const key = this.key(item)
      return localStorage.getItem(key)
    } catch (e) {
      throw new Error(`[ContextStorage.store] ${e}`)
    }
  }

  public save = (item: Item, value?: any): void => {
    if (value === undefined) { return }

    try {
      const key = this.key(item)
      localStorage.setItem(key, value)
    } catch (e) {
      throw new Error(`[ContextStorage.store] ${e}`)
    }
  }

  public remove = (item?: Item) => {
    try {
      if (item) {
        const storageKey = `${ctx}_${this.context.options.key}_${item}`
        localStorage.removeItem(storageKey)
      } else {
        const prefix = `${ctx}_${this.context.options.key}`
        Object.keys(localStorage).forEach(key => {
          if (key.indexOf(prefix) !== 0) { return }
          localStorage.removeItem(key)
        })
      }
    } catch (e) {
      throw new Error(`[ContextStorage.storeClear] ${e}`)
    }
  }

  private key = (item: Item) => `${ctx}_${this.context.options.key}_${item}`
}
