import api from '@/api/v3'
import { DataAdapter } from '@/api/v3/DataAdapter'
import { makeXHRequest } from '@/api/v3/xhr'
import { ChatEventBus, Events } from '@/components/Chat/ChatEventBus'
import { defaultLogger, networkLogger, supportTasksLogger } from '@/loggers'
import store from '@/store'
import * as actionTypes from '@/store/actionTypes'
import * as Consts from '@/store/messages/consts'
import { compareDates, getOrderedMessageIndex } from '@/utils'
import isSupportTask from '@/utils/IsSupportTask'
import { throttle } from 'quasar'
import Vue from 'vue'

export interface ChatStore {
  data: { [messageId: string]: TADA.Message };
  undeliveredData: { [messageId: string]: TADA.Message };
  orderIds: Array<string>;
  undeliveredOrderIds: Array<string>;
}

enum FetchingType {
  LAST = 'last',
  NEW = 'new',
  OLD = 'old',
  AROUND = 'around'
}

export type OrderedMessages = { data: { [messageId: string]: TADA.Message }; orderIds: Array<string> }

type FetchMessagesPayload = {
  chatId: string;
  type: FetchingType;
} & ({
  type: FetchingType.LAST;
} | {
  type: FetchingType.NEW | FetchingType.OLD;
  from: string;
  include?: boolean;
} | {
  type: FetchingType.AROUND;
  from: string;
})

const createChatMessages = (): ChatStore => ({
  data: {},
  undeliveredData: {},
  orderIds: [],
  undeliveredOrderIds: [],
})

class Messages {
  stores: { [chatId: string]: ChatStore }
  lastMessagesSnapshot: { [chatId: string]: TADA.Message }
  throttledStoreUndeliveredMessages: ReturnType<typeof throttle>

  requests: { [type: string]: XMLHttpRequest | undefined | null }

  standaloneData: { [messageId: string]: TADA.Message } | null = null

  constructor () {
    this.stores = {}
    this.requests = {}
    this.lastMessagesSnapshot = {}

    this.restoreUndeliveredMessages()

    this.throttledStoreUndeliveredMessages = throttle(this.storeUndeliveredMessages, 400)
  }

  clearChatStore (chatId: string) {
    this.deleteOf(chatId)

    const chatStore = this.stores[chatId] = createChatMessages()

    const { getters } = store
    const lastMessage = getters.chatLastMessage(chatId)
    if (!lastMessage) return

    const { messageId } = lastMessage
    const { orderIds, data } = chatStore
    orderIds.push(messageId)
    data[messageId] = Object.assign({}, lastMessage)
  }

  // Сообщения вставляются вне очереди, просто в data
  addStandaloneMessage (chatId: string, model: TADA.Message) {
    const { messageId, isStandalone } = model
    if (!isStandalone) return

    this.standaloneData = this.standaloneData || {}
    this.standaloneData[messageId] = model
  }

  clearStandaloneMessages () {
    this.standaloneData = null
  }

  restoreUndeliveredMessages () {
    try {
      const { localStorage } = window
      Object.keys(localStorage).forEach(key => {
        if (key.indexOf(Consts.STORAGE_UNDELIVERED_MESSAGES_KEY) !== 0) return

        const storageKeyLength = Consts.STORAGE_UNDELIVERED_MESSAGES_KEY.length
        const chatId = key.substr(storageKeyLength + 1)

        const storedData = localStorage.getItem(key)
        if (!storedData) return

        const chatMessages = this.getChatMessages(chatId)
        chatMessages.undeliveredData = JSON.parse(storedData)

        localStorage.removeItem(key)
      })
    } catch (e) {
      defaultLogger.warn(`[Messages.restoreUndeliveredMessages]: ${e}`)
    }
  }

  storeUndeliveredMessages (chatId: string, clear: boolean) {
    if (!chatId) return

    try {
      const { localStorage } = window
      const key = Consts.STORAGE_UNDELIVERED_MESSAGES_KEY + `_${chatId}`
      if (clear) {
        localStorage.removeItem(key)
        return
      }

      const chatMessages = this.getChatMessages(chatId)
      const { undeliveredData } = chatMessages
      localStorage.setItem(key, JSON.stringify(undeliveredData))
    } catch (e) {
      defaultLogger.warn(`[Messages.storeUndeliveredMessages]: ${e}`)
    }
  }

  setupIfNeededFor (chat: TADA.Chat) {
    if (!chat) {
      supportTasksLogger.info('[SETUP_MESSAGES] > no chat')
      return
    }

    const { chatId } = chat
    const isSupport = isSupportTask(chatId)

    const { lastMessage } = chat
    if (!lastMessage) {
      isSupport && supportTasksLogger.info('[SETUP_MESSAGES] > no lastMessage')
      return
    }

    const chatMessages = this.getChatMessages(chatId)
    if (chatMessages.orderIds.length > 0) {
      isSupport && supportTasksLogger.info('[SETUP_MESSAGES] > with messages')
      return
    }

    const { messageId } = lastMessage

    chatMessages.orderIds = [messageId]
    chatMessages.data = { [messageId]: lastMessage }
    isSupport && supportTasksLogger.info('[SETUP_MESSAGES] > set only lastMessage')
  }

  abortMessagesFetching (type: FetchingType) {
    const xhr = `xhr${type}`

    const request = this.requests[xhr]
    if (request) {
      request.abort()

      this.requests[xhr] = null
      delete this.requests[xhr]
    }
  }

  abortFetching () {
    this.abortMessagesFetching(FetchingType.LAST)
    this.abortMessagesFetching(FetchingType.NEW)
    this.abortMessagesFetching(FetchingType.OLD)
  }

  fetchMessages (options: FetchMessagesPayload) {
    this.abortMessagesFetching(options.type)

    let url = ''
    switch (options.type) {
      case FetchingType.LAST:
        url = api.messages.getLoadLastURL(options.chatId, Consts.MESSAGES_CAP)
        break
      case FetchingType.NEW:
      case FetchingType.OLD:
        url = api.messages.getLoadDirectionalURL(
          options.chatId,
          options.from,
          options.type,
          options.include ?? false,
          Consts.MESSAGES_CAP,
        )
        break
      case FetchingType.AROUND:
        url = api.messages.getLoadAroundURL(
          options.chatId,
          options.from,
          Consts.MESSAGES_CAP, // backend on around works like CAP before + CAP after the message
        )
        break
      default:
        throw new Error('Unknown FetchingType in fetchMessages.')
    }

    return this.makeGETRequest({ url, type: options.type })
      // вообще нужно сделать нормальные ретраи, но тут вопросы - частота/количество, учитывать отмены
      // запросов и состояние сокета, возможно рисовать в ленте сообщений кнопку "попробовать ещё раз"
      // но это не всегда применимо, поэтому пока что просто делаю ещё одну попытку
      .catch(err => {
        console.warn('Error loading messages, retrying', err, url)
        return this.makeGETRequest({ url, type: options.type })
      })
      .then(r => {
        const { messages: rawMessages } = r
        return DataAdapter.messages(rawMessages)
      })
  }

  makeGETRequest ({ url, type }: { url: string; type: string }): Promise<{ messages: any }> {
    return new Promise((resolve, reject) => {
      const xhr = `xhr${type}`
      this.requests[xhr] = makeXHRequest({ url, resolve, reject })
    })
  }

  getChatMessages (chatId: string) {
    return this.stores[chatId] || (this.stores[chatId] = createChatMessages())
  }

  isChatMessagesExist (chatId: string) {
    return !!this.stores[chatId]
  }

  makeLastMessagesSnapshot () {
    Object.keys(this.stores).forEach(chatId => {
      const lastMessageFromStore = this.getLastMessageFromStore(chatId)
      if (!lastMessageFromStore) return

      this.lastMessagesSnapshot[chatId] = lastMessageFromStore
    })
  }

  async restoreMessages (chats: { [chatId: string]: TADA.Chat }) {
    const chatsMessagesIds = Object.keys(this.stores)
    for (const index in chatsMessagesIds) {
      const storedChatId = chatsMessagesIds[index]
      const chat = chats[storedChatId]
      if (!chat) {
        this.deleteOf(storedChatId)
        continue
      }
      await this.restoreFor(chat)
    }
    this.lastMessagesSnapshot = {}
  }

  async restoreFor (chat: TADA.Chat) {
    const { chatId, lastMessage } = chat
    if (!lastMessage) return

    const lastStoredMessage = this.lastMessagesSnapshot[chatId] || this.getLastMessageFromStore(chatId)
    if (!lastStoredMessage) {
      const messages = await this.loadLastMessages(chatId)
      messages && this.emitMessagesRestored({ chatId, messages })
      return messages
    }

    const { messageId: lastMessageId } = lastMessage
    const { messageId: lastStoredMessageId } = lastStoredMessage
    const messagesDifferent = lastMessageId !== lastStoredMessageId
    if (messagesDifferent) {
      const messages = await this.loadMessages({
        chatId,
        type: FetchingType.NEW,
        from: lastStoredMessageId,

        // Silent fetch
        emitStatus: false,
        emitMessages: false,
      })

      messages && this.emitMessagesRestored({ chatId, messages })
      return messages
    } else {
      const messages = {
        data: { [lastMessageId]: lastMessage },
        orderIds: [lastMessageId],
      }
      this.addMessage({ chatId, messages, delayed: true, confirmed: false })
    }
  }

  deleteOf (chatId: string) {
    delete this.stores[chatId]
  }

  public deleteAll () {
    // TODO
    this.stores = {}
  }

  sortMessages (chatId: string) {
    const chatMessages = this.getChatMessages(chatId)
    const { data, orderIds } = chatMessages

    if (orderIds.length === 0) return chatMessages

    const sortPredicate = (a: any, b: any) => {
      a = data[a]
      b = data[b]
      return a.created < b.created ? -1 : a.created > b.created ? 1 : 0
    }
    const sortedOrderIds = this.stores[chatId].orderIds = orderIds.sort(sortPredicate)

    const lastMessage = data[sortedOrderIds[sortedOrderIds.length - 1]]
    store.dispatch(actionTypes.CHANGE_CHAT_LAST_MESSAGE, { chatId, lastMessage })

    return chatMessages
  }

  getMessage (chatId: string, messageId: string, orderedOnly = false): TADA.Message | null {
    const chatMessages = this.getChatMessages(chatId)
    const { data, undeliveredData } = chatMessages

    if (orderedOnly) return data[messageId]

    return data[messageId] || undeliveredData[messageId] || (this.standaloneData ? this.standaloneData[messageId] : null)
  }

  emitLoadingStatus ({ type, chatId, status }: { type: FetchingType; chatId: string; status: boolean }) {
    ChatEventBus.$emit((Events as any)[`SET_${type.toUpperCase()}_MESSAGES_LOADING_STATUS`], { chatId, status })
  }

  emitAddMessages ({ type, chatId, messages }: { type: FetchingType; chatId: string; messages: { data: { [messageId: string]: TADA.Message }; orderIds: Array<string> } }) {
    ChatEventBus.$emit((Events as any)[`ADD_${type.toUpperCase()}_MESSAGES`], { chatId, messages })
  }

  emitMessagesRestored ({ chatId, messages }: { chatId: string; messages: { data: { [messageId: string]: TADA.Message }; orderIds: Array<string> } }) {
    ChatEventBus.$emit(Events.MESSAGES_RESTORED, { chatId, messages })
  }

  /**
   * Loads a given number messages for a given chat with a given direction.
   * (older, newer, around, last)
   * Adds loaded messages to store.
   * Updates chat window if emitMessages is provided.
   * Shows loading indicator in chat window if emitStatus is provided.
   */
  async loadMessages (options:
    FetchMessagesPayload
    & {
      emitStatus: boolean;
      emitMessages?: boolean;
    },
  ) {
    let messages = null
    const emitMessages = options.emitMessages ?? true
    const { type, chatId, emitStatus } = options

    try {
      emitStatus && this.emitLoadingStatus({ type, chatId, status: true })

      messages = await this.fetchMessages(options)
      this._addMessages(chatId, messages)

      emitMessages && this.emitAddMessages({ type, chatId, messages })
    } catch (e) {
      networkLogger.error('[Messages.loadMessages]:', e)
    }
    emitStatus && this.emitLoadingStatus({ type, chatId, status: false })

    return messages
  }

  getMessages (chatId: string, from: string, older?: boolean): OrderedMessages | null {
    const chatStore = this.sortMessages(chatId)

    const { data, orderIds } = chatStore
    if (!data[from]) return null

    const index = orderIds.indexOf(from)
    if (index < 0) throw new Error(`[Messages.getMessages] Message "${chatId}" is in data but not in order`)

    let start = 0
    let end = 0

    const type = older ? FetchingType.OLD : FetchingType.NEW
    switch (type) {
      case FetchingType.NEW: {
        if (orderIds.length - index - 1 < Consts.MESSAGES_CAP) return null

        start = index + 1
        end = start + Consts.MESSAGES_CAP
        break
      }
      case FetchingType.OLD: {
        if (index < Consts.MESSAGES_CAP) return null

        start = index - Consts.MESSAGES_CAP
        end = index
        break
      }
      default: return null
    }

    const resultOrderIds = orderIds.slice(start, end)
    const resultData: { [messageId: string]: TADA.Message } = {}

    resultOrderIds.forEach(messageId => {
      resultData[messageId] = data[messageId]
    })

    return {
      orderIds: resultOrderIds,
      data: resultData,
    }
  }

  /**
   * Loads last messages in a given chat.
   * @param chatId Chat ID to load messages in.
   * @param emitStatus Display loading indicator in chat widnow.
   * @param emitMessages Update chat window with loaded messages.
   */
  loadLastMessages (chatId: string, emitStatus = true, emitMessages = true) {
    return this.loadMessages({
      chatId,
      type: FetchingType.LAST,
      emitStatus,
      emitMessages,
    })
  }

  /**
   * Loads messages older than a given message ID in a given chat.
   * @param chatId Chat ID to load messages in.
   * @param from Message ID to start loading from.
   * @param emitStatus Display loading indicator in chat widnow.
   * @param emitMessages Update chat window with loaded messages.
   * @param include Load "from" message ID in the batch.
   */
  loadOlderMessages (chatId: string, from: string, emitStatus = true, emitMessages = true, include = false) {
    return this.loadMessages({ chatId, type: FetchingType.OLD, from, emitStatus, emitMessages, include })
  }

  /**
   * Loads messages newer than a given message ID in a given chat.
   * @param chatId Chat ID to load messages in.
   * @param from Message ID to start loading from.
   * @param emitStatus Display loading indicator in chat widnow.
   * @param emitMessages Update chat window with loaded messages.
   */
  loadNewerMessages (chatId: string, from: string, emitStatus = true, emitMessages = true) {
    return this.loadMessages({ chatId, type: FetchingType.NEW, from, emitStatus, emitMessages })
  }

  /**
   * Loads messages around a given message ID in a given chat.
   * @param chatId Chat ID to load messages in.
   * @param from Message ID to start loading from.
   * @param emitStatus Display loading indicator in chat widnow.
   * @param emitMessages Update chat window with loaded messages.
   */
  loadAroundMessages (chatId: string, from: string, emitStatus = true, emitMessages = true) {
    return this.loadMessages({ chatId, type: FetchingType.AROUND, from, emitStatus, emitMessages })
  }

  async loadMessageExact (chatId: string, messageId: string): Promise<TADA.Message | null> {
    const changeLoadingStatus = (status: boolean) => { ChatEventBus.$emit(Events.SET_CHAT_LOADING_STATUS, { chatId, status }) }

    changeLoadingStatus(true)
    const newerMessages = await this.loadNewerMessages(chatId, messageId, false)
    if (!newerMessages) {
      changeLoadingStatus(false)
      throw new Error(`[Messages.loadMessageExact] Error while loading newer messages from ${messageId}. Probably network error occured in [Messages.loadNewerMessages]`)
    }

    // Если это условие выполняется, то, скорее всего, искомое сообщение в чате первое
    // Просто загружаем список последних и берем самое последнее из этого списка
    if (newerMessages.orderIds.length === 0) {
      const lastMessages = await this.loadLastMessages(chatId, false)
      if (!lastMessages) {
        changeLoadingStatus(false)
        throw new Error('[Messages.loadMessageExact] Error while loading last messages. Probably network error occured in [Messages.loadLastMessages]')
      }
      changeLoadingStatus(false) // should be here, right?
      const { data, orderIds } = lastMessages
      return data[orderIds[orderIds.length - 1]]
    }

    // Берем самое первое сообщения из списка загруженных после искомого
    // Значит сообщение над ним должно быть нашим искомым
    const firstMessageIdFromNewer = newerMessages.orderIds[0]
    const olderMessages = await this.loadOlderMessages(chatId, messageId, false, true, true)
    if (!olderMessages) {
      changeLoadingStatus(false)
      throw new Error(`[Messages.loadMessageExact] Error while loading older messages from ${firstMessageIdFromNewer}. Probably network error occured in [Messages.loadOlderMessages]`)
    }

    changeLoadingStatus(false)

    const exactMessage = olderMessages.data[messageId]
    if (!exactMessage) return null

    return exactMessage
  }

  async loadMessagesAround (options: {
    chatId: string;
    messageId: string;
    callback: (messages: OrderedMessages) => any;
    isLast: boolean;
  }): Promise<void> {
    const { chatId, messageId, callback } = options

    const { orderIds, data } = this.sortMessages(chatId)
    const index = orderIds.indexOf(messageId)

    const cap = Consts.MESSAGES_CAP
    let numOlderToLoad = cap
    let numNewerToLoad = cap

    // If the current message is not found in the batch of messages already
    // uploaded to the chat, then we will clear the chat, otherwise there will be
    // a gap between the messages that have already been downloaded and the messages
    // that have just been downloaded. Messages just drop out.
    if (index === -1) this.clearChatStore(chatId)

    // use callback on already loaded messages
    // weird flex, but okay
    if (callback && index >= 0) {
      numOlderToLoad -= index
      numNewerToLoad += index
      const start = Math.max(0, -numOlderToLoad)
      const end = Math.min(orderIds.length, orderIds.length + numNewerToLoad)
      const cappedOrderIds = orderIds.slice(start, end)
      callback({ orderIds: cappedOrderIds, data })
    }

    const loadOlder = numOlderToLoad > 0
    const loadNewer = numNewerToLoad > 0

    if (loadNewer && loadOlder) {
      ChatEventBus.$emit(Events.SET_CHAT_LOADING_STATUS, { chatId, status: true })
      await this.loadAroundMessages(chatId, messageId, false)
      ChatEventBus.$emit(Events.SET_CHAT_LOADING_STATUS, { chatId, status: false })
    } else if (loadNewer) {
      await this.loadNewerMessages(chatId, messageId, !options.isLast)
    } else if (loadOlder) {
      await this.loadOlderMessages(chatId, messageId)
    }
  }

  removeUndeliveredMessage (chatId: string, messageId: string, emitRemoveEvent = true) {
    const chatMessages = this.getChatMessages(chatId)
    const { undeliveredData, undeliveredOrderIds } = chatMessages

    const undeliveredMessage = undeliveredData[messageId]
    if (!undeliveredMessage) return

    delete undeliveredData[messageId]

    const index = undeliveredOrderIds.indexOf(messageId)
    index >= 0 && undeliveredOrderIds.splice(index, 1)

    emitRemoveEvent && ChatEventBus.$emit(Events.REMOVE_UNDELIVERED_MESSAGE, { chatId, messageId })

    return undeliveredMessage
  }

  addUndeliveredMessage (chatId: string, message: TADA.Message) {
    const chatMessages = this.getChatMessages(chatId)

    const { data, undeliveredData, undeliveredOrderIds } = chatMessages
    const { messageId } = message

    if (data[messageId] || undeliveredData[messageId]) return

    undeliveredData[messageId] = message
    undeliveredOrderIds.push(messageId)

    ChatEventBus.$emit(Events.ADD_UNDELIVERED_MESSAGE, { chatId, message })

    this.throttledStoreUndeliveredMessages({ chatId })
  }

  getLastMessageFromStore (chatId: string, allowDeleted = true) {
    const { orderIds, data } = this.sortMessages(chatId)
    const count = orderIds.length
    if (count === 0) return

    if (allowDeleted) return data[orderIds[count - 1]]

    const msgId = orderIds
      .reverse()
      .find(id => data[id]?.content?.type !== TADA.MessageType.DELETED)
    return msgId ? data[msgId] : null
  }

  getLastMessage (chatId: string) {
    const { getters } = store

    const lastMessage = getters.chatLastMessage(chatId) || this.getLastMessageFromStore(chatId)
    const lastUndeliveredMessage = this.getLastUndeliveredMessage(chatId)

    if (!lastMessage) return lastUndeliveredMessage
    if (!lastUndeliveredMessage) return lastMessage

    const { created: a } = lastMessage
    const { created: b } = lastUndeliveredMessage

    return a > b ? lastUndeliveredMessage : lastMessage
  }

  getLastUndeliveredMessage (chatId: string) {
    if (!this.isChatMessagesExist(chatId)) return

    const chatMessages = this.getChatMessages(chatId)
    const { undeliveredData: data, undeliveredOrderIds: orderIds } = chatMessages

    if (orderIds.length === 0) return
    if (orderIds.length === 1) return data[orderIds[0]]

    let searchIndex = 0
    let lastDate = ''
    const searchCallback = (messageId: string, index: number) => {
      const { created } = data[messageId]
      if (created > lastDate) {
        searchIndex = index
        lastDate = created
      }
    }
    orderIds.forEach(searchCallback)
    return data[orderIds[searchIndex]]
  }

  sendAllUndeliveredMessages () {
    Object.keys(this.stores).forEach(chatId => {
      const { undeliveredOrderIds, undeliveredData } = this.stores[chatId]
      undeliveredOrderIds.forEach((messageId: string) => {
        const message = undeliveredData[messageId]
        message && store.dispatch(actionTypes.SOCKET_SEND_MESSAGE, { chatId, message })
      })
    })
  }

  handleSendingMessageError (messageId: string, chatId?: string) {
    const { getters } = store

    chatId = chatId || getters.currentChat as string
    const message = this.getMessage(chatId, messageId)
    if (!message) return

    message.state = TADA.MessageState.SENDING_ERROR
    ChatEventBus.$emit(Events.SENDING_MESSAGE_ERROR, { chatId, message })

    const lastMessage = getters.chatLastMessage(chatId)
    lastMessage && messageId === lastMessage.messageId && Vue.set(lastMessage, 'state', message.state)
  }

  addConfirmedMessage (data: string) {
    const index = data.indexOf('_')
    if (index < 0) return

    const messageId = data.substr(0, index)
    const chatId = data.substr(index + 1, data.length)

    const undeliveredMessage = this.removeUndeliveredMessage(chatId, messageId, false)
    if (!undeliveredMessage) return

    const message = Object.assign(undeliveredMessage, {
      confirmed: true,
      important: false,
      state: TADA.MessageState.NORMAL,
    })

    const messages = {
      data: { [messageId]: message },
      orderIds: [messageId],
    }

    this.addMessage({ chatId, messages, delayed: true, confirmed: true })

    this.throttledStoreUndeliveredMessages({ chatId, clear: true })
  }

  addMessage (params: any) {
    const { messages, delayed, confirmed } = params
    const { orderIds, data } = messages

    const messageId = orderIds[0]
    const message = data[messageId]
    const { chatId } = message

    this._addMessages(chatId, messages)
    ChatEventBus.$emit(Events.ADD_SINGLE_MESSAGE, { chatId, messages, delayed, confirmed })

    // if (delayed || confirmed) return
    // now handled by server.message.push only
    // Notifier.push(message)
  }

  removeMessage (chatId: string, messageId: string, force?: boolean): boolean {
    if (!this.isChatMessagesExist(chatId)) return false

    if (!force) {
      const { getters } = store
      const lastMessage = getters.chatLastMessage(chatId) as (TADA.Message | null)
      if (lastMessage && lastMessage.messageId === messageId) return false
    }

    const chatMessages = this.getChatMessages(chatId)
    const { data, orderIds } = chatMessages

    const exists = data[messageId]
    if (!exists) return false

    const index = orderIds.indexOf(messageId)
    index >= 0 && orderIds.splice(index, 1)

    delete data[messageId]

    return true
  }

  markMessageAsReceived (chatId: string, messageId: string) {
    const message = this.getMessage(chatId, messageId)

    const { getters } = store
    if (!message || message.received || getters.getUserId !== message.sender) return

    message.received = true
    ChatEventBus.$emit(Events.MARK_MESSAGE_AS_RECEIVED, { chatId, messageId })

    const lastMessage = getters.chatLastMessage(chatId)
    messageId === lastMessage.messageId && Vue.set(lastMessage, 'received', true)

    const previousMessageId = message.prev
    if (!previousMessageId) return

    this.markMessageAsReceived(chatId, previousMessageId)
  }

  isMessageLastInChat (chatId: string, messageId: string) {
    const { getters } = store
    const lastMessage = getters.chatLastMessage(chatId)
    return lastMessage && lastMessage.messageId === messageId
  }

  _addMessages (
    chatId: string,
    {
      data: incomingData,
      orderIds: incomingOrderIds,
    }: {
      data: { [messageId: string]: TADA.Message };
      orderIds: Array<string>;
    },
  ) {
    const chatMessages = this.getChatMessages(chatId)
    const { data, orderIds } = chatMessages

    const currentMaxCreated = this.getMessage(
      chatId,
      orderIds[orderIds.length - 1],
    )?.created

    const currentMinCreated = this.getMessage(chatId, orderIds[0])?.created

    const { getters, dispatch } = store

    incomingOrderIds.forEach(messageId => {
      const incomingMessage = incomingData[messageId]
      const existingMessage = data[messageId]

      // if there is no min-max created -> it can't be old
      const isOlder: boolean = currentMinCreated
        ? compareDates(incomingMessage.created, currentMinCreated) === -1
        : false

      // if there is no min-max created -> it's gotta be newer!
      const isNewer: boolean = currentMaxCreated
        ? compareDates(incomingMessage.created, currentMaxCreated) === 1
        : true

      if (existingMessage) {
        // Если сообщение уже помечено как полученное (received = true),
        // то оно таким и должно оставаться
        const { received, confirmed } = existingMessage
        received && (incomingMessage.received = received)

        if (confirmed) {
          delete existingMessage.confirmed
        } else {
          // Удаляем сообщение из хранилища
          const { content } = incomingMessage
          if (content.type === TADA.MessageType.DELETED) {
            this.removeMessage(chatId, messageId, true)
          }
        }
      } else if (isOlder) {
        orderIds.unshift(messageId)
      } else if (isNewer) {
        orderIds.push(messageId)
      } else {
        const insertAt = getOrderedMessageIndex(incomingMessage, data, orderIds)
        orderIds.splice(insertAt, 0, messageId)
      }

      if (incomingMessage.prev) {
        const { received, sender, prev } = incomingMessage
        const checkPreviousMessage = received || sender !== getters.getUserId
        checkPreviousMessage && this.markMessageAsReceived(chatId, prev)
      }

      data[messageId] = incomingMessage
    })

    const lastMessageId = incomingOrderIds[incomingOrderIds.length - 1]
    const lastMessage = incomingData[lastMessageId]
    if (!lastMessage) return

    const isOlder: boolean = currentMinCreated
      ? compareDates(lastMessage.created, currentMinCreated) === -1
      : false
    if (!lastMessage.isLast || isOlder) return

    dispatch(
      actionTypes.CHANGE_CHAT_LAST_MESSAGE,
      { chatId, lastMessage },
    )
  }

  toggleMessageImportance (chatId: string, messageId: string) {
    const message = this.getMessage(chatId, messageId)
    if (!message) return

    message.important = !message.important

    ChatEventBus.$emit(Events.SET_MESSAGE_IMPORTANCE, { chatId, message })

    const payload = { chatId, messageId, important: message.important }
    store.dispatch(actionTypes.CHAT_MARK_MESSAGE_IMPORTANT, payload)
  }

  /**
   * Get all chat uploads grouped by messageId that this each upload belongs to.
   * MessageId is the ID of a 'top-level' message. For uploads found in a linked message
   * it will return messageId of a message that it was linked to.
   * @param chatId
   * @param uploadsType type uploads
   */
  getUploadsByChatIdAndContentType (chatId: string, uploadsType: string): { [key: string]: TADA.Upload[] } {
    const { orderIds, data } = this.sortMessages(chatId)
    const uploads: { [key: string]: TADA.Upload[] } = {}
    // filter uploads by uploads type
    const filterPredicate = (upload: TADA.Upload): boolean => upload.contentType.includes(uploadsType)
    // we go through the sorted array of message keys
    orderIds.forEach(msgId => {
      // saving all uploads from the message
      uploads[msgId] = [...data[msgId].uploads.filter(filterPredicate)]
      // if message is have linked messages, then we take its uploads
      if (data[msgId].linkedMessages.length) {
        data[msgId].linkedMessages.forEach(linkedMsg => {
          uploads[msgId].push(...linkedMsg.uploads.filter(filterPredicate))
        })
      }
    })
    return uploads
  }

  /**
   * Checks if there is an image in the given message
   * @param message
   */
  isImageInMessage (message: TADA.Message | null): boolean {
    /**
     * Check image in message
     * @param msg
     */
    const checkImage = (msg: TADA.Message | null): boolean => {
      return !!msg?.uploads.some(upload => {
        return upload.contentType.includes('image')
      })
    }
    // check image in message uploads
    if (checkImage(message)) {
      return true
    }
    // check image im message linked messages
    return !!message?.linkedMessages?.some(checkImage)
  }
}

const messages = new Messages()

export default messages
