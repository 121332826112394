import { Getters as BaseGetters } from 'vuex-smart-module'
import State from '@/store/modules/contacts/state'
import { JID, Contact } from '@tada-team/tdproto-ts'
import store from '@/store'
import { getChatPlaceholderName, getIconUrl } from '@/utils'
import i18n from '@/i18n'
import { defaultLogger } from '@/loggers'
import { ContactOnlineType } from './types'

export default class Getters extends BaseGetters<State> {
  /**
   * Get contact data by contact id
   * @param contactId
   */
  contact (contactId: JID): Contact | undefined {
    return this.state.data[contactId]
  }

  /**
   * Get default contact by (systembot)
   */
  get defaultContact (): JID | undefined {
    return this.getters.teamMembers.find(item => item.botname === 'systembot')?.jid
  }

  get supportChatId (): JID {
    const chat = this.getters.teamMembers.find(c => c.botname === 'feedbackbot')
    if (!chat) throw new Error('Could not find support chat in team members')
    return chat.jid
  }

  /**
   * Get all contact data
   */
  get teamMembers (): Contact[] {
    return Object.values(this.state.data)
  }

  /**
   * Get all team admins
   */
  get teamAdmins (): Contact[] {
    return this.getters.teamMembers.filter(c => (c.status === 'owner' || c.status === 'admin'))
  }

  /**
   * Get contact list without current user
   */
  get contactList (): Contact[] {
    return this.getters.teamMembers.filter(contact => contact.jid !== store.getters.getUserId)
  }

  /**
   * Get contact icon object
   * @param contactId
   * @param large
   */
  contactIcon (contactId: JID, large?: boolean): string | undefined {
    const contact = this.getters.contact(contactId)
    if (!contact) return
    const { icons } = contact
    return getIconUrl(icons, large)
  }

  /**
   * Get the type of device from which the user is online
   * @param contactId
   */
  contactOnlineType (contactId: JID): ContactOnlineType {
    const onlineContact = this.state.onlineIds.find(o => o.jid === contactId)
    if (!onlineContact) return 'none'
    return onlineContact.mobile ? 'mobile' : 'web'
  }

  /**
   * Get user status online or not
   * @param contactId
   */
  contactIsAfk (contactId: JID): boolean {
    return this.state.afkJids.includes(contactId)
  }

  /**
   * Get user display name
   * @param contactId
   */
  contactDisplayName (contactId: JID | null): string {
    const contact = contactId ? this.getters.contact(contactId) : undefined
    return contact ? (contact.displayName || getChatPlaceholderName({ type: 'direct', jid: '' }))
      : i18n.t('profiles.unknownUser').toString()
  }

  /**
   * Get short user name
   * @param contactId
   */
  contactDisplayNameShort (contactId: JID): string {
    const contact = this.getters.contact(contactId)

    defaultLogger.debug('[contactDisplayNameShort] contact for preview: ', contact)

    return contact
      ? (contact.shortName || contact.displayName || getChatPlaceholderName({ type: 'direct', jid: '' }))
      : i18n.t('profiles.unknownUser').toString()
  }

  /**
   * Get user profile role
   * @param contactId
   */
  contactRole (contactId: JID): string {
    const contact = this.getters.contact(contactId)
    if (!contact) return ''

    const { role } = contact
    return role
  }
}
