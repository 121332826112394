import State from '@/store/modules/billing/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import { PersonalAccountBilling } from '@tada-team/tdproto-ts'

export default class Mutations extends BaseMutations<State> {
  setBillingAccount (account: PersonalAccountBilling | undefined) {
    this.state.account = account
  }

  setBillingTeamAccount (teamAccount: PersonalAccountBilling | undefined) {
    this.state.teamAccount = teamAccount
  }
}
