import { Actions as BaseActions } from 'vuex-smart-module'
import Getters from '@/store/modules/contacts/getters'
import State from '@/store/modules/contacts/state'
import Mutations from '@/store/modules/contacts/mutations'
import { OnlineContact, JID, ContactJSON, Contact } from '@tada-team/tdproto-ts'
import api from '@/api/v3'
import { contacts } from 'td-api'
import * as Sentry from '@sentry/browser'
import store, {
  dataImportsStore,
  sectionsStore,
  teamsStore,
  uiStore,
  groupsStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import ThemeManager from '@/themes'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  /**
   * Reset contacts store
   */
  resetStore (): void {
    this.state.data = {}
    this.state.imports = []
    this.state.onlineIds = []
    this.state.afkJids = []
  }

  /**
   * Updating information that the users is online or not
   * @param contacts OnlineContact object list
   */
  socketOnContactOnline (contacts: Array<OnlineContact>): void {
    this.mutations.addOnline(contacts)
  }

  /**
   * Setting all contacts that are in the team
   */
  async setContacts (): Promise<void> {
    let response: Array<Contact>
    try {
      response = await api.contacts.getAll()
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'set contacts')
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    const contacts = Object.fromEntries(
      response.map(contact => [contact.jid, contact]),
    )
    this.mutations.setContacts(contacts)

    sectionsStore.actions.checkIncorrectSection()
  }

  /**
   * Add new contact to the team
   * @param contact
   */
  async addContact (contact: Contact): Promise<Contact> {
    let addedContact: Contact

    const currentTeamId = teamsStore.state.currentTeamId
    if (!currentTeamId) throw new Error('The ID of the current teams is null')
    try {
      addedContact = await api.contacts.add(currentTeamId, contact)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'add contact')
        scope.setContext('params', { contact })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.addContact(addedContact)
    return addedContact
  }

  async loadContacts (contactsJID: string[]): Promise<void> {
    const jids = contactsJID.filter(jid => !(jid in this.state.data)).join(',')
    if (!jids) return
    let loadedContacts
    try {
      loadedContacts = await contacts.getPaginatedContacts(teamsStore.getters.currentTeam.uid, {
        limit: 0,
        offset: 0,
        id: jids,
      })
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'load contacts')
        scope.setContext('params', { jids })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.addContacts(loadedContacts.objects)
  }

  /**
   * Add new contact list to the team
   * @param contacts
   */
  // eslint-disable-next-line camelcase
  async addContacts (contacts: { user_uid: string }[]): Promise<Contact[]> {
    let addedContacts: Array<Contact> = []
    const currentTeamId = teamsStore.state.currentTeamId
    if (!currentTeamId) throw new Error('The ID of the current teams is null')
    try {
      addedContacts = await api.contacts.addMany(currentTeamId, contacts)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'add contacts')
        // scope.setContext('params', contacts)
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    addedContacts.forEach(this.mutations.addContact)
    return addedContacts
  }

  /**
   * Updating contact information
   * @param contact
   */
  updateContact (contact: Contact): void {
    const contactExists = !!this.state.data[contact.jid]
    contactExists
      ? this.mutations.updateContact(contact)
      : this.mutations.addContact(contact)
  }

  /**
   * Deleting a contact from all group with the subsequent update of all the necessary states of the store
   * @param contactId
   */
  deleteContact (contactId: JID): void {
    const instance = uiStore.getters.currentRightBarInstance
    if ((instance === 'group-profile' || instance === 'contact-profile') &&
      uiStore.getters.currentRightBarPayload === contactId) {
      uiStore.actions.switchRightBarInstance()
    }
    store.dispatch(actionTypes.DELETE_CHAT, { chatId: contactId, type: 'direct' })

    const groups = groupsStore.getters.list
    groups && groups.forEach(group => {
      const { jid: groupId } = group
      groupsStore.actions.deleteMember({ groupId, memberId: contactId })
    })

    this.mutations.deleteContact(contactId)
  }

  /**
   * Updating all contacts according to the new theme
   */
  themeChanged (): void {
    const icon = ThemeManager.getDefaultAvatar('contact')
    Object.keys(this.state.data).forEach(jid => {
      const contact = this.state.data[jid]

      const currentIcon = this.getters.contactIcon(jid)
      const update = ThemeManager.needToSetAvatar(currentIcon)
      update && this.mutations.updateContact(Object.assign(contact, { icon }))
    })
  }

  /**
   * Gets a single contact from server, saves it locally.
   * Takes contact jid as a parameter.
   * @returns parsed loaded contact or rejected promise.
   */
  async loadContact (contactId: JID): Promise<Contact> {
    let contact: Contact
    if (contactId in this.state.data) return this.state.data[contactId]
    try {
      contact = await api.contacts.get(contactId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'load contact')
        scope.setContext('contactId', { contactId })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.addContact(contact)
    return contact
  }

  /**
   * Editing contact data
   * @param payload
   */
  async editContact (payload: { jid: JID, params: Partial<ContactJSON> }): Promise<void> {
    const { jid, params } = payload
    let contact: Contact
    try {
      contact = await api.contacts.edit(jid, params)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'edit contact')
        scope.setContext('contactId', { contactId: jid, params })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    this.mutations.updateContact(contact)
  }

  /**
   * Start importing contact list
   * @param contacts
   */
  async importContacts (contacts: Contact[]): Promise<string> {
    let actionObj
    try {
      actionObj = await api.contacts.startImport(contacts)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('team', 'start import')
        scope.setContext('contacts', { contacts })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }
    const uid = actionObj.action
    this.mutations.addImport(uid)
    dataImportsStore.mutations.addImport(uid)
    return uid
  }
}
