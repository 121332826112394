import ws from '@/ws'
import { CallType, ClientCallOfferParams, ClientCallSdpParams, ClientCallTrickleParams, JID, JSEP } from '@tada-team/tdproto-ts'

export class Signalling {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    private callJid: JID,
    private type: CallType,
  ) {}

  public readonly answer = (p: { sdp: string; uid: string; }): void => {
    const jsep = new JSEP(p.sdp, 'answer')
    const packet = new ClientCallSdpParams(this.callJid, jsep, p.uid)
    ws.send('client.call.sdp', packet.toJSON())
    //
  }

  public readonly offer = (sdp: string, muted?: boolean): void => {
    const packet = new ClientCallOfferParams(
      this.callJid,
      !!muted,
      sdp,
      true,
      this.type,
      !muted,
      false,
    )

    ws.send('client.call.offer', packet.toJSON())
  }

  public readonly trickle = (
    candidate: string,
    sdpMid?: string,
    sdpMLineIndex?: number,
  ): void => {
    const packet = new ClientCallTrickleParams(
      candidate,
      this.callJid,
      sdpMid,
      sdpMLineIndex,
    )
    ws.send('client.call.trickle', packet.toJSON())
  }
}
