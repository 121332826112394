import api from '@/api/v3'
import { ChatEventBus, Events as ChatEvents } from '@/components/Chat/ChatEventBus'
import store, { uiStore } from '@/store'
import State from '@/store/modules/threads/state'
import Getters from '@/store/modules/threads/getters'
import Mutations from '@/store/modules/threads/mutations'
import * as Sentry from '@sentry/browser'
import {
  Chat,
  JID,
  ChatType,
} from '@tada-team/tdproto-ts'
import { Actions as BaseActions } from 'vuex-smart-module'

export default class Actions extends BaseActions<
  State,
  Getters,
  Mutations,
  Actions
> {
  resetStore (): void {
    Object.assign(this.state, {})
  }

  /**
   * Gets a single group from server, saves it locally.
   * @param {string} jid Group jid to load.
   * @returns parsed loaded task or rejected promise.
   */
  async loadThread (jid: JID): Promise<Chat | null> { // LOAD_GROUP
    let result = null
    try {
      result = await api.chats.get(jid)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('threads', 'get')
        scope.setContext('chat id', { jid })
        Sentry.captureException(e)
      })
      return Promise.reject(e)
    }

    const { chat, task, group, thread } = result
    if (!thread) return null
    this.mutations.update(thread)
    return thread
  }

  update (group: Chat): void {
    this.mutations.update(group)
  }

  setup (groups: Record<JID, Chat>): void {
    this.mutations.setData(groups)
  }

  // /**
  //  * Makes request to delete member from group and remove it from store if success
  //  * @param {string} groupId group jid
  //  * @param {string} memberId member jid
  //  **/
  async deleteMember ({ groupId, memberId }: { groupId: JID, memberId: JID }): Promise<void> {
    try {
      await api.groups.deleteMember(groupId, memberId)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('group', 'delete member')
        scope.setContext('params', { groupId, memberId })
        Sentry.captureException(e)
      })
      return
    }
    this.mutations.deleteMember({ groupId, memberId })
  }

  // was DELETE_CHAT, removing group from store
  remove ({ groupId }: { groupId: JID, type: ChatType }): void {
    const instance = store.getters.currentRightBarInstance
    if (instance === 'group-profile' && store.getters.currentRightBarPayload === groupId) {
      uiStore.actions.switchRightBarInstance()
    }
    this.mutations.delete(groupId)
  }

  async pinMessage ({ groupId, messageId, unpin = false }: { groupId: JID, messageId: JID, unpin: boolean}): Promise<void> {
    // if group already had a pinned message
    const previouslyPinned = this.state.data[groupId]?.pinnedMessage

    const payload = { pinned_message: unpin ? null : messageId }
    await this.actions.edit({ groupId, payload })

    previouslyPinned && ChatEventBus.$emit(
      ChatEvents.MESSAGE_PINNED,
      { chatId: groupId, messageId: previouslyPinned.messageId },
    )
    ChatEventBus.$emit(
      ChatEvents.MESSAGE_PINNED,
      { chatId: groupId, messageId },
    )
  }

  // ToDo: remove any
  async edit ({ groupId, payload }: { groupId: JID, payload: any }): Promise<Chat> {
    const updatedGroup = await api.groups.edit(groupId, payload)
    this.actions.update(updatedGroup)
    return updatedGroup
  }
}
