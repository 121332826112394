import Vue from 'vue'

import ScrollBar from '@/directives/scrollbar'
import Tooltip from '@/directives/tooltip'
// import PhoneMaskedInput from './phone-input-mask'
import FlexResize from '@/directives/flex-resize'

const attachDirectives = () => {
  /**
   * TODOs:
   * 1) Context
   * 2) Visibility hidden (clientHeight >= scrollHeight)
   * 3) CSS Themes (styles for track, thumb, parent hover/hover/scroll states)
   * 4) Min thumb height
   * 5) Attach bar to directive target itself, not to his parent
   * 6) Parent must have relative position property - its not ok
   */
  Vue.directive('scroll-bar', ScrollBar)
  Vue.directive('flex-resize', FlexResize)
  Vue.directive('tooltip', Tooltip)
}

export default attachDirectives
