import { Meeting } from '@tada-team/tdproto-ts'
import { MeetingDialogTemplate } from '@/store/modules/meetings/types'

export default class State {
  isMeetingDialogActive = false
  selectedMeetingUUID: string | null = null
  editingMeeting: Meeting | null = null
  meetingDialogTemplate: MeetingDialogTemplate = {}
  count = 0
  dates: string[] = []
  isMeetingsLoading = false
  isMeetingsCountLoading = false
  isAllMeetings = false

  // List of meetings to display on the calendar grid
  meetings: Meeting[] = []

  // List of meetings instances to display on the right column
  meetingsInstances: Meeting[] = []
  instancesOffset = 0
  instancesCount = 0
  // meetings that have ever loaded
  loadedMeetings: Record<string, Meeting> = {}
}
