import i18n from '@/i18n'
import store from '@/store'
import Messages from '@/store/messages'
import ThemeManager from '@/themes'
import { getChatType } from '@/utils'
import {
  Chat,
  ChatType,
  IconData,
  MessageReaction,
  MessageReactionDetail,
  SingleIcon,
} from '@tada-team/tdproto-ts'
import { uid } from 'quasar'

export function getDateWithDifference (): Date
export function getDateWithDifference (type: 'ts'): number
export function getDateWithDifference (type: 'iso'): string
export function getDateWithDifference (type?: 'ts' | 'iso'): Date | number | string {
  const timestamp: number = Date.now() + store.getters.serverTimeDifference
  if (type === 'ts') return timestamp

  const date = new Date(timestamp)
  if (type === 'iso') return date.toISOString()

  return date
}

export const createReaction = (
  name: string,
  sender?: string,
) => MessageReactionDetail.fromJSON({
  name,
  created: getDateWithDifference('iso'),
  sender: sender || store.getters.getUserId,
})

export const createMessageReaction = (
  name: string,
  sender?: string,
) => MessageReaction.fromJSON({
  counter: 1,
  details: [createReaction(name, sender)],
  name: name,
})

interface MessageBase {
  sender: string;
  recipient: string;
  text: string;
  linkedMessages?: Array<TADA.Message>;
  replyTo?: TADA.Message | null;
  uploads?: Array<TADA.Upload>;
}

export const createMessage = (b: MessageBase): TADA.Message => ({
  sender: b.sender,
  recipient: b.recipient,
  chatId: b.recipient,
  chatType: getChatType(b.recipient),
  isFirst: false,
  isLast: true,
  links: [],
  replyTo: b.replyTo ?? null,
  read: true,
  created: getDateWithDifference('iso'),
  edited: null,
  editableUntil: null,
  content: {
    text: b.text,
    type: TADA.MessageType.PLAIN,
  },
  pushText: '',
  reactions: [],
  linkedMessages: b.linkedMessages ?? [],
  messageId: uid(),
  state: TADA.MessageState.UNDELIVERED,
  received: false,
  important: false,
  notice: false,
  silently: false,
  uploads: b.uploads ?? [],
  nopreview: undefined,
  _debug: null,
  isStandalone: false,
  hasPreviews: false,
  markup: [],
})

const generateChat = ({
  canSendMessage = true,
  cantSendMessageReason = null,
  chatId,
  chatType,
  numUnread = 0,
}: {
  canSendMessage: boolean;
  cantSendMessageReason: string | null;
  chatId: string;
  chatType?: ChatType;
  numUnread: number;
}): TADA.Chat => {
  chatType = chatType || getChatType(chatId)

  return {
    audioComposingIds: [],
    baseGentime: 0,
    canDeleteAnyMessage: false,
    canSendMessage,
    canSetImportantAnyMessage: false,
    cantSendMessageReason,
    chatId,
    chatType,
    composingIds: [],
    created: getDateWithDifference('iso'),
    draftNum: -1,
    gentime: 0,
    isGroup: chatType === 'group',
    lastActivity: getDateWithDifference('iso'),
    lastMessage: Messages.getLastMessage(chatId),
    lastReadMessageId: null,
    numUnread,
    numUnreadNotices: 0,
    pinned: false,
    pinnedSortOrdering: null,
  }
}

export const generateTasksColorsRule = (
  {
    priority = 0,
    description = null,
  }: Partial<TADA.TasksColorsRule> = {},
): TADA.TasksColorsRule => ({
  colorIndex: 0,
  description,
  priority,
  section: null,
  sectionEnabled: false,
  tags: [],
  tagsEnabled: false,
  taskComplexity: null,
  taskImportance: null,
  taskImportanceEnabled: false,
  taskStatus: null,
  taskUrgency: null,
  taskUrgencyEnabled: false,
  uid: uid(),
})

export default {
  generateDummyGroup: (options?: { name: string }, closed = false): Chat => {
    const displayName = options?.name || closed
      ? i18n.t('profiles.privateGroup').toString()
      : i18n.t('profiles.unknownGroup').toString()
    const icon = closed
      ? ThemeManager.getDefaultAvatar('private-group')
      : ThemeManager.getDefaultAvatar('group')

    return Chat.fromJSON({
      can_delete_any_message: false,
      can_send_message: false,
      can_add_member: false,
      can_call: false,
      can_change_member_status: false,
      can_change_settings: false,
      can_delete: false,
      can_remove_member: false,
      can_set_important_any_message: false,
      changeable_fields: [],
      public: false,
      counters_enabled: false,
      default_for_all: false,
      description: '',
      feed: true,
      last_activity: getDateWithDifference('iso'),
      notifications_enabled: false,
      num_members: 0,
      pinned: false,
      readonly_for_members: false,
      status: 'member',
      chat_type: 'group',
      created: getDateWithDifference('iso'),
      display_name: displayName,
      gentime: 0,
      icons: IconData.fromJSON({
        stub: icon,
        lg: SingleIcon.fromJSON({ width: 0, height: 0, url: icon }),
        sm: SingleIcon.fromJSON({ width: 0, height: 0, url: icon }),
      }),
      jid: 'dummy',
    })
  },
  generateDummyContact: (options?: { name: string }): TADA.Contact => {
    const result: TADA.Contact = {
      canAddToGroup: false,
      canAddToTeam: false,
      canCall: false,
      canCreateGroup: false,
      canCreateTask: false,
      canImportTasks: false,
      canDelete: false,
      canDeleteAnyMessage: false,
      canJoinPublicGroups: false,
      canJoinPublicTasks: false,
      canManageColorRules: false,
      canManageIntegrations: false,
      canManageSections: false,
      canSendMessage: false,
      cantSendMessageReason: null,
      canImportChats: false,
      changeableFields: [],
      chatType: 'direct',
      customFields: null,
      displayName: (options && options.name) || i18n.t('profiles.unknownUser').toString(),
      email: '',
      familyName: '',
      givenName: '',
      icon: ThemeManager.getDefaultAvatar('contact'),
      icons: IconData.fromJSON({
        lg: { width: 0, height: 0, url: ThemeManager.getDefaultAvatar('contact') },
        sm: { width: 0, height: 0, url: ThemeManager.getDefaultAvatar('contact') },
        stub: ThemeManager.getDefaultAvatar('contact'),
      }),
      isArchive: true,
      isBot: true,
      isGroup: false,
      jid: 'dummy',
      largeIcon: '',
      lastActivity: getDateWithDifference('iso'),
      patronymic: '',
      phone: '',
      role: '',
      section: '',
      sections: [],
      shortName: '',
      status: 'member',
    }
    result.largeIcon = result.icon

    return result
  },
  generateChat,
  generateChatFor: ({ entity, numUnread = 0 }: { entity: TADA.Entity; numUnread: number }): TADA.Chat | null => {
    if (!entity) return null

    const { jid: chatId, canSendMessage, cantSendMessageReason } = entity
    const chatType = getChatType(chatId)

    return generateChat({
      canSendMessage: canSendMessage ?? false,
      cantSendMessageReason,
      chatId,
      chatType,
      numUnread,
    })
  },
}
