import { getPrefix, _fetch } from './index'
import { DataAdapter } from './DataAdapter'

export const twoFactorAuth = {
  // prefix for 2fa settings
  getBasePrefix: () => getPrefix(4) + '/auth/settings/',

  // prefix for auth usually
  getPrefix: () => twoFactorAuth.getBasePrefix() + '2fa/',

  /**
   * Auth by cookie with 2fa
   * @param params  - token and password for 2fa auth
   * @see [index.ts:cookieAuth] as example without 2fa
   * @returns as in see cookieAuth
   */
  setCookie: (params: { token: string; password: string }) =>
    _fetch(getPrefix(4) + '/auth/2fa/set-cookie/', params, 'POST', 'JSON'),

  /**
   * Create password for 2fa
   * @param params - pass, repeating and hint can be nullable (but prefer empty string)
   * @returns new TFA status
   */
  setPassword: (params: {
    // eslint-disable-next-line camelcase
    new_password: string;
    // eslint-disable-next-line camelcase
    new_password_repeat: string;
    hint: string;
  }) =>
    _fetch(twoFactorAuth.getPrefix(), params, 'POST', 'JSON').then(
      DataAdapter.twoFactorAuthInfo,
    ),

  /**
   * Get status of 2fa on it's account
   * @returns current TFA status info
   */
  get: () =>
    _fetch(twoFactorAuth.getPrefix(), null, 'GET').then(
      DataAdapter.twoFactorAuthInfo,
    ),

  /**
   * Change password to another by old password
   * @param params - password it is OLD password
   * @see setPassword
   * @returns new TFA status
   */
  changePassword: (params: {
    password: string;
    // eslint-disable-next-line camelcase
    new_password: string;
    // eslint-disable-next-line camelcase
    new_password_repeat: string;
    hint: string;
  }) =>
    _fetch(twoFactorAuth.getPrefix(), params, 'PUT', 'JSON').then(
      DataAdapter.twoFactorAuthInfo,
    ),

  /**
   * Remove password by current password
   * @param pass
   * @returns new TFA status
   */
  deletePassword: (password: string) =>
    _fetch(twoFactorAuth.getPrefix(), { password }, 'DELETE', 'JSON').then(
      DataAdapter.twoFactorAuthInfo,
    ),

  /**
   * Check that password is right for account
   * @param password
   * @returns current TFA status
   */
  checkPass: (password: string) =>
    _fetch(
      twoFactorAuth.getPrefix() + 'pass-check',
      { password },
      'POST',
      'JSON',
    ).then(DataAdapter.twoFactorAuthInfo),

  /**
   * Set new email (or change old)
   * @param params current pass and new email
   * @returns info about code sended on email and new TFA status
   */
  setMail: (params: { password: string; email: string }) =>
    _fetch(
      twoFactorAuth.getPrefix() + 'mail-confirm',
      params,
      'POST',
      'JSON',
    ).then(DataAdapter.twoFactorAuthEmailConfirm),

  /**
   * Confirm new email created in setMail by code from email
   * @param params current pass, email from setMail, code from email
   * @see setMail
   * @returns new TFA status
   */
  confirmMail: (params: { password: string; email: string; code: string }) =>
    _fetch(
      twoFactorAuth.getPrefix() + 'mail-confirm',
      params,
      'PUT',
      'JSON',
    ).then(DataAdapter.twoFactorAuthInfo),

  /**
   * Decline mail (TFA without recover possibility), not need to confirm that
   * Also it can be used for delete current email
   * Its option instead setMail+confirmMail
   * @param password
   * @returns new TFA status
   */
  declineMail: (password: string) =>
    _fetch(
      twoFactorAuth.getPrefix() + 'decline',
      { password },
      'POST',
      'JSON',
    ).then(DataAdapter.twoFactorAuthInfo),

  /**
   * Ask for sending code to email for recover TFA pass from LOGIN SCREEN ONLY
   * @param token - it's given after auth by phone+SMS by backend
   * @returns info about code sended to email
   */
  sendDiscardCodeLogin: (token: string) =>
    _fetch(
      getPrefix(4) + '/auth/2fa/recovery-mail-send',
      { token },
      'POST',
      'JSON',
    ).then(DataAdapter.twoFactorAuthCode),

  /**
   * Discard TFA password by token+emailCode from LOGIN SCREEN ONLY
   * @see sendDiscardCodeLogin
   * @param code - sended by sendDiscardCodeLogin
   * @param token - given by auth phone+SMS
   * @returns success/error
   */
  forceDiscardByCodeLogin: (code: string, token: string) =>
    _fetch(
      getPrefix(4) + '/auth/2fa/recovery-mail-confirm',
      { code, token },
      'DELETE',
      'JSON',
    ),

  /**
   * Ask for sending code to email for recover TFA pass in account (not LOGIN screen)
   * @returns info about code sended to email
   */
  sendDiscardCode: () =>
    _fetch(
      twoFactorAuth.getPrefix() + 'mail-recovery-send',
      {},
      'POST',
      'JSON',
    ).then(DataAdapter.twoFactorAuthCode),

  /**
   * Discard TFA password by code from email in account
   * @see sendDiscardCode
   * @param code -given by email
   * @returns success/error
   */
  forceDiscardByCode: (code: string) =>
    _fetch(
      twoFactorAuth.getPrefix() + 'mail-recovery-confirm',
      { code },
      'DELETE',
      'JSON',
    ),
}
