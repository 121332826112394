import { Actions } from 'vuex-smart-module'
import { Integrations, Integration } from '@tada-team/tdproto-ts'
import * as Sentry from '@sentry/browser'
import api from '@/api/v3/index'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'

class ModuleActions extends Actions<
  State,
  Getters,
  Mutations,
  ModuleActions
> {
  /**
   * Loads integrations and kinds from server. Saves them to store.
   * @returns received and parsed data
   */
  async setup (): Promise<Integrations> {
    let response: Integrations

    try {
      response = await api.integrations.getAll()
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('integrations', 'setup')
        scope.setContext('error', e)
        Sentry.captureException('Failed to load integrations data.')
      })
      return Promise.reject(e)
    }

    this.mutations.setup(response)

    return response
  }

  /**
   * Makes a request to create new integration. Saves it to store.
   * @param integration Integration data to create integration with.
   * @returns created integration
   */
  async create (integration: Integration): Promise<Integration> {
    const payload = integration.toJSON()
    let response: Integration

    try {
      response = await api.integrations.create(payload)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('integrations', 'create')
        scope.setContext('integration', integration.toJSON() as Record<string, any>)
        scope.setContext('error', e)
        Sentry.captureException('Failed to create integration.')
      })
      return Promise.reject(e)
    }

    this.mutations.add(response)
    return response
  }

  /**
   * Makes a request to edit existing integration. Updates it in store.
   * @param integration Integration data to edit existing integration with.
   * @returns updated integration
   */
  async edit (
    payload: { integration: Integration; earlySave?: boolean },
  ): Promise<Integration> {
    const { integration, earlySave = false } = payload

    // create backup and save early if necessary
    let backup: Integration
    if (earlySave) {
      backup = Integration.fromJSON(integration.toJSON())
      this.mutations.edit(integration)
    }

    const requestPayload = integration.toJSON()
    let response: Integration
    try {
      // TODO: integration uid should always be there. Server?
      if (!integration.uid) throw new Error('Trying to edit integration without uid')
      response = await api.integrations.edit(integration.uid, requestPayload)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('integrations', 'edit')
        scope.setContext('integration', (integration.toJSON() as Record<string, any>))
        scope.setContext('error', e)
        Sentry.captureException('Failed to edit integration.')
      })

      /**
       * Revert backup if necessary.
       */
      earlySave && this.mutations.edit(backup!) // wow, TS, such smart much clever
      return Promise.reject(e)
    }

    this.mutations.edit(response)
    return response
  }

  /**
   * Makes a request to delete an existing integration. Deletes it from store.
   * @param uid Integration uid to delete.
   */
  async remove (uid: string): Promise<void> {
    try {
      await api.integrations.delete(uid)
    } catch (e) {
      // if integration is not found on server, just remove it from store
      if (e.status !== '404') {
        Sentry.withScope(scope => {
          scope.setLevel(Sentry.Severity.Error)
          scope.setTag('integrations', 'delete')
          scope.setContext('integration', this.getters.integrationsByUid[uid] as Record<string, any>)
          scope.setContext('error', e)
          Sentry.captureException('Failed to delete integration.')
        })
        return Promise.reject(e)
      }
    }

    this.mutations.remove(uid)
  }
}

export default ModuleActions
