import { Draft } from '@/store/modules/drafts/types'
import { captureException } from '@sentry/browser'
import { debounce } from 'quasar'
import api from '@/api/v3'

const LIFETIME = 1000 * 60 * 60 * 24 * 30
let lastHandledAt = 0

const getJobs = (namespace: string): string[] => {
  const raw = localStorage.getItem(namespace + '_jobs_')
  if (raw === null) return []
  return raw.split(';')
}

const setJobs = (namespace: string, ids: string[]): void => {
  const uniqueIds = ids.filter((id, index, all) => id.length && all.indexOf(id) === index)
  if (uniqueIds.length) {
    localStorage.setItem(namespace + '_jobs_', uniqueIds.join(';'))
  } else {
    localStorage.removeItem(namespace + '_jobs_')
  }
}

const addJob = (namespace: string, id: string): void => {
  const jobs = getJobs(namespace)
  jobs.push(id)
  setJobs(namespace, jobs)
}

const forgetJobs = (namespace: string, ids: string[]): void => {
  let jobs = getJobs(namespace)
  jobs = jobs.filter(job => !ids.includes(job))
  setJobs(namespace, jobs)
}

const parseDraft = (json: string): Draft | undefined => {
  try {
    const p = JSON.parse(json)

    if (!p || p.id === undefined || p.context === undefined || p.revision === undefined) {
      return
    }

    return {
      id: p.id,
      context: p.context,
      revision: p.revision,
    }
  } catch (e) {
    captureException(e)
  }
}

const setDraft = (namespace: string, draft: Draft): void => {
  localStorage.setItem(namespace + draft.id, JSON.stringify(draft))
}

const getDraft = (namespace: string, id: string): Draft | undefined => {
  const json = localStorage.getItem(namespace + id)
  if (json === null) return undefined
  return parseDraft(json)
}

const validateDraft = (draft: Draft) => draft.revision + LIFETIME > Date.now()

const forgetDrafts = (namespace: string, ids: string[]): void => {
  for (const id of ids) {
    localStorage.removeItem(namespace + id)
  }
}

const parseDraftId = (namespace: string, key: string): string | undefined => {
  if (!key.startsWith(namespace) || key.length === namespace.length) return
  return key.slice(namespace.length)
}

const handler = async (namespace: string): Promise<void> => {
  if (!namespace.length) return

  lastHandledAt = Date.now()

  for (let i = 0; i < 10; i++) {
    const id = getJobs(namespace)[0]
    if (id === undefined) break
    forgetJobs(namespace, [id])

    try {
      const draft = getDraft(namespace, id)
      if (!draft) continue

      await api.saveDraft(draft.id, draft.context, draft.revision)

      const stored = getDraft(namespace, id)
      if (stored && stored.revision === draft.revision) {
        forgetDrafts(namespace, [id])
      }
    } catch (e) {
      addJob(namespace, id)
      captureException(e)
    }
  }
}

const handlerLazy = debounce(handler, 1000)

const handle = (namespace: string): void => {
  if (lastHandledAt + 5000 < Date.now()) {
    handler(namespace)
  }
  handlerLazy(namespace)
}

export const push = (namespace: string, draft: Draft): void => {
  if (!namespace.length) return

  setDraft(namespace, draft)
  addJob(namespace, draft.id)

  handle(namespace)
}

export const store = (namespace: string, draft: Draft): void => {
  if (!namespace.length) return

  setDraft(namespace, draft)
}

export const forget = (namespace: string, ...ids: string[]): void => {
  if (!namespace.length || !ids.length) return

  forgetJobs(namespace, ids)
  forgetDrafts(namespace, ids)
}

export const all = (namespace: string): Draft[] => {
  const drafts: Draft[] = []

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key === null) continue

    const id = parseDraftId(namespace, key)
    if (id === undefined) continue

    const draft = getDraft(namespace, id)
    if (!draft) continue

    if (!validateDraft(draft)) {
      forgetDrafts(namespace, [id])
      continue
    }

    drafts.push(draft)
  }

  return drafts
}

export const watch = (namespaceResolver: () => string, cb: (draft: Draft) => void): void => {
  addEventListener('storage', ({ key, newValue }) => {
    if (key === null || newValue === null) return

    const namespace = namespaceResolver()
    if (!namespace.length) return

    const id = parseDraftId(namespace, key)
    if (id === undefined) return

    const draft = parseDraft(newValue)
    if (draft === undefined) return

    cb(draft)
  })
}

export default {
  push,
  store,
  forget,
  all,
  watch,
}
