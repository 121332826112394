/**
 * Checks if the passed event was triggered by Escape key.
 * @param event Native keyboard event
 * @returns Whether Escape key triggered the event
 */
export function isEscape (event: KeyboardEvent): boolean {
  /**
   * .which and .keyCode are deprecated, see MDN
   */
  const key = event.key || event.keyCode
  return (key === 27 || key === 'Escape')
}
