import { Actions as BaseActions } from 'vuex-smart-module'
import State from '@/store/modules/dataImports/state'
import Mutations from '@/store/modules/dataImports/mutations'
import Getters from '@/store/modules/dataImports/getters'
import { ServerProcessingParams } from '@tada-team/tdproto-ts'
import Vue from 'vue'
import { ImportData } from './types'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  /**
   * Reset data import store
   */
  resetStore (): void {
    Vue.set(this.state, 'data', {})
  }

  /**
   * Adding data to import from socket
   * @param actionMessage socket message
   */
  async socketOnProcessing (actionMessage: ServerProcessingParams): Promise<void> {
    const {
      action: uid,
      num,
      total,
      hasError,
      message,
    } = actionMessage
    const importExists = !!this.state.data[uid]
    !importExists && this.mutations.addImport(uid)

    const currentImport = this.state.data[uid]
    const maxNum = currentImport ? Math.max(num, currentImport.num) : num
    const errorMessages = currentImport ? [...currentImport.errorMessages] : []
    if (hasError) {
      errorMessages.push(message)
    }

    const importData: ImportData = {
      uid,
      errorMessages,
      total,
      num: maxNum,
    }

    this.mutations.setImport(importData)
  }
}
