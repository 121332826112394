import Vue from 'vue'

export const v3 = 'v3'
export const v2 = 'v2'

export const version = v2

/**
 * Initializes reCAPTCHA service if server supports it.
 */

export const recaptchaKeyV3 = window.FEATURES.recaptcha_web_key_v3
export const recaptchaKeyV2 = window.FEATURES.recaptcha_web_key_v2
export const isRecaptchaEnabled = window.FEATURES.is_recaptcha_enabled

// export const isRecaptchaEnabledV3 = isRecaptchaEnabled && version === V3 && recaptchaKeyV3
export const isRecaptchaEnabledV2 = Boolean(isRecaptchaEnabled && version === v2 && recaptchaKeyV2)
export const init = async (): Promise<void> => {
  if (!isRecaptchaEnabled || isRecaptchaEnabledV2) return

  const { VueReCaptcha } = await import('vue-recaptcha-v3')

  Vue.use(VueReCaptcha, { siteKey: recaptchaKeyV3 })
}

let styleContainer: HTMLStyleElement

/**
 * Attempts to show or hide reCAPTCHA badge on the badge.
 * Warn: when hiding the badge - spam checking will be disabled as well.
 * @param show display or hide badge
 */
export const toggleBadge = (show: boolean): void => {
  if (!isRecaptchaEnabled || isRecaptchaEnabledV2) return

  if (!styleContainer) {
    styleContainer = document.createElement('style')
    document.head.appendChild(styleContainer)
  }

  const display = show ? 'block' : 'none'
  styleContainer.innerHTML = `.grecaptcha-badge{display:${display} !important;}`
}
