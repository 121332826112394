import Getters from '@/store/modules/billing/getters'
import Mutations from '@/store/modules/billing/mutations'
import State from '@/store/modules/billing/state'
import { Actions as BaseActions } from 'vuex-smart-module'
import { PersonalAccountBilling } from '@tada-team/tdproto-ts'

export default class Actions extends BaseActions<State, Getters, Mutations, Actions> {
  setBillingAccount (account: PersonalAccountBilling | undefined) {
    this.mutations.setBillingAccount(account)
  }

  setBillingTeamAccount (teamAccount: PersonalAccountBilling | undefined) {
    this.mutations.setBillingTeamAccount(teamAccount)
  }
}
