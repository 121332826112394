import State from '@/store/modules/meetings/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import {
  ChatCounters,
  Meeting,
  DeletedChat,
  ServerMeetingCellUpdatedParams,
} from '@tada-team/tdproto-ts'
import Vue from 'vue'
import { MeetingDialogTemplate } from '@/store/modules/meetings/types'

export default class Mutations extends BaseMutations<State> {
  setMeetings (meetings: Meeting[]) {
    this.state.meetings = meetings
  }

  setMeetingsInstances (meetings: Meeting[]) {
    this.state.meetingsInstances = meetings
  }

  setIsMeetingDialogActive (val: boolean) {
    this.state.isMeetingDialogActive = val
  }

  setSelectedMeetingUUID (meetingUUID: string | null) {
    this.state.selectedMeetingUUID = meetingUUID
  }

  setEditingMeeting (meeting: Meeting | null) {
    this.state.editingMeeting = meeting
  }

  setMeetingDialogTemplate (template: MeetingDialogTemplate) {
    this.state.meetingDialogTemplate = template
  }

  setMeetingsCount (count: number) {
    this.state.count = count
  }

  setMeetingsDates (dates: string[]) {
    this.state.dates = dates
  }

  setInstancesOffset (val: number) {
    this.state.instancesOffset = val
  }

  setInstancesCount (val: number) {
    this.state.instancesCount = val
  }

  setIsMeetingsLoading (val: boolean) {
    this.state.isMeetingsLoading = val
  }

  setIsMeetingsCountLoading (val: boolean) {
    this.state.isMeetingsCountLoading = val
  }

  setIsAllMeetings (val: boolean) {
    this.state.isAllMeetings = val
  }

  removeCalendarMeeting (jid: string) {
    this.state.meetings = this.state.meetings.filter(m => m.jid !== jid)
  }

  removeMeetingInstance (jid: string) {
    this.state.meetingsInstances =
      this.state.meetingsInstances.filter(m => m.jid !== jid)
  }

  removeLoadedMeeting (jid: string) {
    Vue.delete(this.state.loadedMeetings, jid)
  }

  createOrUpdateMeetingInstance (meeting: Meeting) {
    let oldIndex = -1
    let index = -1
    const meetingStart = new Date(meeting.startAt).getTime()
    this.state.meetingsInstances.forEach((m, i) => {
      if (m.jid === meeting.jid) oldIndex = i
      const mStart = new Date(m.startAt).getTime()
      if (mStart > meetingStart && index === -1) index = i
    })
    if (oldIndex > -1) {
      this.state.meetingsInstances.splice(oldIndex, 1)
      if (oldIndex <= index) index -= 1
    }
    if (index >= 0) {
      this.state.meetingsInstances.splice(index, 0, meeting)
    } else {
      this.state.meetingsInstances.push(meeting)
    }
  }

  updateMeetingsInstance (p: { meeting: Meeting, oldStartAt: string }) {
    const { meeting, oldStartAt } = p
    this.state.meetingsInstances.forEach((m, index) => {
      if (m.jid !== meeting.jid && m.startAt !== oldStartAt) return
      this.state.meetingsInstances.splice(index, 1, meeting)
    })
  }

  createOrUpdateCalendarMeetings (meeting: Meeting) {
    let isUpdate = false

    this.state.meetings.forEach((m, index) => {
      if (m.jid !== meeting.jid) return
      isUpdate = true
      this.state.meetings.splice(index, 1, meeting)
    })

    if (!isUpdate) {
      this.state.meetings.push(meeting)
    }
  }

  createOrUpdateMeetingCell (p: {meeting: Meeting, oldStartAt: string}) {
    let isUpdate = false
    const { meeting, oldStartAt } = p
    this.state.meetings.forEach((m, index) => {
      if (m.jid !== meeting.jid && m.startAt !== oldStartAt) return
      isUpdate = true
      this.state.meetings.splice(index, 1, meeting)
    })

    if (!isUpdate) {
      this.state.meetings.push(meeting)
    }
  }

  addOrUpdateLoadedMeeting (meeting: Meeting) {
    this.state.loadedMeetings = { ...this.state.loadedMeetings, [meeting.jid]: meeting }
  }

  // ws mutations

  serverMeetingsCountersUpdated (counters: ChatCounters[]) {
    counters.forEach(counter => {
      if (counter.chatType !== 'meeting') return

      let index = this.state.meetings.findIndex(m => m.jid === counter.jid)
      if (index !== -1) {
        this.state.meetings[index].numUnread = counter.numUnread
        this.state.meetings[index].numUnreadNotices = counter.numUnreadNotices
      }

      index = this.state.meetingsInstances.findIndex(m => m.jid === counter.jid)
      if (index !== -1) {
        this.state.meetingsInstances[index].numUnread = counter.numUnread
        this.state.meetingsInstances[index].numUnreadNotices = counter.numUnreadNotices
      }
    })
  }

  serverMeetingsRemoved (chats: DeletedChat[]) {
    const filter = (m: Meeting) => !chats.some(chat => chat.jid === m.jid)
    this.state.meetings = this.state.meetings.filter(filter)
    this.state.meetingsInstances = this.state.meetingsInstances.filter(filter)
  }

  serverMeetingCellUpdate (p: ServerMeetingCellUpdatedParams) {
    this.state.meetings.forEach((m, index) => {
      if (m.id !== p.meetingId && m.startAt !== p.startAtOld) return
      const newMeeting = Meeting.fromJSON({
        ...m.toJSON(),
        ...{
          startAt: p.startAtNew,
          duration: p.duration,
        },
      })
      this.state.meetings.splice(index, 1, newMeeting)
    })
  }
}
