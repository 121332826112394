import State from '@/store/modules/drafts/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import Vue from 'vue'
import { Draft } from '@/store/modules/drafts/types'

export default class Mutations extends BaseMutations<State> {
  public updateMany (drafts: Draft[]): void {
    for (const draft of drafts) {
      Vue.set(this.state.data, draft.id, draft)
    }
  }

  public deleteMany (ids: string[]): void {
    for (const id of ids) {
      Vue.delete(this.state.data, id)
    }
  }

  public reset (): void {
    this.state.data = {}
    this.state.namespace = ''
  }

  public setNamespace (namespace: string): void {
    this.state.namespace = namespace
  }
}
