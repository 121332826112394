import { Actions as BaseActions } from 'vuex-smart-module'
import Getters from '@/store/modules/sections/getters'
import Mutations from '@/store/modules/sections/mutations'
import { RecordSections } from '@/store/modules/sections/types'
import State from '@/store/modules/sections/state'
import api from '@/api/v3'
import * as Sentry from '@sentry/browser'

import { SectionJSON, ChatType, Section, Contact } from '@tada-team/tdproto-ts'

import { contactsStore, uiStore } from '@/store'

interface SectionPayload {
  data: SectionJSON
  type: ChatType | 'contact'
  uid: string
}

/**
 *
 * @param actionName
 * @param e
 */
const sendToSentry = (actionName: string, e: Error) => {
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Critical)
    scope.setTag('sections', actionName)
    Sentry.captureException(e)
  })
}

export default class Actions extends BaseActions <State, Getters, Mutations, Actions> {
  /**
   * Reset sections store
   */
  resetStore (): void {
    this.state.direct = {}
    this.state.group = {}
    this.state.task = {}
  }

  /**
   * Add sections data
   */
  async setSections (): Promise<void> {
    let result: Section[]
    try {
      result = await api.contacts.sections()
    } catch (e) {
      sendToSentry('load', e as Error)
      return Promise.reject(e)
    }

    const sections: RecordSections = {}
    result.forEach(s => (sections[s.uid] = s))
    this.mutations.setSections({ sections })
    this.actions.checkIncorrectSection()
  }

  // TODO: refactor to use Section instance
  /**
   * Create new section
   * @param payload
   */
  async create (payload: SectionPayload): Promise<Section> {
    const { data, type } = payload
    let section: Section
    try {
      section = await api.sections.create(data, type)
    } catch (e) {
      sendToSentry('create', e as Error)
      return Promise.reject(e)
    }

    // this is an ugly bodge for current API
    // API call is performed via /task|contact-sections/uid...
    // section.updated socket event is received with chat_type: task|direct|group
    // locally sections are stored in state[task|direct|group]
    const chatType: ChatType = type === 'contact' ? 'direct' : type

    this.actions.updateSection({ section, chatType })
    return section
  }

  /**
   * Edit section
   * @param payload
   */
  async edit (payload: SectionPayload): Promise<Section> {
    const { uid, data, type } = payload
    let section: Section
    try {
      section = await api.sections.edit(uid, data, type)
    } catch (e) {
      sendToSentry('edit', e as Error)
      return Promise.reject(e)
    }

    // this is an ugly bodge for current API
    // API call is performed via /task|contact-sections/uid...
    // section.updated socket event is received with chat_type: task|direct|group
    // locally sections are stored in state[task|direct|group]
    const chatType = type === 'contact' ? 'direct' : type

    this.actions.updateSection({ section, chatType })
    return section
  }

  /**
   * Delete section by uid and chat type
   * @param payload
   */
  async delete (payload: SectionPayload): Promise<void> {
    const { uid, type } = payload
    try {
      await api.sections.delete(uid, type)

      if (uiStore.getters.tasksFiltersAndSortParams.section === uid) {
        uiStore.actions.updateRightBarTasksSortFilters({ key: 'section', value: undefined })
      }
    } catch (e) {
      // it's okay to be 404 on DELETE
      // simply means that requested section already does not exist
      // TODO: check and refactor
      const { status } = e as { status: number }
      if (status !== 404) {
        sendToSentry('delete', e as Error)
        return Promise.reject(e)
      }
    }

    uiStore.actions.deleteTaskDeskGroup(uid)

    // this is an ugly bodge for current API
    // API call is performed via /task|contact-sections/uid...
    // section.updated socket event is received with chat_type: task|direct|group
    // locally sections are stored in state[task|direct|group]
    const chatType = type === 'contact' ? 'direct' : type
    this.mutations.delete({ uid, chatType })
  }

  /**
   * Update section data
   * @param payload
   */
  updateSection (payload: { section: Section, chatType: ChatType }): void {
    const { chatType = 'direct', section } = payload

    if (chatType === 'meeting') throw new Error('ChatType error')
    // if such a department already exists, update it
    // else we create a new section object and update the whole state
    const currentSection = this.state[chatType][section.uid]

    if (currentSection) {
      if (currentSection.gentime >= section.gentime) return

      this.mutations.update(payload)
    } else {
      const newSectionRecord: RecordSections = {}

      newSectionRecord[section.uid] = section

      this.mutations.setSections({
        sections: { ...this.state[chatType], ...newSectionRecord },
        chatType,
      })
    }
  }

  /**
   * Check the section for correctness
   */
  checkIncorrectSection (): void {
    const contacts: Contact[] = contactsStore.getters.teamMembers

    const sectionsJids = Object.keys(this.state.direct)
    if (!sectionsJids || sectionsJids.length === 0) return

    const incorrectContacts = contacts.filter(
      contact => contact.sections.filter(
        jid => !sectionsJids.includes(jid),
      ).length > 0,
    )
    if (incorrectContacts.length === 0) return

    Sentry.withScope(scope => {
      const errorObj: Record<string, unknown> = {}
      incorrectContacts.forEach(contact => {
        errorObj[contact.displayName] = {
          jid: contact.jid,
          sections: JSON.stringify(contact.sections),
        }
      })

      scope.setLevel(Sentry.Severity.Error)
      scope.setTag('contacts', 'sections')
      scope.setContext('contacts', errorObj)
      Sentry.captureException(
        new Error('Received contacts with incorrect sections'),
      )
    })
  }

  async move (payload: { a: string, direct: 'before' | 'after', b: string }): Promise<void> {
    const { a, b, direct } = payload

    const sectionA = this.getters.section(a)
    const sectionB = this.getters.section(b)
    if (sectionA && sectionB) {
      const section = Section.fromJSON(sectionA.toJSON())

      switch (direct) {
        case 'before':
          section.sortOrdering = sectionB.sortOrdering - 1
          this.mutations.update({ section })
          break
        case 'after':
          section.sortOrdering = sectionB.sortOrdering + 1
          this.mutations.update({ section })
          break
      }
    }

    try {
      switch (direct) {
        case 'before':
          await api.sections.moveBefore(a, b)
          break
        case 'after':
          await api.sections.moveAfter(a, b)
          break
      }
    } finally {
      await this.actions.setSections()
    }
  }
}
