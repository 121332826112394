export const enum Permission {
  GRANTED = 'granted',
  DENIED = 'denied',
  DEFAULT = 'default'
}

export const enum Type {
  SAFARI = 'safari',
  FIREBASE = 'firebase',
  ELECTRON = 'electron'
}

export interface SubscribeParameters {
  callOnGranted?: Array<() => unknown> | null;
  force?: boolean;
}

export default abstract class {
  abstract init (): void
  abstract subscribe (parameters: SubscribeParameters): Promise<void>
  abstract unsubscribe (): Promise<void>
  abstract getPermission (): Permission
  abstract getType (): Type
}
