import { Chat } from '@tada-team/tdproto-ts'
import { SortType, Tab } from './types'

export default class State {
  chats: Chat[] = []
  isLoaded = false
  isLoading = false
  search = ''
  sortType: SortType = 'activity'
  tab: Tab = 'all'

  rosterIsReady = false // TODO: refactor this
  threadChats: Chat[] = []
}
