class PacketQueue {
  constructor ({ capacity, keyDescriptor, prefix } = {}) {
    this.clear()

    this.capacity = capacity || Infinity
    if (isNaN(this.capacity) || this.capacity <= 0) this.capacity = 100

    this.keyDescriptor = keyDescriptor
    this.prefix = prefix

    this.callbackExecutingDelay = 20
    this.flushingTimeout = this.capacity === Infinity ? Infinity : this.capacity * this.callbackExecutingDelay
  }

  clear () {
    this.packetsOrderList = []
    this.packetsData = {}

    this.flushingInterval && clearInterval(this.flushingInterval)
    this.flushingInterval = null
  }

  addPacket ({ name, body }) {
    let key = this.keyDescriptor && this.keyDescriptor[name]
    if (this.keyDescriptor && !key) return

    key = body?.[key] || Date.now()
    key = name + key

    if (this.packetsData[key]) {
      const index = this.packetsOrderList.indexOf(key)
      index >= 0 && this.packetsOrderList.splice(index, 1)
    } else if (this.packetsOrderList.length >= this.capacity) {
      const firstKey = this.packetsOrderList[0]
      delete this.packetsData[firstKey]
      this.packetsOrderList.splice(0, 1)
    }

    this.packetsOrderList.push(key)
    this.packetsData[key] = { name, body }
  }

  flush (callback) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      if (!callback) return reject('No callback provided')

      let index = 0
      let timer = 0
      let flushedPackets = 0

      const stop = reason => {
        this.clear()

        const prefix = this.prefix
        const count = flushedPackets
        // eslint-disable-next-line prefer-promise-reject-errors
        reason ? reject({ prefix, reason, count }) : resolve(count)
      }

      if (this.packetsOrderList.length === 0) {
        stop()
        return
      }

      this.flushingInterval = setInterval(() => {
        if (timer > this.flushingTimeout) {
          stop('Flushing timeout')
          return
        }

        if (index >= this.packetsOrderList.length) {
          stop()
          return
        }

        const key = this.packetsOrderList[index]
        callback(this.packetsData[key])
        flushedPackets++

        index += 1
        timer += this.callbackExecutingDelay
      }, this.callbackExecutingDelay)
    })
  }
}

export default PacketQueue
