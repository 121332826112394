import { CallDisplayType, CallMdDisplayType, CallMembersDisplayType, CallUiLgRightBarDisplayType, ActiveCall, CallUiLgLayoutType } from './models'
import { MEMBER_DISPLAY_TYPE, UI_LG_LAYOUT_TYPE, UI_LG_RIGHT_BAR_DISPLAY_TYPE, UI_MD_DISPLAY_TYPE } from './defaults'
import { JID } from '@tada-team/tdproto-ts'

class ModuleState {
  /**
   * Current state of window size (type) of a call
   */
  uiDisplayType: CallDisplayType = null

  /**
   * Previous state of window size (type) of a call
   */
  uiDisplayTypePrev: CallDisplayType = null

  /**
   * Current state of what is shown in MD sized call window.
   */
  uiMdDisplayType: CallMdDisplayType = UI_MD_DISPLAY_TYPE

  /**
   * Previous state of what is shown in MD sized call window.
   */
  uiMdDisplayTypePrev: CallMdDisplayType = UI_MD_DISPLAY_TYPE

  uiLgLayoutType: CallUiLgLayoutType = UI_LG_LAYOUT_TYPE
  uiLgLayoutTypePrev: CallUiLgLayoutType = UI_LG_LAYOUT_TYPE

  uiLgRightBarDisplayType: CallUiLgRightBarDisplayType = UI_LG_RIGHT_BAR_DISPLAY_TYPE
  uiLgRightBarDisplayTypePrev: CallUiLgRightBarDisplayType = null

  membersDisplayType: CallMembersDisplayType = MEMBER_DISPLAY_TYPE
  activeCall: ActiveCall | null = null

  /**
   * List of JIDs of the users currently on the call, in the correct order,
   * as is the order of the tracks. Only for multistream call type!
   */
  jids: JID[] = []

  camDenied = false
  micDenied = false
}

export default ModuleState
