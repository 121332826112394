import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as SentryVue } from '@sentry/integrations'

const { FEATURES } = window

const preventQuotaExceeding = () => {
  const { localStorage, Storage } = window
  if (typeof localStorage === 'object') {
    try {
      localStorage.setItem('localStorage', 1)
      localStorage.removeItem('localStorage')
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem
      Storage.prototype.setItem = function () {}
    }
  }
}

const checkStorageVersion = version => {
  const { localStorage } = window
  const key = 'storageVersion'

  const currentVersion = localStorage.getItem(key)
  if (!currentVersion || +currentVersion < version) {
    console.info(`Upgrade storage from ${currentVersion} to ${version}`)

    localStorage.clear()
    localStorage.setItem(key, version)
  }
}

const setupTitle = () => {
  document.title = FEATURES.app_title
}

const installRaven = () => {
  if (window.location.hostname === 'localhost') return

  const { sentry_dsn_js: sentry } = FEATURES
  if (!sentry) return
  Sentry.init({
    dsn: sentry,
    integrations: [new SentryVue({ Vue, attachProps: true, logErrors: true })],
  })
}

const setupMisc = () => {
  window.debug = (message, ...params) => {
    if (!window.localStorage.getItem('debug')) return

    console.log(message, ...params)

    return true
  }

  window.requestAnimFrame = (() => {
    return window.requestAnimationFrame ||
           window.webkitRequestAnimationFrame ||
           window.mozRequestAnimationFrame ||
           window.oRequestAnimationFrame ||
           window.msRequestAnimationFrame ||
           function (callback) { window.setTimeout(callback, 1000 / 60) }
  })()
}

const extendVuePrototype = () => {
  Vue.prototype.$sentry = Sentry
}

export function setup () {
  preventQuotaExceeding()
  checkStorageVersion(7)
  setupTitle()
  installRaven()
  setupMisc()

  extendVuePrototype()
}
