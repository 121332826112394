import { isInDevMode, isTestingE2E } from '@/envHelpers'
import i18n from '@/i18n'
import { networkLogger } from '@/loggers'
import { uiSettingsStore, teamsStore } from '@/store'
import { logout } from '@/utils'
import * as Sentry from '@sentry/browser'
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosError,
  CancelToken,
  CancelTokenSource,
} from 'axios'
import { Cookies } from 'quasar'
import router from '@/router'

const isProxied = isInDevMode() || isTestingE2E()

const { isAxiosError } = axios

export enum BodyType {
  FormData = 'FormData',
  JSON = 'JSON',
  URLSearchParams = 'URLSearchParams'
}

export const createCancelTokenSource = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

function isClientBlocked (e: AxiosError) {
  return e.response?.status === 402
}

class HTTP {
  private instance: AxiosInstance
  private csrftoken: string | null
  private source: CancelTokenSource | null

  constructor (options: AxiosRequestConfig) {
    this.instance = axios.create(options)

    this.instance.interceptors.response.use(undefined, async error => {
      if (!isAxiosError(error)) return Promise.reject(error)
      if (isClientBlocked(error)) {
        await router.push({
          name: 'Blocked',
          params: {
            teamId: teamsStore.getters.currentTeam.uid,
          },
        })
      }
      return Promise.reject(error)
    })

    this.csrftoken = null
    this.source = null
  }

  fetch = (config: { url: string; method?: string; data?: Record<string, any>; bodyType?: BodyType; cancelToken?: CancelToken; timeout?: number }, handleError = true) => {
    let bodyType = config.bodyType || BodyType.JSON

    const { data } = config
    if (data && 'file' in data) {
      const { file } = data as any
      file instanceof Blob && (bodyType = BodyType.FormData)
    }

    const options = config as AxiosRequestConfig
    options.headers = {}

    this.csrftoken = this.csrftoken || (this.csrftoken = Cookies.get('csrftoken'))
    this.csrftoken && (options.headers['X-CSRFToken'] = this.csrftoken)

    options.headers.lang = uiSettingsStore.getters.language || i18n.locale || 'ru'
    options.headers['X-Supported-Features'] = '2FactorAuth'

    if (!options.cancelToken && this.source) {
      options.cancelToken = this.source.token
    }

    if (bodyType !== BodyType.JSON) {
      const container: URLSearchParams = (bodyType === BodyType.FormData ? new FormData() : new URLSearchParams()) as URLSearchParams
      Object.keys(options.data).forEach(key => {
        container.append(key, options.data[key])
      })
      options.data = container
    }

    const promise = this.instance.request(options).then(response => {
      const deprecated = response?.headers?.['x-deprecated']
      if (deprecated) {
        Sentry.withScope(scope => {
          scope.setLevel(Sentry.Severity.Warning)
          scope.setTag('api', 'deprecated')
          scope.setContext('request', config)
          Sentry.captureException(new Error('Request made via a deprecated API'))
        })
      }
      const { ok: success, result } = response.data
      // eslint-disable-next-line prefer-promise-reject-errors
      return success ? result : Promise.reject({ response })
    })

    return handleError
      ? promise.catch(this.handleError)
      : promise.catch(this.propagateErrorStatus)
  }

  get = (url: string): AxiosPromise => {
    return axios.get(url).then(response => response.data).catch(this.handleError)
  }

  setCancelTokenSource = (source: CancelTokenSource | null) => {
    this.source = source
  }

  private prepareClientError = (errorData: any) => {
    const isCanceled = axios.isCancel(errorData)
    const response = errorData.response || { status: -1 }

    return {
      status: response.status,
      code: isCanceled ? 'CANCEL' : (errorData.code || null),
      message: errorData.message || (typeof errorData.toString === 'function' ? errorData.toString() : null),
    }
  }

  private propagateErrorStatus = (errorData: any) => {
    const result = this.prepareClientError(errorData)
    return Promise.reject(result)
  }

  private handleError = (errorData: any) => {
    const { response } = errorData

    if (!response) {
      const responseReject = this.prepareClientError(errorData)
      const { message, status } = responseReject
      networkLogger.debug(`%c ● [HTTP.handleError (${status})]%c Message: "${message}"`, 'background-color: red; color: #fff', 'color: blue')
      return Promise.reject(responseReject)
    }

    const { config, status, statusText, data: responseData } = response

    // TODO remove typecast after update td-proto
    const emailFeatures = window.FEATURES.support_email
    const email = emailFeatures.length ? `— ${emailFeatures}` : emailFeatures

    const getResponseRejectData = () => {
      if (responseData && responseData.error) {
        const { error, details } = responseData
        const returnErrorObject = {
          status: status as number,
          error: '',
          details: null as any,
        }
        switch (error) {
          case 'EMPTY_TOKEN':
          case 'INVALID_TOKEN':
            return logout(false)
          case 'ACCESS_DENIED':
            returnErrorObject.error = i18n.t('errors.ACCESS_DENIED').toString()
            break
          case 'NOT_FOUND':
            returnErrorObject.error = i18n.t('errors.NOT_FOUND').toString()
            break
          case 'INVALID_METHOD':
            returnErrorObject.error = i18n.t('errors.INVALID_METHOD').toString()
            break
          case 'INVALID_DATA':
            returnErrorObject.error = i18n.t('errors.INVALID_DATA').toString()
            returnErrorObject.details = details
            break
          case 'RATE_LIMIT':
            returnErrorObject.error = i18n.t('errors.RATE_LIMIT').toString()
            break
          case 'INTERNAL_SERVER_ERROR':
            returnErrorObject.error = i18n.t('errors.INTERNAL_SERVER_ERROR', { email }).toString()
            break
          default:
            returnErrorObject.error = i18n.t('errors.unknownError', { email }).toString()
            returnErrorObject.details = details
        }
        return returnErrorObject
      }

      let error = null
      switch (status) {
        case 502:
        case 500:
          error = i18n.t('errors.INTERNAL_SERVER_ERROR', { email }).toString()
      }
      return { status, error: error || statusText }
    }

    const { data, url, method } = config
    networkLogger.debug(`%c ● [HTTP.handleError (${status})]%c Request: "${method.toUpperCase()}" (${url}): %c${data || 'empty data'}`, 'background-color: red; color: #fff', 'color: blue', 'color: #e056fd')

    const responseReject = getResponseRejectData()
    networkLogger.debug(`%c ● [HTTP.handleError (${status})]%c Response: "${JSON.stringify(responseReject)}"`, 'background-color: red; color: #fff', 'color: blue')

    return Promise.reject(responseReject)
  }
}

export default new HTTP({
  // baseURL: '/api/v3',
  withCredentials: isProxied,
})
