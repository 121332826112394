import { Mutations } from 'vuex-smart-module'
import { Tag } from '@tada-team/tdproto-ts'
import State from './state'

class ModuleMutations extends Mutations<State> {
  SETUP_TAGS (tags: Tag[]): void {
    this.state.tags = tags
  }

  ADD_TAGS (tags: Tag[]): void {
    this.state.tags.push(...tags)
  }
}

export default ModuleMutations
