import State from '@/store/modules/chatImport/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import { MappedUser, ServerProcessingParams, ParseState } from '@tada-team/tdproto-ts'

export default class Mutations extends BaseMutations<State> {
  resetStore () {
    const clearState = new State()
    const keys = Object.keys(clearState) as Array<keyof State>

    keys.forEach(key => {
      (this.state[key] as State[typeof key]) = clearState[key]
    })
  }

  setIsChatImportDialogOpen (v: boolean): void {
    this.state.isChatImportDialogOpen = v
  }

  setLoadingProgress (v: number): void {
    this.state.loadingProgress = v
  }

  setFileSize (v: number): void {
    this.state.fileSize = v
  }

  setLoadingSize (v: number): void {
    this.state.loadingSize = v
  }

  setLoadDone (v: boolean): void {
    this.state.isLoadDone = v
  }

  setIsLoading (v: boolean): void {
    this.state.isLoading = v
  }

  clearProcessingActions (): void {
    this.state.processingActions = {}
  }

  setHasLoadError (v: boolean): void {
    this.state.hasLoadError = v
  }

  setFileName (v: string): void {
    this.state.fileName = v
  }

  setChatName (v: string): void {
    this.state.chatName = v
  }

  setMappedUsers (users: MappedUser[]): void {
    this.state.mappedUsers = users
  }

  setMappingUsersLoading (v: boolean): void {
    this.state.mappedUsersLoading = v
  }

  setIsMatching (v: boolean): void {
    this.state.isMatching = v
  }

  setParserState (v: ParseState): void {
    this.state.parserState = v
  }

  updateAbortController (): void {
    this.state.abortController = new AbortController()
  }

  addOrUpdateProcessingAction (params: ServerProcessingParams): void {
    const action: Record<string, ServerProcessingParams> = {}
    action[params.action] = params
    this.state.processingActions = { ...this.state.processingActions, ...action }
  }
}
