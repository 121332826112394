import State from '@/store/modules/root/state'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  setIsAppReady (value: boolean): void {
    this.state.isAppReady = value
  }

  setIsNotificationsAllowed (value: boolean): void {
    this.state.isNotificationsAllowed = value
  }
}
