import { JID } from '@tada-team/tdproto-ts'
import { Mutations as BaseMutations } from 'vuex-smart-module'
import State, { DEFAULT_CHATS_BAR_WIDTH } from './state'
import {
  UIDraggedTaskData,
  UIMiddleColumn,
  UIModal,
  UIRightBar,
  UIRightBarFiltersAndSort,
  UISize,
  UITask,
  UITaskDesk,
  UITaskGroup,
} from './types'

export default class Mutations extends BaseMutations<State> {
  setChatsBarWidth (value: number): void {
    this.state.chatsBarWidth = value
  }

  resetChatsBarWidth (): void {
    this.state.chatsBarWidth = DEFAULT_CHATS_BAR_WIDTH
  }

  setRightBarHidden (value: boolean) {
    this.state.keepRightBarHidden = value
  }

  addSelectedMessage (messageId: JID): void { // ADD_SELECTED_MESSAGE
    this.state.selectedMessagesIds.push(messageId)
  }

  removeSelectedMessage (index: number): void { // REMOVE_SELECTED_MESSAGE
    index >= 0 && this.state.selectedMessagesIds.splice(index, 1)
  }

  clearSelectedMessages (): void { // CLEAR_SELECTED_MESSAGES
    this.state.selectedMessagesIds = []
  }

  setRightBarFloatedMode (mode: boolean): void { // UI_SET_RIGHT_BAR_FLOATED_MODE
    this.state.rightBar.floated = mode
  }

  setRightBarData (data: Partial<UIRightBar>): void { // UI_SET_RIGHT_BAR_INSTANCE
    this.state.rightBar = { ...this.state.rightBar, ...data }
  }

  setMiddleColumn (data: Partial<UIMiddleColumn>): void {
    this.state.middleColumn = {
      ...this.state.middleColumn,
      ...data,
    }
  }

  setMiddleColumnInstance (payload: Partial<UIMiddleColumn>): void { // UI_SET_MIDDLE_COLUMN_INSTANCE
    // save prev instance
    this.state.middleColumn = {
      ...this.state.middleColumn,
      ...payload,
      prevInstance: this.state.middleColumn.instance || 'chat',
    }
  }

  setModal (data: Partial<UIModal>): void {
    this.state.modal = { ...this.state.modal, ...data }
  }

  setModalResult (result: string | null) {
    this.state.modal.result = result
  }

  setTaskDeskData (payload: Partial<UITaskDesk>): void { // UI_SETTINGS_SET_TASK_DESK_DATA
    this.state.taskDesk = { ...this.state.taskDesk, ...payload }
  }

  setTaskDragData (payload: Partial<UIDraggedTaskData>): void { // UI_SET_TASK_DRAG_DATA
    this.state.taskDesk.draggedTaskData = {
      ...this.state.taskDesk.draggedTaskData,
      ...payload,
    }
  }

  createTaskDeskGroup (group: UITaskGroup): void { // UI_TASK_DESK_CREATE_GROUP
    if (!this.state.taskDesk.groups) return
    this.state.taskDesk.groups.push(group)
  }

  setTaskDeskGroups (groups: UITaskGroup[]): void { // UI_SETTINGS_SET_TASK_DESK_GROUPS
    this.state.taskDesk.groups = groups
  }

  /**
    * Set new value for key filter or sort
    * Key should be 'assignees' | 'owners' | 'section' | 'tags' | 'sort'
    * @see state for values types
    */
  updateRightBarTasksSortFilters<T extends keyof UIRightBarFiltersAndSort> (
    p: { key: T; value: UIRightBarFiltersAndSort[T] },
  ): void { // UI_UPDATE_RIGHT_BAR_TASKS_SORT_FILTERS
    this.state.rightBar.filtersAndSort = {
      ...this.state.rightBar.filtersAndSort,
      [p.key]: p.value,
    }
  }

  deleteTaskDeskGroup (jid: JID): void { // UI_TASK_DESK_SECTION_DELETED
    if (!this.state.taskDesk.groups) return
    const groupIndex = this.state.taskDesk.groups.findIndex(g => g.key === jid)
    if (groupIndex === -1) return
    this.state.taskDesk.groups.splice(groupIndex, 1)
  }

  /**
     * Reseting filters for tasks
     * @see state for values types
     */
  resetRightBarTasksSortFilters (): void { // UI_RESET_RIGHT_BAR_TASKS_SORT_FILTERS
    this.state.rightBar.filtersAndSort = {
      section: undefined, // project UID
      assignees: [], // array of JIDs
      owners: [], // array of JIDs
      tags: [], // array of strings
      sort: null, // * sort typing @see utils.ts/sortTasks fn
      search: '', // string for searching
    }
  }

  deleteTaskDeskTask (id: JID) { // UI_TASK_DESK_DELETE_TASK
    if (!this.state.taskDesk.groups) return

    this.state.taskDesk.groups.forEach(group => {
      group.tasks = group.tasks.filter(task => task.jid !== id)
    })
  }

  setTaskDeskTask ({ key, task }: { key: string, task: UITask }): void {
    if (!this.state.taskDesk.groups) return

    const group = this.state.taskDesk.groups.find(g => g.key === key)
    group && group.tasks.push(task)
  }

  setCallWindowSize (size: UISize): void {
    this.state.callWindowSize = size
  }
}
