import api from '@/api/v3'
import CallAudioManager from '@/calls/CallAudioManager'
import { callsLogger } from '@/loggers'
import { activeCallStore, teamsStore } from '@/store'
import ws from '@/ws'
import * as Sentry from '@sentry/browser'
import {
  ClientCallRejectParams,
  JID,
  OnlineCall,
  ServerCallBuzzcancelParams,
  ServerCallBuzzParams,
  ServerCallMuteallParams,
} from '@tada-team/tdproto-ts'
import { Actions } from 'vuex-smart-module'
import { BUZZING_WINDOW_TTL } from './defaults'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'

class ModuleActions extends Actions<
  State,
  Getters,
  Mutations,
  ModuleActions
> {
  setCalls (calls: OnlineCall[] | undefined): void {
    const map: Record<string, OnlineCall> = {}
    calls && calls.forEach(c => {
      map[c.jid] = c
    })
    this.mutations.setCalls(map)
  }

  clearCalls (): void {
    const payload: Record<JID, OnlineCall> = {}

    const activeCall = activeCallStore.state.activeCall
    if (activeCall) {
      const onlineCall = new OnlineCall(
        'video',
        activeCall.jid,
        activeCall.uid ?? '',
      )
      payload[onlineCall.jid] = onlineCall
    }

    this.mutations.setCalls(payload)
    this.mutations.setBuzzingCalls({})
  }

  handleBuzz (payload: ServerCallBuzzParams): void {
    if (!payload) {
      callsLogger.warn(
        'Received no payload in server.call.buzz.\n',
        'Payload', payload,
      )
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Warning)
        scope.setTag('calls', 'buzz')
        Sentry.captureException('Empty server.call.buzz params')
      })
      return
    }

    const isInCurrentTeam = payload.teaminfo.uid === teamsStore.state.currentTeamId
    if (!isInCurrentTeam) {
      this.mutations.setExternalCall(payload)
      return
    }

    setTimeout(() => {
      this.mutations.removeBuzzingCall(payload.jid)
      this.mutations.setExternalCall(null)
      CallAudioManager.stop('INCOMING')
    }, payload.buzzTimeout * 1000 || BUZZING_WINDOW_TTL)

    CallAudioManager.play({ track: 'INCOMING', loop: true })
    this.mutations.createOrUpdateBuzzingCall(payload)
  }

  handleBuzzCancel (payload: ServerCallBuzzcancelParams): void {
    CallAudioManager.stop('INCOMING')
    payload.team === teamsStore.state.currentTeamId
      ? this.mutations.removeBuzzingCall(payload.jid)
      : this.mutations.setExternalCall(null)
  }

  /**
   * This is purely for debugging purposes. This payload is somewhat useless.
   * Only check if muted is false, which means we tried to toggle
   * muteall = true, but server did not allow to do so. Which should never
   * happen.
   */
  handleMuteallEvent (ev: ServerCallMuteallParams) {
    if (ev.muted === true) return
    callsLogger.warn(`Unexpected MUTEALL event. Data: ${ev}`)
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Warning)
      scope.setTag('calls', 'muteall')
      scope.setContext('event', ev.toJSON() as Record<string, any>)
      Sentry.captureException('Server returned muteall with muted = false.')
    })
  }

  createOrUpdateCall (c: OnlineCall): void {
    this.mutations.createOrUpdateCall(c)
  }

  removeBuzzingCall (jid: JID): void {
    this.mutations.removeBuzzingCall(jid)
  }

  removeCall (jid: JID): void {
    this.mutations.removeCall(jid)
  }

  async reject (p: { teamId: string, chatId: string }): Promise<void> {
    if (
      activeCallStore.state.activeCall?.jid === p.chatId &&
      activeCallStore.state.activeCall.lifecycleState === 'ENDED'
    ) {
      callsLogger.log(
        'Tried rejecting active call that had already finished.\n',
        'Nothing to do.',
      )
    }

    CallAudioManager.stop('INCOMING')
    this.actions.removeBuzzingCall(p.chatId)

    try {
      await api.ws.rejectCall(p.chatId, p.teamId)
    } catch (e) {
      callsLogger.log(
        'Error REJECTING call via HTTP request.\n',
        'Chat:', p.chatId, '\n',
        'Team:', p.teamId,
      )
    }

    const packet = new ClientCallRejectParams(
      p.chatId,
      // server doesn't care about this string for now
      'dun wanna talk 2 u anymo',
    )
    ws.send('client.call.reject', packet.toJSON())
  }

  async setIceConfig (): Promise<void> {
    const iceConfig = this.getters.iceConfig
    const turn = await api.getTurnConfig()
    if (turn) {
      iceConfig.iceServers = turn.iceServers
      iceConfig.iceTransportPolicy = turn.iceTransportPolicy
    } else {
      iceConfig.iceServers = window.FEATURES.ice_servers
      iceConfig.iceTransportPolicy = window.FEATURES.ice_transport_policy
    }
    this.mutations.setIceConfig(iceConfig)
  }
}

export default ModuleActions
