import { Actions } from 'vuex-smart-module'
import * as Sentry from '@sentry/browser'
import api from '@/api/v3'
import Getters from './getters'
import Mutations from './mutations'
import State from './state'
import { Node } from '@tada-team/tdproto-ts'

export default class ModuleActions extends Actions<
  State,
  Getters,
  Mutations,
  ModuleActions
> {
  async loadAll (): Promise<void> {
    let nodes: Node[]

    try {
      nodes = await api.nodes.getAll()
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('nodes', 'get')
        scope.setContext('error', { e })
        Sentry.captureException('Failed to get nodes.')
      })
      return Promise.reject(e)
    }

    this.mutations.setAll(nodes)
  }
}
