import { JID, TaskColor, TaskStatus } from '@tada-team/tdproto-ts'

export default class State {
  colors: TaskColor[] = []
  colorsRules: TADA.TasksColorsRule[] = []
  count = 0
  data: Record<JID, TADA.Task | undefined> = {}
  imports: string[] = []
  loading = false
  statuses: TaskStatus[] = []
  tabs: Record<string, TADA.TaskTab> = {}
}
