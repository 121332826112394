import BaseNotification, { Type, Permission, SubscribeParameters } from '@/notifications/BaseNotification'
import { allowNotifications } from '@/notifications/Utils'
import { defaultLogger } from '@/loggers'

export default class Electron extends BaseNotification {
  init () { }

  async subscribe ({ callOnGranted }: SubscribeParameters) {
    const permission = this.getPermission()
    await this.checkPermission(permission, callOnGranted)
  }

  async checkPermission (permission: Permission, callOnGranted?: Array<() => unknown> | null) {
    switch (permission) {
      case Permission.DEFAULT: {
        try {
          const result = await Notification.requestPermission()
          this.checkPermission(result as Permission, callOnGranted)
        } catch (error) {
          defaultLogger.warn('[Electron.checkPermission]', error)
        }
        break
      }
      case Permission.GRANTED: {
        allowNotifications(true)

        callOnGranted && callOnGranted.forEach(fn => fn())
        break
      }
      case Permission.DENIED: {
        allowNotifications(false)
        break
      }
    }
  }

  async unsubscribe () {
    allowNotifications(false)
  }

  getPermission () {
    return (window as any).Notification.permission
  }

  getType () {
    return Type.ELECTRON
  }
}
