export const CHATS_ADD_DATA = 'CHATS_ADD_DATA'
export const CHATS_CURRENT_CHAT = 'CHATS_CURRENT_CHAT'
export const CHAT_SET_NUM_UNREAD = 'CHAT_SET_NUM_UNREAD'
export const CHAT_SET_LAST_ACTIVITY = 'CHAT_SET_LAST_ACTIVITY'
export const CHAT_SET_LAST_MESSAGE = 'CHAT_SET_LAST_MESSAGE'
export const CHAT_SET_LAST_READ_MESSAGE_ID = 'CHAT_SET_LAST_READ_MESSAGE_ID'
export const CHAT_MESSAGE_COMPOSING = 'CHAT_MESSAGE_COMPOSING'
export const CHAT_OTHER_MESSAGE_COMPOSING = 'CHAT_OTHER_MESSAGE_COMPOSING'
export const CHATS_REMOVE_CHAT = 'CHATS_REMOVE_CHAT'
export const CHAT_SET_STARTING_MESSAGE_ID = 'CHAT_SET_STARTING_MESSAGE_ID'
export const CHAT_SET_CURRENT_ACTIVE_MESSAGE = 'CHAT_SET_CURRENT_ACTIVE_MESSAGE'
export const CHATS_SET_LAST_CHAT = 'CHATS_SET_LAST_CHAT'
export const CHATS_SETUP_CHAT_BOTCOMMANDS = 'CHATS_SETUP_CHAT_BOTCOMMANDS'
export const CHATS_TOGGLE_CHAT_EXCLUDED_STATE = 'CHATS_TOGGLE_CHAT_EXCLUDED_STATE'
export const CHATS_TOGGLE_CHAT_PIN_STATE = 'CHATS_TOGGLE_CHAT_PIN_STATE'
export const CHAT_SET_ERROR_TYPE = 'CHAT_SET_ERROR_TYPE'
export const CHAT_UPDATE_CHAT = 'CHAT_UPDATE_CHAT'
