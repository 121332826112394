import State from '@/store/modules/sections/state'
import { Getters as BaseGetters } from 'vuex-smart-module'
import { ChatType, Section, Contact } from '@tada-team/tdproto-ts'
import { contactsStore } from '@/store'

export default class Getters extends BaseGetters<State> {
  /**
   * All contact sections but in an array and sorted.
   * @returns {Section[]} array of sorted contact sections
   */
  contactSectionsSorted (): Section[] {
    // TODO: change to Object.values or, better yet, store Sections in data as an Array and create getter for Record<uid, Section>
    return Object.keys(this.state.direct)
      .map((uid: string) => this.state.direct[uid])
      .sort((a, b) => a.sortOrdering - b.sortOrdering)
  }

  /**
   * Get section by chat type and section uid
   * @param uid
   * @param chatType
   */
  section (uid: string, chatType: ChatType = 'direct'): Section {
    if (chatType === 'meeting') throw new Error('ChatType error')
    return this.state[chatType][uid]
  }

  /**
   * Get contacts by section
   * @param uid
   */
  contactsBySection (uid: string): Contact[] {
    return contactsStore.getters.teamMembers.filter((contact: Contact) => {
      const sections = contact.sections ?? []
      return (!uid && sections.length === 0) || sections.includes(uid)
    })
  }

  /**
   * Get direct section by uid
   * @param uid
   */
  sectionNameByUid (uid: string): string {
    return this.getters.section(uid, 'direct')?.name ?? this.getters.section(uid, 'task')?.name
  }

  /**
   * Get task section name by uid
   * @param uid
   */
  tasksSectionNameByUid (uid: string): string {
    return this.getters.section(uid, 'task')?.name
  }
}
