import { Getters as BaseGetters } from 'vuex-smart-module'
import State from '@/store/modules/teams/state'
import { JID, Team } from '@tada-team/tdproto-ts'
import { getIconUrl } from '@/utils'
import * as Sentry from '@sentry/browser'

export default class Getters extends BaseGetters<State> {
  /**
   * Get current team data
   */
  get currentTeam (): Team {
    if (!this.state.currentTeamId) throw Error('The current team is not defined')
    return this.state.data[this.state.currentTeamId]
  }

  get isCurrentTeamSuspended (): boolean {
    return this.getters.currentTeam.accountStatus ===
      'PERSONAL_ACCOUNT_STATUS_SUSPENDED'
  }

  /**
   * Get all teams data
   */
  get teams (): Team[] {
    return Object.keys(this.state.data).map(uid => this.state.data[uid])
  }

  /**
   * Get user fields by current team
   */
  get currentTeamUserFields (): string[] | undefined {
    return this.getters.currentTeam?.userFields
  }

  /**
   * Get team icon url by team id
   * @param teamId
   * @param large return is large icon url
   */
  teamIconURL (teamId: JID, large?: boolean): string | undefined {
    const team = this.state.data[teamId]
    if (!team) return
    const { icons } = team
    return getIconUrl(icons, large)
  }

  /**
   * Get number unread chat and group messages in the team
   * @param teamId
   */
  teamNumUnreadChats (teamId: JID): number {
    const team = this.state.data[teamId]
    if (!team) return 0

    const { unread } = team
    if (!unread) return 0

    const { direct, group } = unread
    return direct.messages + group.messages
  }

  /**
   * Get the number of unread chats in tasks in the team
   * @param teamId
   */
  teamNumUnreadTasks (teamId: JID): number {
    const team = this.state.data[teamId]
    if (!team) return 0

    const { unread } = team
    if (!unread) return 0

    const { task } = unread
    return task.chats
  }

  /**
   * Get is single group
   * @param teamId
   */
  isSingleGroupTeam (teamId?: JID): boolean {
    const team = teamId ? this.state.data[teamId] : this.getters.currentTeam
    if (!team) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('isSingleGroupTeam', 'isSingleGroup was called with a team id that was not found:')
        scope.setContext('teamId', { teamId })
      })
      return false
    }
    return !!team.singleGroup
  }
}
