import State from './state'
import Mutations from './mutations'
import Getters from './getters'
import { Actions } from 'vuex-smart-module'
import {
  teamsStore,
  uiStore,
} from '@/store'
import api from '@/api/v3'
import i18n, { changeLanguage } from '@/i18n'
import { loginLogger, routerLogger } from '@/loggers'
import router from '@/router'
import { initialHook } from '@/setupHelpers'
import { Country, TeamJSON } from '@tada-team/tdproto-ts'
import { move, transformEntityName } from '@/utils'
import * as Sentry from '@sentry/browser'
import { AuthOption } from './types'
import { Cookies, format } from 'quasar'
import { version as captchaTokenVersion } from '@/recaptcha'

const { capitalize } = format

class ModuleActions extends Actions<State, Getters, Mutations, ModuleActions> {
  /**
   * New loggedin status
   * @param isLoggedIn - new state
   */
  setLoginState (isLoggedIn = true) {
    loginLogger.debug('New Login status: ', isLoggedIn)
    this.mutations.setLoginState(isLoggedIn)
  }

  /**
   * Set bad profile status
   * it's about EXISTS name, surname and others
   * @param isBad
   */
  setBadProfileStatus (isBad = true) {
    loginLogger.debug('New bad profile status: ', isBad)
    this.mutations.setBadProfileStatus(isBad)
  }

  /**
   * Change lang and load countries for login
   * use careful because it's developed for login screens
   */
  async changeLang (loadCountries = true) {
    const lang = i18n.locale
    const newLang = lang === 'ru' ? 'en' : 'ru'
    await changeLanguage(newLang)

    window.goal('loginAction', { login: 'Footer: Изменен язык' })

    loadCountries && (await this.actions.getCountries(true))
    return newLang
  }

  /**
   * Auth by cookie
   * @param payload - sms + phone
   * @todo typing of returns value
   */
  cookieAuth ({
    smsCode,
    phoneNumber,
  }: {
    smsCode: string
    phoneNumber: string
  }) {
    return api.cookieAuth(smsCode, phoneNumber)
  }

  /**
   * Login by phone - action send sms for your phone, not instantly login!
   * @param phone
   * @todo typing of api returning result
   */
  async loginByPhone (p: { phone: string, token?: string }) {
    const { phone, token } = p
    const params: Parameters<typeof api.login>[0] = { phone }
    if (token) {
      params.token = token
      params.token_version = captchaTokenVersion
    }
    const result: any = await api.login(params)

    const {
      code_valid_until: codeExpDate,
      next_code_at: nextCodeAtDate,
      code_length: codeLen,
    } = result

    return { codeExpDate, nextCodeAtDate, codeLen }
  }

  /**
   * Auth by login and password to application
   * @param payload
   * @todo typing of api returning results
   */
  authByPassword ({ login, password }: { login: string; password: string }) {
    return api.authByPassword(login, password)
  }

  /**
   * Login by second factor auth with token from phone login and password of 2fa
   * @see loginByPhone
   * @param payload
   * @todo typing of api returning results
   */
  loginBySecondFactor ({
    token,
    password,
  }: {
    token: string
    password: string
  }) {
    return api.twoFactorAuth.setCookie({ token, password })
  }

  /**
   * Load countries in store and return default country
   * @param force - reload countries for new lang for example
   * @returns default country
   */
  async getCountries (force = false) {
    // 1 is here because we set at least one country in state ourselves
    if (this.state.countries.length === 1 || force) {
      let countries: Country[] = await api.getCountries()
      countries = countries.sort((a, b) => (a.name > b.name ? 1 : -1))
      this.mutations.setCountries(countries)
    }
    const defaultCountry = this.state.countries.find(c => c.isDefault)

    return defaultCountry
  }

  /**
   * Setup auth options from features
   * Uses for login tabs!
   * @see Login-index.vue
   */
  setupAuthOptions () {
    const options: Array<AuthOption> = []
    // eslint-disable-next-line camelcase
    const { oauth_services, auth_by_password, auth_by_sms } = window.FEATURES

    let isOauthEnabled = false

    // eslint-disable-next-line camelcase
    oauth_services && (isOauthEnabled = true)

    // eslint-disable-next-line camelcase
    auth_by_password && options.push('byPassword')

    // eslint-disable-next-line camelcase
    auth_by_sms && options.push('bySms')

    if (options.length === 0 && !isOauthEnabled) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('login', 'options')
        scope.setContext('features', window.FEATURES as any)
        Sentry.captureException(new Error('No valid login options provided'))
      })
    }

    this.mutations.setAuthOptions(options)

    this.mutations.setCurrentAuthOption(options[0])
  }

  /**
   * Setup electron server changer
   * @param callback
   * @todo any to typing of events and reply
   * @returns TRUE if server is LOCKED and cannot change, and FALSE if server change is free
   */
  setupElectronServerChanger (
    callback: (buffer: { phone: string; server: string }) => void,
  ): boolean {
    const features = window._electronFeatures
    const { _ipc } = window

    if (!features) return true
    if (features.includes('customBuild')) {
      return true
    }
    if (!features.includes('serverChanger')) return true

    loginLogger.log('setup server-changer')

    _ipc && _ipc.send('server-changer', { message: 'get-buffer' })
    // todo typing
    _ipc && _ipc.on('server-changer', (event: any, reply: any) => {
      const proceed = reply.message && reply.message === 'get-buffer'
      if (!proceed) return
      const buffer = reply.data
      if (!buffer) return
      callback(buffer)
      loginLogger.log('server-changer event', buffer)
    })
    return false
  }

  /**
   * Initiate loading teams and go to Entry or CreateTeam urls
   * @param result from api loadMe
   * @param phone for sentry
   */
  async loginIntoApp ({ result, phone }: { result: any; phone: string }) {
    let uid = ''
    let teams: Array<TeamJSON> = []
    try {
      teams = result.me.teams
      const team = teams && teams.find((team: TeamJSON) => team.last_active)
      uid = team ? team.uid : teams[0].uid ?? ''
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal)
        scope.setTag('login', 'onboarding')
        scope.setContext('features', window.FEATURES as any)
        scope.setContext('response', result)
        scope.setUser({
          id: 'not-logged-in',
          username: phone,
        })
        scope.setExtra('error', e)
        Sentry.captureException(new Error('Error during onboarding'))
      })
    }

    this.actions.setLoginState()

    if (uid) {
      await initialHook()
      router.push({ name: 'Main' }).catch(e => {
        routerLogger.debug('Router warning CreateTeam: ', e)
      })
      return
    }

    router.push({ name: 'Teams' }).catch(e => {
      routerLogger.debug('Router warning CreateTeam: ', e)
    })
  }

  /**
   * Seend mobile screen state
   * @param value
   * @see Login-index.vue - it's redirected to Mobile screen
   * @see Mobile-index.vue - the mobile screen
   */
  setMobileSeenState (value = true) {
    this.mutations.setMobileSeenState(value)
  }

  /**
   * Join into team by token from cookie
   * Must show in CreateTeam view and Entry.vue
   * @todo refactor, quasar get cookie use
   * @todo !tests!
   * @see quasar.dev/quasar-plugins/cookies#introduction
   * @deprecated
   */
  async tryJoinByInvite () {
    const token = Cookies.get('invitation_token')
    if (!token || !this.state.isLoggedIn) return
    try {
      const info = await api.invitations.info(token)
      uiStore.actions.showModal({
        instance: 'universal-yes-no',
        payload: {
          title: capitalize(i18n.t('modals.InvitationModal.title').toString()),
          text: `${i18n.t(
            'modals.InvitationModal.text',
          )} <span style="font-weight: 800;">«${transformEntityName(info.team &&
          info.team.name)}»</span>`,
          yesText: i18n.t('modals.InvitationModal.yesText'),
          yes: async () => {
            Cookies.remove('invitation_token')
            this.actions.setBadProfileStatus(true)
            teamsStore.actions.joinTeamByInvite(token).then(move)
          },
          no: () => {
            Cookies.remove('invitation_token')
            uiStore.actions.hideModal()
          },
        },
      })
    } catch (e) {
      loginLogger.warn('[Invitation error]', e)
    }
  }

  /**
   * Set profile info for current user (based on contact editing)
   * @param firstName - string
   * @param secondName - string
   * @param patronymic - string  | undef
   * @param avatar - Blob | undef
   * @throws Error with fields given_name, family_name, file or empty  key in details
   */
  async setProfileInfo ({
    firstName,
    secondName,
    patronymic,
    avatar,
  }: {
    firstName: string
    secondName: string
    patronymic?: string
    avatar?: Blob | null
  }): Promise<void> {
    const team = teamsStore.getters.currentTeam
    if (!team) return
    const { me, uid, usePatronymic } = team
    const { jid } = me

    const data: {
      // eslint-disable-next-line camelcase
      given_name: string
      // eslint-disable-next-line camelcase
      family_name: string
      patronymic?: string
    } = {
      given_name: firstName,
      family_name: secondName,
    }
    usePatronymic && (data.patronymic = patronymic)

    const profile = await api.contacts.edit(jid, data)

    let icons = null
    if (avatar) {
      try {
        icons = await api.contacts.setIcon(jid, avatar, uid)
        profile.icons = icons
      } catch (e) {
        loginLogger.error('Error with setting profile icon')
      }
      window.goal('onboardingAction', {
        onboarding: 'Profile: Установлен аватар',
      })
    }

    teamsStore.actions.updateTeam(Object.assign(team, { me: profile }))
  }

  /**
   * Uses for displaying accepted invites
   * @param value  - uid of team
   */
  pushAcceptedInviteUid (value: string) {
    if (this.state.acceptedInvitesUids.includes(value)) return
    this.mutations.pushAcceptedInviteUid(value)
  }

  /**
   * Add to team
   * @param payload - array of phones and team uid
   */
  async addManyToTeam (payload: { phones: Array<string>; teamId: string }) {
    payload.phones.forEach(p => {
      p = p.replace(/\s/g, '')
      if (!p) return
      api.contacts.add(payload.teamId, { phone: p })
    })
  }

  /**
   * Get invitation info by token
   * @param token
   */
  getInvitationInfo (token: string) {
    return api.invitations.info(token)
  }

  /**
   * Decline invitations by deleting ME from the team
   * @param payload - jid of team.me.jid and team.uid
   */
  async declineTeamInvitation (payload: {
    jid: string
    uid: string
  }): Promise<void> {
    await api.contacts.delete(payload.jid, payload.uid)
    await teamsStore.actions.deleteTeam(payload.uid)
  }

  /**
   * Get custom server theme by flag from FEATURES - custom_theme
   * @see themes.ts-setupFeaturesTheme
   */
  getServerTheme (): Promise<Record<'web_base', Record<string, string>>> {
    return api.serverTheme()
  }
}

export default ModuleActions
