export function filterMutationsByChatId (chatId, mutations) {
  const mutationsTypes = Object.keys(mutations)
  const filteredMutations = {}
  mutationsTypes.forEach(mutationType => {
    filteredMutations[mutationType] = (state, payload) => {
      if (payload && payload.chatId === chatId) {
        mutations[mutationType](state, payload)
      }
    }
  })
  return filteredMutations
}

export function mapFunctionForTypes (types, func) {
  const result = {}
  types.forEach(type => {
    result[type] = func
  })
  return result
}
