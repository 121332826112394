import Vue from 'vue'

export const Events = {
  CHAT_READY: 'CHAT_READY',

  ADD_UNDELIVERED_MESSAGE: 'ADD_UNDELIVERED_MESSAGE',
  REMOVE_UNDELIVERED_MESSAGE: 'REMOVE_UNDELIVERED_MESSAGE',

  SENDING_MESSAGE_ERROR: 'SENDING_MESSAGE_ERROR',
  ADD_SINGLE_MESSAGE: 'ADD_SINGLE_MESSAGE',
  ADD_NEW_MESSAGES: 'ADD_NEW_MESSAGES',
  ADD_LAST_MESSAGES: 'ADD_LAST_MESSAGES',
  ADD_OLD_MESSAGES: 'ADD_OLD_MESSAGES',
  ADD_AROUND_MESSAGES: 'ADD_AROUND_MESSAGES',
  SET_MESSAGE_IMPORTANCE: 'SET_MESSAGE_IMPORTANCE',
  TOGGLE_MESSAGE_REACTION: 'TOGGLE_MESSAGE_REACTION',
  MARK_MESSAGE_AS_RECEIVED: 'MARK_MESSAGE_AS_RECEIVED',
  READ_MESSAGES_IN_ACTIVE_CHAT: 'READ_MESSAGES_IN_ACTIVE_CHAT',
  MESSAGES_RESTORED: 'MESSAGES_RESTORED',

  SET_CHAT_LOADING_STATUS: 'SET_CHAT_LOADING_STATUS',
  SET_OLD_MESSAGES_LOADING_STATUS: 'SET_OLD_MESSAGES_LOADING_STATUS',
  SET_NEW_MESSAGES_LOADING_STATUS: 'SET_NEW_MESSAGES_LOADING_STATUS',
  SET_LAST_MESSAGES_LOADING_STATUS: 'SET_LAST_MESSAGES_LOADING_STATUS',
  SET_AROUND_MESSAGES_LOADING_STATUS: 'SET_AROUND_MESSAGES_LOADING_STATUS',

  TOGGLE_HISTORY_MODE: 'TOGGLE_HISTORY_MODE',
  CHAT_WAS_READED_TO: 'CHAT_WAS_READED_TO',
  SCROLL_CHAT: 'SCROLL_CHAT',
  UPDATE_CHAT_SCROLLBAR: 'UPDATE_CHAT_SCROLLBAR',
  TOGGLE_IMPORTANT_MESSAGES_VIEWER: 'TOGGLE_IMPORTANT_MESSAGES_VIEWER',
  TOGGLE_MESSAGE_ACTIVE: 'TOGGLE_MESSAGE_ACTIVE',

  HANDLE_ALL_SELECTED: 'HANDLE_ALL_SELECTED',
  OPEN_IMAGE_VIEWER: 'OPEN_IMAGE_VIEWER',
  REINIT_CHAT: 'REINIT_CHAT',

  MESSAGE_PINNED: 'MESSAGE_PINNED',
  GO_TO_MESSAGE: 'GO_TO_MESSAGE',

  SENDER_UPDATED: 'SENDER_UPDATED',
}

export const ChatEventBus = new Vue()
