import { CallDisplayType, CallMdDisplayType, CallMembersDisplayType, CallUiLgLayoutType, CallUiLgRightBarDisplayType } from './models'

export const UI_DISPLAY_TYPE: CallDisplayType = null
export const UI_MD_DISPLAY_TYPE: CallMdDisplayType = 'CALL'
export const UI_LG_RIGHT_BAR_DISPLAY_TYPE: CallUiLgRightBarDisplayType = null
export const UI_LG_LAYOUT_TYPE: CallUiLgLayoutType = 'GRID'
export const MEMBER_DISPLAY_TYPE: CallMembersDisplayType = 'CARDS'
export const TALKING_TIMEOUT = Math.PI * 1000 // писекунд
export const BUZZING_WINDOW_TTL = 30000
export const OUTPUT_VOLUME = 1
