import { activeCallStore, callsStore } from '@/store'
import State from '@/store/modules/root/state'
import { CallStatus } from '@/store/modules/root/types'
import { JID } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'

export default class Getters extends BaseGetters<State> {
  get callStatuses (): Record<JID, CallStatus | undefined> {
    const res: Record<JID, CallStatus> = {}

    for (const call of Object.values(callsStore.state.calls)) {
      res[call.jid] = callsStore.state.incomingBuzzingCalls[call.jid]
        ? 'buzzing'
        : 'default'
    }

    const { activeCall } = activeCallStore.state
    if (activeCall) {
      res[activeCall.jid] = (
        activeCall.isLoud &&
        activeCall.isOutgoing &&
        activeCall.maxConnectedMembersCount < 2
      ) ? 'outgoing' : 'active'
    }

    return res
  }
}
