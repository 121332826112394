import { DataAdapter } from '@/api/v3/DataAdapter'
import Notifier from '@/electron/Notifier'
import { defaultLogger, networkLogger } from '@/loggers'
import router from '@/router'
import store, {
  activeCallStore,
  callsStore,
  chatsBarStore,
  chatImportStore,
  contactsStore,
  dataImportsStore,
  draftsStore,
  groupsStore,
  meetingsStore,
  publicGroupsStore,
  remindsStore,
  sectionsStore,
  tagsStore,
  tasksStore,
  teamsStore,
  uiSettingsStore,
  uiStore,
  threadsStore,
} from '@/store'
import * as actionTypes from '@/store/actionTypes'
import Messages from '@/store/messages'
import { getChatType, logout } from '@/utils'
import Socket from '@/ws'
import { mutationTypes } from '@/ws/store'
import * as Sentry from '@sentry/browser'
import {
  CallEvent,
  CallEventJSON,
  Chat,
  ChatCounters,
  Contact,
  ContactJSON,
  OnlineContact,
  ServerCallAnswerParams,
  ServerCallAnswerParamsJSON,
  ServerCallBuzzcancelParams,
  ServerCallBuzzcancelParamsJSON,
  ServerCallBuzzParams,
  ServerCallBuzzParamsJSON,
  ServerCallMuteallParams,
  ServerCallMuteallParamsJSON,
  ServerCallRejectParams,
  ServerCallRejectParamsJSON,
  ServerCallRestartParams,
  ServerCallRestartParamsJSON,
  ServerCallScreenShareParams,
  ServerCallScreenShareParamsJSON,
  ServerCallSdpParams,
  ServerCallSdpParamsJSON,
  ServerCallSoundParamsJSON,
  ServerCallTalkingParams,
  ServerCallTalkingParamsJSON,
  ServerChatComposingParamsJSON,
  ServerChatDeletedParams,
  ServerChatDeletedParamsJSON,
  ServerChatDraftParams,
  ServerChatDraftParamsJSON,
  ServerChatLastreadParams,
  ServerChatLastreadParamsJSON,
  ServerChatUpdatedParams,
  ServerChatUpdatedParamsJSON,
  ServerConfirmParams,
  ServerConfirmParamsJSON,
  ServerMeetingUpdatedParams,
  ServerMeetingUpdatedParamsJSON,
  ServerMessageReceivedParams,
  ServerMessageReceivedParamsJSON,
  ServerMessageUpdatedParams,
  ServerMessageUpdatedParamsJSON,
  ServerMeetingCellUpdatedParams,
  ServerMeetingCellUpdatedParamsJSON,
  ServerOnlineParams,
  ServerOnlineParamsJSON,
  ServerProcessingParams,
  ServerProcessingParamsJSON,
  ServerRemindDeletedParams,
  ServerRemindDeletedParamsJSON,
  ServerRemindFiredParams,
  ServerRemindFiredParamsJSON,
  ServerRemindUpdatedParams,
  ServerRemindUpdatedParamsJSON,
  ServerSectionDeletedParams,
  ServerSectionDeletedParamsJSON,
  ServerSectionUpdatedParams,
  ServerSectionUpdatedParamsJSON,
  ServerTeamCountersParams,
  ServerTeamCountersParamsJSON,
  ServerTeamUpdatedParamsJSON,
  ServerTimeParamsJSON,
  Tag,
  TagJSON,
  Team,
  TeamJSON,
  TaskItemJSON,
  TaskItem,
} from '@tada-team/tdproto-ts'

function onDisconnect () {
  Messages.makeLastMessagesSnapshot()
  store.commit(mutationTypes.SOCKET_CONNECTION_STATE, false)
  store.commit(mutationTypes.SOCKET_READY_STATE, false)

  callsStore.actions.clearCalls()
}

export default {
  open () {
    store.commit(mutationTypes.SOCKET_CONNECTION_STATE, true)
    Messages.sendAllUndeliveredMessages()

    store.dispatch(actionTypes.LOAD_ROSTER)
  },
  close () {
    onDisconnect()
  },
  error () {
    onDisconnect()
  },
  start () {
    store.commit(mutationTypes.SOCKET_READY_STATE, true)
  },
  'server.team.updated' (params: ServerTeamUpdatedParamsJSON) {
    params.teams.forEach(team => {
      teamsStore.actions.updateTeam(Team.fromJSON(team))
    })
  },
  'server.team.counters' (params: ServerTeamCountersParamsJSON) {
    teamsStore.actions.socketOnTeamsUnreads(ServerTeamCountersParams.fromJSON(params).teams)
  },
  'server.online' (params: ServerOnlineParamsJSON): void {
    const parsed = ServerOnlineParams.fromJSON(params)
    callsStore.actions.setCalls(parsed.calls)

    contactsStore.actions.socketOnContactOnline(params.contacts.map(OnlineContact.fromJSON))
  },
  'server.chat.composing' (
    { jid, actor, composing, is_audio: audio }: ServerChatComposingParamsJSON,
  ): void {
    store.dispatch(actionTypes.SOCKET_ON_OTHER_MESSAGE_COMPOSING, {
      chatId: jid,
      actorId: actor,
      composing,
      audio,
    })
  },
  'server.team.deleted' ({ teams }: { teams: TeamJSON[] }) {
    const currentTeamId = teamsStore.state.currentTeamId
    let isHaveCurrentTeam = false
    const removedTeamsUid: string[] = []
    teams.forEach(team => {
      if (team.uid === currentTeamId) isHaveCurrentTeam = true
      teamsStore.actions.deleteTeam(team.uid)
      removedTeamsUid.push(team.uid)
    })
    const currentTeams = teamsStore.getters.teams.filter(t => !removedTeamsUid.includes(t.uid))
    if (isHaveCurrentTeam) {
      if (currentTeams.length) {
        router.push({
          name: 'Team',
          params: {
            teamId: currentTeams[0].uid,
          },
        })
      } else {
        router.push({
          name: 'Teams',
        })
      }
    }
  },
  'server.contact.updated' ({ contacts: rawContacts }: { contacts: ContactJSON[] }) {
    rawContacts.forEach(rawContact => {
      const contact = Contact.fromJSON(rawContact)
      store.dispatch(actionTypes.UPDATE_CONTACT, { contact })
      teamsStore.actions.updateContactInCurrentTeam(contact)
      contactsStore.actions.updateContact(contact)
    })
  },
  'server.contact.deleted' ({ contacts }: { contacts: TADA.Contact[] }) {
    contacts.forEach(({ jid }) => contactsStore.actions.deleteContact(jid))
  },
  'server.section.updated' (p: ServerSectionUpdatedParamsJSON): void {
    const { sections, chatType } = ServerSectionUpdatedParams.fromJSON(p)
    if (!sections) return

    sections.forEach(section => {
      sectionsStore.actions.updateSection({ section, chatType })
    })
  },
  'server.section.deleted' (p: ServerSectionDeletedParamsJSON): void {
    const { sections, chatType } = ServerSectionDeletedParams.fromJSON(p)
    if (!sections) return

    sections
      .forEach(s => sectionsStore.mutations.delete({ uid: s.uid, chatType }))
  },
  'server.chat.draft' (p: ServerChatDraftParamsJSON) {
    const params = ServerChatDraftParams.fromJSON(p)
    draftsStore.actions.updateManyByServer([{
      id: params.jid,
      context: params.draft,
      revision: params.revision,
    }])
  },
  'server.chat.updated' (p: ServerChatUpdatedParamsJSON) {
    p.chats.forEach(rawChat => {
      if (rawChat.chat_type === 'task') {
        const task = DataAdapter.task(rawChat)
        tasksStore.mutations.addTask({ jid: rawChat.jid, task })
        uiStore.actions.taskDeskTaskUpdated({ task })
      }

      if (rawChat.chat_type === 'group') {
        const group = Chat.fromJSON(rawChat)
        groupsStore.actions.update(group)

        if (
          publicGroupsStore.getters.exists(group.jid) &&
          !publicGroupsStore.getters.wasJoined(group.jid) &&
          group.members?.some(item => item.jid === store.getters.profileId)
        ) {
          publicGroupsStore.mutations.addJoinedId(group.jid)

          if (publicGroupsStore.state.sneakpeekId === group.jid) {
            publicGroupsStore.mutations.removeSneakpeekId()
          }
        }
      }

      if (rawChat.chat_type === 'thread') {
        const thread = Chat.fromJSON(rawChat)
        chatsBarStore.actions.loadThreads()
      }

      const chat = DataAdapter.chat(rawChat)
      store.dispatch(actionTypes.UPDATE_CHAT, chat)
    })

    const params = ServerChatUpdatedParams.fromJSON(p)
    chatsBarStore.actions.onServerChatUpdated(params)
  },
  'server.chat.deleted' (p: ServerChatDeletedParamsJSON) {
    p.chats.forEach(({ jid, chat_type: chatType }) => {
      store.dispatch(actionTypes.DELETE_CHAT, { chatId: jid, type: chatType })

      if (chatType === 'task') {
        tasksStore.mutations.remove(jid)
        uiStore.actions.deleteTaskDeskTask({ jid })
      }
    })

    const params = ServerChatDeletedParams.fromJSON(p)
    chatsBarStore.actions.onServerChatDeleted(params)
    meetingsStore.actions.serverMeetingsRemoved(params.chats)
    draftsStore.actions.onServerChatsDeleted(params.chats)
  },
  'server.message.updated' (p: ServerMessageUpdatedParamsJSON) {
    // const parsed = ServerMessageUpdatedParams.fromJSON(p)
    /**
     * Bodgy bodge because server sometimes sends null
     * for badge and team_counters. Which is against defined proto.
     */
    const counters = p.chat_counters.map(c => ChatCounters.fromJSON(c))
    const { messages: rawMessages, delayed } = p
    const messages = DataAdapter.messages(rawMessages)
    networkLogger.debug(
      `-> Message: [${messages.data[messages.orderIds[0]].content.text.slice(0, 42)}...] delayed=${delayed}`,
      counters[0] && counters[0].numUnread,
    )

    /**
     * takes all chatIds mentioned in a socket event
     * puts them in a single array
     * filters out duplicates
     * of which filters out chats that are not loaded locally
     */
    const missingChats = [
      ...counters.map(c => c.jid),
      ...Object.values(messages.data).map(m => m.chatId),
    ].filter((chatId, i, arr) => (
      arr.indexOf(chatId) === i &&
      !store.getters.chat(chatId)
    ))

    missingChats.forEach(chatId => store.dispatch('LOAD_CHAT', chatId))

    // only updates counters for chats already in store
    counters
      .filter(c => !missingChats.includes(c.jid))
      .forEach(c => {
        const {
          jid: chatId,
          numUnread,
          numUnreadNotices,
          lastActivity,
        } = c
        networkLogger.debug('Received last activity in socket', lastActivity)

        store.dispatch(
          actionTypes.SOCKET_ON_CHAT_COUNTERS,
          { chatId, numUnread, numUnreadNotices, lastActivity },
        )
      })

    // only updates Messages store and tasks for chats already in store
    const messageId = messages.orderIds[0]
    const chatId = messages.data[messageId].chatId
    if (missingChats.includes(chatId)) return

    Messages.addMessage({ messages, delayed })
    tasksStore.actions.updateByMessages(messages)

    const params = ServerMessageUpdatedParams.fromJSON(p)
    chatsBarStore.actions.onServerMessageUpdated(params)
    meetingsStore.actions.serverMeetingsCountersUpdated(counters)
  },
  'server.message.push' (pushMessage: any) {
    Notifier.push({
      chatId: pushMessage.chat,
      teamId: pushMessage.team,
      chatType: getChatType(pushMessage.chat),
      sender: pushMessage.sender,
      tag: pushMessage.chat, // push collapse tag
      title: pushMessage.title,
      icon: pushMessage.icon_url, // clean up push text?
      body: pushMessage.message,
      normalized: true,
    })
  },
  'server.message.received' (p: ServerMessageReceivedParamsJSON) {
    p.messages.forEach(data => {
      const { chat, message_id: messageId } = data
      Messages.markMessageAsReceived(chat, messageId)
    })

    const params = ServerMessageReceivedParams.fromJSON(p)
    chatsBarStore.mutations.setLastMessagesReceived(
      params.messages.map(
        ({ chat: chatId, messageId, received }) => ({
          chatId,
          messageId,
          received,
        }),
      ),
    )
  },

  'server.chat.lastread' (p: ServerChatLastreadParamsJSON) {
    p.chats && p.chats.forEach(chat => {
      const {
        jid: chatId,
        last_read_message_id: messageId,
        num_unread: numUnread,
        num_unread_notices: numUnreadNotices,
      } = chat

      store.dispatch(actionTypes.SOCKET_ON_LAST_READ_MESSAGE, { chatId, messageId })
      store.dispatch(actionTypes.SOCKET_ON_CHAT_COUNTERS, { chatId, numUnread, numUnreadNotices })
    })

    const params = ServerChatLastreadParams.fromJSON(p)
    chatsBarStore.actions.onServerChatLastread(params)

    const { chats, teamUnread: unread } = params
    const teamId = teamsStore.getters.currentTeam.uid
    teamsStore.mutations.updateTeamUnread({ teamId, unread })
    meetingsStore.actions.serverMeetingsCountersUpdated(chats)
  },
  'server.confirm' (p: ServerConfirmParamsJSON) {
    const { confirmId } = ServerConfirmParams.fromJSON(p)
    const id = confirmId.charAt(0)
    const data = confirmId.substring(1)
    switch (id) {
      case 'm':
        Messages.addConfirmedMessage(data)
        break
    }
  },
  'server.panic' ({ code }: { code: any }) {
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal)
      scope.setTag('server', 'panic')
      scope.setContext('code', code)
      Sentry.captureException('Server PANIC packet received in main socket')
    })
    switch (code) {
      case 'NOT_FOUND': {
        logout(true)
        break
      }
      case 'SERVER_INTERNAL_ERROR': {
        Socket.close('Server internal error')
        break
      }
      default: {
        logout()
      }
    }
  },
  'server.warning' ({ code }: { code: any }) {
    Sentry.withScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal)
      scope.setTag('server', 'warning')
      scope.setContext('code', code)
      Sentry.captureException('Server WARNING packet received in main socket')
    })
  },
  'server.remind.updated' (params: ServerRemindUpdatedParamsJSON) {
    const { reminds } = ServerRemindUpdatedParams.fromJSON(params)
    remindsStore.mutations.updateReminds(reminds)
  },
  'server.remind.deleted' (params: ServerRemindDeletedParamsJSON) {
    const { reminds } = ServerRemindDeletedParams.fromJSON(params)
    remindsStore.mutations.deleteReminds(reminds.map(item => item.uid))
  },
  'server.remind.fired' (params: ServerRemindFiredParamsJSON) {
    const { reminds } = ServerRemindFiredParams.fromJSON(params)
    remindsStore.mutations.deleteReminds(reminds.map(item => item.uid))
  },
  'server.login' () {
    if (uiStore.getters.currentModal === 'MobileLogin') {
      uiStore.actions.showModal({ instance: 'MobileLogin', payload: { success: true } })
      window.setTimeout(() => uiStore.actions.hideModal(), 500)
    }
  },
  'server.taskitems.updated' (p: { jid: string; items: TaskItemJSON[] }) {
    const items = p.items.map(item => TaskItem.fromJSON(item))
    tasksStore.actions.updateItems({ jid: p.jid, items })
  },
  'server.uisettings' (params: any) {
    const ns = params.namespace

    let settings: any = null
    if (!ns) {
      settings = params
    } else if (ns === 'web') {
      settings = params.data
    }
    if (!settings) return

    const currentTeamId = teamsStore.state.currentTeamId
    const updatedSettings = settings[`settings-${currentTeamId}`]
    updatedSettings && uiSettingsStore.actions.addUISettingsData({ uiSettings: updatedSettings })
  },
  'server.processing' (actionMessage: ServerProcessingParamsJSON) {
    const params = ServerProcessingParams.fromJSON(actionMessage)

    if (params.actionType === 'archive_unpacking' || params.actionType === 'generate_chat') {
      chatImportStore.actions.onProcessing(params)
    } else {
      dataImportsStore.actions.socketOnProcessing(params)
    }
  },

  /**
   * Tags are not editable or deletable for now. This only fires for new tags.
   */
  'server.tag.updated' ({ tags }: { tags: TagJSON[] }) {
    tagsStore.mutations.ADD_TAGS(tags.map(Tag.fromJSON))
  },

  'server.time' (p: ServerTimeParamsJSON): void {
    defaultLogger.warn('Unexpected server.time event', p)
  },

  /**
   *
   * CALL EVENTS BELOW
   *
   */

  'server.call.state' (p: CallEventJSON): void {
    activeCallStore.actions.handleStateEvent(CallEvent.fromJSON(p))
  },

  /**
   * Handling this event is unnecessary.
   * Client is the source of truth for its own mute state.
   * This is until server brings back the 'mute all' logic to calls.
   * Even then, this will need to be adjusted because WS are asyncronous.
   * Client needs to know the origin of mute state change.
   * @param p this
   */
  'server.call.sound' (p: ServerCallSoundParamsJSON): void {
    networkLogger.log('Received call.sound event. Not handling.', p)
  },

  'server.call.sdp' (p: ServerCallSdpParamsJSON): void {
    activeCallStore.actions.handleNewPeer(ServerCallSdpParams.fromJSON(p))
  },

  'server.call.muteall' (p: ServerCallMuteallParamsJSON): void {
    callsStore.actions.handleMuteallEvent(ServerCallMuteallParams.fromJSON(p))
  },

  'server.call.answer' (p: ServerCallAnswerParamsJSON): void {
    activeCallStore.actions.handleAnswerEvent(ServerCallAnswerParams.fromJSON(p))
  },

  'server.call.restart' (p: ServerCallRestartParamsJSON): void {
    activeCallStore.actions.handleRestartEvent(ServerCallRestartParams.fromJSON(p))
  },

  'server.call.talking' (p: ServerCallTalkingParamsJSON): void {
    activeCallStore.actions.handleTalkingEvent(ServerCallTalkingParams.fromJSON(p))
  },

  'server.call.reject' (p: ServerCallRejectParamsJSON): void {
    activeCallStore.actions.handleRejectEvent(ServerCallRejectParams.fromJSON(p))
  },

  'server.call.buzzcancel' (p: ServerCallBuzzcancelParamsJSON): void {
    callsStore.actions.handleBuzzCancel(ServerCallBuzzcancelParams.fromJSON(p))
  },

  'server.call.buzz' (p: ServerCallBuzzParamsJSON): void {
    callsStore.actions.handleBuzz(ServerCallBuzzParams.fromJSON(p))
  },

  'server.call.screenshare' (p: ServerCallScreenShareParamsJSON): void {
    activeCallStore.actions.onServerCallScreenshare(
      ServerCallScreenShareParams.fromJSON(p),
    )
  },
  'server.meeting.updated' (p: ServerMeetingUpdatedParamsJSON): void {
    const params = ServerMeetingUpdatedParams.fromJSON(p)
    meetingsStore.actions.serverMeetingsUpdated(params.meetings)
    meetingsStore.actions.setMeetingsCount(params.userMeetingsCount)
    meetingsStore.actions.setMeetingsDates(params.userMeetingsDates)
  },
  'server.meetingcell.updated' (p: ServerMeetingCellUpdatedParamsJSON): void {
    meetingsStore.actions
      .serverMeetingCellUpdated(ServerMeetingCellUpdatedParams.fromJSON(p))
  },
}
