import { contactsStore, groupsStore, tasksStore, threadsStore } from '@/store'
import { getChatType, getChatPlaceholderName } from '@/utils'

export default {
  directChats: state => state.chats.directChats,
  directChatsIds: (state, { directChats }) => Object.keys(directChats),

  groupChats: state => Object.fromEntries(
    Object.entries(state.chats.groupChats).filter(
      item => item[1].chatId !== null,
    ),
  ),
  groupChatsIds: (state, { groupChats }) => Object.keys(groupChats),

  taskChats: state => state.chats.taskChats,
  taskChatsIds: (state, { taskChats }) => Object.keys(taskChats),

  meetingChats: state => state.chats.meetingChats,

  threadChats: state => state.chats.threadChats,

  chatsIds: (state, { directChatsIds, groupChatsIds }) => {
    return [...directChatsIds, ...groupChatsIds]
  },
  chat: (state, { directChats, groupChats, taskChats, meetingChats, threadChats }) => chatId => {
    return directChats[chatId] || groupChats[chatId] || taskChats[chatId] || meetingChats[chatId] || threadChats[chatId]
  },
  chatExists: (state, { directChatsIds, groupChatsIds, taskChatsIds }) => chatId => {
    const ids = [...directChatsIds, ...groupChatsIds, ...taskChatsIds]
    return ids.includes(chatId)
  },
  chatLastReadMessageId: (state, getters) => id => {
    const chat = getters.chat(id)
    return chat && chat.lastReadMessageId
  },
  chatStartingMessageId: (state, getters) => id => {
    const chat = getters.chat(id)
    return chat && chat.startingMessageId
  },
  chatBotCommands: (state, getters) => (chatId, type = 'commands') => {
    const chat = getters.chat(chatId)
    return chat && chat[type]
  },
  chatType: (state, getters) => id => {
    const chat = getters.chat(id)
    return chat?.chatType || getChatType(id)
  },
  chatIsGroup: () => id => !!groupsStore.state.data[id],
  chatIsThread: () => id => !!threadsStore.state.data[id],
  chatIsDirect: (state, { directChats }) => id => !!directChats[id],
  chatIcon: () => id => contactsStore.getters.contactIcon(id) || groupsStore.getters.icon(id),
  chatName: (state, getters) => id => {
    if (groupsStore.state.data[id]) return groupsStore.getters.displayName(id)
    if (tasksStore.state.data[id]) return tasksStore.getters.displayName(id)
    if (contactsStore.getters.contact(id)) return contactsStore.getters.contactDisplayName(id)
    if (getters.meetingChats[id]) return getters.meetingChats[id].displayName
    if (getters.threadChats[id]) return getters.threadChats[id].pushText

    return getChatPlaceholderName({ jid: id })
  },
  chatDescription: () => id => contactsStore.getters.contactRole(id) || groupsStore.getters.description(id),
  chatComposingIds: (state, getters) => chatId => {
    if (!getters.isSocketOpen) {
      return []
    }
    const chat = getters.chat(chatId)
    return chat?.composingIds.filter(id => id !== getters.getUserId) || []
  },
  chatAudioComposingIds: (state, getters) => chatId => {
    if (!getters.isSocketOpen) {
      return []
    }
    const chat = getters.chat(chatId)
    return chat?.audioComposingIds.filter(id => id !== getters.getUserId) || []
  },
  chatWasNoticed: (state, getters) => id => {
    const chat = getters.chat(id)
    return chat && !!chat.numUnreadNotices
  },
  chatLastMessage: (state, getters) => chatId => {
    const chat = getters.chat(chatId)
    const chatLastMessage = chat && chat.lastMessage

    return chatLastMessage
  },
  chatNumUnread: (state, getters) => chatId => {
    const chat = getters.chat(chatId)
    return chat ? chat.numUnread || 0 : 0
  },
  chatPinnedState: (state, getters) => chatId => {
    const chat = getters.chat(chatId)
    return chat && chat.pinned
  },
  chatsTotalUnreadNum: (state, { chatsIds, chatNumUnread }) => {
    let total = 0
    chatsIds.forEach(chatId => {
      total += chatNumUnread(chatId) || 0
    })
    return total
  },
  chatsWasNoticed: (state, { chatsIds, chatWasNoticed }) => {
    return chatsIds.some(chatId => chatWasNoticed(chatId))
  },
  lastChat: state => state.chats.lastChat,
  currentChat: state => state.chats.currentChat,
  currentChatErrorType: state => state.chats.currentChatErrorType,
  previousOpenChat: state => state.chats.previousOpenChat,
  isChatOpen: state => chatId => state.chats.currentChat === chatId,
  getCurrentActiveMessageData: state => state.chats.currentActiveMessageData,
}
