import { Getters } from 'vuex-smart-module'
import { CallType, OnlineCall } from '@tada-team/tdproto-ts'
import State from './state'

class ModuleGetters extends Getters<State> {
  call (jid: string): OnlineCall | undefined {
    return this.state.calls?.[jid]
  }

  isBuzzing (jid: string): boolean {
    return !!this.state.incomingBuzzingCalls[jid]
  }

  get membersLimit (): number {
    return window.FEATURES.max_participants_per_call ?? 0
  }

  isCallingEnabled (type?: CallType): boolean {
    const {
      calls_audio_enabled: audioEnabled,
      calls_video_enabled: videoEnabled,
      calls_video_multistream_enabled: videoMultistreamEnabled,
    } = window.FEATURES

    switch (type) {
      case 'audio': return audioEnabled
      case 'video': return videoEnabled
      case 'video_multistream': return videoMultistreamEnabled
      default: return audioEnabled || videoEnabled || videoMultistreamEnabled
    }
  }

  get preferredCallType (): CallType | null {
    if (this.getters.isCallingEnabled('video_multistream')) {
      return 'video_multistream'
    }
    if (this.getters.isCallingEnabled('video')) return 'video'
    if (this.getters.isCallingEnabled('audio')) return 'audio'
    return null
  }

  get iceConfig (): RTCConfiguration {
    return this.state.iceConfig
  }
}

export default ModuleGetters
