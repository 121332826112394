import { AuthOption } from './types'
import { Country } from '@tada-team/tdproto-ts'

class ModuleState {
  /**
   * Global app state with flag that means Logged In or Not
   * probably setup by Login screen or initialHook on app starting
   */
  isLoggedIn = false

  /**
   * Countries accepted by backend for login
   * Initialize with default country - Russia for better UX
   * reloading by openning the page Login
   * @see Login-index.vue
   */
  countries: Array<Country> = [new Country('+7', 'RU', 'Россия')]

  /**
   * Storing the auth options from features in store
   */
  authOptions: Array<AuthOption> = ['bySms']

  /**
   * Current auth option from authOptions array above on login screen
   * @see Login-index.vue
   */
  currentAuthOption: AuthOption = 'bySms'

  /**
   * Bad profile means empty first and second names on profile
   * Uses for show modal after loading to app for inputting that
   */
  isBadProfile = false

  /**
   * Flag of seeing mobile screen with "go to app/web" meaningful
   * @see Mobile-index.vue
   */
  seenMobile = false

  /**
   * Array of teams uids that are accepted in TheInvitationsView screen
   * Uses for storing accepted invites before reload the page
   * THis is ugly, but server do not provide api for accepting the invites
   * @see Team.needConfirmation only for teams with this param
   * @see TheInvitationsView.vue
   */
  acceptedInvitesUids: Array<string> = []
}

export default ModuleState
