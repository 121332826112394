import State from '@/store/modules/threads/state'
import {
  Chat,
  JID,
  GroupMembership,
  IconData,
} from '@tada-team/tdproto-ts'
import Vue from 'vue'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  setData (groups: Record<JID, Chat>): void {
    this.state.data = groups
  }

  update (thread: Chat): void {
    Vue.set(this.state.data, thread.jid, thread)
  }

  delete (jid: JID): void {
    Vue.delete(this.state.data, jid)
  }

  deleteMember ({ groupId, memberId }: { groupId: JID, memberId: JID }): void {
    const group = this.state.data[groupId]
    if (!group || !group.members) return

    const index = group.members.findIndex(item => item.jid === memberId)
    if (!index) return

    group.members.splice(index, 1)
    group.numMembers && group.numMembers--
  }

  setMember ({ groupId, member }: {
    groupId: JID,
    member: GroupMembership
  }): void {
    const group = this.state.data[groupId]
    if (!group) return
    if (!group.members) Vue.set(group, 'members', group.members = [])

    const index = group.members.findIndex(item => item.jid === member.jid)

    if (index >= 0) {
      group.members.splice(index, 1, member)
    } else {
      group.members.push(member)
      group.numMembers && group.numMembers++
    }
  }

  setMembers ({ groupId, members }: {
    groupId: JID,
    members: GroupMembership[]
  }): void {
    const group = this.state.data[groupId]
    if (!group) return
    if (!group.members) Vue.set(group, 'members', group.members = [])

    group.members = members
  }

  setIcon ({ jid, icons }: { jid: JID, icons: IconData }): void {
    const group = this.state.data[jid]
    group && Vue.set(group, 'icons', icons)
  }
}
