import { webRTCLogger } from '@/loggers'
import { CallLifecycleState } from '@/store/modules/activeCall/models'
import { CallType } from '@tada-team/tdproto-ts'
import { Signalling } from './Signalling'

export type WebRTCBaseConstructorParams = {
  callJid: string,
  callType: CallType,
  iceConfig: RTCConfiguration,
  onStateChange: (state: CallLifecycleState) => unknown,
  onFatalFailure: (reason: string) => void
}

export abstract class WebRTCBase {
  protected readonly peerConnection: RTCPeerConnection
  protected readonly signalling: Signalling
  protected readonly callJid: string
  private readonly callType: CallType
  private readonly iceConfig: RTCConfiguration
  private readonly onStateChange: (state: CallLifecycleState) => unknown
  private readonly onFatalFailure: (reason: string) => void

  private reconnectAttempt = 0

  private static readonly RECONNECT_DELAYS = [100, 200, 300, 400, 500, 1000]
  private static readonly MAX_RECONNECT_ATTEMPTS: number = 7

  constructor (p: WebRTCBaseConstructorParams) {
    this.callJid = p.callJid
    this.callType = p.callType
    this.iceConfig = p.iceConfig
    this.onStateChange = p.onStateChange
    this.onFatalFailure = p.onFatalFailure

    this.signalling = new Signalling(this.callJid, this.callType)

    this.peerConnection = this.connect()
  }

  private readonly reconnect = async () => {
    webRTCLogger.log('Reconnecting...')
    this.reconnectAttempt++
    this.disconnect('attempting to reconnect')
    this.connect()
  }

  protected readonly connect = (): RTCPeerConnection => {
    webRTCLogger.debug('Connecting WebRTC')
    let pc: RTCPeerConnection
    try {
      pc = new RTCPeerConnection(this.iceConfig)
    } catch (e) {
      webRTCLogger.error('Error initialising peer connection', e)
      throw new Error(e)
    }

    this.addWebRTCListeners(pc)

    return pc
  }

  public readonly disconnect = (reason?: string): void => {
    webRTCLogger.debug(
      'Disconnecting WebRTC.\n',
      'Reason:', reason,
    )

    if (this.peerConnection) {
      webRTCLogger.debug('Closing peer connection...')
      this.peerConnection.close()
    }
  }

  private readonly addWebRTCListeners = (pc: RTCPeerConnection): void => {
    const log = webRTCLogger.debug

    pc.oniceconnectionstatechange = this.onIceConnectionStateChange
    pc.onicecandidate = this.onIceCandidate
    pc.onnegotiationneeded = this.onNegotiationNeeded
    pc.onconnectionstatechange = this.onConnectionStateChange
    pc.ondatachannel = ev => log('On data channel', ev)
    pc.onicecandidateerror = ev => log('On ice candidate error', ev)
    pc.onicegatheringstatechange = ev => log('On ice gathering state change', ev)
    pc.onsignalingstatechange = ev => log('signalingstatechange', ev)
  }

  private readonly onIceConnectionStateChange = (event: Event) => {
    webRTCLogger.log(
      'Handling ICE connection state changed:\n',
      'ICE connection state', this.peerConnection?.iceConnectionState, '\n',
      'Connection state', this.peerConnection?.connectionState,
    )
    webRTCLogger.debug('Original event:', event)
    if (!this.peerConnection) return

    const { iceConnectionState } = this.peerConnection

    switch (iceConnectionState) {
      case 'connected':
        this.onStateChange('CONNECTED')
        this.reconnectAttempt = 0
        break
      case 'disconnected':
        this.onStateChange('RECONNECTING')
        break
      case 'closed':
      case 'failed':
        this.tryToReconnect()
        break
    }
  }

  private readonly onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
    webRTCLogger.log('Handling ICE candidate...')

    const candidateObject = event.candidate
    if (!candidateObject) {
      webRTCLogger.debug(
        'No candidate object in ICE event. That\'s OK.\n',
        'This is probably last ICE candidate that was generated.\n',
        'Sending empty trickle...\n',
        'Event:', event,
      )
    }

    const { candidate, sdpMid, sdpMLineIndex } = candidateObject ?? {}
    this.signalling.trickle(
      candidate ?? '',
      sdpMid ?? undefined,
      sdpMLineIndex ?? undefined,
    )
  }

  private readonly onNegotiationNeeded = (ev: Event): void => {
    webRTCLogger.warn('Negotiation needed')
    webRTCLogger.debug('Event:', ev)
  }

  private readonly onConnectionStateChange = (ev: Event): void => {
    const pc = this.peerConnection
    webRTCLogger.debug(
      'Connection state change\n',
      'Connection state:', pc.connectionState, '\n',
      'Ice state:', pc.iceConnectionState,
      'Raw event:', ev,
    )
  }

  protected readonly tryToReconnect = () => {
    webRTCLogger.log(
      'Trying to reconnect...\n',
      'Reconnection attempt', this.reconnectAttempt,
    )

    this.onStateChange('RECONNECTING')

    /**
     * Do not attempt to reconnect more that a set number of times.
     *
     * Attempting to reconnect for an extended period of time may lead to
     * reconnecting to a call that had long finished. Which, in turn, leads to
     * starting a new call in the same chat, when everyone is already gone.
     *
     * This is ugly and has everything to do with current client-server
     * communication scheme. Client essentially has the same procedure for:
     * - starting a new call
     * - connecting to an existing call
     * - reconnecting to an existing call
     *
     * Currently client is not able to clearly state its connection intent to
     * the server. So there is no way for the server to know whether it's
     * a reconnection attempt or a client simply wanting to start a new call.
     */
    if (this.reconnectAttempt >= WebRTCBase.MAX_RECONNECT_ATTEMPTS) {
      const msg = 'Exceeded max reconnection attempts. Disconnecting.'
      webRTCLogger.warn(msg)
      this.onFatalFailure(msg)
      return
    }

    /**
     * Calculate the next reconnection delay to use.
     * Essentially a progressing throttle.
     */
    const delayNumber = Math.min(
      this.reconnectAttempt,
      WebRTCBase.RECONNECT_DELAYS.length - 1,
    )
    const delay = WebRTCBase.RECONNECT_DELAYS[delayNumber]

    webRTCLogger.debug('Using reconnection delay: ', delay, 'ms')
    setTimeout(this.reconnect, delay)
  }
}
