import State from '@/store/modules/tasks/state'
import { JID, TaskColor, TaskStatus } from '@tada-team/tdproto-ts'
import Vue from 'vue'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  /**
   * @param {string} payload UID
   */
  addImport (payload: string): void {
    this.state.imports.push(payload)
  }

  addTask ({ jid, task }: { jid: JID, task: Partial<TADA.Task> }): void {
    Vue.set(this.state.data, jid, { ...this.state.data[jid], ...task })
  }

  addTasks (payload: TADA.Task[]): void {
    this.state.data = {
      ...this.state.data,
      ...Object.fromEntries(payload.map(item => [item.jid, item])),
    }
  }

  /**
   * Adds a provided rule to store
   * @param {TADA.TasksColorsRule} payload - Rule to add
   */
  createColorsRule (payload: TADA.TasksColorsRule): void {
    this.state.colorsRules.push(payload)
  }

  /**
   * Deletes a rule with a given uid from store
   * @param {string} payload - Rule uid to delete
   */
  deleteColorsRule (payload: string): void {
    const { colorsRules } = this.state
    const index = colorsRules.findIndex(item => item.uid === payload)
    if (index > -1) colorsRules.splice(index, 1)
  }

  remove (jid: JID): void {
    Vue.delete(this.state.data, jid)
  }

  reset (): void {
    const clearState = new State()
    const keys = Object.keys(clearState) as Array<keyof State>

    keys.forEach(key => {
      (this.state[key] as State[typeof key]) = clearState[key]
    })
  }

  setCount (payload: number): void {
    this.state.count = payload
  }

  setStatuses (payload: TaskStatus[]): void {
    this.state.statuses = payload
  }

  /**
   * Takes tasks colors and completely replaces currently stored ones
   * @param {TaskColor[]} payload - Array of tasks colors
   */
  setupColors (payload: TaskColor[]): void {
    this.state.colors = payload
  }

  /**
   * Takes rules and completely replaces currently stored ones
   * @param {TADA.TasksColorsRule[]} payload - Array of rules to save
   */
  setupColorsRules (payload: TADA.TasksColorsRule[]): void {
    this.state.colorsRules = payload
  }

  /**
   * Takes tasks tabs and completely replaces currently stored ones
   * @param {TADA.TaskTab[]} payload - Array of tasks tabs
   */
  setupTabs (payload: TADA.TaskTab[]): void {
    this.state.tabs = Object.fromEntries(
      payload.map(item => [item.key, item]),
    )
  }

  /**
   * Updates a provided rule in store
   * @param {Partial<TADA.TasksColorsRule>} payload - Rule to update
   */
  updateColorsRule (payload: Partial<TADA.TasksColorsRule>): void {
    const { colorsRules } = this.state
    const index = colorsRules.findIndex(item => item.uid === payload.uid)
    colorsRules.splice(index, 1, { ...colorsRules[index], ...payload })
  }

  /**
   * Updates a provided rules in store
   * @param {Array<Partial<TADA.TasksColorsRule>>} payload - Rules to update
   */
  updateColorsRules (payload: Array<Partial<TADA.TasksColorsRule>>): void {
    const { colorsRules } = this.state
    payload.forEach(rule => {
      const index = colorsRules.findIndex(item => item.uid === rule.uid)
      colorsRules.splice(index, 1, { ...colorsRules[index], ...rule })
    })
  }
}
