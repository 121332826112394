import { contactsStore, tasksStore } from '@/store'

/**
 * Checks if a task was created based on a message to support.
 * @param {string} taskId Task/Chat jid to check.
 * @returns {boolean}
 */
export default (taskId: string): boolean => {
  const task: TADA.Task | undefined = tasksStore.state.data[taskId]
  if (!task) return false

  const owner = contactsStore.getters.contact(task.owner)
  if (!owner) return false

  return owner.botname === 'systembot'
}
