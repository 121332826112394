import Vue from 'vue'
import * as Vuex from 'vuex'
import { registerModule } from 'vuex-smart-module'

/**
 * regular vuex modules and plugins
 */
import getters from '@/store/getters'
import chats from '@/store/modules/chat'
import launch from '@/store/modules/launch'
import profile from '@/store/modules/profile'
import createPersistedState from '@/store/plugins/persistedState'
import tabAppearance from '@/store/plugins/tabAppearance'
import socket from '@/ws/store'

/**
 * vuex-smart-module modules
 */
import activeCallModule from '@/store/modules/activeCall'
import calendarModule from '@/store/modules/calendar'
import callsModule from '@/store/modules/calls'
import chatsBarModule from '@/store/modules/chatsBar'
import chatImportModule from '@/store/modules/chatImport'
import contactsModule from '@/store/modules/contacts'
import dataImportsModule from '@/store/modules/dataImports'
import draftsModule from '@/store/modules/drafts'
import groupsModule from '@/store/modules/groups'
import integrationsModule from '@/store/modules/integrations'
import invitableUsersModule from '@/store/modules/invitableUsers'
import loginModule from '@/store/modules/login'
import meetingsModule from '@/store/modules/meetings'
import nodesModule from '@/store/modules/nodes'
import publicGroupsModule from '@/store/modules/publicGroups'
import remindsModule from '@/store/modules/reminds'
import rootModule from '@/store/modules/root'
import sectionsModule from '@/store/modules/sections'
import tagsModule from '@/store/modules/tags'
import tasksModule from '@/store/modules/tasks'
import teamsModule from '@/store/modules/teams'
import uiModule from '@/store/modules/ui'
import uiSettingsModule from '@/store/modules/uiSettings'
import threadsModule from '@/store/modules/threads'
import billingModule from '@/store/modules/billing'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    socket,
    launch,
    profile,
    chats,
  },
  getters,
  plugins: [
    createPersistedState(20),
    tabAppearance,
  ],
})

const vuexSmartModuleRegister = (m, name) => {
  registerModule(
    store,
    name,
    `${name}/`,
    m,
  )
}

const vuexSmartModules = {
  activeCall: activeCallModule,
  calendar: calendarModule,
  calls: callsModule,
  chatsBar: chatsBarModule,
  chatImport: chatImportModule,
  contacts: contactsModule,
  dataImports: dataImportsModule,
  drafts: draftsModule,
  groups: groupsModule,
  integrations: integrationsModule,
  invitableUsers: invitableUsersModule,
  login: loginModule,
  meetings: meetingsModule,
  nodes: nodesModule,
  publicGroups: publicGroupsModule,
  reminds: remindsModule,
  root: rootModule,
  sections: sectionsModule,
  tags: tagsModule,
  tasks: tasksModule,
  teams: teamsModule,
  ui: uiModule,
  uiSettings: uiSettingsModule,
  threads: threadsModule,
  billing: billingModule,
}

Object.entries(vuexSmartModules).forEach(([name, m]) => {
  vuexSmartModuleRegister(m, name)
})

export const activeCallStore = activeCallModule.context(store)
export const calendarStore = calendarModule.context(store)
export const callsStore = callsModule.context(store)
export const chatsBarStore = chatsBarModule.context(store)
export const chatImportStore = chatImportModule.context(store)
export const contactsStore = contactsModule.context(store)
export const dataImportsStore = dataImportsModule.context(store)
export const draftsStore = draftsModule.context(store)
export const groupsStore = groupsModule.context(store)
export const threadsStore = threadsModule.context(store)
export const integrationsStore = integrationsModule.context(store)
export const invitableUsersStore = invitableUsersModule.context(store)
export const loginStore = loginModule.context(store)
export const meetingsStore = meetingsModule.context(store)
export const nodesStore = nodesModule.context(store)
export const publicGroupsStore = publicGroupsModule.context(store)
export const remindsStore = remindsModule.context(store)
export const rootStore = rootModule.context(store)
export const sectionsStore = sectionsModule.context(store)
export const tagsStore = tagsModule.context(store)
export const tasksStore = tasksModule.context(store)
export const teamsStore = teamsModule.context(store)
export const uiSettingsStore = uiSettingsModule.context(store)
export const uiStore = uiModule.context(store)
export const billingStore = billingModule.context(store)

export default store
