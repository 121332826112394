import { teamsStore } from '@/store'
import { JID } from '@tada-team/tdproto-ts'
import { Getters as BaseGetters } from 'vuex-smart-module'
import State from './state'
import {
  UIPayload,
  UIRightBarFiltersAndSort,
  UITaskDeskFilters,
} from './types'

export default class Getters extends BaseGetters<State> {
  get isRightBarFloated (): boolean {
    return this.state.rightBar.floated
  }

  get currentRightBarInstance (): string {
    return this.state.rightBar.secondaryInstance || this.state.rightBar.instance
  }

  get currentRightBarPayload (): Record<string, unknown> | null | string {
    return this.state.rightBar.secondaryPayload || this.state.rightBar.payload
  }

  get currentRightBarPrimaryInstance (): string {
    return this.state.rightBar.instance
  }

  get currentRightBarPrimaryPayload (): UIPayload {
    return this.state.rightBar.payload
  }

  get currentModal (): string {
    return this.state.modal.instance
  }

  get currentModalPayload (): UIPayload {
    return this.state.modal.payload
  }

  get modalResult (): string | null {
    return this.state.modal.result
  }

  get currentMiddleColumnInstance (): string {
    return this.state.middleColumn.instance
  }

  get currentMiddleColumnPayload (): UIPayload {
    return this.state.middleColumn.payload
  }

  get currentMiddleColumnSmall (): boolean {
    return this.state.middleColumn.small
  }

  get selectedMessagesIds (): JID[] {
    return this.state.selectedMessagesIds
  }

  get isTaskDeskOpen (): boolean {
    return this.state.taskDesk.active || false
  }

  get leftColumnHidden (): boolean {
    return this.state.taskDesk.leftColumnHidden
  }

  get currentDeskTabKey (): null | string {
    return this.state.taskDesk.tabKey
  }

  get currentDeskFilters (): UITaskDeskFilters {
    return this.state.taskDesk.filters
  }

  get currentDeskGroups () {
    return this.state.taskDesk.groups
  }

  get taskDeskCompactCards (): boolean {
    return this.state.taskDesk.compactCards
  }

  get showDirectChatsRelated (): boolean {
    return !teamsStore.getters.isSingleGroupTeam()
  }

  get showTaskDeskRelated (): boolean {
    if (!window.FEATURES.task_dashboard) return false
    return !teamsStore.getters.isSingleGroupTeam()
  }

  get showTasksRelated (): boolean {
    return !teamsStore.getters.isSingleGroupTeam()
  }

  get showContactsSectionsRelated (): boolean {
    return !teamsStore.getters.isSingleGroupTeam()
  }

  /**
   * @returns current filter params for right bar tasks tab
   */
  get tasksFiltersAndSortParams (): UIRightBarFiltersAndSort {
    return this.state.rightBar.filtersAndSort
  }
}
