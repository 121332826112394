import { downloadFileQuery } from '@/components/Chat/Instance/DOM/Components/Content/File'
import { Chat } from '@tada-team/tdproto-ts'
import Vue from 'vue'
import type {
  UISettingData,
  UISettings,
  UISettingsGlobal,
  UISettingsTaskGroupOrder,
  UISettingsTaskDeskTabSortingKey,
} from './types'

import type { ThemeName } from '@/themes'
import State from './state'
import { LogLevelDesc } from 'loglevel'
import { Mutations as BaseMutations } from 'vuex-smart-module'

export default class Mutations extends BaseMutations<State> {
  setChatSortingType ({ direct, value }: { direct: boolean, value: number }) { // SET_CHAT_SORTING_TYPE
    this.state.data.sortingChatsType[direct ? 'direct' : 'group'] = value || 0
  }

  /**
   * Completely replaces current ui-settings data
   * with provided params
   */
  setData (data: UISettings) { // UI_SETTINGS_SET_DATA
    this.state.data = data
      ? { ...data }
      : { ...this.state.defaultTeamSettings }
  }

  setupUISettingData (data: UISettingData) {
    this.state.uiSettingData = data
  }

  setUISettingData ({ id, data }: {
    id: string, data: Partial<UISettings>
  }) {
    Vue.set(this.state.uiSettingData, id, data)
  }

  setUISettingCurrentTeam (teamId: string) {
    this.state.uiSettingData.current_team = teamId
  }

  /**
   * Appends (and overwrites if needed) provided data
   * to already existing ui settings data
   */
  addData ({ uiSettings, language }: {
    uiSettings?: UISettings,
    language?: string
  }) { // UI_SETTINGS_ADD_DATA
    if (uiSettings) {
      this.state.data = { ...this.state.data, ...uiSettings }
    } else {
      // this should not be necessary at all really
      // but just in case fill missing settings (if any)
      // in state.data with default ones
      // just to make sure that all of them are in place and at least default
      this.state.data = { ...this.state.defaultTeamSettings, ...this.state.data }
    }
    if (language) {
      this.state.language = language
    }
  }

  setDesktopAutodownloadFiles (value: boolean) { // UI_SET_DEKSTOP_AUTODOWNLOAD_FILES
    this.state.data.desktopAutodownloadFiles = value
    value && downloadFileQuery()
  }

  setCurrentTheme (theme: ThemeName | '') { // SET_CURRENT_THEME
    this.state.theme = theme
    this.state.data.theme = theme
  }

  setPreviousTheme (theme: ThemeName | '') { // SET_PREVIOUS_THEME
    this.state.data.previousTheme = theme
  }

  setTriedNYThemes (triedThemes: (string | number)[]) { // SET_TRIED_NY_THEMES
    this.state.data.triedNYThemes = triedThemes
  }

  updateTaskDraft (draft: Partial<Chat> | null) { // UI_UPDATE_TASK_DRAFT
    this.state.data.taskDraft = draft
  }

  setLastOpenTasksTab (tabKey: string) { // UI_UPDATE_LAST_OPEN_TASKS_TAB
    this.state.data.lastOpenTasksTab = tabKey
  }

  setExperimentalTasksTabsDesign (value: boolean) { // UI_UPDATE_EXPERIMENTAL_TASKS_TABS_DESIGN
    this.state.data.experimentalTasksTabsDesign = value
  }

  setTaskDeskTabFilterKey (payload: keyof UISettingsTaskGroupOrder) { // UI_SETTINGS_SET_TASK_DESK_TAB_FILTER_KEY
    this.state.data.taskDesk.tabFilterKey = payload
  }

  setTaskDeskTabSortingKey (payload: UISettingsTaskDeskTabSortingKey) { // UI_SETTINGS_SET_TASK_DESK_TAB_SORTING_KEY
    this.state.data.taskDesk.tabSortingKey = payload
  }

  setGlobalSettings (payload: Partial<UISettingsGlobal>) { // UI_SET_GLOBAL_SETTINGS
    this.state.globalSettings = { ...this.state.globalSettings, ...payload }
  }

  setTaskDeskColumnOrder (payload: UISettingsTaskGroupOrder) { // UI_SET_TASK_DESK_COLUMN_ORDER
    this.state.data.taskDesk.groupsOrder = payload
  }

  setDebuglevel (payload: LogLevelDesc) { // UI_SETTINGS_SET_DEBUGLEVEL
    this.state.data.debugLevel = payload
  }

  setReordableNotification (shown = true) { // UI_TASK_DESK_SET_REORDABLE_NOTIFICATION
    this.state.data.taskDesk.reordableNotificationShown = shown
  }
}
