import i18n from '@/i18n'
import { compareDates, hasCyrillic } from '@/utils'
import { Chat } from '@tada-team/tdproto-ts'

const commonSort = (a: Chat, b: Chat, unreadFirst?: boolean): -1 | 0 | 1 => {
  if (a.pinnedSortOrdering !== b.pinnedSortOrdering) {
    if (a.pinnedSortOrdering && b.pinnedSortOrdering) {
      return a.pinnedSortOrdering > b.pinnedSortOrdering ? 1 : -1
    }
    return a.pinnedSortOrdering ? -1 : 1
  }

  if (unreadFirst && !!a.numUnread !== !!b.numUnread) {
    return a.numUnread ? -1 : 1
  }

  return 0
}

export const byActivity = (a: Chat, b: Chat, unreadFirst?: boolean) => {
  const byPinned = commonSort(a, b, unreadFirst)
  if (byPinned !== 0) return byPinned

  if (a.lastActivity !== b.lastActivity) {
    if (a.lastActivity && b.lastActivity) {
      return compareDates(b.lastActivity, a.lastActivity)
    }
    return a.lastActivity ? -1 : 1
  }

  return 0
}

export const byName = (a: Chat, b: Chat, unreadFirst?: boolean) => {
  const byPinned = commonSort(a, b, unreadFirst)
  if (byPinned !== 0) return byPinned

  if (i18n.locale === 'ru') {
    const aHasCyrillic = hasCyrillic(a.displayName)
    const bHasCyrillic = hasCyrillic(b.displayName)
    if (aHasCyrillic !== bHasCyrillic) return aHasCyrillic ? -1 : 1
  }

  return a.displayName.localeCompare(b.displayName) as -1 | 0 | 1
}
