import { makeXHRequest } from '@/api/v3/xhr'
import { DataAdapter } from '@/api/v3/DataAdapter'

export default class {
  constructor ({ url, parameters, limit, adapter }) {
    if (!url) return

    this.offset = -1
    this.count = 0

    this.adapter = adapter

    this.xhr = null
    this.url = url

    this.parameters = parameters || {}
    this.limit = limit || 100
  }

  makeRequest (url) {
    return new Promise((resolve, reject) => {
      this.xhr = makeXHRequest({ url, resolve, reject, method: 'GET' })
    })
  }

  cancel () {
    this.xhr && this.xhr.abort()
  }

  hasMore () {
    return this.offset < this.count
  }

  async next () {
    if (!this.hasMore()) return

    let url = this.url + `?limit=${this.limit}&offset=${Math.max(this.offset, 0)}`
    Object.keys(this.parameters).forEach(key => {
      const value = this.parameters[key]
      if (!value) return
      url += `&${key}=${encodeURIComponent(value)}`
    })
    const { count, limit, objects, offset } = await this.makeRequest(url)

    this.count = count
    this.offset = offset + limit
    this.limit = limit

    if (offset >= count) return

    return this.adapter ? objects.map(object => DataAdapter[this.adapter](object)) : objects
  }

  amountPages () {
    return Math.ceil(this.count / this.limit)
  }

  currentPage () {
    return Math.min(Math.round(this.offset / this.limit) + 1, this.amountPages())
  }
}
