import Vue, { DirectiveOptions } from 'vue'

import ResizableInstance, { Options } from './ResizableInstance'
import * as Utils from './Utils'

export const ctx = '@@flex-resize'

export default {
  bind (e: any, b) {
    let { value, modifiers } = b
    modifiers = modifiers || {}
    value = value || {}

    const {
      key, minSize, maxSize, anchorThickness, preventReflow,
      defaultSize, anchorClass, isColumnFlexDirection, startEdge,
      onReady, onResizeStarted, onResizeStopped, onSizeChanged, onSizeChanging, onDestroyed,
      inertiaOffset, disabled, zIndex,
    } = value

    const size = Utils.toNumber(defaultSize)
    const isDefaultSizeUnknown = isNaN(size)
    if (isDefaultSizeUnknown && preventReflow) {
      throw new Error(`[${ctx}.bind] "preventReflow" is true, but "defaultSize" is not specified`)
    }

    const options = {
      isDisabled: disabled || false,

      key: key || e.getAttribute('id'),
      minSize: minSize || 0,
      maxSize: maxSize || Infinity,
      preventReflow: preventReflow || modifiers.preventReflow || false,
      anchorThickness: anchorThickness || 6,

      isColumnFlexDirection: isColumnFlexDirection || modifiers.vertical,
      startEdge: startEdge || modifiers.start,
      anchorClass,
      anchorZIndex: isNaN(zIndex) ? 10 : zIndex,
      defaultSize: isDefaultSizeUnknown ? undefined : size,
      inertiaOffset: isNaN(inertiaOffset) ? 50 : inertiaOffset,

      onReady,
      onResizeStarted,
      onResizeStopped,
      onSizeChanged,
      onSizeChanging,
      onDestroyed,
    } as Options

    if (!options.key) {
      throw new Error(`[${ctx}.bind] key is null/undefined`)
    }

    e[ctx] = new ResizableInstance(e, options)
  },
  inserted (e: any) {
    const context = e[ctx] as ResizableInstance | null
    if (!context) { return }

    context.prepare()
  },
  update (e: any, b) {
    const context = e[ctx] as ResizableInstance | null
    if (!context) { return }

    const { isDisabled } = context.options
    const disabled = (b.value || {}).disabled || false

    if (isDisabled !== disabled) { context.toggleActiveState(disabled) }
  },
  componentUpdated (e: any) {
    const context = e[ctx] as ResizableInstance | null
    if (!context) { return }

    Vue.nextTick(context.update)
  },
  unbind (e: any) {
    const context = e[ctx] as ResizableInstance | null
    if (!context) { return }

    context.flush()

    delete e[ctx]
  },
} as DirectiveOptions
